import React, { Component } from 'react';
import email from "../assets/images/email.png";
import globalsettingswhite from "../assets/images/global-settings-white.png";
import line from "../assets/images/line.svg";
import logo from "../assets/images/logo.png";
import { Redirect, Link } from 'react-router-dom';
import "../styles/Styles.css";
import { connect } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import BuildIcon from '@mui/icons-material/Build';
import trendwhite from "../assets/images/trendwhite.png";
import customer from "../assets/images/customer.png";
import statisticssettings12 from "../assets/images/statistics-settings12.png";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { userDetails } from '../actions/ManagerActions';


let l111 = ''

let visible = false
class LeftNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectpath: '',
            userData: null
        }

    }

    handleLogOut = async () => {
        const data = {
            accesstoken: this.props.data && this.props.data.accesstoken,
            idtoken: this.props.data && this.props.data.idtoken,
            refreshtoken: this.props.data && this.props.data.refreshtoken
        }
        axios({
            method: "POST",
            url: `https://61aonvy5u7.execute-api.us-east-1.amazonaws.com/dev/auth/signout`,
            crossDomain: true,
            data
        })
            .then(res => {
                console.log("hi logout linkedin")
            })


        try {
            await Auth.signOut({ global: true });
            await localStorage.removeItem('userdetails');
            await localStorage.removeItem('usersinfo')
            this.setState({ redirectpath: '/' })
        } catch (err) {
            console.log(err, 'error signing out...');
        }
    };

    async componentDidMount() {
        this.props.userDetails()
        Auth.currentSession().then((session) => {
            l111 = session.getAccessToken().getJwtToken();
        }, (err) => {
            console.log(err)
        })

        // if (this.props.data && this.props.data.showBox === true) {
        //     visible = true
        // }
    }

    componentDidUpdate(nextProps) {

        if (
            this.props.managerReducer &&
            this.props.managerReducer.arcMan &&
            this.props.managerReducer.arcMan !==
            nextProps.managerReducer.arcMan &&
            this.props.managerReducer.arcManSuccess === true
        ) {
            console.log(this.props.managerReducer.arcMan, "leftnav")
            this.setState({ userData: this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes })
        }
    }

    //launch tool
    TitleMatchTool = async () => {
        // dev
        window.open(`http://title-match.com.s3-website-us-east-1.amazonaws.com/?profile=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.profile}
        &email=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.email} &user_id=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.sub}`)
        //demo
        // window.open(`http://demo-ui-titlematch.s3-website-us-east-1.amazonaws.com/?profile=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.profile}&email=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.email}&user_id=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.sub}`)

        //UAT
        // window.open(`http://uititlematch.s3-website-us-east-1.amazonaws.com/?profile=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.profile}&email=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.email}&user_id=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.sub}`)
    }

    ListTool = async () => {
        //dev
        window.open(`https://listool.digitivy.com/?profile=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.profile}&email=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.email}&user_id=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.sub}`)
        //demo
        // window.open(`https://arcmanuat.solpub.com/demolisttool/?profile=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.profile}&email=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.email}&user_id=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.sub}`)

        //UAT
        // window.open(`https://arcmanuat.solpub.com/listtool/?profile=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.profile}&email=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.email}&user_id=${this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes.sub}`)
    }

    DataShaper = () => {
        window.open("/createrecipe")
    }

    LinkedInLookups = () => {
        window.open("http://lilut.arcman.solpub.com/")
    }

    netLine = () => {
        window.open("/step1")
        // this.setState({redirectpath: "/step1"})
    }



    VisibleData = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: "Are you sure you want to delete selected info? Press 'Yes' to continue.",
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ''
                },
                {
                    label: 'Cancel',
                    onClick: () => ''
                }
            ]
        });
    }



    render() {

        // console.log(this.props.data && this.props.data.showBox, "boxxxxxxxx")
        const { redirectpath } = this.state;
        // if (this.props.data && this.props.data.showBox) {
        //     visible = true
        // }
        return (
            <div>
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div
                    className="row"
                    style={{ padding: "27px 0" }}
                >
                    <img
                        src={logo}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />
                    <img
                        src={line}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />

                </div>

                <ul

                    className="nav row">
                    <li
                        // onClick={visible === true ? this.VisibleData : null}
                        id={window.location.pathname === "/acustomers" || window.location.pathname === "/customersetup" ? 'selectednav' : ''}

                    >

                        <Link
                            to={visible === true ? this.VisibleData : "/acustomers"}
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={customer}
                                    style={{ height: "24px", width: "24px" }}
                                    alt="Customer Setup"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}> Customer Setup</span>
                        </Link>
                    </li>
                    <li
                        id={window.location.pathname === '/campaignsetuplist' ? 'selectednav' : ''}
                    >
                        <Link
                            to="/campaignsetuplist"
                            style={{
                                color: "#ffffff",
                                fontSize: "16px",
                                lineHeight: "24px",
                                display: "inline-flex",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={globalsettingswhite}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="dashboard"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>  Campaign Setup</span>
                        </Link>
                    </li>
                    <li
                        id={window.location.pathname === '/campaignslist' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/campaignslist"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={email}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="Pending Campaigns"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>  Pending Campaigns</span>
                        </Link>
                    </li>
                    <li className="navarrow">
                        <span style={{ marginRight: "15px", display: "inline-flex" }}>
                            < BuildIcon
                                style={{ height: '20px', alignSelf: 'center' }}
                            />
                        </span>
                        <span
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "30px",
                                letterSpacing: "2px"
                            }}
                        >
                            Tools
                        </span>
                        <ul>
                            {/* <li>
                                <span
                                    onClick={this.netLine}
                                    style={{
                                        color: "#ffffff",
                                        display: "inline-flex",
                                        fontSize: "16px",
                                        lineHeight: "30px",
                                        letterSpacing: "2px",
                                        textDecoration: "none"
                                    }}
                                >
                                    Netline
                                </span>
                            </li> */}
                            <li
                                onClick={this.ListTool}
                            >
                                <span
                                    style={{
                                        color: "#ffffff",
                                        display: "inline-flex",
                                        fontSize: "16px",
                                        lineHeight: "30px",
                                        letterSpacing: "2px",
                                        textDecoration: "none"
                                    }}
                                >
                                    List Tool
                                </span>
                            </li>
                            <li
                                onClick={this.TitleMatchTool}

                            >
                                <span
                                    style={{
                                        color: "#ffffff",
                                        display: "inline-flex",
                                        fontSize: "16px",
                                        lineHeight: "30px",
                                        letterSpacing: "2px",
                                        textDecoration: "none"
                                    }}
                                >
                                    Title Match
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={this.DataShaper}
                                    style={{
                                        color: "#ffffff",
                                        display: "inline-flex",
                                        fontSize: "16px",
                                        lineHeight: "30px",
                                        letterSpacing: "2px",
                                        textDecoration: "none"
                                    }}
                                >
                                    Lead Shaper
                                </span>
                            </li>

                            <li
                                onClick={this.LinkedInLookups}

                            >
                                <span
                                    style={{
                                        color: "#ffffff",
                                        display: "inline-flex",
                                        fontSize: "16px",
                                        lineHeight: "30px",
                                        letterSpacing: "2px",
                                        textDecoration: "none"
                                    }}
                                >
                                    LinkedIn Lookups
                                </span>
                            </li>


                        </ul>
                    </li>

                    <li
                        id={window.location.pathname === '/userstates' ? 'selectednav' : ''}
                    >
                        <Link
                            to="/userstates"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={trendwhite}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="User Stats"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}> User Stats</span>
                        </Link>
                    </li>

                    <li
                        id={window.location.pathname === '/toolstates' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/toolstates"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={statisticssettings12}
                                    style={{ height: "20px", width: "20px" }}
                                    alt=" Tool Stats"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>  Tool Stats</span>
                        </Link>
                    </li>

                    <li
                        onClick={this.handleLogOut}
                    >
                        <span style={{ marginRight: "15px", display: "inline-flex" }}>
                            <LogoutIcon
                                style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                            />
                            {/* <img
                                // src={toolstate}
                                style={{ height: "24px", width: "24px" }}
                            // alt=" Tool Stats"
                            /> */}
                        </span>
                        <span
                            style={{ alignSelf: 'center' }}>  Log out</span>
                    </li>
                </ul>
            </div >
        )
    }
}


function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        userDetails: userinfo => dispatch(userDetails(userinfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeftNavigation);


