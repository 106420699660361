import React, { Component } from "react";
import "../../styles/Styles.css";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from "../../components/Header";
import { BarChart, Bar, LineChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { toolList, userStates, productivityData } from '../../actions/Campaignactions';
import { launchtool, toolWaiting } from '../../actions/ManagerActions';
import LeftNavigation from "../../components/LeftNavigation";
import Dropdown from 'react-dropdown';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';




const data = [
    {
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
];


class Userstates extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            showBox: false,
            campaign: false,
            userstate: false,
            toolstate: false,
            campaignList: [],
            userStates: [],
            tool_list: [],
            launchList: [],
            productivitydata1: [],
            tabvalue: 'one'
        };
    }


    _onSelectMail = (dateList) => {
        this.setState({ dateList }, () =>
            console.log(`Option selected:`, this.state.dateList)
        );
    };


    handleChange = (event, newValue) => {
        this.setState({ tabvalue: newValue })
        const uinfo1 = {
            tool_id: this.state.filtersdata != null && this.state.filtersdata != undefined && this.state.filtersdata.value,
            dateinfo: newValue
        }
        this.props.productivityData(uinfo1)

        // setValue(newValue);
    };

    _onFilter = (filtersdata) => {
        const uinfo = {
            tool_id: filtersdata.value
        }
        const uinfo1 = {
            tool_id: filtersdata.value,
            dateinfo: this.state.tabvalue
        }
        this.props.launchtool(uinfo)
        this.props.productivityData(uinfo1)
        this.setState({ filtersdata });
    }

    componentDidMount() {
        this.props.userStates()
        this.props.toollist();

    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.userStatus &&
            this.props.campaignReducer.userStatus !==
            nextProps.campaignReducer.userStatus &&
            this.props.campaignReducer.userStatusSuccess === true
        ) {

            this.setState({ userStates: this.props.campaignReducer.userStatus[0] })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.toolList &&
            this.props.campaignReducer.toolList !==
            nextProps.campaignReducer.toolList &&
            this.props.campaignReducer.toolListSuccess === true
        ) {
            const userList = [];
            this.props.campaignReducer.toolList && this.props.campaignReducer.toolList.map((item, index) => {
                if (item.tool_name) {
                    userList.push({
                        value: item.tool_id,
                        label: item.tool_name
                    });
                }
                return true;
            })
            this.setState({ tool_list: userList })
        }


        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.productivitydata &&
            this.props.campaignReducer.productivitydata !==
            nextProps.campaignReducer.productivitydata &&
            this.props.campaignReducer.productivitydataSuccess === true
        ) {
            console.log(this.props.campaignReducer.productivitydata, "pdataassss")
            this.setState({ productivitydata1: this.props.campaignReducer.productivitydata })

        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.laucnTool &&
            this.props.managerReducer.laucnTool !==
            nextProps.managerReducer.laucnTool &&
            this.props.managerReducer.laucnToolSuccess === true
        ) {
            this.setState({ launchList: this.props.managerReducer.laucnTool })

        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.toolwaiting &&
            this.props.managerReducer.toolwaiting !==
            nextProps.managerReducer.toolwaiting &&
            this.props.managerReducer.toolwaitingSuccess === true
        ) {
            this.setState({ hoursList: this.props.managerReducer.toolwaiting })
        }


    }

    render() {
        const { redirectpath, userStates, tool_list, launchList, productivitydata1 } = this.state;
        // console.log(launchList, "toollll")
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock1">
                        <LeftNavigation />
                    </div>
                    <div
                        className="col-md-10 header"
                    >
                        <Header />


                        <div className="row filterdiv">
                            <div className="col-md-9 col-sm-12 col-12 mb-3 mt-4">

                            </div>
                            <div className="col-md-3 col-sm-12 col-12 mb-3 mt-4">
                                <Dropdown
                                    options={tool_list}
                                    onChange={this._onFilter}
                                    className='myClassName'
                                    placeholder="Select..." />
                            </div>
                        </div>
                        <div className="mt-3 maindiv1">
                            <div className="mt-2 pl-0 pr-0 pt-5 pb-5 listcontainer2">
                                <div style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                                    <span className="strokelinetext1">Average time spent on each tool</span>
                                </div>
                                <ResponsiveContainer>
                                    <LineChart
                                        width={900}
                                        height={350}
                                        data={launchList}
                                    >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis
                                            style={{
                                                fontSize: '11px',
                                                color: 'rgba(0, 0, 0, 0.4)',
                                                fontFamily: 'Montserrat',
                                            }}
                                            padding={{ left: 30, right: 30 }} dataKey="noofdays" axisLine={false} tickLine={false} interval="preserveStartEnd" />
                                        <YAxis
                                            style={{
                                                fontSize: '11px',
                                                color: 'rgba(0, 0, 0, 0.4)',
                                                fontFamily: 'Montserrat',
                                            }}
                                            orientation='right' axisLine={false} tickLine={false} interval="preserveStartEnd" />
                                        {/* <Legend /> */}
                                        <Line type="monotone" dot={false} dataKey="time_taken" stroke="#9191B7" />
                                        {/* <Line type="monotone" dot={false} dataKey="uv" stroke="#82ca9d" /> */}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>


                        <div
                            className="maindiv3"
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <div className="mt-2 pl-0 pr-0 pt-5 pb-5 linecontainer1">
                                <div style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                                    <span className="strokelinetext1">No. of campaigns built</span>
                                </div>

                                <ResponsiveContainer
                                    width={'100%'} height={300}

                                >
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={userStates}
                                        style={{ padding: '10px' }}
                                    >
                                        <XAxis
                                            style={{
                                                fontSize: '11px',
                                                color: 'rgba(0, 0, 0, 0.4)',
                                                fontFamily: 'Montserrat',
                                            }}
                                            dataKey="noofdays"
                                            axisLine={false} tickLine={false} interval="preserveStartEnd" />
                                        <YAxis
                                            style={{
                                                fontSize: '11px',
                                                color: 'rgba(0, 0, 0, 0.4)',
                                                fontFamily: 'Montserrat',
                                                paddingBottom: '15px',
                                                marginBottom: '15px'
                                            }}

                                            orientation='right' axisLine={false} tickLine={false} interval="preserveStartEnd" />
                                        <Line type="monotone" dot={false} dataKey="count1111" stroke="#9191B7" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="mt-2 pl-0 pr-0 pt-5 pb-5 linecontainer12">
                                <div style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                                    <div className="row">
                                        <TabContext value={this.state.tabvalue}>
                                            {/* <div className="col-md-2">
                                                <span className="strokelinetext2">Productivity</span>
                                            </div> */}
                                            <div
                                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                                            >
                                                <span className="strokelinetext2">Productivity - Activity Time</span>
                                                <Box sx={{ borderBottom: 1, borderColor: '#FFFFFF' }}>
                                                    <TabList
                                                        onChange={this.handleChange} aria-label="lab API tabs example">
                                                        <Tab label="This Week" value='one' />
                                                        <Tab label="This Month" value='two' />
                                                    </TabList>
                                                </Box>

                                            </div>
                                        </TabContext>
                                        {/* <div className="col-md-2">
                                            <span className="strokelinetext3">This Week</span>
                                        </div>
                                        <div className="col-md-2">
                                            <span className="strokelinetext3">This Month</span>
                                        </div> */}
                                    </div>
                                </div>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={900}
                                        height={400}
                                        data={productivitydata1}
                                    >
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="10%" stopColor="#9191B7" stopOpacity={1} />
                                                <stop offset="90%" stopColor="#484870" stopOpacity={1} />
                                            </linearGradient>

                                        </defs>
                                        <Bar
                                            dataKey={this.state.tabvalue === 'one' ? "step_completed" : this.state.tabvalue === 'two' ? "steps_completed" : ''}
                                            barSize={25} fill="url(#colorUv)" />
                                        <XAxis
                                            style={{
                                                fontSize: '11px',
                                                color: 'rgba(0, 0, 0, 0.4)',
                                                fontFamily: 'Montserrat',
                                            }}
                                            dataKey={this.state.tabvalue === 'one' ? "dayName" : this.state.tabvalue === 'two' ? "month_name" : ''}
                                            interval='preserveStartEnd' axisLine={false} tickLine={false} />
                                        <YAxis
                                            style={{
                                                fontSize: '11px',
                                                color: 'rgba(0, 0, 0, 0.4)',
                                                fontFamily: 'Montserrat',
                                            }}
                                            orientation='right'
                                            interval='preserveStartEnd'
                                            // dataKey="students"
                                            axisLine={false} tickLine={false} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}
function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer

    };
}
function mapDispatchToProps(dispatch) {
    return {
        userStates: userinfo => dispatch(userStates(userinfo)),
        toollist: userinfo => dispatch(toolList(userinfo)),
        launchtool: userinfo => dispatch(launchtool(userinfo)),
        toolwaiting: userinfo => dispatch(toolWaiting(userinfo)),
        productivityData: userinfo => dispatch(productivityData(userinfo))
    };
}



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Userstates);

