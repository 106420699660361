export const CAMPAIGN_LIST_LOADING = "CAMPAIGN_LIST_LOADING";
export const CAMPAIGN_LIST_SUCCESS = "CAMPAIGN_LIST_SUCCESS";
export const CAMPAIGN_LIST_FAILURE = "CAMPAIGN_LIST_FAILURE";

export const TOOL_LIST_LOADING = "TOOL_LIST_LOADING";
export const TOOL_LIST_SUCCESS = "TOOL_LIST_SUCCESS";
export const TOOL_LIST_FAILURE = "TOOL_LIST_FAILURE";

export const TOOL_SWAP_LIST_LOADING = "TOOL_SWAP_LIST_LOADING";
export const TOOL_SWAP_LIST_SUCCESS = "TOOL_SWAP_LIST_SUCCESS";
export const TOOL_SWAP_LIST_FAILURE = "TOOL_SWAP_LIST_FAILURE";

export const CAMPAIGN_UPDATE_LOADING = "CAMPAIGN_UPDATE_LOADING";
export const CAMPAIGN_UPDATE_SUCCESS = "CAMPAIGN_UPDATE_SUCCESS";
export const CAMPAIGN_UPDATE_FAILURE = "CAMPAIGN_UPDATE_FAILURE";

export const CAMPAIGNSETUP_INSERT_LOADING = "CAMPAIGNSETUP_INSERT_LOADING";
export const CAMPAIGNSETUP_INSERT_SUCCESS = "CAMPAIGNSETUP_INSERT_SUCCESS";
export const CAMPAIGNSETUP_INSERT_FAILURE = "CAMPAIGNSETUP_INSERT_FAILURE";

export const USER_LIST_LOADING = "USER_LIST_LOADING";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const TOOLCAMPAIGN_INSERT_LOADING = "TOOLCAMPAIGN_INSERT_LOADING";
export const TOOLCAMPAIGN_INSERT_SUCCESS = "TOOLCAMPAIGN_INSERT_SUCCESS";
export const TOOLCAMPAIGN_INSERT_FAILURE = "TOOLCAMPAIGN_INSERT_FAILURE";

// <-------Customersetup Insert------->
export const CUSTOMERSETUP_INSERT_LOADING = "CUSTOMERSETUP_INSERT_LOADING";
export const CUSTOMERSETUP_INSERT_SUCCESS = "CUSTOMERSETUP_INSERT_SUCCESS";
export const CUSTOMERSETUP_INSERT_FAILURE = "CUSTOMERSETUP_INSERT_FAILURE";

//ManagerActions

export const OPENCAMPAIGN_LIST_LOADING = "OPENCAMPAIGN_LIST_LOADING";
export const OPENCAMPAIGN_LIST_SUCCESS = "OPENCAMPAIGN_LIST_SUCCESS";
export const OPENCAMPAIGN_LIST_FAILURE = "OPENCAMPAIGN_LIST_FAILURE";

export const MYWORKSTACK_LIST_LOADING = "MYWORKSTACK_LIST_LOADING";
export const MYWORKSTACK_LIST_SUCCESS = "MYWORKSTACK_LIST_SUCCESS";
export const MYWORKSTACK_LIST_FAILURE = "MYWORKSTACK_LIST_FAILURE";


export const HOT_MOVE_LOADING = "HOT_MOVE_LOADING";
export const HOT_MOVE_SUCCESS = "HOT_MOVE_SUCCESS";
export const HOT_MOVE_FAILURE = "HOT_MOVE_FAILURE";

export const WHAT_IS_HOT_LIST_LOADING = "WHAT_IS_HOT_LIST_LOADING";
export const WHAT_IS_HOT_LIST_SUCCESS = "WHAT_IS_HOT_LIST_SUCCESS";
export const WHAT_IS_HOT_LIST_FAILURE = "WHAT_IS_HOT_LIST_FAILURE";

export const MANAGERWORKSTACK_LIST_LOADING = "MANAGERWORKSTACK_LIST_LOADING";
export const MANAGERWORKSTACK_LIST_SUCCESS = "MANAGERWORKSTACK_LIST_SUCCESS";
export const MANAGERWORKSTACK_LIST_FAILURE = "MANAGERWORKSTACK_LIST_FAILURE";

export const READYTOBILL_LIST_LOADING = "READYTOBILL_LIST_LOADING";
export const READYTOBILL_LIST_SUCCESS = "READYTOBILL_LIST_SUCCESS";
export const READYTOBILL_LIST_FAILURE = "READYTOBILL_LIST_FAILURE";


export const ARCMAN_LOGIN_LOADING = "ARCMAN_LOGIN_LOADING";
export const ARCMAN_LOGIN_SUCCESS = "ARCMAN_LOGIN_SUCCESS";
export const ARCMAN_LOGIN_FAILURE = "ARCMAN_LOGIN_FAILURE";

export const MANAGER_NOTES_LOADING = "MANAGER_NOTES_LOADING";
export const MANAGER_NOTES_SUCCESS = "MANAGER_NOTES_SUCCESS";
export const MANAGER_NOTES_FAILURE = "MANAGER_NOTES_FAILURE";

export const CUSTOMER_PREFILLDATA_LOADING = "CUSTOMER_PREFILLDATA_LOADING";
export const CUSTOMER_PREFILLDATA_SUCCESS = "CUSTOMER_PREFILLDATA_SUCCESS";
export const CUSTOMER_PREFILLDATA_FAILURE = "CUSTOMER_PREFILLDATA_FAILURE";


export const CUSTOMER_LASTRECORDS_LOADING = "CUSTOMER_LASTRECORDS_LOADING";
export const CUSTOMER_LASTRECORDS_SUCCESS = "CUSTOMER_LASTRECORDS_SUCCESS";
export const CUSTOMER_LASTRECORDS_FAILURE = "CUSTOMER_LASTRECORDS_FAILURE";


export const COUNTRY_LIST_LOADING = "COUNTRY_LIST_LOADING";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_FAILURE = "COUNTRY_LIST_FAILURE";


export const STATE_LIST_LOADING = "STATE_LIST_LOADING";
export const STATE_LIST_SUCCESS = "STATE_LIST_SUCCESS";
export const STATE_LIST_FAILURE = "STATE_LIST_FAILURE";

export const NOTIFICATION_LIST_LOADING = "NOTIFICATION_LIST_LOADING";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAILURE = "NOTIFICATION_LIST_FAILURE";

export const SES_NOTIFICATION_LOADING = "SES_NOTIFICATION_LOADING";
export const SES_NOTIFICATION_SUCCESS = "SES_NOTIFICATION_SUCCESS";
export const SES_NOTIFICATION_FAILURE = "SES_NOTIFICATION_FAILURE";

export const NOTES_INSERT_LOADING = "NOTES_INSERT_LOADING";
export const NOTES_INSERT_SUCCESS = "NOTES_INSERT_SUCCESS";
export const NOTES_INSERT_FAILURE = "NOTES_INSERT_FAILURE";

export const INPUT_FILE_LOADING = "INPUT_FILE_LOADING";
export const INPUT_FILE_SUCCESS = "INPUT_FILE_SUCCESS";
export const INPUT_FILE_FAILURE = "INPUT_FILE_FAILURE";

export const LAUNCH_TOOL_LOADING = "LAUNCH_TOOL_LOADING";
export const LAUNCH_TOOL_SUCCESS = "LAUNCH_TOOL_SUCCESS";
export const LAUNCH_TOOL_FAILURE = "LAUNCH_TOOL_FAILURE";

export const CAMPAIGN_NAMELIST_LOADING = "CAMPAIGN_NAMELIST_LOADING";
export const CAMPAIGN_NAMELIST_SUCCESS = "CAMPAIGN_NAMELIST_SUCCESS";
export const CAMPAIGN_NAMELIST_FAILURE = "CAMPAIGN_NAMELIST_FAILURE";


export const TOOL_WAITINGTIME_LOADING = "TOOL_WAITINGTIME_LOADING";
export const TOOL_WAITINGTIME_SUCCESS = "TOOL_WAITINGTIME_SUCCESS";
export const TOOL_WAITINGTIME_FAILURE = "TOOL_WAITINGTIME_FAILURE";

export const USER_STATES_LOADING = "USER_STATES_LOADING";
export const USER_STATES_SUCCESS = "USER_STATES_SUCCESS";
export const USER_STATES_FAILURE = "USER_STATES_FAILURE";

export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const PROFILE_DATA_LOADING = "PROFILE_DATA_LOADING";
export const PROFILE_DATA_SUCCESS = "PROFILE_DATA_SUCCESS";
export const PROFILE_DATA_FAILURE = "PROFILE_DATA_FAILURE";

export const LINKEDIN_LIST_LOADING = "LINKEDIN_LIST_LOADING";
export const LINKEDIN_LIST_SUCCESS = "LINKEDIN_LIST_SUCCESS";
export const LINKEDIN_LIST_FAILURE = "LINKEDIN_LIST_FAILURE";

export const BARGRAPH_DATA_LOADING = "BARGRAPH_DATA_LOADING";
export const BARGRAPH_DATA_SUCCESS = "BARGRAPH_DATA_SUCCESS";
export const BARGRAPH_DATA_FAILURE = "BARGRAPH_DATA_FAILURE";

export const PRODUCTIVITY_DATA_LOADING = "PRODUCTIVITY_DATA_LOADING";
export const PRODUCTIVITY_DATA_SUCCESS = "PRODUCTIVITY_DATA_SUCCESS";
export const PRODUCTIVITY_DATA_FAILURE = "PRODUCTIVITY_DATA_FAILURE";


export const DATASHAPER_LIST_LOADING = "DATASHAPER_LIST_LOADING";
export const DATASHAPER_LIST_SUCCESS = "DATASHAPER_LIST_SUCCESS";
export const DATASHAPER_LIST_FAILURE = "DATASHAPER_LIST_FAILURE";

export const DATASETS_LIST_LOADING = "DATASETS_LIST_LOADING";
export const DATASETS_LIST_SUCCESS = "DATASETS_LIST_SUCCESS";
export const DATASETS_LIST_FAILURE = "DATASETS_LIST_FAILURE";

export const NETLINE_INSERT_LOADING = "NETLINE_INSERT_LOADING";
export const NETLINE_INSERT_SUCCESS = "NETLINE_INSERT_SUCCESS";
export const NETLINE_INSERT_FAILURE = "NETLINE_INSERT_FAILURE";

export const NETLINE_LIST_LOADING = "NETLINE_LIST_LOADING";
export const NETLINE_LIST_SUCCESS = "NETLINE_LIST_SUCCESS";
export const NETLINE_LIST_FAILURE = "NETLINE_LIST_FAILURE";

export const NETLINE_INPUT_INSERT_LOADING = "NETLINE_INPUT_INSERT_LOADING";
export const NETLINE_INPUT_INSERT_SUCCESS = "NETLINE_INPUT_INSERT_SUCCESS";
export const NETLINE_INPUT_INSERT_FAILURE = "NETLINE_INPUT_INSERT_FAILURE";

export const CAMPAIGN_DELETE_LOADING = "CAMPAIGN_DELETE_LOADING";
export const CAMPAIGN_DELETE_SUCCESS = "CAMPAIGN_DELETE_SUCCESS";
export const CAMPAIGN_DELETE_FAILURE = "CAMPAIGN_DELETE_FAILURE";

export const CUSTOMERS_LIST_LOADING = "CUSTOMERS_LIST_LOADING";
export const CUSTOMERS_LIST_SUCCESS = "CUSTOMERS_LIST_SUCCESS";
export const CUSTOMERS_LIST_FAILURE = "CUSTOMERS_LIST_FAILURE";

export const CUSTOMERS_DELETE_LOADING = "CUSTOMERS_DELETE_LOADING";
export const CUSTOMERS_DELETE_SUCCESS = "CUSTOMERS_DELETE_SUCCESS";
export const CUSTOMERS_DELETE_FAILURE = "CUSTOMERS_DELETE_FAILURE";

export const CUSTOMERS_EDIT_LOADING = "CUSTOMERS_EDIT_LOADING";
export const CUSTOMERS_EDIT_SUCCESS = "CUSTOMERS_EDIT_SUCCESS";
export const CUSTOMERS_EDIT_FAILURE = "CUSTOMERS_EDIT_FAILURE";

export const CUSTOMERS_DISPLAY_DATA_LOADING = "CUSTOMERS_DISPLAY_DATA_LOADING";
export const CUSTOMERS_DISPLAY_DATA_SUCCESS = "CUSTOMERS_DISPLAY_DATA_SUCCESS";
export const CUSTOMERS_DISPLAY_DATA_FAILURE = "CUSTOMERS_DISPLAY_DATA_FAILURE";

export const CREATE_RECIPE_LOADING = "CREATE_RECIPE_LOADING";
export const CREATE_RECIPE_SUCCESS = "CREATE_RECIPE_SUCCESS";
export const CREATE_RECIPE_FAILURE = "CREATE_RECIPE_FAILURE";

export const CREATE_DATASET_LOADING = "CREATE_DATASET_LOADING";
export const CREATE_DATASET_SUCCESS = "CREATE_DATASET_SUCCESS";
export const CREATE_DATASET_FAILURE = "CREATE_DATASET_FAILURE";

export const START_JOB_LOADING = "START_JOB_LOADING";
export const START_JOB_SUCCESS = "START_JOB_SUCCESS";
export const START_JOB_FAILURE = "START_JOB_FAILURE";

export const DATASHAPER_INSERT_LOADING = "DATASHAPER_INSERT_LOADING";
export const DATASHAPER_INSERT_SUCCESS = "DATASHAPER_INSERT_SUCCESS";
export const DATASHAPER_INSERT_FAILURE = "DATASHAPER_INSERT_FAILURE";

export const DATASHAPER_LISTDATA_LOADING = "DATASHAPER_LISTDATA_LOADING";
export const DATASHAPER_LISTDATA_SUCCESS = "DATASHAPER_LISTDATA_SUCCESS";
export const DATASHAPER_LISTDATA_FAILURE = "DATASHAPER_LISTDATA_FAILURE";

export const NETLINE_POPUP_LOADING = "NETLINE_POPUP_LOADING";
export const NETLINE_POPUP_SUCCESS = "NETLINE_POPUP_SUCCESS";
export const NETLINE_POPUP_FAILURE = "NETLINE_POPUP_FAILURE";

export const SOURCE_LIST_DATA_LOADING = "SOURCE_LIST_DATA_LOADING";
export const SOURCE_LIST_DATA_SUCCESS = "SOURCE_LIST_DATA_SUCCESS";
export const SOURCE_LIST_DATA_FAILURE = "SOURCE_LIST_DATA_FAILURE";

export const TARGET_LIST_DATA_LOADING = "TARGET_LIST_DATA_LOADING";
export const TARGET_LIST_DATA_SUCCESS = "TARGET_LIST_DATA_SUCCESS";
export const TARGET_LIST_DATA_FAILURE = "TARGET_LIST_DATA_FAILURE";

export const DELIVERY_TEMPLATE_INSERT_LOADING = "DELIVERY_TEMPLATE_INSERT_LOADING";
export const DELIVERY_TEMPLATE_INSERT_SUCCESS = "DELIVERY_TEMPLATE_INSERT_SUCCESS";
export const DELIVERY_TEMPLATE_INSERT_FAILURE = "DELIVERY_TEMPLATE_INSERT_FAILURE";

export const JOB_UPDATE_LOADING = "JOB_UPDATE_LOADING";
export const JOB_UPDATE_SUCCESS = "JOB_UPDATE_SUCCESS";
export const JOB_UPDATE_FAILURE = "JOB_UPDATE_FAILURE";

export const JOB_STATUS_LOADING = "JOB_STATUS_LOADING";
export const JOB_STATUS_SUCCESS = "JOB_STATUS_SUCCESS";
export const JOB_STATUS_FAILURE = "JOB_STATUS_FAILURE";

export const DELIVERY_DYNAMIC_LOADING = "DELIVERY_DYNAMIC_LOADING";
export const DELIVERY_DYNAMIC_SUCCESS = "DELIVERY_DYNAMIC_SUCCESS";
export const DELIVERY_DYNAMIC_FAILURE = "DELIVERY_DYNAMIC_FAILURE";

export const PUBLISH_RECEPIE_LOADING = "PUBLISH_RECEPIE_LOADING";
export const PUBLISH_RECEPIE_SUCCESS = "PUBLISH_RECEPIE_SUCCESS";
export const PUBLISH_RECEPIE_FAILURE = "PUBLISH_RECEPIE_FAILURE";

export const DELETE_JOB_LOADING = "DELETE_JOB_LOADING";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";

export const DELETE_DATASET_LOADING = "DELETE_DATASET_LOADING";
export const DELETE_DATASET_SUCCESS = "DELETE_DATASET_SUCCESS";
export const DELETE_DATASET_FAILURE = "DELETE_DATASET_FAILURE";

export const DELETE_DATASETDB_LOADING = "DELETE_DATASETDB_LOADING";
export const DELETE_DATASETDB_SUCCESS = "DELETE_DATASETDB_SUCCESS";
export const DELETE_DATASETDB_FAILURE = "DELETE_DATASETDB_FAILURE";

export const DATASHAPER_NOTIFICATION_LOADING = "DATASHAPER_NOTIFICATION_LOADING";
export const DATASHAPER_NOTIFICATION_SUCCESS = "DATASHAPER_NOTIFICATION_SUCCESS";
export const DATASHAPER_NOTIFICATION_FAILURE = "DATASHAPER_NOTIFICATION_FAILURE";

export const DATASHAPER_NOTIFICATIONLIST_LOADING = "DATASHAPER_NOTIFICATIONLIST_LOADING";
export const DATASHAPER_NOTIFICATIONLIST_SUCCESS = "DATASHAPER_NOTIFICATIONLIST_SUCCESS";
export const DATASHAPER_NOTIFICATIONLIST_FAILURE = "DATASHAPER_NOTIFICATIONLIST_FAILURE";

export const FETCH_COLUMN_NAMES_LOADING = "FETCH_COLUMN_NAMES_LOADING";
export const FETCH_COLUMN_NAMES_SUCCESS = "FETCH_COLUMN_NAMES_SUCCESS";
export const FETCH_COLUMN_NAMES_FAILURE = "FETCH_COLUMN_NAMES_FAILURE";

export const LIST_OF_TITLES_LOADING = "LIST_OF_TITLES_LOADING";
export const LIST_OF_TITLES_SUCCESS = "LIST_OF_TITLES_SUCCESS";
export const LIST_OF_TITLES_FAILURE = "LIST_OF_TITLES_FAILURE";

export const GET_FILE_NAME_LOADING = "GET_FILE_NAME_LOADING";
export const GET_FILE_NAME_SUCCESS = "GET_FILE_NAME_SUCCESS";
export const GET_FILE_NAME_FAILURE = "GET_FILE_NAME_FAILURE";

export const NLP_INSERT_LOADING = "NLP_INSERT_LOADING";
export const NLP_INSERT_SUCCESS = "NLP_INSERT_SUCCESS";
export const NLP_INSERT_FAILURE = "NLP_INSERT_FAILURE";

export const NLP_MATCH_LOADING = "NLP_MATCH_LOADING";
export const NLP_MATCH_SUCCESS = "NLP_MATCH_SUCCESS";
export const NLP_MATCH_FAILURE = "NLP_MATCH_FAILURE";

export const NLP_DASHBOARD_LOADING = "NLP_DASHBOARD_LOADING";
export const NLP_DASHBOARD_SUCCESS = "NLP_DASHBOARD_SUCCESS";
export const NLP_DASHBOARD_FAILURE = "NLP_DASHBOARD_FAILURE";

export const CREATE_TITLE_BUCKET_LOADING = "CREATE_TITLE_BUCKET_LOADING";
export const CREATE_TITLE_BUCKET_SUCCESS = "CREATE_TITLE_BUCKET_SUCCESS";
export const CREATE_TITLE_BUCKET_FAILURE = "CREATE_TITLE_BUCKET_FAILURE";

export const LIST_TITLE_BUCKET_LOADING = "LIST_TITLE_BUCKET_LOADING";
export const LIST_TITLE_BUCKET_SUCCESS = "LIST_TITLE_BUCKET_SUCCESS";
export const LIST_TITLE_BUCKET_FAILURE = "LIST_TITLE_BUCKET_FAILURE";


export const DELETE_TITLE_BUCKET_LOADING = "DELETE_TITLE_BUCKET_LOADING";
export const DELETE_TITLE_BUCKET_SUCCESS = "DELETE_TITLE_BUCKET_SUCCESS";
export const DELETE_TITLE_BUCKET_FAILURE = "DELETE_TITLE_BUCKET_FAILURE";


export const PREFILL_TITLE_BUCKET_LOADING = "PREFILL_TITLE_BUCKET_LOADING";
export const PREFILL_TITLE_BUCKET_SUCCESS = "PREFILL_TITLE_BUCKET_SUCCESS";
export const PREFILL_TITLE_BUCKET_FAILURE = "PREFILL_TITLE_BUCKET_FAILURE";


export const UPDATE_TITLE_BUCKET_LOADING = "UPDATE_TITLE_BUCKET_LOADING";
export const UPDATE_TITLE_BUCKET_SUCCESS = "UPDATE_TITLE_BUCKET_SUCCESS";
export const UPDATE_TITLE_BUCKET_FAILURE = "UPDATE_TITLE_BUCKET_FAILURE";


export const APPEND_TITLE_BUCKET_LOADING = "APPEND_TITLE_BUCKET_LOADING";
export const APPEND_TITLE_BUCKET_SUCCESS = "APPEND_TITLE_BUCKET_SUCCESS";
export const APPEND_TITLE_BUCKET_FAILURE = "APPEND_TITLE_BUCKET_FAILURE";


export const FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_LOADING = "FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_LOADING";
export const FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_SUCCESS = "FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_SUCCESS";
export const FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_FAILURE = "FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_FAILURE";



export const FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_LOADING = "FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_LOADING";
export const FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_SUCCESS = "FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_SUCCESS";
export const FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_FAILURE = "FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_FAILURE";

export const BATCH_ID_LOADING = "BATCH_ID_LOADING";
export const BATCH_ID_SUCCESS = "BATCH_ID_SUCCESS";
export const BATCH_ID_FAILURE = "BATCH_ID_FAILURE";

export const GET_NLP_OUTPUTFILE_LOADING = "GET_NLP_OUTPUTFILE_LOADING";
export const GET_NLP_OUTPUTFILE_SUCCESS = "GET_NLP_OUTPUTFILE_SUCCESS";
export const GET_NLP_OUTPUTFILE_FAILURE = "GET_NLP_OUTPUTFILE_FAILURE";


export const INSERT_RECIPIE_NAME_LOADING = "INSERT_RECIPIE_NAME_LOADING";
export const INSERT_RECIPIE_NAME_SUCCESS = "INSERT_RECIPIE_NAME_SUCCESS";
export const INSERT_RECIPIE_NAME_FAILURE = "INSERT_RECIPIE_NAME_FAILURE";


export const GET_LEADSHAPER_FILES_LOADING = "GET_LEADSHAPER_FILES_LOADING";
export const GET_LEADSHAPER_FILES_SUCCESS = "GET_LEADSHAPER_FILES_SUCCESS";
export const GET_LEADSHAPER_FILES_FAILURE = "GET_LEADSHAPER_FILES_FAILURE";

export const STATUS_DASHBOARD_LOADING = "STATUS_DASHBOARD_LOADING";
export const STATUS_DASHBOARD_SUCCESS = "STATUS_DASHBOARD_SUCCESS";
export const STATUS_DASHBOARD_FAILURE = "STATUS_DASHBOARD_FAILURE";

export const GET_STATUS_LOADING = "GET_STATUS_LOADING";
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";
export const GET_STATUS_FAILURE = "GET_STATUS_FAILURE";



















