import React, { Component } from "react";
import "../../styles/DatashaperStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { datashaperlist, dataSetList, createRecipe, startJob, datashaperInsert } from "../../actions/Campaignactions";
import Header from "../../components/Header";
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Leftnav from "../../components/Leftnav";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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'
})

const S3_BUCKET = 'solpub-campaign-workflow';
const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 60; // in seconds

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

toast.configure()

const minuteSeconds = 60;
const hourSeconds = 300;
const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6
};

const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
const endTime = stratTime + 243248; // use UNIX timestamp in seconds

const remainingTime = endTime - stratTime;



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


class DataShaper extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            fields: {},
            errors: {},
            input_files: '',
            sourcefile: [],
            recipes: [],
            recipeList: [],
            datasetList: [],
            showtime: false

        };
        // socket = socketIOClient(URL);
    }



    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    generatePreSignedPutUrl = (fileName, fileType) => {
        myBucket.getSignedUrl('putObject', {
            Key: `campaign-starters/${fileName}`,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.input_files[0]
            }).then((res) => {
                console.log(res, "resssssssssssss")
            })
            return url
        });
    }

    uploadInputFiles = (e) => {
        this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type)
        this.setState({ input_files: e.target.files })
    }


    notify = () => {
        toast.success('successfully Saved', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    renderTime = (dimension, time) => {
        return (
            <div className="time-wrapper">
                <div className="time">{time}</div>
                <div>{dimension}</div>
            </div>
        );
    };

    getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;

    //handle source files
    handleSourceFile = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ sourcefile: typeof value === 'string' ? value.split(',') : value })
    }

    handleRecipes = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ recipes: typeof value === 'string' ? value.split(',') : value })
    }

    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }

        this.props.datashaperlist();
        this.props.dataSetList()
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlist !==
            nextProps.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlistSuccess === true
        ) {

            // console.log(this.props.campaignReducer.datashaperlist, "datashaperlist")
            const recipeList = [];
            this.props.campaignReducer.datashaperlist && this.props.campaignReducer.datashaperlist.length > 0 && this.props.campaignReducer.datashaperlist.map((item, index) => {
                if (item.Name) {
                    recipeList.push(
                        item.Name
                        // value: item.Name,
                        // label: item.Name
                    );
                }
                return true;
            })
            this.setState({ recipeList: recipeList })


        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datasetlist &&
            this.props.campaignReducer.datasetlist !==
            nextProps.campaignReducer.datasetlist &&
            this.props.campaignReducer.datasetlistSuccess === true
        ) {
            const datasetList = [];
            this.props.campaignReducer.datasetlist && this.props.campaignReducer.datasetlist.length > 0 && this.props.campaignReducer.datasetlist.map((item, index) => {
                if (item.Name) {
                    datasetList.push(
                        item.Name
                    );
                }
                return true;
            })
            this.setState({ datasetList: datasetList })


        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsert !==
            nextProps.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsertSuccess === true
        ) {
            const shaperinfo = {
                name: this.state.fields.name,
                dataset: this.state.sourcefile,
                recipes: this.state.recipes
            }
            this.props.createRecipe(shaperinfo)
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipe !==
            nextProps.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipeSuccess === true
        ) {
            const shaperinfo = {
                name: this.state.fields.name,
                dataset: this.state.sourcefile,
            }
            this.props.startJob(shaperinfo)
            this.notify()
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsert !==
            nextProps.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsertSuccess === false
        ) {
            const errors = {};
            errors.name = "Name can't be duplicate."
            this.setState({ errors })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipe !==
            nextProps.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipeSuccess === false
        ) {
            const errors = {};
            errors.name = "Name can't be duplicate."
            this.setState({ errors })
        }
    }


    validateDatashaper() {
        const errors = {};
        const { fields } = this.state;
        const strings = /^[A-Za-z0-9 ]+$/;

        let formIsValid = true;

        if (!fields.name) {
            errors.name = 'Please enter batch name ';
            formIsValid = false;
        }

        if (fields.name && fields.name.length < 3) {
            errors.name = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.name && fields.name.length > 500) {
            errors.name = 'batch name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.name && !strings.test(fields.name)) {
            errors.name = 'Please enter characters only';
            formIsValid = false;
        }

        if (this.state.offer_codes === '') {
            errors.offer_codes = 'Please select offer file ';
            formIsValid = false;
        }
        if (this.state.sourcefile.length === 0) {
            errors.sourcefile = 'Please select dataset name ';
            formIsValid = false;
        }

        if (this.state.recipes.length === 0) {
            errors.recipes = 'Please select recipe ';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;
    }

    Output = () => {
        // console.log("hi out putt")
        this.setState({ redirectpath: '/datashaperstep1' })
    }

    //customersetup insert
    dataShaper = () => {
        if (this.validateDatashaper()) {
            const shaperinfo = {
                name: this.state.fields.name,
                dataset: this.state.sourcefile,
                recipes: this.state.recipes
            }
            this.props.datashaperInsert(shaperinfo)
        }

    };
    render() {
        const { redirectpath, fields, errors, recipeList, datasetList } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { dataset: this.state.fields.name } }} />
                ) : null}

                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <Leftnav />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Step 1</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.dataShaper}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Apply Transformations</span>
                                </button>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <div className="row container">
                                            <div className="col-md-12 col-12 col-sm-12 mt-3 mb-3">
                                                <p className="inputlabel mt-3 mb-3">Name</p>
                                                <input
                                                    type="text"
                                                    value={fields.name}
                                                    onChange={this.handleChange.bind(this, "name")}
                                                    className="campaigntext"
                                                    placeholder="Enter Name"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.name}
                                                </span>
                                            </div>
                      
                                            <label className="inputlabel mt-3 mb-3">Dataset Selection</label>
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        displayEmpty
                                                        value={this.state.sourcefile}
                                                        placeholder="Select title"
                                                        onChange={this.handleSourceFile}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span className="inputfileplaceholder">Select dataset</span>;
                                                            }

                                                            return selected.join(', ');
                                                        }}
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {datasetList.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <Checkbox checked={this.state.sourcefile.indexOf(name) > -1} />
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                <span className="errors mt-2">
                                                    {errors && errors.sourcefile}
                                                </span>
                                            </div>

                                            <label className="inputlabel pt-3 mt-3 mb-3 mb-3">Recipe Selection</label>
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        displayEmpty
                                                        value={this.state.recipes}
                                                        placeholder="Select title"
                                                        onChange={this.handleRecipes}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span className="inputfileplaceholder">Select Recipe</span>;
                                                            }

                                                            return selected.join(', ');
                                                        }}
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {recipeList.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <Checkbox checked={this.state.recipes.indexOf(name) > -1} />
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                <span className="errors mt-2">
                                                    {errors && errors.recipes}
                                                </span>
                                            </div>
                                        </div>

                                    </Scrollbars>
                                </div>


                            </div>

                            <div className="col-md-12 col-sm-12  mt-5 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.dataShaper}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Apply Transformations</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlist: userinfo => dispatch(datashaperlist(userinfo)),
        dataSetList: userinfo => dispatch(dataSetList(userinfo)),
        createRecipe: userinfo => dispatch(createRecipe(userinfo)),
        startJob: userinfo => dispatch(startJob(userinfo)),
        datashaperInsert: userinfo => dispatch(datashaperInsert(userinfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataShaper);


