import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import moment from "moment";
import Header from "../../../components/Header";
import { saveAs } from 'file-saver';
import { statusDashboardList, batchIdList } from '../../../actions/Campaignactions';

import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import NLPNav from "../../../components/NLPNav";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Button from '@mui/material/Button';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import AWS from 'aws-sdk';




//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGZIW5Y5ZF',
    secretAccessKey: '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'
})

//uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARDHV752W5',
//     secretAccessKey: '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'
// })

//dev
// const S3_BUCKET = 'solpub-campaign-workflow';
//uat
const S3_BUCKET = 'leadshaper';

//dev
// const S3_BUCKET11 = 'titlematch';

//uat
const S3_BUCKET11 = 'leadshaper';

const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900;


const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
    signatureVersion: 'v4',
})


const myBucket11 = new AWS.S3({
    params: { Bucket: S3_BUCKET11 },
    region: REGION,
    signatureVersion: 'v4',
})


toast.configure()

class StatusDashboard extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            campaignList: [],
            BatchIdList: [],
            fields: {},
            fields1: {},
            noOfPages: [],
            itemsPerPage: 10,
            totalitems: '',
            page: 1,
            functioncodes: null,
            datashaper_id: ''
        };
    }



    handleChange = (event, value) => {
        this.setState({ page: value })
    };


    _onFilter = (Lead_type) => {
        console.log(Lead_type, "Lead_type")
        // const filterinfo = {
        //     filterdata: Lead_type.value
        // }
        // this.props.openCampaignList(filterinfo);
    };




    generatePreSignedPutUrl11 = (item) => {
        myBucket11.getSignedUrl('getObject', {
            //dev
            Key: item,
            //uat
            // Key: `datashaperInput/${item}`,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            saveAs(url)
            return url
        });
    }


    downLoadInputfile = (item) => {
        // console.log(item !== null && item !== undefined && item !== '', "downLoadInputfile")
        if (item !== null && item !== undefined && item !== '') {
            this.generatePreSignedPutUrl11(item)

        }

    }


    async componentDidMount() {
        this.props.statusDashboardList();
        this.props.batchIdList();
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.statusdashboarddata &&
            this.props.campaignReducer.statusdashboarddata !==
            nextProps.campaignReducer.statusdashboarddata &&
            this.props.campaignReducer.statusdashboarddataSuccess === true
        ) {
            const itemsPerPage = 10
            console.log(this.props.campaignReducer.statusdashboarddata.length, "this.props.campaignReducer.nlpDashboard")
            // getstatusdataSuccess
            this.setState({ campaignList: this.props.campaignReducer.statusdashboarddata, noOfPages: Math.ceil(this.props.campaignReducer.statusdashboarddata.length / itemsPerPage), totalitems: this.props.campaignReducer.statusdashboarddata.length })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.getstatusdata &&
            this.props.campaignReducer.getstatusdata !==
            nextProps.campaignReducer.getstatusdata &&
            this.props.campaignReducer.getstatusdataSuccess === true
        ) {
            console.log(this.props.campaignReducer.getstatusdata, "this.props.campaignReducer.getstatusdata")

        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.batchIdList &&
            this.props.campaignReducer.batchIdList !==
            nextProps.campaignReducer.batchIdList &&
            this.props.campaignReducer.batchIdListSuccess === true
        ) {
            const BatchIdList = [];
            this.props.campaignReducer.batchIdList && this.props.campaignReducer.batchIdList.length > 0 && this.props.campaignReducer.batchIdList.map((item) => {
                if (item["nlp id"]) {
                    BatchIdList.push({
                        value: item["nlp id"],
                        label: item["nlp id"]
                    });
                }
                return true;
            })

            this.setState({ BatchIdList: BatchIdList })
        }

    }

    Launch = () =>{
        this.setState({redirectpath: '/createrecipe'})
    }

    render() {
        const { redirectpath, campaignList, fields, fields1, noOfPages, page, itemsPerPage, totalitems } = this.state;

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: fields.campaign_id, status: this.state.functioncodes && this.state.functioncodes.value, fields1 } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NLPNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChange}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>
                        </div>



                        <div className="row mt-3 maindiv1">

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4 shadowdropdown">
                                    <Dropdown
                                        options={this.state.BatchIdList}
                                        onChange={this._onFilter}
                                        className='myClassName ml-3'
                                        placeholder="Select....." />
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="mt-5">
                                    <div className="scroller mt-4">
                                        {campaignList && campaignList.length === 0 ?
                                            <div
                                                className="opencampaignheader mt-5"
                                                style={{ textAlign: 'center' }}
                                            >no records found</div> :
                                            <table className="table">
                                                <thead>
                                                    <tr className="stickyheader">
                                                        <th className="opencampaignheader">  Batch No</th>
                                                        <th className="opencampaignheader">NLP Output Status</th>
                                                        <th className="opencampaignheader">NLP Launch</th>
                                                        <th className="opencampaignheader"> Shaping Status </th>
                                                        <th className="opencampaignheader">Shaping Launch</th>
                                                        <th className="opencampaignheader">   Leadshaper Output Status</th>
                                                        <th className="opencampaignheader">Leadshaper Launch</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {campaignList && campaignList.length > 0 && campaignList
                                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                            <tr key={index}>

                                                                <td className="opencampaigntext">
                                                                    {item.Batch_No}
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    {item.nlp_output_status}
                                                                </td>


                                                                <td className="opencampaigntext">
                                                                    {/* {item.nlp_Launch} */}
                                                                    <Button
                                                                        style={{
                                                                            padding: '5px 15px',
                                                                            backgroundColor: '#23857A',
                                                                            borderRadius: '0.5rem',
                                                                            borderColor: 'transparent',
                                                                            fontFamily: 'Montserrat',
                                                                            color: '#ffffff'
                                                                        }}
                                                                        onClick={this.Launch}>Launch button</Button>
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    {item.shaping_status}
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <Button
                                                                        style={{
                                                                            padding: '5px 15px',
                                                                            backgroundColor: '#23857A',
                                                                            borderRadius: '0.5rem',
                                                                            borderColor: 'transparent',
                                                                            fontFamily: 'Montserrat',
                                                                            color: '#ffffff'
                                                                        }}
                                                                        onClick={this.Launch}>Launch button</Button>
                                                                    {/* {item.shaping_Launch} */}
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    {item.leadshaper_output_status}
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <Button
                                                                        style={{
                                                                            padding: '5px 15px',
                                                                            backgroundColor: '#23857A',
                                                                            borderRadius: '0.5rem',
                                                                            borderColor: 'transparent',
                                                                            fontFamily: 'Montserrat',
                                                                            color: '#ffffff'
                                                                        }}
                                                                        onClick={this.Launch}>Launch button</Button>
                                                                    {/* {item.leadshaper_Launch} */}
                                                                </td>


                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        statusDashboardList: userinfo => dispatch(statusDashboardList(userinfo)),
        batchIdList: userinfo => dispatch(batchIdList(userinfo)),


    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatusDashboard);

