import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { userDetails } from './actions/ManagerActions';
import { usersInfo } from './actions/Campaignactions';
import { connect } from 'react-redux';



//Header
import Leftnav from './components/Leftnav';
// import LeftNavigation from './components/LeftNavigation';


//User Login
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Confirmation from "./pages/Confirmation";
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';

//Compaigns 
import CampaignsList from "./pages/campaigns/CampaignsList";
import CamapignSetupList from "./pages/campaigns/CamapignSetupList";
import Campaignsetup from './pages/campaigns/Campaignsetup';
import CampaignReview from './pages/campaigns/CampaignReview';
import ToolSequencing from './pages/campaigns/ToolSequencing';
import CustomerSetUp from './pages/campaigns/CustomerSetUp';

//Customer
import AcustomersList from './pages/campaigns/AcustomersList';


//Toolstate
import ToolStates from './pages/toolstates/ToolStates';
import Userstates from './pages/toolstates/Userstates';

//Manager
import OpenCampaign from './pages/manager/OpenCampaign';
import Hot from './pages/manager/Hot';
import MyWorkStack from './pages/manager/MyWorkStack';
import RedZone from './pages/manager/RedZone';
import ReadyToBill from './pages/manager/ReadyToBill';


//Work Stack
import Producerworkstack from './pages/workstack/Producerworkstack';
import Producerusestates from './pages/workstack/Producerusestates';


//Tools
import DataShaper from './pages/tools/DataShaper';
import DatashaperStep1 from './pages/tools/DatashaperStep1';




//Netline
import Step1 from './pages/tools/netline/Step1';
import Step1Yield from './pages/tools/netline/Step1Yield';
import NetLineProgress from './pages/tools/netline/NetLineProgress';
import NetlineDashboard from './pages/tools/netline/NetlineDashboard';

//NLP
import ListofTitleBuckets from './pages/tools/NLP/ListofTitleBuckets';
import CreateTitleBucket from './pages/tools/NLP/CreateTitleBucket';
import RunConsole from './pages/tools/NLP/RunConsole';
import NLPDashboard from './pages/tools/NLP/NLPDashboard';
import CreateDataSet from './pages/tools/NLP/CreateDataSet';
import CreateRecipe from './pages/tools/NLP/CreateRecipe';
import StatusDashboard from './pages/tools/NLP/StatusDashboard';

import AppLogout from './AppLogout';



import { Auth } from 'aws-amplify';



const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

let timer;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      isLoggedIn: false,
      linkedInLoggedIn: false
    };


  }





  // async logout() {
  //   try {
  //     await Auth.signOut({ global: true });
  //     await localStorage.removeItem('userdetails');
  //     await localStorage.removeItem('usersinfo');
  //     window.location.pathname = "/";
  //   } catch (err) {
  //     console.log(err, 'error signing out...');
  //   }
  //   this.setState({ isLoggedIn: false });
  //   this.destroy(); // Cleanup
  // }

  // destroy() {
  //   this.clearTimeout();
  //   for (var i in this.events) {
  //     window.removeEventListener(this.events[i], this.resetTimeout);
  //   }
  // }

  // <======== *********** ==========>




  // this function sets the timer that logs out the user after 10 secs
  // handleLogoutTimer = () => {
  //   timer = setTimeout(() => {
  //     // clears any pending timer.
  //     this.resetTimer();
  //     // Listener clean up. Removes the existing event listener from the window
  //     Object.values(events).forEach((item) => {
  //       window.removeEventListener(item, this.resetTimer);
  //     });
  //     // logs out user
  //     this.logout();
  //   }, 10000); // 10000ms = 10secs. You can change the time.
  // };

  // // this resets the timer if it exists.
  // resetTimer = () => {
  //   if (timer) clearTimeout(timer);
  // };


  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  async componentDidMount() {
    this.props.userDetails();
    this.props.usersInfo();
    try {
      const session = await Auth.currentSession();
      const l111 = session.getAccessToken().getJwtToken();
      this.setAuthStatus(true);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
    } catch (error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }

    this.setState({ isAuthenticating: false });


  

  }

  // componentWillMount() {
  //   Object.values(events).forEach((item) => {
  //     window.addEventListener(item, () => {
  //       this.resetTimer();
  //       this.handleLogoutTimer();
  //     });
  //   });
  //   // Perform the required
  //   // activity inside it
  //   }



  componentWillUnmount() {
  }


  componentDidUpdate(nextProps) {
    if (
      this.props.managerReducer &&
      this.props.managerReducer.arcMan &&
      this.props.managerReducer.arcMan !==
      nextProps.managerReducer.arcMan &&
      this.props.managerReducer.arcManSuccess === true
    ) {
      this.setState({ isLoggedIn: true })
    }
    if (
      this.props.managerReducer &&
      this.props.managerReducer.arcMan &&
      this.props.managerReducer.arcMan !==
      nextProps.managerReducer.arcMan &&
      this.props.managerReducer.arcManSuccess === false
    ) {
      this.setState({ isLoggedIn: false })
    }
  }


  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }
    return (
      <Router>
        <div>
          <Switch>

            <Route exact path="/" render={(props) => <Login {...props} auth={authProps} />} />
            <Route exact path="/signup" render={(props) => <SignUp {...props} auth={authProps} />} />
            <Route exact path="/confirmation" render={(props) => <Confirmation {...props} auth={authProps} />} />
            <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
            <Route exact path="/changepassword" render={(props) => <ChangePassword {...props} auth={authProps} />} />

            {this.state.isLoggedIn === true ?
              <AppLogout>
                <Route exact path="/campaignslist" render={(props) => <CampaignsList {...props} auth={authProps} />} />
                <Route
                  exact
                  path="/campaignsetup" render={(props) => <Campaignsetup {...props} auth={authProps} />} />
                <Route path="/campaignsetup/:id" render={(props) => <Campaignsetup {...props} auth={authProps} />} />
                <Route exact path="/campaignreview" render={(props) => <CampaignReview {...props} auth={authProps} />} />
                <Route exact path="/toolsequencing" render={(props) => <ToolSequencing {...props} auth={authProps} />} />
                <Route exact path="/customersetup" render={(props) => <CustomerSetUp {...props} auth={authProps} />} />
                <Route path="/customersetup/:id" render={(props) => <CustomerSetUp {...props} auth={authProps} />} />
                <Route exact path="/toolstates" render={(props) => <ToolStates {...props} auth={authProps} />} />
                <Route exact path="/userstates" render={(props) => <Userstates {...props} auth={authProps} />} />
                <Route exact path="/opencampaign" render={(props) => <OpenCampaign {...props} auth={authProps} />} />
                <Route exact path="/hot" render={(props) => <Hot {...props} auth={authProps} />} />
                <Route exact path="/redzone" render={(props) => <RedZone {...props} auth={authProps} />} />
                <Route exact path="/workstack" render={(props) => <MyWorkStack {...props} auth={authProps} />} />
                <Route exact path="/readytobill" render={(props) => <ReadyToBill {...props} auth={authProps} />} />
                <Route exact path="/producerworkstack" render={(props) => <Producerworkstack {...props} auth={authProps} />} />
                <Route exact path="/producerusestates" render={(props) => <Producerusestates {...props} auth={authProps} />} />
                <Route exact path="/leftnav" render={(props) => <Leftnav {...props} auth={authProps} />} />
                <Route exact path="/campaignsetuplist" render={(props) => <CamapignSetupList {...props} auth={authProps} />} />
                <Route exact path="/datashaper" render={(props) => <DataShaper {...props} auth={authProps} />} />
                <Route exact path="/datashaperstep1" render={(props) => <DatashaperStep1 {...props} auth={authProps} />} />
                <Route exact path="/createdataset" render={(props) => <CreateDataSet {...props} auth={authProps} />} />
                <Route exact path="/step1" render={(props) => <Step1 {...props} auth={authProps} />} />
                <Route exact path="/stepyield" render={(props) => <Step1Yield {...props} auth={authProps} />} />
                <Route exact path="/netlineprogress" render={(props) => <NetLineProgress {...props} auth={authProps} />} />
                <Route exact path="/dashboard" render={(props) => <NetlineDashboard {...props} auth={authProps} />} />
                <Route exact path="/acustomers" render={(props) => <AcustomersList {...props} auth={authProps} />} />
                <Route exact path="/createrecipe" render={(props) => <CreateRecipe {...props} auth={authProps} />} />
                <Route exact path="/nlpdashboard" render={(props) => <NLPDashboard {...props} auth={authProps} />} />
                <Route exact path="/runconsole" render={(props) => <RunConsole {...props} auth={authProps} />} />
                <Route exact path="/createtitlebucket" render={(props) => <CreateTitleBucket {...props} auth={authProps} />} />
                <Route path="/createtitlebucket/:id" render={(props) => <CreateTitleBucket {...props} auth={authProps} />} />
                <Route exact path="/listoftitlebuckets" render={(props) => <ListofTitleBuckets {...props} auth={authProps} />} />
                <Route exact path="/statusdashboard" render={(props) => <StatusDashboard {...props} auth={authProps} />} />




              </AppLogout> :
              // null
              <Route exact path="/" render={(props) => <Login {...props} auth={authProps} />} />
            }

          </Switch>
        </div>
      </Router>
    );
  }
}

// export default App;
function mapStateToProps(state) {
  return {
    campaignReducer: state.campaignReducer,
    managerReducer: state.managerReducer
  };
}
function mapDispatchToProps(dispatch) {
  return {
    userDetails: userinfo => dispatch(userDetails(userinfo)),
    usersInfo: userinfo => dispatch(usersInfo(userinfo)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

