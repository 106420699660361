import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import '../styles/LoginStyles.css';
import logo from '../assets/images/logo.png';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dropdown from 'react-dropdown';


const options = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Producer', label: 'Producer' },
    { value: 'Manager', label: 'Manager' },
];
class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectpath: '',
            email: '',
            password: '',
            confirmpassword: '',
            passwordLength: false,
            containsNumbers: false,
            isUpperCase: false,
            isLoweCase: false,
            showPassword: false,
            showConfirmPassword: false,
            containsSymbols: false,
            selectedOption: null,
            errors: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);



    }

    handleChange(event) {
        this.setState({ email: event.target.value });
    }
    handleConfirmPassword(event) {
        this.setState({ confirmpassword: event.target.value });

    }
    //To check password strength
    checkForPasswordStrength = (string) => {
        if (string.match(/[A-Z]/) !== null) {
            this.setState({ isUpperCase: true })
        }
        else {
            this.setState({ isUpperCase: false })
        }

        if (string.match(/[a-z]/) !== null) {
            this.setState({ isLoweCase: true })
        }
        else {
            this.setState({ isLoweCase: true })
        }

        if (string.match(/\d+/g) !== null) {
            this.setState({ containsNumbers: true })
        }
        else {
            this.setState({ containsNumbers: false })
        }

        if (string.match(/[!@#$%^&*]/) !== null) {
            this.setState({ containsSymbols: true })
        }
        else {
            this.setState({ containsSymbols: false })
        }

        if (string.length > 7) {
            this.setState({ passwordLength: true })
        }
        else {
            this.setState({ passwordLength: false })
        }
    }
    //For password value
    handlePasswordChange(event) {
        const targetValue = event.target.value;
        this.checkForPasswordStrength(targetValue);
        this.setState({ password: targetValue });
    }
    //For password visivility
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    showConfirmPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

    }

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //For user type like admin,producer,manager
    handleDropdown = selectedOption => {
        this.setState({ selectedOption });
    };


    validateSignup() {
        const errors = {};
        let formIsValid = true;
        if (this.state.password !== this.state.confirmpassword) {
            errors.password = "Passwords don't match.";
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;
    }

    //This function will call on click of sign up button
    onSubmit = async () => {
        if (this.validateSignup()) {
            const { password, email } = this.state;
            try {
                await Auth.signUp({
                    username: email,
                    password,
                    attributes: {
                        profile: this.state.selectedOption && this.state.selectedOption.value,
                    }
                });
                this.props.history.push('/confirmation', { email: this.state.email });

            } catch (error) {
                console.log('error signing up:', error);
                const errors = {};
                errors.password = 'An account with the given email already exists.';
                this.setState({ errors })
            }
        }
    };


    render() {
        const { email, password, redirectpath, passwordLength, containsNumbers, isUpperCase, containsSymbols, isLoweCase, errors, selectedOption, confirmpassword } = this.state;
        let btnstatus = passwordLength && containsNumbers && isUpperCase && isLoweCase && containsSymbols ? false : true
        console.log(this.state.errors, "errorss")
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath}
                        state={this.state.data}
                    />
                ) : null}
                <div className="row">
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}
                        className="col-md-6 col-sm-6 col-lg-6 col-6 leftpanel">
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12">
                                <p className="lefttext">Hello, Welcome to Arc Man!</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12 col-12 col-sm-12">
                                <p className="audiencetext">Audience Response Curation Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-12 rightpanel">
                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <img
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">

                            </div>
                            <div className="col-md-6">
                                <label className="emailtext mt-4 mb-2">E-mail</label>
                                <input
                                    type="text"
                                    value={email}
                                    onChange={this.handleChange}
                                    className="emailinput"
                                    placeholder="Example@gmail.com"
                                />


                                <label className="emailtext mt-4 mb-2">Password</label>
                                <div className="input-icons">
                                    <input
                                        type={this.state.showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={this.handlePasswordChange}
                                        className="emailinput"
                                        placeholder="Enter Password"
                                    />
                                    <i
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        className="toggleicon"> {this.state.showPassword ? <Visibility /> : <VisibilityOff />}</i>
                                </div>

                                {password !== '' ?
                                    <div className="mt-2">
                                        <div className={isLoweCase ? 'green' : 'red'}><FontAwesomeIcon className="icons mr-5" icon={isLoweCase ? faCheck : faTimes} />{' '}Password must contain a lower case letter</div>
                                        <div className={passwordLength ? 'green' : 'red'}><FontAwesomeIcon className="icons mr-5" icon={passwordLength ? faCheck : faTimes} />{' '}Password must contain at least 8 characters</div>
                                        <div className={containsNumbers ? 'green' : 'red'}><FontAwesomeIcon className="icons mr-5" icon={containsNumbers ? faCheck : faTimes} />{' '}Password must contain a number</div>
                                        <div className={isUpperCase ? 'green' : 'red'}><FontAwesomeIcon className="icons mr-5" icon={isUpperCase ? faCheck : faTimes} />{' '}Password must contain an upper case letter</div>
                                        <div className={containsSymbols ? 'green' : 'red'}><FontAwesomeIcon className="icons mr-5" icon={containsSymbols ? faCheck : faTimes} />{' '}Password must contain a special character</div>
                                    </div> : null}



                                <label className="emailtext mt-4 mb-2">Confirm Password</label>
                                <div className="input-icons">
                                    <input
                                        type={this.state.showConfirmPassword ? "text" : "password"}
                                        value={confirmpassword}
                                        onChange={this.handleConfirmPassword}
                                        className="emailinput"
                                        placeholder="Enter Confirm Password"
                                    />
                                    {/* <i
                                        onClick={this.showConfirmPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        className="toggleicon"> {this.state.showPassword ? <Visibility /> : <VisibilityOff />}</i> */}
                                </div>

                                <label className="emailtext mt-4 mb-2">Role</label>
                                <Dropdown
                                    options={options}
                                    onChange={this.handleDropdown}
                                    value={selectedOption}
                                    className='myClassName'
                                    placeholder="Select an option" />

                                <span className="errors mt-2">
                                    {errors && errors.password}
                                </span>

                                {email === '' || password === '' || btnstatus === true || selectedOption === null ?
                                    <button
                                        onClick={this.onSubmit}
                                        disabled={true}
                                        type="button"
                                        className="loginbuttondis mt-5"
                                    >
                                        <span className="logintext">Sign up</span>
                                    </button> :
                                    <button
                                        onClick={this.onSubmit}
                                        type="button"
                                        className="loginbutton mt-5"
                                    >
                                        <span className="logintext">Sign up</span>
                                    </button>}

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default SignUp;
