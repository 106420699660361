import React, { Component } from "react";
import "../../styles/ManagerStyles.css";
import moment from "moment";
import "../../styles/CreateCampaignStyles.css";
import "../../styles/RedZoneStyles.css";
import Header from "../../components/Header";
import filter1 from "../../assets/images/filter.svg";
import Dropdown from 'react-dropdown';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { saveAs } from 'file-saver';
import DeleteIcon from '@material-ui/icons/Delete';
import { Modal } from "react-bootstrap";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LaunchIcon from '@material-ui/icons/Launch';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import 'react-dropdown/style.css';
import { managerWorkStacklist, managerWorkStackNotes, notesInsert, inputFile, launchtool, userDetails } from '../../actions/ManagerActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNavigation2 from '../../components/LeftNavigation2';




const data = [
    { value: 'A week ago', label: 'A week ago' },
    { value: 'A month ago', label: 'A month ago' }
];

const statusdata = [
    { value: '', label: 'Select...' },
    { value: 'Active', label: 'Active' },
    { value: 'Created', label: 'Created' },
    { value: 'Completed', label: 'Completed' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Pending', label: 'Pending' }
];

const headers = [
    // { value: '', label: 'Select...' },
    'Active',
    'Created',
    'Pending'
];

toast.configure()
class MyWorkStack extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            functioncodes: null,
            Steps: [],
            errors: {},
            opencampaignList: [],
            managerworkstack_id: '',
            assigned_user: '',
            urlArray: [],
            matchFileUrl: '',
            discardFileUrl: '',
            status_campname: '',
            status_toolname: '',
            nextStepData: [],
            userData: null,
            itemsPerPage: 10,
            totalitems: '',
            addmore: false,
            page: 1,
            description: ''
        };
        // this.handleChange = this.handleChange.bind(this);
    }

    _onFilter = (Lead_type) => {
        const filterinfo = {
            filterdata: Lead_type.value
        }
        this.props.managerWorkStacklist(filterinfo);
    };

    notify = () => {
        toast.warning('Please add notes also it is required to complete your step', { autoClose: 10000, position: toast.POSITION.TOP_CENTER })
    }

    // _onSelectFunctions = (functioncodes) => {
    //     this.setState({ functioncodes });
    //     this.notify()
    // };

    //status
    _onSelectStatus = (event) => {
        this.setState({ functioncodes: event.target.value });
        this.notify()
    };



    statusUpdate = (item) => {
        this.setState({
            managerworkstack_id: item.tool_camp_id,
            assigned_user: item.assigned_user,
            status_campname: item.campaign_name,
            status_toolname: item.tool_name,
        })
    }



    openModal = (item) => {
        // console.log(item, "immmmmm")
        this.setState({
            visible: true,
            managerworkstack_id: item.tool_camp_id
        });
    }

    closeModal = () => {
        this.setState({
            visible: false,
            addmore: false,
            Steps: []
        });
    }


    addMoreData = () => {
        const Steps = this.state.Steps;
        if (this.state.description !== '') {
            var Action = {};
            Action.notes = this.state.description
            Steps.push({
                Action
            });
        }


        this.setState({ Steps, description: '' })
        this.setState({
            addmore: true
        });
    }


    // onCloseModal = () => {
    //     const Steps = this.state.Steps;
    //     if (this.state.optionSelected) {
    //         var Action = {};
    //         var Parameters = {};
    //         if (this.state.optionSelected !== null && this.state.optionSelected.value === 'RENAME') {
    //             Parameters.sourceColumn = this.state.value.value;
    //             Parameters.targetColumn = this.state.targetvalue.value;
    //         }
    //         if (this.state.optionSelected !== null && this.state.optionSelected.value === 'FILL_WITH_CUSTOM') {
    //             Parameters.columnDataType = "string";
    //             Parameters.sourceColumn = this.state.value.value;
    //             Parameters.value = this.state.customvalue !== null && this.state.customvalue.value;
    //         }
    //         if (this.state.optionSelected !== null && this.state.optionSelected.value === 'MOVE_AFTER') {
    //             Parameters.targetIndex = "NaN";
    //             Parameters.sourceColumn = this.state.value.value;
    //             Parameters.targetColumn = this.state.targetvalue.value;
    //         }
    //         if (this.state.optionSelected !== null && this.state.optionSelected.value === 'Create_New_Column') {
    //             Parameters.pattern = " ";
    //             Parameters.sourceColumn = this.state.value.value;
    //             Parameters.targetColumn = this.state.targetvalue.value;
    //         }
    //         // EXTRACT_PATTERN
    //         let data = this.state.optionSelected !== null && this.state.optionSelected.value === 'Create_New_Column' ? 'EXTRACT_PATTERN' : this.state.optionSelected.value
    //         Action.Operation = data;
    //         Action.Parameters = Parameters;

    //         Steps.push({
    //             Action
    //         });

    //     }
    //     this.setState({ Steps, optionSelected: '', value: '', targetvalue: '' })
    //     this.setState({ addmore: false });
    // };


    validateLogin() {
        const errors = {};
        let formIsValid = true;
        if (!this.state.notes) {
            errors.notes = 'Please enter notes';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;
    }
    //to handle input field
    handleChange = (e) => {
        this.setState({ description: e.target.value });
    }
    // <----- to handle pagination ----->
    handleChangePagination = (event, value) => {
        this.setState({ page: value })
    };

    notifyOnLaunchTool = () => {
        toast.error('Please make sure input is available', { autoClose: 10000, position: toast.POSITION.TOP_CENTER })
    }

    //launch tool
    onLaunchTool = async (item, index) => {
        let current = new Date();
        let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        let dateTime = cDate + ' ' + cTime;
        const userdetails = {
            start_time: dateTime,
            tool_id: item.tool_camp_id
        }
        this.props.notesInsert(userdetails)
        this.setState({ nextStepData: item })
        let profileinformation = this.state.userData && this.state.userData.attributes
        if (index === 0) {

            if (item.tool_name === "Data Shaper") {
                //uat
                // window.open(`http://solpub-uat.s3-website-us-east-1.amazonaws.com/createdataset/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}  `)
                //demo
                // window.open(`http://demo-solpub.s3-website-us-east-1.amazonaws.com/createrecipe/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                //dev
                window.open(`http://solpub-web.s3-website.us-east-2.amazonaws.com/createdataset/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
            }
            if (item.tool_name === "Title Match") {
                //DEV
                window.open(`http://title-match.com.s3-website-us-east-1.amazonaws.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                //demo
                // window.open(`http://demo-ui-titlematch.s3-website-us-east-1.amazonaws.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)

                //UAT
                // window.open(`http://uititlematch.s3-website-us-east-1.amazonaws.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
            }

            if (item.tool_name === "List Suppression") {
                //dev
                window.open(`https://listool.digitivy.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                //demo
                // window.open(`https://arcmanuat.solpub.com/demolisttool/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)

                //uat
                // window.open(`https://arcmanuat.solpub.com/listtool/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
            }
        }
        else {
            if (item.inpinputfile === null || item.inpinputfile === undefined) {
                this.notifyOnLaunchTool()
            }
            else {
                if (item.tool_name === "Data Shaper") {
                    //demo
                    // window.open(`http://demo-solpub.s3-website-us-east-1.amazonaws.com/createrecipe/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)

                    //uat
                    // window.open(`http://solpub-uat.s3-website-us-east-1.amazonaws.com/createdataset/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                    //dev
                    window.open(`http://solpub-web.s3-website.us-east-2.amazonaws.com/createdataset/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                }
                if (item.tool_name === "Title Match") {
                    //DEV
                    window.open(`http://title-match.com.s3-website-us-east-1.amazonaws.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                    //demo
                    // window.open(`http://demo-ui-titlematch.s3-website-us-east-1.amazonaws.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)

                    //UAT
                    // window.open(`http://uititlematch.s3-website-us-east-1.amazonaws.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                }

                if (item.tool_name === "List Suppression") {
                    //dev
                    window.open(`https://listool.digitivy.com/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                    //demo
                    // window.open(`https://arcmanuat.solpub.com/demolisttool/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                    //uat
                    // window.open(`https://arcmanuat.solpub.com/listtool/?steps_id=${item.tool_camp_id}&campaign_id=${item.campaign_id}&campaign_name=${item.campaign_name}&inputfile=${this.state.nextStepData && this.state.nextStepData.outputfile}&profile=${profileinformation.profile}&email=${profileinformation.email}&user_id=${profileinformation.sub}`)
                }


            }
        }

    }
    //input file
    downLoadInputfile = (item) => {
        saveAs(this.state.matchFileUrl)
        const inputurl = {
            inputfile: this.state.nextStepData && this.state.nextStepData.outputfile,
            tool_id: item.tool_camp_id
        }
        this.props.notesInsert(inputurl)
    }

    //output file
    downLoadOutputfile = (item) => {
        saveAs(this.state.discardFileUrl)
        const outputurl = {
            outputfile: this.state.discardFileUrl,
            tool_id: item.tool_camp_id
        }
        this.props.notesInsert(outputurl)
    }

    componentDidMount() {
        const userinfo = {
            assigned_to_me: this.props.auth && this.props.auth.user
                && this.props.auth.user.attributes && this.props.auth.user.attributes.sub,
        };
        this.props.managerWorkStacklist(userinfo);
        this.props.inputFile()
        this.props.userDetails();
    }

    DeleteIconData = (item) => {
        const Steps = this.state.Steps;

        const index = Steps.indexOf(item);
        if (index > -1) {
            Steps.splice(index, 1);
        }

        this.setState({ Steps })
    };


    notesInsert = () => {
        // let notesobject = {}
        // let notesArray = this.state.notesArray
        // let urlArray = this.state.urlArray
        // notesobject['url'] = this.state.fields.url;
        // notesobject['notes'] = this.state.fields.notes;
        // notesobject['workstack_id'] = this.state.managerworkstack_id
        // console.log(this.state.managerworkstack_id, "workkk")
        // notesArray.push(this.state.fields.notes)
        // urlArray.push(this.state.fields.url)

        // this.setState({ notesArray: notesArray, urlArray: urlArray })
    }

    notesInsertDetails = () => {
        this.closeModal()
        let current = new Date();
        let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        let dateTime = cDate + ' ' + cTime;

        const userdetails = {
            notesdata: this.state.Steps,
            urldata: this.state.urlArray,
            workstack_id: this.state.managerworkstack_id,
            status_1: this.state.functioncodes,
            StepAssigneeName: this.state.assigned_user,
            status_toolname: this.state.status_toolname,
            status_campname: this.state.status_campname,
            stop_time: dateTime
        }
        // console.log(userdetails, "fornotess")
        this.props.notesInsert(userdetails)
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.managerReducer &&
            this.props.managerReducer.managerworkstackList &&
            this.props.managerReducer.managerworkstackList !==
            nextProps.managerReducer.managerworkstackList &&
            this.props.managerReducer.managerworkstackListSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({
                opencampaignList: this.props.managerReducer.managerworkstackList,
                noOfPages: Math.ceil(this.props.managerReducer.managerworkstackList.length / itemsPerPage),
                totalitems: this.props.managerReducer.managerworkstackList.length
            })

        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.inpufile &&
            this.props.managerReducer.inpufile !==
            nextProps.managerReducer.inpufile &&
            this.props.managerReducer.inpufileSuccess === true
        ) {
            this.setState({
                matchFileUrl: this.props.managerReducer.inpufile && this.props.managerReducer.inpufile.matchFileUrl,
                discardFileUrl: this.props.managerReducer.inpufile && this.props.managerReducer.inpufile.discardFileUrl

            })

        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.arcMan &&
            this.props.managerReducer.arcMan !==
            nextProps.managerReducer.arcMan &&
            this.props.managerReducer.arcManSuccess === true
        ) {
            this.setState({ userData: this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan })
        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.laucnTool &&
            this.props.managerReducer.laucnTool !==
            nextProps.managerReducer.laucnTool &&
            this.props.managerReducer.laucnToolSuccess === true
        ) {
            // console.log(this.props.managerReducer.laucnTool, "tool name")
        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.managerNotes &&
            this.props.managerReducer.managerNotes !==
            nextProps.managerReducer.managerNotes &&
            this.props.managerReducer.managerNotesSuccess === true
        ) {
            this.notify()
        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.notesinsert &&
            this.props.managerReducer.notesinsert !==
            nextProps.managerReducer.notesinsert &&
            this.props.managerReducer.notesinsertSuccess === true
        ) {
            this.props.managerWorkStacklist();
        }
    }

    render() {
        const { redirectpath, opencampaignList, noOfPages, page, itemsPerPage, totalitems, fields, errors, Steps } = this.state;
        console.log(opencampaignList, "opencampaignList")
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">

                        <LeftNavigation2 />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="opencampaignheading">My Work Stack</span>
                            </div>
                        </div>

                        <div className="row mt-4 mb-2 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChangePagination}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>

                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <div className="col-md-7">
                                </div>
                                <div className="col-md-3 shadowdropdown">
                                    <Dropdown
                                        options={data}
                                        onChange={this._onFilter}
                                        className='myClassName ml-3'
                                        placeholder="Select....." />
                                </div>
                                <div className="col-md-1 d-md-flex justify-content-end">

                                    <img
                                        src={filter1}
                                        style={{ height: "29px", alignSelf: 'center' }}
                                        alt="filter"
                                    />
                                </div>
                            </div>

                            <div className="mt-5">
                                <div className="scroller mt-4">
                                    {opencampaignList && opencampaignList.length === 0 ?
                                        <div
                                            className="opencampaignheader mt-5"
                                            style={{ textAlign: 'center' }}
                                        >no records found</div> :
                                        <table className="table">
                                            <thead>
                                                <tr className="stickyheader">
                                                    <th className="opencampaignheader">Name</th>
                                                    <th className="opencampaignheader">Created Date</th>
                                                    <th className="opencampaignheader">Deadline</th>
                                                    <th className="opencampaignheader">Steps Name</th>
                                                    <th className="opencampaignheader">Steps Assigned</th>
                                                    <th className="opencampaignheader">Status</th>
                                                    <th className="opencampaignheader">Notes</th>
                                                    <th className="opencampaignheader">Input File</th>
                                                    <th className="opencampaignheader">Output File</th>
                                                    <th className="opencampaignheader">Launch Tool</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {opencampaignList && opencampaignList.length > 0 && opencampaignList
                                                    .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                        <tr
                                                            // onClick={() => this.setIdData(item)}
                                                            key={index}>
                                                            <td className="opencampaigntext mt-4">
                                                                {item.campaign_name && item.campaign_name.length > 15 ? `${item.campaign_name.substring(0, 15)}...` : item.campaign_name}
                                                                {/* {item.campaign_name} */}
                                                            </td>
                                                            <td className="opencampaigntext">{moment(item.created_at).format('MM-DD-YYYY')}</td>
                                                            <td className="opencampaigntext">{moment(item.deadline_date).format('MM-DD-YYYY')}</td>
                                                            <td className="opencampaigntext">{item.tool_name}</td>
                                                            <td className="opencampaigntext">{item.assigned_user}</td>
                                                            <td
                                                                onClick={() => this.statusUpdate(item)}
                                                            >
                                                                <select
                                                                    onChange={this._onSelectFunctions}
                                                                    className="statusdrop">
                                                                    <option value="">{item.workstack_status ? item.workstack_status : "Select Value"}</option>
                                                                    {headers.map((headers) => {
                                                                        return (
                                                                            <option key={headers}>{headers}</option>
                                                                        );
                                                                    })}
                                                                </select>

                                                            </td>

                                                            <td className="opencampaigntext">
                                                                <RateReviewIcon
                                                                    onClick={() => this.openModal(item)}
                                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                            </td>

                                                            <td className="opencampaigntext">
                                                                <CloudDownloadIcon
                                                                    onClick={() => this.downLoadInputfile(item)}
                                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                            </td>

                                                            <td className="opencampaigntext">
                                                                <CloudDownloadIcon
                                                                    onClick={() => this.downLoadOutputfile(item)}
                                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                            </td>

                                                            <td>
                                                                <LaunchIcon
                                                                    onClick={() => this.onLaunchTool(item, index)}
                                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                />

                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal show={this.state.visible} onHide={this.closeModal}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12 mt-3">
                                <AddCircleIcon
                                    fontSize={'large'}
                                    onClick={this.addMoreData}
                                    style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                />
                                <span className="createtextnoteshead">Add Note</span>
                            </div>

                            {this.state.addmore === true ?
                                <div className="col-md-12 col-12 col-sm-12 mt-3">
                                    <input
                                        type="text"
                                        value={this.state.description}
                                        onChange={this.handleChange}
                                        className="workstackmultiplenotes"
                                        placeholder="Enter Name"
                                    />
                                    <span className="errors mt-2">
                                        {errors && errors.name}
                                    </span>
                                </div> : null}

                        </div>
                        {this.state.Steps && this.state.Steps.length === 0 ?
                            <div
                                className="opencampaignheader mt-5"
                                style={{ textAlign: 'center' }}
                            >no records found</div> :
                            <div className="mt-5">
                                <div className="row">
                                    <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                        Description
                                    </div>
                                    <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                        Delete
                                    </div>
                                </div>

                                {this.state.Steps && this.state.Steps.length > 0 && this.state.Steps
                                    .map((item, index) => (

                                        <div
                                            className="row rowdata">
                                            <div className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                {/* {item.Action && item.Action.notes} */}
                                                {item.Action && item.Action.notes && item.Action.notes.length > 50 ? `${item.Action.notes.substring(0, 50)}...` : item.Action.notes}
                                            </div>
                                            <div className="col-md-2 pt-3 pb-3 opencampaigntext102">
                                                <DeleteIcon
                                                    onClick={() => this.DeleteIconData(item)}
                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                            </div>
                                        </div>
                                    ))}

                            </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                onClick={this.notesInsertDetails}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Save</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        managerWorkStacklist: userinfo => dispatch(managerWorkStacklist(userinfo)),
        managerWorkStackNotes: userinfo => dispatch(managerWorkStackNotes(userinfo)),
        notesInsert: userinfo => dispatch(notesInsert(userinfo)),
        inputFile: userinfo => dispatch(inputFile(userinfo)),
        launchtool: userinfo => dispatch(launchtool(userinfo)),
        userDetails: userinfo => dispatch(userDetails(userinfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyWorkStack);

