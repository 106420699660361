import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { datashaperlist, dataSetList, netLineInsert, netlineInputInsert } from "../../../actions/Campaignactions";
import Header from "../../../components/Header";
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NetlineNav from "../../../components/NetlineNav";
import Radio from '@mui/material/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from "react-js-loader";

import AWS from 'aws-sdk';

//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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'
})

//uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARKRGNQL5O',
//     secretAccessKey: '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'
// })

//dev
const S3_BUCKET = 'solpubwithsse';

//UAT
// const S3_BUCKET = 'netlinetool';


const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900; // in seconds

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

toast.configure()





toast.configure()
class Step1 extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            fields: {},
            errors: {},
            input_files: '',
            offer_codes: '',
            sourcefile: [],
            recipes: [],
            recipeList: [],
            datasetList: [],
            checked: false,
            mode: '',
            test_mode: 'N',
            loading: false,
            selectedValue: 'basic'

        };

    }


    checkValue = (event) => {
        if (event.target.checked === true) {
            this.setState({ mode: 'test', test_mode: 'Y', checked: event.target.checked });
        }
        else {
            this.setState({ mode: '', test_mode: 'N' });
        }
    };


    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    generatePreSignedPutUrl = (fileName, fileType) => {
        myBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.input_files[0]
            }).then((res) => {
                console.log(res, "resssssssssssss")
                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }


    generatePreSignedPutUrlForOutput = (fileName, fileType) => {
        myBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.offer_codes[0]
            }).then((res) => {
                console.log(res, "resssssssssssss")
                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }


    uploadInputFiles = (e) => {
        this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type)
        this.setState({ input_files: e.target.files })
    }

    handleChangeRadio = (e) => {
        this.setState({ selectedValue: e.target.value })
    }


    notify = () => {
        toast.success('successfully launched', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }


    handleOfferCodesList = (e) => {
        this.generatePreSignedPutUrlForOutput(e.target.files[0].name, e.target.files[0].type)
        this.setState({ offer_codes: e.target.files })

    }

    notify = () => {
        toast.success('Thanks. Batch submitted. Please view status on Dashboard', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    notify1 = () => {
        toast.success('Thanks. Batch submitted. Please view status on Dashboard', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }


    notifyerror = () => {
        toast.success('details already exists', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }



    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }

        this.props.datashaperlist();
        this.props.dataSetList()
    }



    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.netlineinsert &&
            this.props.campaignReducer.netlineinsert !==
            nextProps.campaignReducer.netlineinsert &&
            this.props.campaignReducer.netlineinsertSuccess === true
        ) {
            // this.notify()
            // console.log(this.props.campaignReducer.netlineinsert, "in true mode")
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.netlineinputinsert &&
            this.props.campaignReducer.netlineinputinsert !==
            nextProps.campaignReducer.netlineinputinsert &&
            this.props.campaignReducer.netlineinputinsertSuccess === true
        ) {
            console.log("after node api success ")
            this.notify()
            const userinfo = {
                //dev
                bucketName: 'solpubwithsse',
                //UAT
                // bucketName: 'netlinetool',
                input: this.state.input_files[0].name,
                offer: this.state.offer_codes[0].name,
                batchName: this.state.fields.batch_name,
                mode: this.state.mode,
                executionMode: this.state.selectedValue
            };
            this.props.netLineInsert(userinfo)
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.netlineinputinsert &&
            this.props.campaignReducer.netlineinputinsert !==
            nextProps.campaignReducer.netlineinputinsert &&
            this.props.campaignReducer.netlineinputinsertSuccess === false
        ) {
            this.notifyerror()
        }
    }
    validateNetline() {
        const errors = {};
        const { fields } = this.state;
        const strings = /^[A-Za-z0-9 ]+$/;

        let formIsValid = true;

        if (!fields.batch_name) {
            errors.batch_name = 'Please enter batch name ';
            formIsValid = false;
        }

        if (fields.batch_name && fields.batch_name.length < 3) {
            errors.batch_name = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.batch_name && fields.batch_name.length > 500) {
            errors.batch_name = 'batch name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.batch_name && !strings.test(fields.batch_name)) {
            errors.batch_name = 'Please enter characters only';
            formIsValid = false;
        }
        if (this.state.offer_codes === '') {
            errors.offer_codes = 'Please select offer file ';
            formIsValid = false;
        }
        if (this.state.input_files.length === 0) {
            errors.input_files = 'Please select input file ';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;


    }

    //customersetup insert
    netLine = () => {
        if (this.validateNetline()) {
            // this.setState({ loading: true })
            const userinfo1 = {
                input: this.state.input_files[0].name,
                offer: this.state.offer_codes[0].name,
                batchName: this.state.fields.batch_name,
                test_mode: this.state.test_mode,
                execution_mode: this.state.selectedValue,
                mode: this.state.mode
            };
            this.props.netlineInputInsert(userinfo1)
        }
    };
    render() {
        const { redirectpath, fields, errors } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NetlineNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Netline</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <div className="row container">
                                            <div className="col-md-12 col-12 col-sm-12">
                                                <p className="inputlabel mt-3 mb-3">Batch Name</p>
                                                <input
                                                    type="text"
                                                    value={fields.batch_name}
                                                    onChange={this.handleChange.bind(this, "batch_name")}
                                                    className="campaigntext"
                                                    placeholder="Batch Name"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.batch_name}
                                                </span>
                                            </div>

                                            <label className="inputlabel  mt-3 mb-3">Input File</label>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <label htmlFor="chooseInputFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                        {this.state.input_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files[0].name

                                                        }
                                                        <input
                                                            className="Field"
                                                            id="chooseInputFile"
                                                            onChange={this.uploadInputFiles}
                                                            accept=".csv"
                                                            type="file"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                        <span className="browsebutton">
                                                            <span className="browsebuttontext">Browse</span>
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <span className="errors mt-2">
                                                {errors && errors.input_files}
                                            </span>

                                            <label className="inputlabel  mt-3 pt-3 mb-3">Offer Codes List File</label>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <label htmlFor="chooseOfferCodesFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                        {this.state.offer_codes === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.offer_codes[0].name}
                                                        <input
                                                            className="Field"
                                                            id="chooseOfferCodesFile"
                                                            onChange={this.handleOfferCodesList}
                                                            accept=".csv"
                                                            type="file"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                        <span className="browsebutton">
                                                            <span className="browsebuttontext">Browse</span>
                                                        </span>
                                                    </div>
                                                </label>

                                            </div>
                                            <span className="errors mt-2">
                                                {errors && errors.offer_codes}
                                            </span>

                                            <div className="col-md-12 col-12 col-sm-12">
                                                <p className="inputlabel mt-3 pt-3">Execution Mode</p>
                                                <div className="groupnetlinebutton">
                                                    <Radio
                                                        checked={this.state.selectedValue === 'basic'}
                                                        onChange={this.handleChangeRadio}
                                                        value="basic"
                                                        size={'small'}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                    <span
                                                        style={{ alignSelf: 'center', marginLeft: '10px', marginRight: '14px' }}
                                                        className="netlineyes"
                                                    >Basic</span>
                                                    <Radio
                                                        checked={this.state.selectedValue === 'extended'}
                                                        onChange={this.handleChangeRadio}
                                                        value="extended"
                                                        size={'small'}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                    />
                                                    <span
                                                        style={{ alignSelf: 'center', marginLeft: '10px', }}
                                                        className="netlineyes"
                                                    >Extended</span>

                                                </div>
                                            </div>


                                            <div className="col-md-12 col-sm-12 col-12">
                                                <p className="inputlabel mt-3">Test Mode</p>

                                                {/* <input type="checkbox" /> */}
                                                {/* <Checkbox
                                                    value={this.state.checked}
                                                    onClick={this.checkValue}
                                                // style={{ alignSelf: 'center' }}
                                                /> */}
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0 }} >
                                                <Checkbox
                                                    value={this.state.checked}
                                                    onClick={this.checkValue}
                                                    className="netlineyes1"
                                                    style={{ alignSelf: 'center' }}
                                                />
                                                <span
                                                    style={{ alignSelf: 'center' }}
                                                    className="netlineyes"
                                                >Yes</span>
                                            </div>

                                            <span className="errors mt-2">
                                                {errors && errors.duplicate_name}
                                            </span>

                                        </div>

                                    </Scrollbars>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.netLine}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Submit to Netline</span>
                                </button>
                            </div>
                            {this.state.loading === true ?
                                <Loader type="spinner-circle" bgColor={"rgba(29, 65, 122, 1)"} color={'rgba(29, 65, 122, 1)'} size={100} /> :
                                null}
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlist: userinfo => dispatch(datashaperlist(userinfo)),
        dataSetList: userinfo => dispatch(dataSetList(userinfo)),
        netLineInsert: userinfo => dispatch(netLineInsert(userinfo)),
        netlineInputInsert: userinfo => dispatch(netlineInputInsert(userinfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1);


