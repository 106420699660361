import {
    OPENCAMPAIGN_LIST_LOADING,
    OPENCAMPAIGN_LIST_SUCCESS,
    OPENCAMPAIGN_LIST_FAILURE,

    MYWORKSTACK_LIST_LOADING,
    MYWORKSTACK_LIST_SUCCESS,
    MYWORKSTACK_LIST_FAILURE,

    HOT_MOVE_LOADING,
    HOT_MOVE_SUCCESS,
    HOT_MOVE_FAILURE,

    WHAT_IS_HOT_LIST_LOADING,
    WHAT_IS_HOT_LIST_SUCCESS,
    WHAT_IS_HOT_LIST_FAILURE,

    MANAGERWORKSTACK_LIST_LOADING,
    MANAGERWORKSTACK_LIST_SUCCESS,
    MANAGERWORKSTACK_LIST_FAILURE,

    READYTOBILL_LIST_LOADING,
    READYTOBILL_LIST_SUCCESS,
    READYTOBILL_LIST_FAILURE,

    ARCMAN_LOGIN_LOADING,
    ARCMAN_LOGIN_SUCCESS,
    ARCMAN_LOGIN_FAILURE,

    MANAGER_NOTES_LOADING,
    MANAGER_NOTES_SUCCESS,
    MANAGER_NOTES_FAILURE,

    SES_NOTIFICATION_LOADING,
    SES_NOTIFICATION_SUCCESS,
    SES_NOTIFICATION_FAILURE,

    NOTES_INSERT_LOADING,
    NOTES_INSERT_SUCCESS,
    NOTES_INSERT_FAILURE,

    INPUT_FILE_LOADING,
    INPUT_FILE_SUCCESS,
    INPUT_FILE_FAILURE,

    LAUNCH_TOOL_LOADING,
    LAUNCH_TOOL_SUCCESS,
    LAUNCH_TOOL_FAILURE,

    CAMPAIGN_NAMELIST_LOADING,
    CAMPAIGN_NAMELIST_SUCCESS,
    CAMPAIGN_NAMELIST_FAILURE,

    TOOL_WAITINGTIME_LOADING,
    TOOL_WAITINGTIME_SUCCESS,
    TOOL_WAITINGTIME_FAILURE

} from "../actions/constants";

const initialState = {
    openCampaignList: {},
    openCampaignListSuccess: false,
    myworkstackList: {},
    myworkstackListSuccess: false,
    managerworkstackList: {},
    managerworkstackListSuccess: false,
    readytobillList: {},
    readytobillListSuccess: false,
    hotMove: {},
    hotMoveSuccess: false,
    arcMan: {},
    managerNotes: {},
    managerNotesSuccess: false,
    arcManSuccess: false,
    whatishotList: {},
    whatishotListSuccess: false,
    sesNotification: {},
    sesNotificationSuccess: false,
    notesinsert: {},
    notesinsertSuccess: false,
    inpufile: {},
    inpufileSuccess: false,
    laucnTool: {},
    laucnToolSuccess: false,
    toolwaiting: {},
    toolwaitingSuccess: false,
    campaignnamelist: {},
    campaignnamelistSuccess: false,
};
export default function managerReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        //tool waiting
        case TOOL_WAITINGTIME_LOADING:
            return {
                ...state,
                toolwaitingSuccess: false
            };
        case TOOL_WAITINGTIME_SUCCESS:
            return {
                ...state,
                toolwaiting: action.payload,
                toolwaitingSuccess: true
            };
        case TOOL_WAITINGTIME_FAILURE:
            return {
                ...state,
                toolwaiting: action.payload,
                toolwaitingSuccess: false
            };
        //campaign name list
        case CAMPAIGN_NAMELIST_LOADING:
            return {
                ...state,
                campaignnamelistSuccess: false
            };
        case CAMPAIGN_NAMELIST_SUCCESS:
            return {
                ...state,
                campaignnamelist: action.payload,
                campaignnamelistSuccess: true
            };
        case CAMPAIGN_NAMELIST_FAILURE:
            return {
                ...state,
                campaignnamelist: action.payload,
                campaignnamelistSuccess: false
            };
        //ses notification
        case NOTES_INSERT_LOADING:
            return {
                ...state,
                notesinsertSuccess: false
            };
        case NOTES_INSERT_SUCCESS:
            return {
                ...state,
                notesinsert: action.payload,
                notesinsertSuccess: true
            };
        case NOTES_INSERT_FAILURE:
            return {
                ...state,
                notesinsert: action.payload,
                notesinsertSuccess: false
            };
        //input file
        case INPUT_FILE_LOADING:
            return {
                ...state,
                inpufileSuccess: false
            };
        case INPUT_FILE_SUCCESS:
            return {
                ...state,
                inpufile: action.payload,
                inpufileSuccess: true
            };
        case INPUT_FILE_FAILURE:
            return {
                ...state,
                inpufile: action.payload,
                inpufileSuccess: false
            };

        //launch tool
        case LAUNCH_TOOL_LOADING:
            return {
                ...state,
                laucnToolSuccess: false
            };
        case LAUNCH_TOOL_SUCCESS:
            return {
                ...state,
                laucnTool: action.payload,
                laucnToolSuccess: true
            };
        case LAUNCH_TOOL_FAILURE:
            return {
                ...state,
                laucnTool: action.payload,
                laucnToolSuccess: false
            };

        case OPENCAMPAIGN_LIST_LOADING:
            return {
                ...state,
                openCampaignListSuccess: false
            };
        case OPENCAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                openCampaignList: action.payload,
                openCampaignListSuccess: true
            };
        case OPENCAMPAIGN_LIST_FAILURE:
            return {
                ...state,
                openCampaignList: action.payload,
                openCampaignListSuccess: false
            };
        //ses notification
        case SES_NOTIFICATION_LOADING:
            return {
                ...state,
                sesNotificationSuccess: false
            };
        case SES_NOTIFICATION_SUCCESS:
            return {
                ...state,
                sesNotification: action.payload,
                sesNotificationSuccess: true
            };
        case SES_NOTIFICATION_FAILURE:
            return {
                ...state,
                sesNotification: action.payload,
                sesNotificationSuccess: false
            };

        case MYWORKSTACK_LIST_LOADING:
            return {
                ...state,
                myworkstackListSuccess: false
            };
        case MYWORKSTACK_LIST_SUCCESS:
            return {
                ...state,
                myworkstackList: action.payload,
                myworkstackListSuccess: true
            };
        case MYWORKSTACK_LIST_FAILURE:
            return {
                ...state,
                myworkstackList: action.payload,
                myworkstackListSuccess: false
            };

        case HOT_MOVE_LOADING:
            return {
                ...state,
                hotMoveSuccess: false
            };
        case HOT_MOVE_SUCCESS:
            return {
                ...state,
                hotMove: action.payload,
                hotMoveSuccess: true
            };
        case HOT_MOVE_FAILURE:
            return {
                ...state,
                hotMove: action.payload,
                hotMoveSuccess: false
            };

        case WHAT_IS_HOT_LIST_LOADING:
            return {
                ...state,
                whatishotListSuccess: false
            };
        case WHAT_IS_HOT_LIST_SUCCESS:
            return {
                ...state,
                whatishotList: action.payload,
                whatishotListSuccess: true
            };
        case WHAT_IS_HOT_LIST_FAILURE:
            return {
                ...state,
                whatishotList: action.payload,
                whatishotListSuccess: false
            };

        case MANAGERWORKSTACK_LIST_LOADING:
            return {
                ...state,
                managerworkstackListSuccess: false
            };
        case MANAGERWORKSTACK_LIST_SUCCESS:
            return {
                ...state,
                managerworkstackList: action.payload,
                managerworkstackListSuccess: true
            };
        case MANAGERWORKSTACK_LIST_FAILURE:
            return {
                ...state,
                managerworkstackList: action.payload,
                managerworkstackListSuccess: false
            };

        case READYTOBILL_LIST_LOADING:
            return {
                ...state,
                readytobillListSuccess: false
            };
        case READYTOBILL_LIST_SUCCESS:
            return {
                ...state,
                readytobillList: action.payload,
                readytobillListSuccess: true
            };
        case READYTOBILL_LIST_FAILURE:
            return {
                ...state,
                readytobillList: action.payload,
                readytobillListSuccess: false
            };

        case ARCMAN_LOGIN_LOADING:
            return {
                ...state,
                arcManSuccess: false
            };
        case ARCMAN_LOGIN_SUCCESS:
            return {
                ...state,
                arcMan: action.payload,
                arcManSuccess: true
            };
        case ARCMAN_LOGIN_FAILURE:
            return {
                ...state,
                arcMan: action.payload,
                arcManSuccess: false
            };

        case MANAGER_NOTES_LOADING:
            return {
                ...state,
                managerNotesSuccess: false
            };
        case MANAGER_NOTES_SUCCESS:
            return {
                ...state,
                managerNotes: action.payload,
                managerNotesSuccess: true
            };
        case MANAGER_NOTES_FAILURE:
            return {
                ...state,
                managerNotes: action.payload,
                managerNotesSuccess: false
            };
        default:
            return state;
    }
}