import {
    CAMPAIGN_LIST_LOADING,
    CAMPAIGN_LIST_SUCCESS,
    CAMPAIGN_LIST_FAILURE,

    TOOL_LIST_LOADING,
    TOOL_LIST_SUCCESS,
    TOOL_LIST_FAILURE,

    TOOL_SWAP_LIST_LOADING,
    TOOL_SWAP_LIST_SUCCESS,
    TOOL_SWAP_LIST_FAILURE,

    USER_LIST_LOADING,
    USER_LIST_SUCCESS,
    USER_LIST_FAILURE,

    CAMPAIGNSETUP_INSERT_LOADING,
    CAMPAIGNSETUP_INSERT_SUCCESS,
    CAMPAIGNSETUP_INSERT_FAILURE,

    CUSTOMERSETUP_INSERT_LOADING,
    CUSTOMERSETUP_INSERT_SUCCESS,
    CUSTOMERSETUP_INSERT_FAILURE,

    TOOLCAMPAIGN_INSERT_LOADING,
    TOOLCAMPAIGN_INSERT_SUCCESS,
    TOOLCAMPAIGN_INSERT_FAILURE,

    CAMPAIGN_UPDATE_LOADING,
    CAMPAIGN_UPDATE_SUCCESS,
    CAMPAIGN_UPDATE_FAILURE,

    CUSTOMER_PREFILLDATA_LOADING,
    CUSTOMER_PREFILLDATA_SUCCESS,
    CUSTOMER_PREFILLDATA_FAILURE,

    CUSTOMER_LASTRECORDS_LOADING,
    CUSTOMER_LASTRECORDS_SUCCESS,
    CUSTOMER_LASTRECORDS_FAILURE,


    COUNTRY_LIST_LOADING,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAILURE,

    STATE_LIST_LOADING,
    STATE_LIST_SUCCESS,
    STATE_LIST_FAILURE,

    NOTIFICATION_LIST_LOADING,
    NOTIFICATION_LIST_SUCCESS,
    NOTIFICATION_LIST_FAILURE,

    USER_STATES_LOADING,
    USER_STATES_SUCCESS,
    USER_STATES_FAILURE,

    UPDATE_PROFILE_LOADING,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,

    PROFILE_DATA_LOADING,
    PROFILE_DATA_SUCCESS,
    PROFILE_DATA_FAILURE,

    LINKEDIN_LIST_LOADING,
    LINKEDIN_LIST_SUCCESS,
    LINKEDIN_LIST_FAILURE,

    BARGRAPH_DATA_LOADING,
    BARGRAPH_DATA_SUCCESS,
    BARGRAPH_DATA_FAILURE,

    PRODUCTIVITY_DATA_LOADING,
    PRODUCTIVITY_DATA_SUCCESS,
    PRODUCTIVITY_DATA_FAILURE,

    DATASHAPER_LIST_LOADING,
    DATASHAPER_LIST_SUCCESS,
    DATASHAPER_LIST_FAILURE,

    DATASETS_LIST_LOADING,
    DATASETS_LIST_SUCCESS,
    DATASETS_LIST_FAILURE,

    NETLINE_INSERT_LOADING,
    NETLINE_INSERT_SUCCESS,
    NETLINE_INSERT_FAILURE,

    NETLINE_LIST_LOADING,
    NETLINE_LIST_SUCCESS,
    NETLINE_LIST_FAILURE,

    NETLINE_INPUT_INSERT_LOADING,
    NETLINE_INPUT_INSERT_SUCCESS,
    NETLINE_INPUT_INSERT_FAILURE,

    CAMPAIGN_DELETE_LOADING,
    CAMPAIGN_DELETE_SUCCESS,
    CAMPAIGN_DELETE_FAILURE,

    CREATE_RECIPE_LOADING,
    CREATE_RECIPE_SUCCESS,
    CREATE_RECIPE_FAILURE,

    CREATE_DATASET_LOADING,
    CREATE_DATASET_SUCCESS,
    CREATE_DATASET_FAILURE,

    START_JOB_LOADING,
    START_JOB_SUCCESS,
    START_JOB_FAILURE,

    DATASHAPER_INSERT_LOADING,
    DATASHAPER_INSERT_SUCCESS,
    DATASHAPER_INSERT_FAILURE,

    DATASHAPER_LISTDATA_LOADING,
    DATASHAPER_LISTDATA_SUCCESS,
    DATASHAPER_LISTDATA_FAILURE,

    NETLINE_POPUP_LOADING,
    NETLINE_POPUP_SUCCESS,
    NETLINE_POPUP_FAILURE,

    SOURCE_LIST_DATA_LOADING,
    SOURCE_LIST_DATA_SUCCESS,
    SOURCE_LIST_DATA_FAILURE,

    TARGET_LIST_DATA_LOADING,
    TARGET_LIST_DATA_SUCCESS,
    TARGET_LIST_DATA_FAILURE,

    DELIVERY_TEMPLATE_INSERT_LOADING,
    DELIVERY_TEMPLATE_INSERT_SUCCESS,
    DELIVERY_TEMPLATE_INSERT_FAILURE,

    JOB_UPDATE_LOADING,
    JOB_UPDATE_SUCCESS,
    JOB_UPDATE_FAILURE,

    JOB_STATUS_LOADING,
    JOB_STATUS_SUCCESS,
    JOB_STATUS_FAILURE,

    DELIVERY_DYNAMIC_LOADING,
    DELIVERY_DYNAMIC_SUCCESS,
    DELIVERY_DYNAMIC_FAILURE,

    PUBLISH_RECEPIE_LOADING,
    PUBLISH_RECEPIE_SUCCESS,
    PUBLISH_RECEPIE_FAILURE,

    DELETE_JOB_LOADING,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_FAILURE,

    DELETE_DATASET_LOADING,
    DELETE_DATASET_SUCCESS,
    DELETE_DATASET_FAILURE,

    DELETE_DATASETDB_LOADING,
    DELETE_DATASETDB_SUCCESS,
    DELETE_DATASETDB_FAILURE,

    DATASHAPER_NOTIFICATION_LOADING,
    DATASHAPER_NOTIFICATION_SUCCESS,
    DATASHAPER_NOTIFICATION_FAILURE,

    DATASHAPER_NOTIFICATIONLIST_LOADING,
    DATASHAPER_NOTIFICATIONLIST_SUCCESS,
    DATASHAPER_NOTIFICATIONLIST_FAILURE,

    FETCH_COLUMN_NAMES_LOADING,
    FETCH_COLUMN_NAMES_SUCCESS,
    FETCH_COLUMN_NAMES_FAILURE,

    LIST_OF_TITLES_LOADING,
    LIST_OF_TITLES_SUCCESS,
    LIST_OF_TITLES_FAILURE,

    GET_FILE_NAME_LOADING,
    GET_FILE_NAME_SUCCESS,
    GET_FILE_NAME_FAILURE,


    NLP_INSERT_LOADING,
    NLP_INSERT_SUCCESS,
    NLP_INSERT_FAILURE,

    NLP_MATCH_LOADING,
    NLP_MATCH_SUCCESS,
    NLP_MATCH_FAILURE,


    NLP_DASHBOARD_LOADING,
    NLP_DASHBOARD_SUCCESS,
    NLP_DASHBOARD_FAILURE,

    CREATE_TITLE_BUCKET_LOADING,
    CREATE_TITLE_BUCKET_SUCCESS,
    CREATE_TITLE_BUCKET_FAILURE,

    LIST_TITLE_BUCKET_LOADING,
    LIST_TITLE_BUCKET_SUCCESS,
    LIST_TITLE_BUCKET_FAILURE,


    DELETE_TITLE_BUCKET_LOADING,
    DELETE_TITLE_BUCKET_SUCCESS,
    DELETE_TITLE_BUCKET_FAILURE,


    UPDATE_TITLE_BUCKET_LOADING,
    UPDATE_TITLE_BUCKET_SUCCESS,
    UPDATE_TITLE_BUCKET_FAILURE,


    APPEND_TITLE_BUCKET_LOADING,
    APPEND_TITLE_BUCKET_SUCCESS,
    APPEND_TITLE_BUCKET_FAILURE,


    PREFILL_TITLE_BUCKET_LOADING,
    PREFILL_TITLE_BUCKET_SUCCESS,
    PREFILL_TITLE_BUCKET_FAILURE,

    FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_LOADING,
    FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_SUCCESS,
    FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_FAILURE,

    FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_LOADING,
    FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_SUCCESS,
    FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_FAILURE,

    BATCH_ID_LOADING,
    BATCH_ID_SUCCESS,
    BATCH_ID_FAILURE,


    GET_NLP_OUTPUTFILE_LOADING,
    GET_NLP_OUTPUTFILE_SUCCESS,
    GET_NLP_OUTPUTFILE_FAILURE,


    INSERT_RECIPIE_NAME_LOADING,
    INSERT_RECIPIE_NAME_SUCCESS,
    INSERT_RECIPIE_NAME_FAILURE,

    GET_LEADSHAPER_FILES_LOADING,
    GET_LEADSHAPER_FILES_SUCCESS,
    GET_LEADSHAPER_FILES_FAILURE,


    STATUS_DASHBOARD_LOADING,
    STATUS_DASHBOARD_SUCCESS,
    STATUS_DASHBOARD_FAILURE,


    GET_STATUS_LOADING,
    GET_STATUS_SUCCESS,
    GET_STATUS_FAILURE,







} from "../actions/constants";

const initialState = {
    campaignList: {},
    campaignListSuccess: false,

    fetchTitleFileColumnNames: {},
    fetchTitleFileColumnNamesSuccess: false,

    toolList: {},
    toolListSuccess: false,

    countryList: {},
    countryListSuccess: false,

    stateList: {},
    stateListSuccess: false,

    toolSwapList: {},
    toolSwapListSuccess: false,

    notificationList: {},
    notificationListSuccess: false,

    userList: {},
    userListSuccess: false,

    lastRecords: {},
    lastRecordsSuccess: false,

    campaignSetUpInsert: {},
    campaignSetUpInsertSuccess: false,

    customerSetUpInsert: {},
    customerSetUpInsertSuccess: false,

    customerprefilldata: {},
    customerprefilldataSuccess: false,

    toolCampaignInsert: {},
    toolCampaignInsertSuccess: false,

    campaignUpdate: {},
    campaignUpdateSuccess: false,

    userStatus: {},
    userStatusSuccess: false,

    profileupdate: {},
    profileupdateSuccess: false,

    profiledata: {},
    profiledataSuccess: false,

    linkedindata: {},
    linkedindataSuccess: false,

    bardata: {},
    bardataSuccess: false,

    productivitydata: {},
    productivitydataSuccess: false,


    datashaperlist: {},
    datashaperlistSuccess: false,

    datasetlist: {},
    datasetlistSuccess: false,

    netlineinsert: {},
    netlineinsertSuccess: false,

    netlinelist: {},
    netlinelistSuccess: false,

    netlineinputinsert: {},
    netlineinputinsertSuccess: false,

    campaigndelete: {},
    campaigndeleteSuccess: false,

    createrecipe: {},
    createrecipeSuccess: false,

    createdataset: {},
    createdatasetSuccess: false,

    startjob: {},
    startjobSuccess: false,


    datashaperInsert: {},
    datashaperInsertSuccess: false,

    datashaperlistdata: {},
    datashaperlistdataSuccess: false,

    netlinepopup: {},
    netlinepopupSuccess: false,

    sourcelistdata: {},
    sourcelistdataSuccess: false,

    targetlistdata: {},
    targetlistdataSuccess: false,

    delivertemplate: {},
    delivertemplateSuccess: false,

    jobupdate: {},
    jobupdateSuccess: false,

    jobstatus: {},
    jobstatusSuccess: false,

    deliverydynamictemplate: {},
    deliverydynamictemplateSuccess: false,

    publishrecpie: {},
    publishrecpieSuccess: false,

    deletejob: {},
    deletejobSuccess: false,

    deletejobdb: {},
    deletejobdbSuccess: false,

    deletedataset: {},
    deletedatasetSuccess: false,

    datashapersnsnotification: {},
    datashapersnsnotificationSuccess: false,

    datashapernotificationlist: {},
    datashapernotificationlistSuccess: false,

    fetchColumns: {},
    fetchColumnsSuccess: false,

    listTitles: {},
    listTitlesSuccess: false,

    getFileName: {},
    getFileNameSuccess: false,

    nlpInsert: {},
    nlpInsertSuccess: false,


    nlpMatch: {},
    nlpMatchSuccess: false,

    nlpDashboard: {},
    nlpDashboardSuccess: false,


    createTitleBucket: {},
    createTitleBucketSuccess: false,

    listTitleBucket: {},
    listTitleBucketSuccess: false,

    deletebucket: {},
    deletebucketSuccess: false,

    updatebucket: {},
    updatebucketSuccess: false,

    appendtitle: {},
    appendtitleSuccess: false,


    prefillbucket: {},
    prefillbucketSuccess: false,

    fetchDelTemColumnNames: {},
    fetchDelTemColumnNamesSuccess: false,

    batchIdList: {},
    batchIdListSuccess: false,

    getNLPOutPutFile: {},
    getNLPOutPutFileSuccess: false,

    insertRecipieNameData: {},
    insertRecipieNameDataSuccess: false,

    getLeadShaperFiles: {},
    getLeadShaperFilesSuccess: false,

    statusdashboarddata: {},
    statusdashboarddataSuccess: false,

    getstatusdata: {},
    getstatusdataSuccess: false

};
export default function adminReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {

        case STATUS_DASHBOARD_LOADING:
            return {
                ...state,
                statusdashboarddataSuccess: false
            };
        case STATUS_DASHBOARD_SUCCESS:
            return {
                ...state,
                statusdashboarddata: action.payload,
                statusdashboarddataSuccess: true
            };
        case STATUS_DASHBOARD_FAILURE:
            return {
                ...state,
                statusdashboarddata: action.payload,
                statusdashboarddataSuccess: false
            };
            

        case GET_STATUS_LOADING:
            return {
                ...state,
                getstatusdataSuccess: false
            };
        case GET_STATUS_SUCCESS:
            return {
                ...state,
                getstatusdata: action.payload,
                getstatusdataSuccess: true
            };
        case GET_STATUS_FAILURE:
            return {
                ...state,
                getstatusdata: action.payload,
                getstatusdataSuccess: false
            };


        case GET_LEADSHAPER_FILES_LOADING:
            return {
                ...state,
                getLeadShaperFilesSuccess: false
            };
        case GET_LEADSHAPER_FILES_SUCCESS:
            return {
                ...state,
                getLeadShaperFiles: action.payload,
                getLeadShaperFilesSuccess: true
            };
        case GET_LEADSHAPER_FILES_FAILURE:
            return {
                ...state,
                getLeadShaperFiles: action.payload,
                getLeadShaperFilesSuccess: false
            };

        case INSERT_RECIPIE_NAME_LOADING:
            return {
                ...state,
                insertRecipieNameDataSuccess: false
            };
        case INSERT_RECIPIE_NAME_SUCCESS:
            return {
                ...state,
                insertRecipieNameData: action.payload,
                insertRecipieNameDataSuccess: true
            };
        case INSERT_RECIPIE_NAME_FAILURE:
            return {
                ...state,
                insertRecipieNameData: action.payload,
                insertRecipieNameDataSuccess: false
            };

        case GET_NLP_OUTPUTFILE_LOADING:
            return {
                ...state,
                getNLPOutPutFileSuccess: false
            };
        case GET_NLP_OUTPUTFILE_SUCCESS:
            return {
                ...state,
                getNLPOutPutFile: action.payload,
                getNLPOutPutFileSuccess: true
            };
        case GET_NLP_OUTPUTFILE_FAILURE:
            return {
                ...state,
                getNLPOutPutFile: action.payload,
                getNLPOutPutFileSuccess: false
            };

        case BATCH_ID_LOADING:
            return {
                ...state,
                batchIdListSuccess: false
            };
        case BATCH_ID_SUCCESS:
            return {
                ...state,
                batchIdList: action.payload,
                batchIdListSuccess: true
            };
        case BATCH_ID_FAILURE:
            return {
                ...state,
                batchIdList: action.payload,
                batchIdListSuccess: false
            };

        case FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_LOADING:
            return {
                ...state,
                fetchDelTemColumnNamesSuccess: false
            };
        case FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                fetchDelTemColumnNames: action.payload,
                fetchDelTemColumnNamesSuccess: true
            };
        case FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                fetchDelTemColumnNames: action.payload,
                fetchDelTemColumnNamesSuccess: false
            };


        case FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_LOADING:
            return {
                ...state,
                fetchTitleFileColumnNamesSuccess: false
            };
        case FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                fetchTitleFileColumnNames: action.payload,
                fetchTitleFileColumnNamesSuccess: true
            };
        case FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                fetchTitleFileColumnNames: action.payload,
                fetchTitleFileColumnNamesSuccess: false
            };


        case PREFILL_TITLE_BUCKET_LOADING:
            return {
                ...state,
                prefillbucketSuccess: false
            };
        case PREFILL_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                prefillbucket: action.payload,
                prefillbucketSuccess: true
            };
        case PREFILL_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                prefillbucket: action.payload,
                prefillbucketSuccess: false
            };

        case DELETE_TITLE_BUCKET_LOADING:
            return {
                ...state,
                deletebucketSuccess: false
            };
        case DELETE_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                deletebucket: action.payload,
                deletebucketSuccess: true
            };
        case DELETE_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                deletebucket: action.payload,
                deletebucketSuccess: false
            };

        case UPDATE_TITLE_BUCKET_LOADING:
            return {
                ...state,
                updatebucketSuccess: false
            };
        case UPDATE_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                updatebucket: action.payload,
                updatebucketSuccess: true
            };
        case UPDATE_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                updatebucket: action.payload,
                updatebucketSuccess: false
            };

        case APPEND_TITLE_BUCKET_LOADING:
            return {
                ...state,
                appendtitleSuccess: false
            };
        case APPEND_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                appendtitle: action.payload,
                appendtitleSuccess: true
            };
        case APPEND_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                appendtitle: action.payload,
                appendtitleSuccess: false
            };


        case CREATE_TITLE_BUCKET_LOADING:
            return {
                ...state,
                createTitleBucketSuccess: false
            };
        case CREATE_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                createTitleBucket: action.payload,
                createTitleBucketSuccess: true
            };
        case CREATE_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                createTitleBucket: action.payload,
                createTitleBucketSuccess: false
            };

        case LIST_TITLE_BUCKET_LOADING:
            return {
                ...state,
                listTitleBucketSuccess: false
            };
        case LIST_TITLE_BUCKET_SUCCESS:
            return {
                ...state,
                listTitleBucket: action.payload,
                listTitleBucketSuccess: true
            };
        case LIST_TITLE_BUCKET_FAILURE:
            return {
                ...state,
                listTitleBucket: action.payload,
                listTitleBucketSuccess: false
            };


        case NLP_DASHBOARD_LOADING:
            return {
                ...state,
                nlpDashboardSuccess: false
            };
        case NLP_DASHBOARD_SUCCESS:
            return {
                ...state,
                nlpDashboard: action.payload,
                nlpDashboardSuccess: true
            };
        case NLP_DASHBOARD_FAILURE:
            return {
                ...state,
                nlpDashboard: action.payload,
                nlpDashboardSuccess: false
            };

        case NLP_INSERT_LOADING:
            return {
                ...state,
                nlpInsertSuccess: false
            };
        case NLP_INSERT_SUCCESS:
            return {
                ...state,
                nlpInsert: action.payload,
                nlpInsertSuccess: true
            };
        case NLP_INSERT_FAILURE:
            return {
                ...state,
                nlpInsert: action.payload,
                nlpInsertSuccess: false
            };

        case NLP_MATCH_LOADING:
            return {
                ...state,
                nlpMatchSuccess: false
            };
        case NLP_MATCH_SUCCESS:
            return {
                ...state,
                nlpMatch: action.payload,
                nlpMatchSuccess: true
            };
        case NLP_MATCH_FAILURE:
            return {
                ...state,
                nlpMatch: action.payload,
                nlpMatchSuccess: false
            };

        case GET_FILE_NAME_LOADING:
            return {
                ...state,
                getFileNameSuccess: false
            };
        case GET_FILE_NAME_SUCCESS:
            return {
                ...state,
                getFileName: action.payload,
                getFileNameSuccess: true
            };
        case GET_FILE_NAME_FAILURE:
            return {
                ...state,
                getFileName: action.payload,
                getFileNameSuccess: false
            };

        case FETCH_COLUMN_NAMES_LOADING:
            return {
                ...state,
                fetchColumnsSuccess: false
            };
        case FETCH_COLUMN_NAMES_SUCCESS:
            return {
                ...state,
                fetchColumns: action.payload,
                fetchColumnsSuccess: true
            };
        case FETCH_COLUMN_NAMES_FAILURE:
            return {
                ...state,
                fetchColumns: action.payload,
                fetchColumnsSuccess: false
            };

        case LIST_OF_TITLES_LOADING:
            return {
                ...state,
                listTitlesSuccess: false
            };
        case LIST_OF_TITLES_SUCCESS:
            return {
                ...state,
                listTitles: action.payload,
                listTitlesSuccess: true
            };
        case LIST_OF_TITLES_FAILURE:
            return {
                ...state,
                listTitles: action.payload,
                listTitlesSuccess: false
            };


        case DATASHAPER_NOTIFICATIONLIST_LOADING:
            return {
                ...state,
                datashapernotificationlistSuccess: false
            };
        case DATASHAPER_NOTIFICATIONLIST_SUCCESS:
            return {
                ...state,
                datashapernotificationlist: action.payload,
                datashapernotificationlistSuccess: true
            };
        case DATASHAPER_NOTIFICATIONLIST_FAILURE:
            return {
                ...state,
                datashapernotificationlist: action.payload,
                datashapernotificationlistSuccess: false
            };

        case DATASHAPER_NOTIFICATION_LOADING:
            return {
                ...state,
                datashapersnsnotificationSuccess: false
            };
        case DATASHAPER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                datashapersnsnotification: action.payload,
                datashapersnsnotificationSuccess: true
            };
        case DATASHAPER_NOTIFICATION_FAILURE:
            return {
                ...state,
                datashapersnsnotification: action.payload,
                datashapersnsnotificationSuccess: false
            };

        case DELETE_DATASETDB_LOADING:
            return {
                ...state,
                deletejobdbSuccess: false
            };
        case DELETE_DATASETDB_SUCCESS:
            return {
                ...state,
                deletejobdb: action.payload,
                deletejobdbSuccess: true
            };
        case DELETE_DATASETDB_FAILURE:
            return {
                ...state,
                deletejobdb: action.payload,
                deletejobdbSuccess: false
            };

        case DELETE_DATASET_LOADING:
            return {
                ...state,
                deletedatasetSuccess: false
            };
        case DELETE_DATASET_SUCCESS:
            return {
                ...state,
                deletedataset: action.payload,
                deletedatasetSuccess: true
            };
        case DELETE_DATASET_FAILURE:
            return {
                ...state,
                deletedataset: action.payload,
                deletedatasetSuccess: false
            };

        case DELETE_JOB_LOADING:
            return {
                ...state,
                deletejobSuccess: false
            };
        case DELETE_JOB_SUCCESS:
            return {
                ...state,
                deletejob: action.payload,
                deletejobSuccess: true
            };
        case DELETE_JOB_FAILURE:
            return {
                ...state,
                deletejob: action.payload,
                deletejobSuccess: false
            };

        case PUBLISH_RECEPIE_LOADING:
            return {
                ...state,
                publishrecpieSuccess: false
            };
        case PUBLISH_RECEPIE_SUCCESS:
            return {
                ...state,
                publishrecpie: action.payload,
                publishrecpieSuccess: true
            };
        case PUBLISH_RECEPIE_FAILURE:
            return {
                ...state,
                publishrecpie: action.payload,
                publishrecpieSuccess: false
            };

        case DELIVERY_DYNAMIC_LOADING:
            return {
                ...state,
                deliverydynamictemplateSuccess: false
            };
        case DELIVERY_DYNAMIC_SUCCESS:
            return {
                ...state,
                deliverydynamictemplate: action.payload,
                deliverydynamictemplateSuccess: true
            };
        case DELIVERY_DYNAMIC_FAILURE:
            return {
                ...state,
                deliverydynamictemplate: action.payload,
                deliverydynamictemplateSuccess: false
            };

        case JOB_STATUS_LOADING:
            return {
                ...state,
                jobstatusSuccess: false
            };
        case JOB_STATUS_SUCCESS:
            return {
                ...state,
                jobstatus: action.payload,
                jobstatusSuccess: true
            };
        case JOB_STATUS_FAILURE:
            return {
                ...state,
                jobstatus: action.payload,
                jobstatusSuccess: false
            };

        case JOB_UPDATE_LOADING:
            return {
                ...state,
                jobupdateSuccess: false
            };
        case JOB_UPDATE_SUCCESS:
            return {
                ...state,
                jobupdate: action.payload,
                jobupdateSuccess: true
            };
        case JOB_UPDATE_FAILURE:
            return {
                ...state,
                jobupdate: action.payload,
                jobupdateSuccess: false
            };


        case DELIVERY_TEMPLATE_INSERT_LOADING:
            return {
                ...state,
                delivertemplateSuccess: false
            };
        case DELIVERY_TEMPLATE_INSERT_SUCCESS:
            return {
                ...state,
                delivertemplate: action.payload,
                delivertemplateSuccess: true
            };
        case DELIVERY_TEMPLATE_INSERT_FAILURE:
            return {
                ...state,
                delivertemplate: action.payload,
                delivertemplateSuccess: false
            };

        case TARGET_LIST_DATA_LOADING:
            return {
                ...state,
                targetlistdataSuccess: false
            };
        case TARGET_LIST_DATA_SUCCESS:
            return {
                ...state,
                targetlistdata: action.payload,
                targetlistdataSuccess: true
            };
        case TARGET_LIST_DATA_FAILURE:
            return {
                ...state,
                targetlistdata: action.payload,
                targetlistdataSuccess: false
            };
        case SOURCE_LIST_DATA_LOADING:
            return {
                ...state,
                sourcelistdataSuccess: false
            };
        case SOURCE_LIST_DATA_SUCCESS:
            return {
                ...state,
                sourcelistdata: action.payload,
                sourcelistdataSuccess: true
            };
        case SOURCE_LIST_DATA_FAILURE:
            return {
                ...state,
                sourcelistdata: action.payload,
                sourcelistdataSuccess: false
            };

        case NETLINE_POPUP_LOADING:
            return {
                ...state,
                netlinepopupSuccess: false
            };
        case NETLINE_POPUP_SUCCESS:
            return {
                ...state,
                netlinepopup: action.payload,
                netlinepopupSuccess: true
            };
        case NETLINE_POPUP_FAILURE:
            return {
                ...state,
                netlinepopup: action.payload,
                netlinepopupSuccess: false
            };

        case DATASHAPER_LISTDATA_LOADING:
            return {
                ...state,
                datashaperlistdataSuccess: false
            };
        case DATASHAPER_LISTDATA_SUCCESS:
            return {
                ...state,
                datashaperlistdata: action.payload,
                datashaperlistdataSuccess: true
            };
        case DATASHAPER_LISTDATA_FAILURE:
            return {
                ...state,
                datashaperlistdata: action.payload,
                datashaperlistdataSuccess: false
            };

        case DATASHAPER_INSERT_LOADING:
            return {
                ...state,
                datashaperInsertSuccess: false
            };
        case DATASHAPER_INSERT_SUCCESS:
            return {
                ...state,
                datashaperInsert: action.payload,
                datashaperInsertSuccess: true
            };
        case DATASHAPER_INSERT_FAILURE:
            return {
                ...state,
                datashaperInsert: action.payload,
                datashaperInsertSuccess: false
            };


        case START_JOB_LOADING:
            return {
                ...state,
                startjobSuccess: false
            };
        case START_JOB_SUCCESS:
            return {
                ...state,
                startjob: action.payload,
                startjobSuccess: true
            };
        case START_JOB_FAILURE:
            return {
                ...state,
                startjob: action.payload,
                startjobSuccess: false
            };


        case CREATE_DATASET_LOADING:
            return {
                ...state,
                createdatasetSuccess: false
            };
        case CREATE_DATASET_SUCCESS:
            return {
                ...state,
                createdataset: action.payload,
                createdatasetSuccess: true
            };
        case CREATE_DATASET_FAILURE:
            return {
                ...state,
                createdataset: action.payload,
                createdatasetSuccess: false
            };

        case CREATE_RECIPE_LOADING:
            return {
                ...state,
                createrecipeSuccess: false
            };
        case CREATE_RECIPE_SUCCESS:
            return {
                ...state,
                createrecipe: action.payload,
                createrecipeSuccess: true
            };
        case CREATE_RECIPE_FAILURE:
            return {
                ...state,
                createrecipe: action.payload,
                createrecipeSuccess: false
            };

        //campaign delete
        case CAMPAIGN_DELETE_LOADING:
            return {
                ...state,
                campaigndeleteSuccess: false
            };
        case CAMPAIGN_DELETE_SUCCESS:
            // console.log(action.payload, "delete")
            return {
                ...state,
                campaigndelete: action.payload,
                campaigndeleteSuccess: true
            };
        case CAMPAIGN_DELETE_FAILURE:
            return {
                ...state,
                campaigndelete: action.payload,
                campaigndeleteSuccess: false
            };
        //netline input insert
        case NETLINE_INPUT_INSERT_LOADING:
            return {
                ...state,
                netlineinputinsertSuccess: false
            };
        case NETLINE_INPUT_INSERT_SUCCESS:
            return {
                ...state,
                netlineinputinsert: action.payload,
                netlineinputinsertSuccess: true
            };
        case NETLINE_INPUT_INSERT_FAILURE:
            return {
                ...state,
                netlineinputinsert: action.payload,
                netlineinputinsertSuccess: false
            };
        //netline list
        case NETLINE_LIST_LOADING:
            return {
                ...state,
                netlinelistSuccess: false
            };
        case NETLINE_LIST_SUCCESS:
            return {
                ...state,
                netlinelist: action.payload,
                netlinelistSuccess: true
            };
        case NETLINE_LIST_FAILURE:
            return {
                ...state,
                netlinelist: action.payload,
                netlinelistSuccess: false
            };

        //netline insert
        case NETLINE_INSERT_LOADING:
            return {
                ...state,
                netlineinsertSuccess: false
            };
        case NETLINE_INSERT_SUCCESS:
            console.log(action.payload, "payloadnetline")
            return {
                ...state,
                netlineinsert: action.payload,
                netlineinsertSuccess: true
            };
        case NETLINE_INSERT_FAILURE:
            return {
                ...state,
                netlineinsert: action.payload,
                netlineinsertSuccess: false
            };

        //get datasetlist
        case DATASETS_LIST_LOADING:
            return {
                ...state,
                datasetlistSuccess: false
            };
        case DATASETS_LIST_SUCCESS:
            return {
                ...state,
                datasetlist: action.payload,
                datasetlistSuccess: true
            };
        case DATASETS_LIST_FAILURE:
            return {
                ...state,
                datasetlist: action.payload,
                datasetlistSuccess: false
            };
        //get productivitydata
        case DATASHAPER_LIST_LOADING:
            return {
                ...state,
                datashaperlistSuccess: false
            };
        case DATASHAPER_LIST_SUCCESS:
            return {
                ...state,
                datashaperlist: action.payload,
                datashaperlistSuccess: true
            };
        case DATASHAPER_LIST_FAILURE:
            return {
                ...state,
                datashaperlist: action.payload,
                datashaperlistSuccess: false
            };
        //get productivitydata
        case PRODUCTIVITY_DATA_LOADING:
            return {
                ...state,
                productivitydataSuccess: false
            };
        case PRODUCTIVITY_DATA_SUCCESS:
            return {
                ...state,
                productivitydata: action.payload,
                productivitydataSuccess: true
            };
        case PRODUCTIVITY_DATA_FAILURE:
            return {
                ...state,
                productivitydata: action.payload,
                productivitydataSuccess: false
            };
        //get bardata
        case BARGRAPH_DATA_LOADING:
            return {
                ...state,
                bardataSuccess: false
            };
        case BARGRAPH_DATA_SUCCESS:
            return {
                ...state,
                bardata: action.payload,
                bardataSuccess: true
            };
        case BARGRAPH_DATA_FAILURE:
            return {
                ...state,
                bardata: action.payload,
                bardataSuccess: false
            };

        //get linkedindata
        case LINKEDIN_LIST_LOADING:
            return {
                ...state,
                linkedindataSuccess: false
            };
        case LINKEDIN_LIST_SUCCESS:
            return {
                ...state,
                linkedindata: action.payload,
                linkedindataSuccess: true
            };
        case LINKEDIN_LIST_FAILURE:
            return {
                ...state,
                linkedindata: action.payload,
                linkedindataSuccess: false
            };
        //get profile
        case PROFILE_DATA_LOADING:
            return {
                ...state,
                profiledataSuccess: false
            };
        case PROFILE_DATA_SUCCESS:
            return {
                ...state,
                profiledata: action.payload,
                profiledataSuccess: true
            };
        case PROFILE_DATA_FAILURE:
            return {
                ...state,
                profiledata: action.payload,
                profiledataSuccess: false
            };
        //update profile
        case UPDATE_PROFILE_LOADING:
            return {
                ...state,
                profileupdateSuccess: false
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                profileupdate: action.payload,
                profileupdateSuccess: true
            };
        case UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                profileupdate: action.payload,
                profileupdateSuccess: false
            };

        case USER_STATES_LOADING:
            return {
                ...state,
                userStatusSuccess: false
            };
        case USER_STATES_SUCCESS:
            return {
                ...state,
                userStatus: action.payload,
                userStatusSuccess: true
            };
        case USER_STATES_FAILURE:
            return {
                ...state,
                userStatus: action.payload,
                userStatusSuccess: false
            };

        case CAMPAIGN_LIST_LOADING:
            return {
                ...state,
                campaignListSuccess: false
            };
        case CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                campaignList: action.payload,
                campaignListSuccess: true
            };
        case CAMPAIGN_LIST_FAILURE:
            return {
                ...state,
                campaignList: action.payload,
                campaignListSuccess: false
            };


        case NOTIFICATION_LIST_LOADING:
            return {
                ...state,
                notificationListSuccess: false
            };
        case NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                notificationList: action.payload,
                notificationListSuccess: true
            };
        case NOTIFICATION_LIST_FAILURE:
            return {
                ...state,
                notificationList: action.payload,
                notificationListSuccess: false
            };


        case TOOL_LIST_LOADING:
            return {
                ...state,
                toolListSuccess: false
            };
        case TOOL_LIST_SUCCESS:
            return {
                ...state,
                toolList: action.payload,
                toolListSuccess: true
            };
        case TOOL_LIST_FAILURE:
            return {
                ...state,
                toolList: action.payload,
                toolListSuccess: false
            };



        //Country list
        case COUNTRY_LIST_LOADING:
            return {
                ...state,
                countryListSuccess: false
            };
        case COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                countryList: action.payload,
                countryListSuccess: true
            };
        case COUNTRY_LIST_FAILURE:
            return {
                ...state,
                countryList: action.payload,
                countryListSuccess: false
            };

        //State list
        case STATE_LIST_LOADING:
            return {
                ...state,
                stateListSuccess: false
            };
        case STATE_LIST_SUCCESS:
            return {
                ...state,
                stateList: action.payload,
                stateListSuccess: true
            };
        case STATE_LIST_FAILURE:
            return {
                ...state,
                stateList: action.payload,
                stateListSuccess: false
            };



        case CUSTOMER_LASTRECORDS_LOADING:
            return {
                ...state,
                lastRecordsSuccess: false
            };
        case CUSTOMER_LASTRECORDS_SUCCESS:
            return {
                ...state,
                lastRecords: action.payload,
                lastRecordsSuccess: true
            };
        case CUSTOMER_LASTRECORDS_FAILURE:
            return {
                ...state,
                lastRecords: action.payload,
                lastRecordsSuccess: false
            };

        case TOOL_SWAP_LIST_LOADING:
            return {
                ...state,
                toolSwapListSuccess: false
            };
        case TOOL_SWAP_LIST_SUCCESS:
            return {
                ...state,
                toolSwapList: action.payload,
                toolSwapListSuccess: true
            };
        case TOOL_SWAP_LIST_FAILURE:
            return {
                ...state,
                toolSwapList: action.payload,
                toolSwapListSuccess: false
            };

        //user list
        case USER_LIST_LOADING:
            return {
                ...state,
                userListSuccess: false
            };
        case USER_LIST_SUCCESS:
            return {
                ...state,
                userList: action.payload,
                userListSuccess: true
            };
        case USER_LIST_FAILURE:
            return {
                ...state,
                userList: action.payload,
                userListSuccess: false
            };


        case CAMPAIGN_UPDATE_LOADING:
            return {
                ...state,
                campaignUpdateSuccess: false
            };
        case CAMPAIGN_UPDATE_SUCCESS:
            return {
                ...state,
                campaignUpdate: action.payload,
                campaignUpdateSuccess: true
            };
        case CAMPAIGN_UPDATE_FAILURE:
            return {
                ...state,
                campaignUpdate: action.payload,
                campaignUpdateSuccess: false
            };


        case CAMPAIGNSETUP_INSERT_LOADING:
            return {
                ...state,
                campaignSetUpInsertSuccess: false
            };
        case CAMPAIGNSETUP_INSERT_SUCCESS:
            return {
                ...state,
                campaignSetUpInsert: action.payload,
                campaignSetUpInsertSuccess: true
            };
        case CAMPAIGNSETUP_INSERT_FAILURE:
            return {
                ...state,
                campaignSetUpInsert: action.payload,
                campaignSetUpInsertSuccess: false
            };

        // <-------Customersetup Insert------->
        case CUSTOMERSETUP_INSERT_LOADING:
            return {
                ...state,
                customerSetUpInsertSuccess: false
            };
        case CUSTOMERSETUP_INSERT_SUCCESS:
            return {
                ...state,
                customerSetUpInsert: action.payload,
                customerSetUpInsertSuccess: true
            };
        case CUSTOMERSETUP_INSERT_FAILURE:
            return {
                ...state,
                customerSetUpInsert: action.payload,
                customerSetUpInsertSuccess: false
            };

        case TOOLCAMPAIGN_INSERT_LOADING:
            return {
                ...state,
                toolCampaignInsertSuccess: false
            };
        case TOOLCAMPAIGN_INSERT_SUCCESS:
            return {
                ...state,
                toolCampaignInsert: action.payload,
                toolCampaignInsertSuccess: true
            };
        case TOOLCAMPAIGN_INSERT_FAILURE:
            return {
                ...state,
                toolCampaignInsert: action.payload,
                toolCampaignInsertSuccess: false
            };

        case CUSTOMER_PREFILLDATA_LOADING:
            return {
                ...state,
                customerprefilldataSuccess: false
            };
        case CUSTOMER_PREFILLDATA_SUCCESS:
            return {
                ...state,
                customerprefilldata: action.payload,
                customerprefilldataSuccess: true
            };
        case CUSTOMER_PREFILLDATA_FAILURE:
            return {
                ...state,
                customerprefilldata: action.payload,
                customerprefilldataSuccess: false
            };
        default:
            return state;
    }
}