import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import moment from "moment";
import Header from "../../../components/Header";
import { saveAs } from 'file-saver';
import { netlineBatchList, netlinePopup } from '../../../actions/Campaignactions';
import InputIcon from '@mui/icons-material/Input';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NetlineNav from "../../../components/NetlineNav";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import AWS from 'aws-sdk';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import DesktopDatePicker from '@mui/lab/DatePicker';
// import Box from '@mui/material/Box';


const leadtype = [
    { value: '', label: 'Select...' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Incomplete', label: 'Incomplete' }
];



//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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'
})

//uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARKRGNQL5O',
//     secretAccessKey: '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'
// })

class NetlineDashboard extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            campaignList: [],
            selectedValue: '',
            fields: {},
            fields1: {},
            statusinfo: [],
            user_list: [],
            noOfPages: [],
            netlinepopupList: [],
            itemsPerPage: 10,
            totalitems: '',
            page: 1,
            functioncodes: null,
            status_info: '',
            toDate: null,
            open: false,
            value: null
        };
        this._handleRefresh = this._handleRefresh.bind(this)
    }


    _onSelectFunctions = (functioncodes) => {
        this.setState({ functioncodes });
    };


    handleChange = (event, value) => {
        this.setState({ page: value })
    };


    //status
    _onSelectStatus = (event) => {
        const userinfo = {
            status_info: event.target.value
        }
        this.props.netlineBatchList(userinfo);
        this.setState({ statusinfo: event.target.value });
    };

    generatePresignedUrl = async (item) => {
        try {
            let bucketParams = {
                //dev
                Bucket: 'netlinepoc',
                //uat
                // Bucket: 'netlinetool',
                Key: item
            }
            var s3 = new AWS.S3()
            let presignedGETURL = s3.getSignedUrl('getObject', bucketParams);
            let datalist = presignedGETURL
            saveAs(datalist)
        }
        catch (err) {
            console.log(err, "errr for presigned3")
        }
    }

    generatePresignedUrlInputOffer = async (item) => {
        try {
            let bucketParams = {
                //dev
                Bucket: 'solpubwithsse',
                //uat
                // Bucket: 'netlinetool',
                Key: item
            }
            var s3 = new AWS.S3()
            let presignedGETURL = s3.getSignedUrl('getObject', bucketParams);
            let datalist = presignedGETURL
            saveAs(datalist)
        }
        catch (err) {
            console.log(err, "errr for presigned3")
        }
    }

    //input file
    downLoadOutputfile = (item) => {
        if (item !== null) {
            let dt = item.slice(36)
            this.generatePresignedUrl(dt)

        }

    }
    downLoadUnsubmittedFilefile = (item) => {
        if (item !== null) {
            let dt = item.slice(36)
            this.generatePresignedUrl(dt)
        }
    }

    downLoadInputfile = (item) => {
        if (item !== null) {
            //uat
            // if (item.startsWith('https://netlinetool.s3.amazonaws.com/')) {
            //     let dt = item.slice(37)
            //     this.generatePresignedUrlInputOffer(dt)
            // }
            //dev
            if (item.startsWith('https://solpubtest.s3.amazonaws.com/')) {
                let dt = item.slice(36)
                console.log(dt, "dttttttttttttt")
                this.generatePresignedUrlInputOffer(dt)
            }

            else {
                this.generatePresignedUrlInputOffer(item)
            }
        }
    }

    downLoadOfferfile = (item) => {
        if (item !== null) {
            // if (item.startsWith('https://netlinetool.s3.amazonaws.com/')) {
            //     let dt = item.slice(37)
            //     this.generatePresignedUrlInputOffer(dt)
            // }
            //dev
            if (item.startsWith('https://solpubtest.s3.amazonaws.com/')) {
                let dt = item.slice(36)
                console.log(dt, "dttttttttttttt")
                this.generatePresignedUrlInputOffer(dt)
            }

            else {
                this.generatePresignedUrlInputOffer(item)
            }
        }

        // if (item !== null) {
        //     if (item.startsWith('https://netlinetool.s3.amazonaws.com/')) {
        //         let dt = item.slice(37)
        //         this.generatePresignedUrlInputOffer(dt)
        //     }
        //     else {
        //         this.generatePresignedUrlInputOffer(item)
        //     }
        // }
    }
    // _handleFromDate = (newValue) => {
    //     this.setState({ fromDate: newValue })
    // }

    _handleToDate = (newValue) => {
        const dateinfo = {
            fromdate: this.state.value,
            todate: newValue
        }
        this.props.netlineBatchList(dateinfo);
        this.setState({ toDate: newValue })
    }

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            const dateinfo = {
                fromdate: this.state.fromDate,
                todate: this.state.toDate
            }
            // this.props.netlineBatchList();
            this.props.netlineBatchList(dateinfo);
        }
    };

    _handleRefresh() {
        // this.setState({ status_info: '' })
        this.props.netlineBatchList();
    }

    onOpenModal = (item) => {
        const data = {
            batch_id: item
        }
        this.props.netlinePopup(data)
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };



    async componentDidMount() {
        this.props.netlineBatchList();
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.netlinelist &&
            this.props.campaignReducer.netlinelist !==
            nextProps.campaignReducer.netlinelist &&
            this.props.campaignReducer.netlinelistSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({ campaignList: this.props.campaignReducer.netlinelist, noOfPages: Math.ceil(this.props.campaignReducer.netlinelist.length / itemsPerPage), totalitems: this.props.campaignReducer.netlinelist.length })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.netlinepopup &&
            this.props.campaignReducer.netlinepopup !==
            nextProps.campaignReducer.netlinepopup &&
            this.props.campaignReducer.netlinepopupSuccess === true
        ) {

            this.setState({ netlinepopupList: this.props.campaignReducer.netlinepopup })
        }
    }

    render() {
        const { redirectpath, campaignList, fields, fields1, noOfPages, page, itemsPerPage, totalitems, netlinepopupList } = this.state;
        let statusList = leadtype.length > 0
            && leadtype.map((item, i) => {
                return (
                    <option
                        className="netlineoption"
                        key={i} value={item.label}>{item.label}</option>
                )
            }, this);
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: fields.campaign_id, status: this.state.functioncodes && this.state.functioncodes.value, fields1 } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NetlineNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-4 maindiv1">
                            <div className="netlinecontainer">
                                <div className="row">
                                    <div className="col-md-4">
                                        <span className="netlinedate">Status</span>
                                        <select
                                            className="statusdropnet"
                                            // value={this.state.functioncodes}
                                            onChange={this._onSelectStatus}
                                        >

                                            {statusList}
                                        </select>

                                    </div>
                                    <div className="col-md-4">
                                        <div
                                            style={{ display: 'flex', flexDirection: 'row' }}
                                        >
                                            <span className="netlinedate">From</span>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker

                                                    value={this.state.value}
                                                    onChange={(newValue) => {
                                                        this.setState({ value: newValue });
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <span className="netlinedate">To</span>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                // label="Basic example"
                                                value={this.state.toDate}
                                                onChange={(newValue) => this._handleToDate(newValue)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        <AutorenewIcon
                                            onClick={() => this._handleRefresh()}
                                            style={{ alignSelf: 'center', color: '#23857A', marginLeft: '10px' }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChange}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>



                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">


                                <div className="mt-5">
                                    <div className="scroller mt-4">
                                        {campaignList && campaignList.length === 0 ?
                                            <div
                                                className="opencampaignheader mt-5"
                                                style={{ textAlign: 'center' }}
                                            >no records found</div> :
                                            <table className="table">
                                                <thead>
                                                    <tr className="stickyheader">
                                                        <th className="opencampaignheader"> Batch Name</th>
                                                        <th className="opencampaignheader">Submitted Date</th>
                                                        <th className="opencampaignheader"># of Records</th>
                                                        <th className="opencampaignheader"> Status</th>
                                                        <th className="opencampaignheader">Input File</th>
                                                        <th className="opencampaignheader">Offer Codes List</th>
                                                        <th className="opencampaignheader">Processed %</th>
                                                        <th className="opencampaignheader">Accepted Items</th>
                                                        <th className="opencampaignheader">Rejected Items</th>
                                                        <th className="opencampaignheader">Unsubmitted Items</th>
                                                        <th className="opencampaignheader">Errors</th>
                                                        <th className="opencampaignheader">Test Mode</th>
                                                        <th className="opencampaignheader">Execution Mode</th>
                                                        <th className="opencampaignheader">Output</th>
                                                        <th className="opencampaignheader">unsubmitted file</th>
                                                        <th className="opencampaignheader">Offer Summary</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {campaignList && campaignList.length > 0 && campaignList
                                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="opencampaigntext">{item.batch_name}</td>
                                                                <td className="opencampaigntext">{moment(item.submitted_date).format('MM-DD-YYYY')}</td>
                                                                <td className="opencampaigntext">{item.no_of_records}</td>
                                                                <td className="opencampaigntext">{item.status_info}</td>
                                                                <td>
                                                                    <InputIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.downLoadInputfile(item.input_file)}
                                                                    />

                                                                </td>
                                                                <td>
                                                                    <LocalOfferIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.downLoadOfferfile(item.offer_codes_list)} />
                                                                </td>
                                                                <td className="opencampaigntext">{item.proccessed_items && item.proccessed_items.toFixed(2)}</td>
                                                                <td className="opencampaigntext">{item.accepted_items}</td>
                                                                <td className="opencampaigntext">{item.rejected_items}</td>
                                                                <td className="opencampaigntext">{item.unsubmitted_items}</td>
                                                                <td className="opencampaigntext">{item.errors}</td>
                                                                <td className="opencampaigntext">{item.test_mode}</td>
                                                                <td className="opencampaigntext">{item.execution_mode}</td>
                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadOutputfile(item.output_file)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadUnsubmittedFilefile(item.unsubmitted_file)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>
                                                                <td className="opencampaigntext">
                                                                    <VisibilityIcon
                                                                        onClick={() => this.onOpenModal(item.batch_id)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>


                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Modal open={this.state.open} onClose={this.onCloseModal}>
                        <div className="scroller mt-4">
                            {netlinepopupList && netlinepopupList.length === 0 ?
                                <div
                                    className="opencampaignheader mt-5"
                                    style={{ textAlign: 'center', padding: '20px' }}
                                >sorry summary is not updated yet..</div> :
                                <table className="table">
                                    <thead>
                                        <tr className="stickyheader">
                                            <th className="opencampaignheader"> offerCode</th>
                                            <th className="opencampaignheader">accepted</th>
                                            <th className="opencampaignheader">rejected</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {netlinepopupList && netlinepopupList.length > 0 && netlinepopupList.map((item, index) => (
                                            <tr key={index}>
                                                <td className="opencampaigntext">{item.offerCode}</td>
                                                <td className="opencampaigntext">{item.accepted}</td>
                                                <td className="opencampaigntext">{item.rejected}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </Modal>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        netlineBatchList: userinfo => dispatch(netlineBatchList(userinfo)),
        netlinePopup: userinfo => dispatch(netlinePopup(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NetlineDashboard);

