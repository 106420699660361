import React, { Component } from "react";
import "../../styles/Styles.css";
import "../../styles/CreateCampaignStyles.css";
import moment from "moment";
import { Auth } from 'aws-amplify';
import Header from "../../components/Header";
import Pagination from '@mui/material/Pagination';
import { customersList, customerDelete } from '../../actions/Customeractions';
import { Redirect } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import LeftNavigation from "../../components/LeftNavigation";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EditIcon from '@mui/icons-material/Edit';





class AcustomersList extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            showBox: false,
            campaign: false,
            userstate: false,
            toolstate: false,
            campaignList: [],
            noOfPages: [],
            itemsPerPage: 10,
            totalitems: '',
            page: 1,
            selectedValue: '',
            fields: {},
            fields1: {},
            statusinfo: [],
            user_list: [],
            functioncodes: null
        };
    }

    //Signout
    signOut = async () => {
        try {
            await localStorage.removeItem('userdetails');
            await localStorage.removeItem('linkedinauth')
            await Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            this.setState({ redirectpath: '/' })
        }
        catch (error) {
            console.log('error sign out:', error)
        }
    };

    //redirect
    goNext = (params) => {
        if (params === 'setup') {
            this.setState({ redirectpath: '/customersetup' })
        }
    };

    _onSelectFunctions = (functioncodes) => {
        this.setState({ functioncodes });
    };


    handleChange = (event, value) => {
        this.setState({ page: value })
    };


    //status
    _onSelectStatus = (event) => {
        this.setState({ statusinfo: event.target.value });
    };

    delete = (item) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: "Are you sure you want to delete selected info? Press 'Yes' to continue.",
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.customerDelete(item)
                },
                {
                    label: 'Cancel',
                    onClick: () => ''
                }
            ]
        });
    };

    submit = (item) => {
        this.setState({ redirectpath: `/customersetup/${item}` })
    };



    componentDidMount() {
        this.props.customersdata()

    }

    clickupArrow = (e) => {
        const userinfo = {
            name: "DESC"
        }
        this.props.customersdata(userinfo)
    }

    clickDownArrow = () => {
        const userinfo = {
            name: "ASC"
        }
        this.props.customersdata(userinfo)
    }


    componentDidUpdate(nextProps) {
        if (
            this.props.customerReducer &&
            this.props.customerReducer.customersList &&
            this.props.customerReducer.customersList !==
            nextProps.customerReducer.customersList &&
            this.props.customerReducer.customersListSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({
                campaignList: this.props.customerReducer.customersList,
                noOfPages: Math.ceil(this.props.customerReducer.customersList.length / itemsPerPage),
                totalitems: this.props.customerReducer.customersList.length
            })

        }

        if (
            this.props.customerReducer &&
            this.props.customerReducer.customersdelete &&
            this.props.customerReducer.customersdelete !==
            nextProps.customerReducer.customersdelete &&
            this.props.customerReducer.customersdeleteSuccess === true
        ) {
            this.props.customersdata();
        }

    }

    render() {
        const { redirectpath, campaignList, fields, fields1, noOfPages, page, itemsPerPage, totalitems } = this.state;
        // console.log(campaignList, "cmplisttt")
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: fields.campaign_id, status: this.state.functioncodes && this.state.functioncodes.value, fields1 } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <LeftNavigation />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Campaigns Setup</span>
                            </div>
                        </div>

                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChange}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">

                                <div className="mt-5">
                                    <div className="scroller mt-4">
                                        {campaignList && campaignList.length === 0 ?
                                            <div
                                                className="opencampaignheader mt-5"
                                                style={{ textAlign: 'center' }}
                                            >no records found</div> :
                                            <table className="table">
                                                <thead>
                                                    <tr className="stickyheader">
                                                        <th className="opencampaignheader position-relative">
                                                            <div>
                                                                Name
                                                            </div>

                                                            <div className="position-absolute">
                                                                <div className="up-arrow"
                                                                    onClick={(e) => this.clickupArrow(e)}
                                                                >

                                                                </div>
                                                                <div className="down-arrow"
                                                                    onClick={(e) => this.clickDownArrow(e)}
                                                                >
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="opencampaignheader">Email</th>
                                                        <th className="opencampaignheader">Created Date</th>
                                                        <th className="opencampaignheader"> Update</th>
                                                        <th className="opencampaignheader"> Delete</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {campaignList && campaignList.length > 0 && campaignList
                                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="opencampaigntext"> {item.customer_name}</td>
                                                                <td className="opencampaigntext">{item.email}</td>

                                                                <td className="opencampaigntext">
                                                                    {moment(item.created_at).format('MM-DD-YYYY')}
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <EditIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.submit(item.customer_setup_id)}
                                                                    />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <DeleteIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.delete(item.customer_setup_id)}
                                                                    />
                                                                    {/* <VisibilityIcon
                                                                        onClick={() => this.onOpenModal(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} /> */}
                                                                </td>


                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                    </div>
                                </div>
                                {/* <div
                                    className="bdrbottom listcontainerhead p-4">
                                    <div className="row">
                                        <div className="col-md-3 listheader">
                                            Name
                                        </div>
                                        <div className="col-md-3 listheader">
                                            Email
                                        </div>
                                        <div className="col-md-3 listheader">
                                            Created Date
                                        </div>
                                        <div className="col-md-2 listheader">
                                            Update
                                        </div>
                                        <div className="col-md-1 listheader">
                                            Delete
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    >


                                        {campaignList && campaignList.length > 0 && campaignList.map((item, index) => (
                                            <div className="bdrbottom p-3" key={index}>
                                                <div className="row">
                                                    <div className="col-md-3 listtext">
                                                    
                                                        {item.customer_name}
                                                    </div>
                                                    <div
                                                        className="col-md-3 listtext">
                                                        {item.email}
                                                    </div>
                                                    <div
                                                        className="col-md-3 listtext">
                                                        {moment(item.created_at).format('MM-DD-YYYY')}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <EditIcon
                                                            style={{ color: '#1D417A', height: '16px' }}
                                                            onClick={() => this.submit(item.customer_setup_id)}
                                                        />
                                                    </div>

                                                    <div className="col-md-1">
                                                        <DeleteIcon
                                                            style={{ color: '#1D417A', height: '18px' }}
                                                            onClick={() => this.delete(item.customer_setup_id)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </Scrollbars>
                                </div> */}
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={() => this.goNext("setup")}
                                    className="createbuttonnext mt-5 mb-5"
                                >
                                    <span className="createtext">Create New Customer</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        customerReducer: state.customerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        customersdata: userinfo => dispatch(customersList(userinfo)),
        customerDelete: userinfo => dispatch(customerDelete(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AcustomersList);

