import React, { Component } from "react";
import "../../styles/DatashaperStyles.css";
import moment from "moment";
import Header from "../../components/Header";
import { saveAs } from 'file-saver';
import { datashaperlistdata, jobstatus, deletedataset, deleteJOB, deletedatasetdatabase } from '../../actions/Campaignactions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Redirect } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import Leftnav from "../../components/Leftnav";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import VisibilityIcon from '@material-ui/icons/Visibility';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AWS from 'aws-sdk';




//dev
// AWS.config.update({
//     accessKeyId: 'AKIAXTPFNQTGZIW5Y5ZF',
//     secretAccessKey: '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'
// })

//uat
AWS.config.update({
    accessKeyId: 'AKIAQ4QZYNARDHV752W5',
    secretAccessKey: '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'
})

//dev
// const S3_BUCKET = 'aws-glue-databrew-jobs';
//uat
const S3_BUCKET = 'datashaper';

//dev
// const S3_BUCKET11 = 'solpub-campaign-workflow';

//uat
const S3_BUCKET11 = 'datashaper';

const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900;


const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
    signatureVersion: 'v4',
})


const myBucket11 = new AWS.S3({
    params: { Bucket: S3_BUCKET11 },
    region: REGION,
    signatureVersion: 'v4',
})


toast.configure()

class DatashaperStep1 extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            showBox: false,
            campaign: false,
            userstate: false,
            toolstate: false,
            campaignList: [],
            selectedValue: '',
            fields: {},
            fields1: {},
            statusinfo: [],
            user_list: [],
            noOfPages: [],
            Lead_type: [],
            statusdata: '',
            itemsPerPage: 10,
            totalitems: '',
            page: 1,
            functioncodes: null,
            status_info: '',
            fromDate: '',
            toDate: '',
            open: false,
            deletedataset: '',
            datashaper_id: ''
        };
        this._handleRefresh = this._handleRefresh.bind(this)
    }


    _onSelectFunctions = (functioncodes) => {
        this.setState({ functioncodes });
    };


    handleChange = (event, value) => {
        this.setState({ page: value })
    };


    //status
    _onSelectStatus = (event) => {
        const userinfo = {
            status_info: event.target.value
        }
        this.props.netlineBatchList(userinfo);
        this.setState({ statusinfo: event.target.value });
    };


    generatePreSignedPutUrl = (item) => {
        let data = item + '_part00000.csv'
        myBucket.getSignedUrl('getObject', {
            //dev
            Key: `address_std_output/${data}`,
            //uat
            // Key: `datashaperOutput/${data}`,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            console.log(err, "errrrr in output file download")
            saveAs(url)
            return url
        });
    }


    generatePreSignedPutUrl11 = (item) => {
        myBucket11.getSignedUrl('getObject', {
            //dev
            Key: item,
            //uat
            // Key: `datashaperInput/${item}`,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            saveAs(url)
            return url
        });
    }






    //input file
    downLoadOutputfile = async (item) => {
        if (item !== null) {
            this.generatePreSignedPutUrl(item)

        }

    }

    downLoadInputfile = (item) => {
        if (item !== null) {
            this.generatePreSignedPutUrl11(item)

        }

    }


    _handleFromDate = (e) => {
        this.setState({ fromDate: e.target.value })
    }

    _handleToDate = (e) => {
        this.setState({ toDate: e.target.value })
    }

    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            const dateinfo = {
                fromdate: this.state.fromDate,
                todate: this.state.toDate
            }
            this.props.netlineBatchList(dateinfo);
        }
    };

    _handleRefresh() {
        this.props.netlineBatchList();
    }

    _onSelectLeadType = (event) => {
        this.setState({ Lead_type: event.target.value });
    };



    delete = (item) => {
        this.setState({ deletedataset: item.input_file, datashaper_id: item.datashaper_id })
        const name = {
            name: item.name
        }
        confirmAlert({
            title: 'Confirm to delete',
            message: "Are you sure you want to delete selected info? Press 'Yes' to continue.",
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.deleteJOB(name)
                },
                {
                    label: 'Cancel',
                    onClick: () => ''
                }
            ]
        });
    };


    notify = () => {
        toast.success('Dataset Deleted Successfully', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }


    // sortColumnName = () => {
    //     const data = {
    //         sort: "name"
    //     }
    //     this.props.datashaperlistdata(data);
    //     console.log("hi inside sort ")
    // }

    clickupArrow = (e) => {
        const data = {
            sort: "DESC"
        }
        this.props.datashaperlistdata(data);
    }



    clickDownArrow = () => {
        const data = {
            sort: "ASC"
        }
        this.props.datashaperlistdata(data);
    }

    onOpenModal = (item) => {
        const data = {
            run_id: item.run_id,
            name: item.name
        }
        console.log(data, "onOpenModal")
        this.props.jobstatus(data)
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };


    async componentDidMount() {
        this.props.datashaperlistdata();
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperlistdata &&
            this.props.campaignReducer.datashaperlistdata !==
            nextProps.campaignReducer.datashaperlistdata &&
            this.props.campaignReducer.datashaperlistdataSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({ campaignList: this.props.campaignReducer.datashaperlistdata, noOfPages: Math.ceil(this.props.campaignReducer.datashaperlistdata.length / itemsPerPage), totalitems: this.props.campaignReducer.datashaperlistdata.length })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.jobstatus &&
            this.props.campaignReducer.jobstatus !==
            nextProps.campaignReducer.jobstatus &&
            this.props.campaignReducer.jobstatusSuccess === true
        ) {
            this.setState({ statusdata: this.props.campaignReducer.jobstatus })
        }


        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.jobstatus &&
            this.props.campaignReducer.jobstatus !==
            nextProps.campaignReducer.jobstatus &&
            this.props.campaignReducer.jobstatusSuccess === false
        ) {
            this.setState({ statusdata: "status not found" })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.jobstatus &&
            this.props.campaignReducer.jobstatus !==
            nextProps.campaignReducer.jobstatus &&
            this.props.campaignReducer.jobstatusSuccess === false
        ) {
            this.setState({ statusdata: "status not found" })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.deletejob &&
            this.props.campaignReducer.deletejob !==
            nextProps.campaignReducer.deletejob &&
            this.props.campaignReducer.deletejobSuccess === true
        ) {
            const name = {
                datashaper_id: this.state.datashaper_id
            }

            this.props.deletedatasetdatabase(name)
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.deletejobdb &&
            this.props.campaignReducer.deletejobdb !==
            nextProps.campaignReducer.deletejobdb &&
            this.props.campaignReducer.deletejobdbSuccess === true
        ) {
            const name = {
                deletedataset: this.state.deletedataset
            }
            this.props.deletedataset(name)

        }


        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.deletedataset &&
            this.props.campaignReducer.deletedataset !==
            nextProps.campaignReducer.deletedataset &&
            this.props.campaignReducer.deletedatasetSuccess === true
        ) {
            this.notify()
            this.props.datashaperlistdata();
        }
    }

    render() {
        const { redirectpath, campaignList, fields, fields1, statusdata, noOfPages, page, itemsPerPage, totalitems } = this.state;

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: fields.campaign_id, status: this.state.functioncodes && this.state.functioncodes.value, fields1 } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <Leftnav />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChange}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>



                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">


                                <div className="mt-5">
                                    <div className="scroller mt-4">
                                        {campaignList && campaignList.length === 0 ?
                                            <div
                                                className="opencampaignheader mt-5"
                                                style={{ textAlign: 'center' }}
                                            >no records found</div> :
                                            <table className="table">
                                                <thead>
                                                    <tr className="stickyheader">
                                                        <th className="opencampaignheader">
                                                            <div>
                                                                Name
                                                            </div>

                                                            <div className="position-absolute">
                                                                <div className="up-arrow"
                                                                    onClick={(e) => this.clickupArrow(e)}
                                                                >

                                                                </div>
                                                                <div className="down-arrow"
                                                                    onClick={(e) => this.clickDownArrow(e)}
                                                                >
                                                                </div>
                                                            </div>
                                                            {/* Name
                                                            <FontAwesomeIcon
                                                                onClick={this.sortColumnName}
                                                                style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center', marginLeft: '10px', marginTop: '10px' }}
                                                                icon={faSort} /> */}
                                                        </th>

                                                        <th className="opencampaignheader">Submitted Date</th>
                                                        <th className="opencampaignheader">Input File</th>
                                                        <th className="opencampaignheader">Output</th>
                                                        <th className="opencampaignheader">View Status</th>
                                                        <th className="opencampaignheader"> Delete</th>


                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {campaignList && campaignList.length > 0 && campaignList
                                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="opencampaigntext">{item.name}</td>
                                                                <td className="opencampaigntext">{moment(item.submitted_date).format('MM-DD-YYYY')}</td>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadInputfile(item.input_file)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadOutputfile(item.name)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <VisibilityIcon
                                                                        onClick={() => this.onOpenModal(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <DeleteIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.delete(item)}
                                                                    />
                                                                    {/* <VisibilityIcon
                                                                        onClick={() => this.onOpenModal(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} /> */}
                                                                </td>


                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Modal open={this.state.open} onClose={this.onCloseModal}>
                    <div className="scroller mt-4">
                        {statusdata === "status not found" ?
                            <div
                                className="opencampaignheader mt-5"
                                style={{ textAlign: 'center', padding: '20px' }}
                            >sorry status is not updated yet..</div>
                            : <table className="table">
                                <thead>
                                    <tr className="stickyheader">
                                        <th className="opencampaignheader">Job Name</th>
                                        <th className="opencampaignheader">Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="opencampaigntext">{statusdata.JobName}</td>
                                        <td className="opencampaigntext">{statusdata.State}</td>

                                    </tr>
                                </tbody>
                            </table>}
                    </div>
                </Modal>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlistdata: userinfo => dispatch(datashaperlistdata(userinfo)),
        jobstatus: userinfo => dispatch(jobstatus(userinfo)),
        deletedataset: userinfo => dispatch(deletedataset(userinfo)),
        deleteJOB: userinfo => dispatch(deleteJOB(userinfo)),
        deletedatasetdatabase: userinfo => dispatch(deletedatasetdatabase(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DatashaperStep1);

