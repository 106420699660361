import React, { Component } from "react";
import "../../styles/ManagerStyles.css";
import moment from "moment";
import "../../styles/CreateCampaignStyles.css";
import filter1 from "../../assets/images/filter.svg";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DeleteIcon from '@material-ui/icons/Delete';
import { Modal } from "react-bootstrap";
import RateReviewIcon from '@material-ui/icons/RateReview';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { openCampaignList, readyToBilllist } from '../../actions/ManagerActions';
import Header from "../../components/Header";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNavigation2 from '../../components/LeftNavigation2';

const data = [
    { value: 'A week ago', label: 'A week ago' },
    { value: 'A month ago', label: 'A month ago' }
];
const statusdata = [
    { value: '', label: 'Select...' },
    { value: 'Active', label: 'Active' },
    { value: 'Created', label: 'Created' },
    { value: 'Pending', label: 'Pending' }
];

class ReadyToBill extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            functioncodes: null,
            campaignList: [],
            opencampaignList: [],
            Steps: [],
            itemsPerPage: 10,
            totalitems: '',
            addmore: false,
            page: 1,
            description: '',
            errors: {},
            visible: false,
        };
    }


    _onFilter = (Lead_type) => {
        const filterinfo = {
            filterdata: Lead_type.value
        }
        this.props.readyToBilllist(filterinfo);
    };


    //to handle input field
    handleChangeDesc = (e) => {
        this.setState({ description: e.target.value });
    }
    // <----- to handle pagination ----->
    handleChangePagination = (event, value) => {
        this.setState({ page: value })
    };

    openModal = (item) => {
        this.setState({
            visible: true,
            managerworkstack_id: item.tool_camp_id
        });
    }


    DeleteIconData = (item) => {
        const Steps = this.state.Steps;

        const index = Steps.indexOf(item);
        if (index > -1) {
            Steps.splice(index, 1);
        }

        this.setState({ Steps })
    };


    closeModal = () => {
        this.setState({
            visible: false,
            addmore: false,
            Steps: []
        });
    }


    addMoreData = () => {
        const Steps = this.state.Steps;
        if (this.state.description !== '') {
            var Action = {};
            Action.notes = this.state.description
            Steps.push({
                Action
            });
        }


        this.setState({ Steps, description: '' })
        this.setState({
            addmore: true
        });
    }

    componentDidMount() {
        this.props.openCampaignList();
        this.props.readyToBilllist();
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.managerReducer &&
            this.props.managerReducer.readytobillList &&
            this.props.managerReducer.readytobillList !==
            nextProps.managerReducer.readytobillList &&
            this.props.managerReducer.readytobillListSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({
                opencampaignList: this.props.managerReducer.readytobillList,
                noOfPages: Math.ceil(this.props.managerReducer.readytobillList.length / itemsPerPage),
                totalitems: this.props.managerReducer.readytobillList.length
            })

        }
    }

    render() {
        const { redirectpath, opencampaignList, noOfPages, page, itemsPerPage, totalitems, errors } = this.state;
        let statusList = statusdata.length > 0
            && statusdata.map((item, i) => {
                return (
                    <option key={i} value={item.label}>{item.label}</option>
                )
            }, this);
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <LeftNavigation2 />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="opencampaignheading">Ready To Bill</span>
                            </div>
                        </div>
                        <div className="row mt-4 mb-2 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChangePagination}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <div className="col-md-7">
                                </div>
                                <div className="col-md-3 shadowdropdown">
                                    <Dropdown
                                        options={data}
                                        onChange={this._onFilter}
                                        className='myClassName ml-3'
                                        placeholder="Select....." />
                                </div>
                                <div className="col-md-1 d-md-flex justify-content-end">

                                    <img
                                        src={filter1}
                                        style={{ height: "29px", alignSelf: 'center' }}
                                        alt="filter"
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className="scroller mt-4">
                                    {opencampaignList && opencampaignList.length === 0 ?
                                        <div
                                            className="opencampaignheader mt-5"
                                            style={{ textAlign: 'center' }}
                                        >no records found</div> :
                                        <table className="table">
                                            <thead>
                                                <tr className="stickyheader">
                                                    <th className="opencampaignheader">Name</th>
                                                    <th className="opencampaignheader">Client</th>
                                                    <th className="opencampaignheader">Created Date</th>
                                                    <th className="opencampaignheader">Deadline</th>
                                                    <th className="opencampaignheader">Status</th>
                                                    <th className="opencampaignheader">Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {opencampaignList && opencampaignList.length > 0 && opencampaignList
                                                    .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                        <tr
                                                          
                                                            key={index}>
                                                            <td className="opencampaigntext mt-4">
                                                                {item.campaign_name && item.campaign_name.length > 15 ? `${item.campaign_name.substring(0, 15)}...` : item.campaign_name}
                                                            </td>
                                                            <td className="opencampaigntext">{item.customer}</td>
                                                            <td className="opencampaigntext">{moment(item.created_at).format('MM-DD-YYYY')}</td>
                                                            <td className="opencampaigntext">{moment(item.deadline_date).format('MM-DD-YYYY')}</td>
                                                            <td
                                                            // onClick={() => this.statusUpdate(item)}
                                                            >
                                                                <select className="statusdrop"
                                                                    // value={item.status_1 ? item.status_1 : this.state.functioncodes}
                                                                    onChange={this._onSelectFunctions}
                                                                >

                                                                    {statusList}
                                                                </select>
                                                            </td>

                                                            <td className="opencampaigntext">
                                                                <RateReviewIcon
                                                                    onClick={() => this.openModal(item)}
                                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                            {/* 
                            <div className=" mt-5">
                                <div className="scroller mt-4">
                                    <table className="table">
                                        <thead className="bdrbottom">
                                            <tr className="stickyheader">
                                                <th className="opencampaignheader">Name</th>
                                                <th className="opencampaignheader">Client</th>
                                                <th className="opencampaignheader">Created Date</th>
                                                <th className="opencampaignheader">Deadline</th>
                                                <th className="opencampaignheader">Status</th>
                                                <th className="opencampaignheader">Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {opencampaignList && opencampaignList.length > 0 && opencampaignList.map((item, index) => (
                                                <tr key={index} className="bdrbottom">
                                                    <td className="opencampaigntext">{item.campaign_name}</td>
                                                    <td className="opencampaigntext">{item.customer}</td>
                                                    <td className="opencampaigntext">{moment(item.created_at).format('MM-DD-YYYY')}</td>
                                                    <td className="opencampaigntext">{moment(item.deadline_date).format('MM-DD-YYYY')}</td>
                                                    <td className="opencampaigntext">
                                                        <Dropdown
                                                            options={statusdata}
                                                            onChange={this._onSelectFunctions}
                                                            value={item.status_1 ? item.status_1 : this.state.functioncodes}
                                                            className='myClassName'
                                                            placeholder="Select...." />
                                                    </td>
                                                    <td className="notes">
                                                        <span
                                                            style={{ borderBottom: '1px solid rgba(29,65,122,1)', color: 'rgba(29,65,122,1)', fontSize: '14px' }}
                                                            onClick={this.openModal}>
                                                            View Add Note</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div> */}

                            <Modal show={this.state.visible} onHide={this.closeModal}>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12 col-12 col-sm-12 mt-3">
                                            <AddCircleIcon
                                                fontSize={'large'}
                                                onClick={this.addMoreData}
                                                style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                            />
                                            <span className="createtextnoteshead">Add Note</span>
                                        </div>

                                        {this.state.addmore === true ?
                                            <div className="col-md-12 col-12 col-sm-12 mt-3">
                                                <input
                                                    type="text"
                                                    value={this.state.description}
                                                    onChange={this.handleChangeDesc}
                                                    className="workstackmultiplenotes"
                                                    placeholder="Enter Name"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.name}
                                                </span>
                                            </div> : null}

                                    </div>
                                    {this.state.Steps && this.state.Steps.length === 0 ?
                                        <div
                                            className="opencampaignheader mt-5"
                                            style={{ textAlign: 'center' }}
                                        >no records found</div> :
                                        <div className="mt-5">
                                            <div className="row">
                                                <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                                    Description
                                                </div>
                                                <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                                    Delete
                                                </div>
                                            </div>

                                            {this.state.Steps && this.state.Steps.length > 0 && this.state.Steps
                                                .map((item, index) => (

                                                    <div
                                                        className="row rowdata">
                                                        <div className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                            {/* {item.Action && item.Action.notes} */}
                                                            {item.Action && item.Action.notes && item.Action.notes.length > 50 ? `${item.Action.notes.substring(0, 50)}...` : item.Action.notes}
                                                        </div>
                                                        <div className="col-md-2 pt-3 pb-3 opencampaigntext102">
                                                            <DeleteIcon
                                                                onClick={() => this.DeleteIconData(item)}
                                                                style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                        </div>
                                                    </div>
                                                ))}

                                        </div>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                        <button
                                            onClick={this.notesInsertDetails}
                                            className="browsebutton  mt-4"
                                        >
                                            <span className="browsebuttontext">Save</span>
                                        </button>
                                    </div>
                                </Modal.Footer>
                            </Modal>

                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        openCampaignList: userinfo => dispatch(openCampaignList(userinfo)),
        readyToBilllist: userinfo => dispatch(readyToBilllist(userinfo)),


    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReadyToBill);

