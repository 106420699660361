import React, { Component } from "react";
import "../../styles/Styles.css";
import "../../styles/CreateCampaignStyles.css"
import backarrow from "../../assets/images/backarrow.png";
import { userDetails } from '../../actions/ManagerActions';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { toolList, userList, toolCampaignInsert, customerlastrecords } from '../../actions/Campaignactions';
import { connect } from 'react-redux';
import 'react-dropdown/style.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Checkbox from '@material-ui/core/Checkbox';
import Header from "../../components/Header";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LeftNavigation from "../../components/LeftNavigation";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';



toast.configure()

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const data = [
    {
        name: "one",
        id: 1
    },
    {
        name: "two",
        id: 2
    },
    {
        name: "three",
        id: 3
    },
    {
        name: "four",
        id: 4
    },
    {
        name: "five",
        id: 5
    }
]

const names = [
    'C-Level',
    'Director',
    'Executive Vice Presiden',
    'Individual Contributor',
    'Manager',
    'Senior Director',
    'Senior Manager'
];


const options = [
    { label: 'Thing 1', value: 1 },
    { label: 'Thing 2', value: 2 },
];

class ToolSequencing extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            assignedusers: [],
            toolLists: [],
            toolrandom: [],
            toolInfo: [],
            toolNames: [],
            toolobj: {},
            user_list: [],
            toolarray: [],
            fieldsData: {},
            list: data,
            functioncodes: null,
            selected: [],
            domain_types: [],
            toolId: '',
            checkvalue: '',
            optionSelected: null,
            idx: ''
            // checkval: false
        };
    }

    // Signout
    signOut = async () => {
        try {
            await localStorage.removeItem('userdetails');
            await localStorage.removeItem('linkedinauth')
            await Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            this.setState({ redirectpath: '/' })
        }
        catch (error) {
            console.log('error sign out:', error)
        }
    };




    handleChange = (selected) => {
        this.setState({
            optionSelected: selected
        });
        const ulist = []
        selected.map((item, index) => {
            ulist.push(item.value)
            return true;
        });

        this.setState({ assignedusers: ulist });
    };


    _onSelectDomainTypes = (event) => {
        const {
            target: { value },
        } = event;

        const ulist = []
        value.map((item, index) => {
            ulist.push(item)
            return true;
        });

        this.setState({ assignedusers: ulist });
        this.setState({ domain_types: typeof value === 'string' ? value.split(',') : value })
    }


    checkValue = (event) => {
        const toolNames = this.state.toolNames
        const toolobj = {};
        if (event.target.checked) {
            toolobj['toolname'] = event.target.value
            toolNames.push(event.target.value);

            this.setState({ toolId: event.target.value })

        }
        else {
            const idx = toolNames.indexOf(event.target.value);
            toolNames.splice(idx, 1)
            this.setState({ toolId: '' })
        }
        this.setState({
            toolobj: toolobj,
            toolNames,
            checkvalue: event.target.checked
        })
    }


    notify = () => {
        toast.success('successfully launched', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }



    handlesetSelected = (item) => {
        this.setState({ selected: item })
        const ulist = []
        item.map((item, index) => {
            ulist.push(item.value)
            return true;
        });

        this.setState({ assignedusers: ulist });
    }

    _onSelectFunctions = (functioncodes) => {
        this.setState({ functioncodes });
    };

    componentDidMount() {
        this.props.toollist();
        this.props.userlist();
        this.props.userDetails();
        this.props.customerlastrecords()
    }
    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.lastRecords &&
            this.props.campaignReducer.lastRecords !==
            nextProps.campaignReducer.lastRecords &&
            this.props.campaignReducer.lastRecordsSuccess === true
        ) {
            this.setState({ fieldsData: this.props.campaignReducer.lastRecords[0] })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.toolList &&
            this.props.campaignReducer.toolList !==
            nextProps.campaignReducer.toolList &&
            this.props.campaignReducer.toolListSuccess === true
        ) {
            this.setState({ toolLists: this.props.campaignReducer.toolList })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.toolCampaignInsert &&
            this.props.campaignReducer.toolCampaignInsert !==
            nextProps.campaignReducer.toolCampaignInsert &&
            this.props.campaignReducer.toolCampaignInsertSuccess === true
        ) {
            console.log("hi insert in tool")
            this.notify()
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.userList &&
            this.props.campaignReducer.userList !==
            nextProps.campaignReducer.userList &&
            this.props.campaignReducer.userListSuccess === true
        ) {
            const userList = [];
            this.props.campaignReducer.userList && this.props.campaignReducer.userList.users.map((item, index) => {
                if (item.Attributes[3]) {
                    // userList.push(
                    //     item.Attributes[3].Value
                    // );
                    userList.push({
                        value: item.Attributes[3].Value,
                        label: item.Attributes[3].Value
                    });
                }
                return true;
            })
            this.setState({ user_list: userList })
        }
        if (
            this.props.managerReducer &&
            this.props.managerReducer.arcMan &&
            this.props.managerReducer.arcMan !==
            nextProps.managerReducer.arcMan &&
            this.props.managerReducer.arcManSuccess === true
        ) {
            this.setState({ userData: this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes })
        }
    }

    toolSequencingInsert(data) {
        let current = new Date();
        let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        let dateTime = cDate + ' ' + cTime;

        const toolInfo = {
            //to send multiple row at a time
            data: data,
            toolId: this.state.toolId,
            StepAssigneeName: this.state.assignedusers,
            sequence: this.state.toolNames,
            campaign_id: this.props.location.state !== undefined && this.props.location.state.campaign_id,
            user_id: this.props.managerReducer &&
                this.props.managerReducer.arcMan &&
                this.props.managerReducer.arcMan.attributes.sub,
            staus: 'In Progress',
            email_time: dateTime
        };

        console.log(toolInfo, "infooo")
        this.props.toolinsert(toolInfo);
    }


    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    // setIndex = (index) => {
    //     this.setState({ idx: index })
    //     console.log(index, "indexxxxxxxxxxxxxxxxxxxxxxxxx")

    // }

    dragEnd = (result) => {
        const dt = this.reorder(this.state.toolLists, result.source.index, result.destination.index)
        this.setState({ toolLists: dt })
    }

    render() {
        const { redirectpath, toolLists, toolobj, assignedusers, user_list, toolInfo, fieldsData, checkvalue } = this.state;
        toolobj.assigneduser = assignedusers
        toolInfo.push(toolobj);

        // console.log(this.state.optionSelected === null, this.state.toolobj['assigneduser'].length, "selected option is here")

        //To remove duplicate
        const jsonObject = toolInfo.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse);


        //To remove empty array of object
        if (uniqueArray.length && Object.keys(uniqueArray[0]).length === 1) {
            uniqueArray.splice(0, 1);
            // this.setState({ toolarray: uniqueArray })
        }

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 col-12 col-sm-12 menublock">
                        <LeftNavigation />
                    </div>
                    <div className="col-md-10 header">
                        <Header />
                        <div className="row maindiv">
                            <div className="col-md-12 mb-2">
                                <img
                                    onClick={this.redirecToBack}
                                    src={backarrow}
                                    style={{ height: "24px", width: "24px", alignSelf: 'center', justifyContent: 'center' }}
                                    alt="backarrow"
                                />
                            </div>
                        </div>

                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Pending Campaigns</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-md-between">
                                <span className="campaignsetup12">{fieldsData && fieldsData.customer_name ? fieldsData.customer_name : ''} Lead Campaign</span>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%' }}
                                    >
                                        <div
                                            className="p-4"
                                            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.16)' }}
                                        >
                                            <div className="row">
                                                <div className="col-md-8 tool">
                                                    Tools
                                                </div>
                                                <div className="col-md-4 tool">
                                                    Users
                                                </div>
                                            </div>
                                        </div>
                                        <DragDropContext onDragEnd={this.dragEnd}>
                                            <Droppable
                                                droppableId="12345678"
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                    >
                                                        {toolLists && toolLists.length > 0 && toolLists.map((item, index) => (
                                                            <Draggable
                                                                draggableId={item.tool_id.toString()}
                                                                key={item.tool_id}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className="p-4" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.16)' }}>
                                                                            <div className="row">
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}
                                                                                    className="col-md-8 invoicesfortext">
                                                                                    <div className="row">
                                                                                        <div style={{ display: 'flex', flexDirection: 'row' }} className="col-md-12">
                                                                                            <Checkbox
                                                                                                value={item.tool_id}
                                                                                                onClick={this.checkValue}
                                                                                                style={{ alignSelf: 'center' }}
                                                                                            />
                                                                                            <span
                                                                                                style={{ alignSelf: 'center' }}
                                                                                            >{item.tool_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-md-4 col-sm-12 col-12">
                                                                                    <ReactMultiSelectCheckboxes
                                                                                        onChange={this.handleChange}
                                                                                        options={user_list} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}

                                                    </div>

                                                )}

                                            </Droppable>
                                        </DragDropContext>
                                    </Scrollbars>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                {!checkvalue || this.state.optionSelected === null || this.state.toolobj['assigneduser'].length === 0 ?
                                    <button
                                        style={{ opacity: 0.6 }}
                                        // onClick={this.toolSequencingInsert.bind(this, uniqueArray)}
                                        className="createbuttonnext mt-5"
                                    >
                                        <span className="createtext">Launch Campaign</span>
                                    </button>
                                    :
                                    <button
                                        onClick={this.toolSequencingInsert.bind(this, uniqueArray)}
                                        className="createbuttonnext mt-5"
                                    >
                                        <span className="createtext">Launch Campaign</span>
                                    </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        userlist: userinfo => dispatch(userList(userinfo)),
        toollist: userinfo => dispatch(toolList(userinfo)),
        toolinsert: userinfo => dispatch(toolCampaignInsert(userinfo)),
        customerlastrecords: userinfo => dispatch(customerlastrecords(userinfo)),
        userDetails: userinfo => dispatch(userDetails(userinfo)),





    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToolSequencing);
