import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createDataSet, datashaperlist, dataSetList, createRecipe, startJob, datashaperInsert, jobUpdate, datashaperNotificationList, datashaperSNSNotification } from "../../../actions/Campaignactions";
import Header from "../../../components/Header";
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NLPNav from "../../../components/NLPNav";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import AWS from 'aws-sdk';
//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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'
})

// uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARDHV752W5',
//     secretAccessKey: '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'
// })

//dev
// const S3_BUCKET = 'solpub-campaign-workflow';

//uat
const S3_BUCKET = 'leadshaper'

const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900; // in seconds

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

toast.configure()


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


class CreateDataSet extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            fields: {},
            errors: {},
            input_files: '',
            sourcefile: [],
            recipes: [],
            recipeList: [],
            datasetList: [],
            matchId: '',
            notificationdataList: [],

        };
    }



    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    generatePreSignedPutUrl = (fileName, fileType, file) => {
        myBucket.getSignedUrl('putObject', {
            //dev
            Key: fileName,
            //uat
            // Key: `datashaperInput/${fileName}`,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            console.log(err, "errrrrr")
            fetch(url, {
                method: 'PUT',
                body: file
            }).then((res) => {
                console.log(res, "resss")
                const info = {
                    // name: this.state.input_files[0].name,
                    // input: this.state.input_files[0].name,
                    "csv_filename1": fileName
                }
                console.log(info)
                this.props.createDataSet(info)
            })
            return url
        });
    }

    uploadInputFiles = (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.datasetname = ""
            this.setState({ input_files: e.target.files, errors })
        } else {
            errors.datasetname = "Please select csv only"
            this.setState({ errors })
        }
    }


    //handle source files
    handleSourceFile = (event) => {
        this.setState({ sourcefile: event.target.value })
    }

    handleRecipes = (event) => {
        this.setState({ recipes: event.target.value })
    }

    notify = () => {
        toast.success('Data Set Created Successfully', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }


    notifyforjob = () => {
        toast.success('Job started.....', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    notifyforjobfail = () => {
        toast.error('Job failed.....', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }
        const data = {
            tool_campg_id: this.props.location.search !== '' && this.props.location.search.split('&') &&
                this.props.location.search.split('&').length > 0 &&
                this.props.location.search.split('&')[0].split('=') &&
                this.props.location.search.split('&')[0].split('=').length > 0 &&
                this.props.location.search.split('&')[0].split('=')[1]
        }

        this.props.datashaperNotificationList(data)

        this.props.datashaperlist();
        this.props.dataSetList()
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.createdataset &&
            this.props.campaignReducer.createdataset !==
            nextProps.campaignReducer.createdataset &&
            this.props.campaignReducer.createdatasetSuccess === true
        ) {
            this.setState({
                matchId: this.props.campaignReducer.createdataset &&
                this.props.campaignReducer.createdataset.nlp_id
          
            })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashapernotificationlist &&
            this.props.campaignReducer.datashapernotificationlist !==
            nextProps.campaignReducer.datashapernotificationlist &&
            this.props.campaignReducer.datashapernotificationlistSuccess === true
        ) {
            // console.log(this.props.campaignReducer.datashapernotificationlist.length, "datasetcreated successfully")
            this.setState({
                notificationdataList: this.props.campaignReducer.datashapernotificationlist && this.props.campaignReducer.datashapernotificationlist.length > 0 && this.props.campaignReducer.datashapernotificationlist[0]
            })
        }

        // if (
        //     this.props.campaignReducer &&
        //     this.props.campaignReducer.createdataset &&
        //     this.props.campaignReducer.createdataset !==
        //     nextProps.campaignReducer.createdataset &&
        //     this.props.campaignReducer.createdatasetSuccess === false
        // ) {
        //     // console.log(this.props.campaignReducer.createdataset.Error, "dataset")
        //     const errors = {};
        //     errors.datasetname = "Dataset name can't be duplicate. and Dataset name must begin with an alphanumeric character. "
        //     this.setState({ errors })
        // }


        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlist !==
            nextProps.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlistSuccess === true
        ) {
            const recipeList = [];
            this.props.campaignReducer.datashaperlist && this.props.campaignReducer.datashaperlist.length > 0 && this.props.campaignReducer.datashaperlist.map((item, index) => {
                if (item.recipe) {
                    recipeList.push(
                        item.recipe
                    );
                }
                return true;
            })
            this.setState({ recipeList: recipeList })


        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datasetlist &&
            this.props.campaignReducer.datasetlist !==
            nextProps.campaignReducer.datasetlist &&
            this.props.campaignReducer.datasetlistSuccess === true
        ) {
            const datasetList = [];
            this.props.campaignReducer.datasetlist && this.props.campaignReducer.datasetlist.length > 0 && this.props.campaignReducer.datasetlist.map((item, index) => {
                if (item.Name) {
                    datasetList.push(
                        item.Name
                    );
                }
                return true;
            })
            this.setState({ datasetList: datasetList })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsert !==
            nextProps.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsertSuccess === true
        ) {
            console.log(this.props.campaignReducer.datashaperInsert, "this.props.campaignReducer.datashaperInsert")
            // const shaperinfo = {
            //     name: this.state.fields.name,
            //     dataset: this.state.input_files[0].name,
            //     recipes: this.state.recipes
            // }
            // this.props.createRecipe(shaperinfo)

            // let current = new Date();
            // let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            // let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
            // let dateTime = cDate + ' ' + cTime;

            // let datashapersns = {
            //     tool_campg_id: parseInt(this.props.location.search !== '' && this.props.location.search.split('&') &&
            //         this.props.location.search.split('&').length > 0 &&
            //         this.props.location.search.split('&')[0].split('=') &&
            //         this.props.location.search.split('&')[0].split('=').length > 0 &&
            //         this.props.location.search.split('&')[0].split('=')[1]),
            //     tool_assignee_user_email: this.state.notificationdataList.assigned_user,
            //     start_time: dateTime,
            //     campaign_id: this.state.notificationdataList.campaign_id,
            //     job_name: this.state.fields.name

            // }
            // console.log(datashapersns, "sjsdfdjdfjsnsdata")
            // this.props.datashaperSNSNotification(datashapersns)

        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipe !==
            nextProps.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipeSuccess === true
        ) {
            const shaperinfo = {
                name: this.state.fields.name,
                dataset: this.state.input_files[0].name,
            }
            this.props.startJob(shaperinfo)
            this.notify()
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.startjob &&
            this.props.campaignReducer.startjob !==
            nextProps.campaignReducer.startjob &&
            this.props.campaignReducer.startjobSuccess === true
        ) {
            this.notifyforjob()
            const jobinfo = {
                run_id: this.props.campaignReducer.startjob && this.props.campaignReducer.startjob.RunId,
                datashaper_id: this.props.campaignReducer.datashaperInsert && this.props.campaignReducer.datashaperInsert.insertId,
            }
            this.props.jobUpdate(jobinfo)
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.startjob &&
            this.props.campaignReducer.startjob !==
            nextProps.campaignReducer.startjob &&
            this.props.campaignReducer.startjobSuccess === false
        ) {
            this.notifyforjobfail()
        }


        // if (
        //     this.props.campaignReducer &&
        //     this.props.campaignReducer.datashaperInsert &&
        //     this.props.campaignReducer.datashaperInsert !==
        //     nextProps.campaignReducer.datashaperInsert &&
        //     this.props.campaignReducer.datashaperInsertSuccess === false
        // ) {
        //     console.log(this.props.campaignReducer.datashaperInsert, "insert")
        //     const errors = {};
        //     errors.name = "Name can't be duplicate."
        //     this.setState({ errors })
        // }
        // if (
        //     this.props.campaignReducer &&
        //     this.props.campaignReducer.createrecipe &&
        //     this.props.campaignReducer.createrecipe !==
        //     nextProps.campaignReducer.createrecipe &&
        //     this.props.campaignReducer.createrecipeSuccess === false
        // ) {
        //     const errors = {};
        //     errors.name = "Name can't be duplicate."
        //     this.setState({ errors })
        // }
    }


    validateDatashaper() {
        const errors = {};
        const { fields } = this.state;
        // const strings = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;

        let formIsValid = true;
        if (this.state.input_files === '') {
            errors.datasetname = 'Please upload file ';
            formIsValid = false;
        }
        if (!fields.name) {
            errors.name = 'Please enter batch name ';
            formIsValid = false;
        }

        if (fields.name && fields.name.length < 3) {
            errors.name = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.name && fields.name.length > 500) {
            errors.name = 'batch name should be 15 characters only';
            formIsValid = false;
        }
        // if (fields.name && !strings.test(fields.name)) {
        //     errors.name = 'Please enter characters only';
        //     formIsValid = false;
        // }

        if (this.state.offer_codes === '') {
            errors.offer_codes = 'Please select offer file ';
            formIsValid = false;
        }


        if (this.state.recipes.length === 0) {
            errors.recipes = 'Please select recipe ';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;
    }

    // dataShaper = () => {
    //     const shaperinfo = {
    //         name: this.state.input_files[0].name,
    //         input: this.state.input_files[0].name,
    //     }
    //     console.log(shaperinfo, "sinfoooooooooo")
    //     this.props.createDataSet(shaperinfo)
    // };

    dataShaper = () => {
        // if (this.validateDatashaper()) {
            const info = {
                // name: this.state.fields.name,
                // dataset: this.state.input_files[0].name,
                // recipes: this.state.recipes,

                bucket_name: "titlematch",
                csv_filename1: this.state.input_files[0].name,
                recipe_name: this.state.recipes,
                matchId: this.state.matchId
            }
            console.log(info, "info")
            this.props.datashaperInsert(info)
        // }

    };
    render() {
        const { redirectpath, fields, errors } = this.state;

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NLPNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Create Data Set</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.dataShaper}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Submit</span>
                                </button>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <div className="row container">
                                            <label className="inputlabel  mb-3">Input Files</label>
                                            <div className="inputlabel  mb-3" style={{ display: 'flex', flexDirection: 'row' }}>
                                                <label htmlFor="chooseInputFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                        {this.state.input_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files[0].name

                                                        }
                                                        <input
                                                            className="Field"
                                                            id="chooseInputFile"
                                                            onChange={this.uploadInputFiles}
                                                            accept=".csv"
                                                            type="file"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                        <span className="browsebutton1">
                                                            <span className="browsebuttontext">Browse</span>
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <span className="errors mt-2">
                                                {errors && errors.datasetname}
                                            </span>
                                            {/* 
                                            <div className="col-md-12 col-12 col-sm-12 mt-3 mb-3">
                                                <p className="inputlabel">Name</p>
                                                <input
                                                    type="text"
                                                    value={fields.name}
                                                    onChange={this.handleChange.bind(this, "name")}
                                                    className="campaigntext"
                                                    placeholder="Enter Name"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.name}
                                                </span>
                                            </div> */}

                                            {/* <label className="inputlabel mt-3 mb-3">Dataset Selection</label>
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        displayEmpty
                                                        value={this.state.sourcefile}
                                                        onChange={this.handleSourceFile}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select dataset....</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {this.state.datasetList && this.state.datasetList.length > 0 && this.state.datasetList.map((name) => (
                                                            <MenuItem key={name} value={name}>{name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>

                                                <span className="errors mt-2">
                                                    {errors && errors.sourcefile}
                                                </span>
                                            </div> */}

                                            <label className="inputlabel mt-3 mb-3">Select Delivery Template</label>
                                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        displayEmpty
                                                        value={this.state.recipes}
                                                        onChange={this.handleRecipes}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select recipe...</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {this.state.recipeList && this.state.recipeList.length > 0 && this.state.recipeList.map((name) => (
                                                            <MenuItem key={name} value={name}>{name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>

                                                <span className="errors mt-2">
                                                    {errors && errors.recipes}
                                                </span>
                                            </div>
                                        </div>

                                    </Scrollbars>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.dataShaper}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Submit</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlist: userinfo => dispatch(datashaperlist(userinfo)),
        dataSetList: userinfo => dispatch(dataSetList(userinfo)),
        createRecipe: userinfo => dispatch(createRecipe(userinfo)),
        startJob: userinfo => dispatch(startJob(userinfo)),
        datashaperInsert: userinfo => dispatch(datashaperInsert(userinfo)),
        datashaperNotificationList: userinfo => dispatch(datashaperNotificationList(userinfo)),
        datashaperSNSNotification: userinfo => dispatch(datashaperSNSNotification(userinfo)),
        createDataSet: userinfo => dispatch(createDataSet(userinfo)),
        jobUpdate: userinfo => dispatch(jobUpdate(userinfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateDataSet);


