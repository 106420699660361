import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router";
import '../styles/LoginStyles.css';
import logo from '../assets/images/logo.png';



class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            redirectpath: '',
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ code: event.target.value });
    }

    validateLogin() {
        const errors = {};
        let formIsValid = true;
        if (!this.state.code) {
            errors.code = 'Please enter your verfication code';
            formIsValid = false;
        }
        if (this.state.code && this.state.code.length < 6) {
            errors.code = 'Code must be 6 digit';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;
    }


    handleSubmit = async () => {
        if (this.validateLogin()) {
            const { code } = this.state;
            const username = this.props.location.state !== undefined ? this.props.location.state.email : null;
            try {
                await Auth.confirmSignUp(username, code);
                if (this.props.auth && this.props.auth.isAuthenticated === true) {
                    this.setState({ redirectpath: '/campaignslist' })
                }
                else {
                    this.setState({ redirectpath: '/' })
                }
            } catch (error) {
                const errors = {};
                errors.code = 'Please enter valid code';
                this.setState({ errors })
                // console.log('error confirming sign up', error);
            }
        }
    }

    resendConfirmationCode = async () => {
        const username = this.state.email;
        try {
            await Auth.resendSignUp(username);
            console.log('code resent successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    render() {
        const { redirectpath, code, errors } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}
                        className="col-md-6 leftpanel">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="lefttext">Hello, Welcome to Arc Man!</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <p className="audiencetext">Audience Response Curation Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 rightpanel">
                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <img
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">

                            </div>
                            <div className="col-md-6">
                                <label className="emailtext mt-4 mb-3">Verification Code</label>
                                <input
                                    type="password"
                                    value={code}
                                    onChange={this.handleChange}
                                    className="emailinput"
                                    placeholder="Enter verification code"
                                />
                                <span className="errors mt-2">
                                    {errors && errors.code}
                                </span>

                                <button
                                    onClick={this.handleSubmit}
                                    type="button"
                                    className="loginbutton mt-5"
                                >
                                    <span className="logintext">Confirm</span>
                                </button>

                                <button
                                    onClick={this.resendConfirmationCode}
                                    type="button"
                                    className="loginbutton mt-5"
                                >
                                    <span className="logintext">Resend</span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Confirmation;
