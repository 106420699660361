import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router";
import '../styles/LoginStyles.css';
import linkedin from '../assets/images/linkedin.png';
import logo from '../assets/images/logo.png';



class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            redirectpath: '',
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ email: event.target.value });
    }
    validateForgotPassword() {
        const errors = {};
        let formIsValid = true;
        if (!this.state.email) {
            errors.email = 'Please enter email';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;
    }

    handleSubmit = async () => {
        if (this.validateForgotPassword()) {
            try {
                const user = await Auth.forgotPassword(this.state.email);
                console.log(user, "userrr")
                this.setState({ redirectpath: '/changepassword' })
            } catch (error) {
                console.log(error);
            }
        }
    }

    render() {
        const { redirectpath, email,errors } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}
                        className="col-md-6 leftpanel">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="lefttext">Hello, Welcome to Arc Man!</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <p className="audiencetext">Audience Response Curation Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 rightpanel">
                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <img
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">

                            </div>
                            <div className="col-md-6">
                                <label className="emailtext mt-4 mb-3">E-mail</label>
                                <input
                                    type="text"
                                    value={email}
                                    onChange={this.handleChange}
                                    className="emailinput"
                                    placeholder="Example@gmail.com"
                                />
                                <span className="errors mt-2">
                                    {errors && errors.email}
                                </span>

                                <button
                                    onClick={this.handleSubmit}
                                    type="button"
                                    className="loginbutton mt-5"
                                >
                                    <span className="logintext">Send Code</span>
                                </button>

                                <div
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                    className="mt-5">
                                    <hr
                                        style={{ width: '50%' }}
                                    />
                                    <span className="ortext pl-2">&nbsp;OR&nbsp;</span>
                                    <hr
                                        style={{ width: '50%' }}
                                    />
                                </div>

                                <button type="button" className="linkedinbutton mt-5">
                                    <div className="row">
                                        <div className="col-md-2 col-sm-2 col-2">
                                            <img
                                                src={linkedin}
                                                style={{ height: '22px', width: '22px' }}
                                                alt="linkedin"
                                            />
                                        </div>
                                        <div className="col-md-8 col-sm-8 col-8">
                                            <span className="linkedintext">Signin with LinkedIn</span>
                                        </div>
                                    </div>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ForgotPassword;
