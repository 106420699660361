import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { datashaperlist, dataSetList, netLineInsert } from "../../../actions/Campaignactions";
import Header from "../../../components/Header";
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NetlineNav from "../../../components/NetlineNav";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import S3FileUpload from 'react-s3';



toast.configure()


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const inputconfig = {
    bucketName: 'solpubtest',
    dirName: 'suppressionfiles',
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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',
    region: 'us-east-1',
}

const inputfiles3 = []

class Step1Yield extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            fields: {},
            errors: {},
            input_files: [],
            sourcefile: [],
            recipes: [],
            recipeList: [],
            datasetList: [],

        };
        // socket = socketIOClient(URL);
    }



    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    //handle input file
    handleInputFileUpload = async (file) => {
        const result = await S3FileUpload.uploadFile(file, inputconfig)
            .then((data) => {
                if (data && data.location) {
                    inputfiles3.push({
                        inputfiles3: data.location,
                    });
                }
            })
            .catch((err) => console.error(err));
        return result;
    };

    uploadInputFiles = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            this.handleInputFileUpload(e.target.files[i]);
        }
        this.setState({ input_files: [...this.state.input_files, ...e.target.files] })

    }

    notify = () => {
        toast.success('successfully Saved', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    //handle source files
    handleSourceFile = (event) => {
        // console.log(event.target.value, "value")
        const {
            target: { value },
        } = event;

        this.setState({ sourcefile: typeof value === 'string' ? value.split(',') : value })
    }

    handleRecipes = (event) => {
        // console.log(event.target.value, "value")
        const {
            target: { value },
        } = event;

        this.setState({ recipes: typeof value === 'string' ? value.split(',') : value })
    }

    // getData = (params) => {
    //     this.setState({ customernotification: params })
    // }

    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }

        this.props.datashaperlist();
        this.props.dataSetList()
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.netlineinsert &&
            this.props.campaignReducer.netlineinsert !==
            nextProps.campaignReducer.netlineinsert &&
            this.props.campaignReducer.netlineinsertSuccess === true
        ) {

            console.log(this.props.campaignReducer.netlineinsert, "netlineinsert")

        }

    }


    validateCampaign() {
        // const errors = {};
        // const { fields } = this.state;

        // this.setState({ errors });
        // return formIsValid;
    }

    //customersetup insert
    netLine = () => {
        this.setState({ redirectpath: '/netlineprogress' })
        // if (this.validateCampaign()) {
        const userinfo = {
            customer_name: this.state.fields.customer_name,
            contact_name: this.state.fields.contact_name,
            mobile_no: this.state.fields.mobile_no,
            email: this.state.fields.email
        };
        // datashaperstep1
        // console.log(userinfo, "info")
        // this.props.customerInsert(userinfo);
        // }

    };
    render() {
        const { redirectpath, fields, errors, recipeList, datasetList } = this.state;
        console.log(this.props, "assssswwssssss")
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NetlineNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Netline</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.netLine}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Yield Output</span>
                                </button>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <div className="row container">
                                            <label className="inputlabel  mb-3 mb-3">Offer Codes List File</label>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <label htmlFor="chooseInputFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                        {this.state.input_files.length === 0 ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files && this.state.input_files.map((file) => (
                                                            <span className="inputfileplaceholder">
                                                                {file.name}
                                                            </span>
                                                        ))
                                                        }
                                                        <input
                                                            className="Field"
                                                            id="chooseInputFile"
                                                            onChange={this.uploadInputFiles}
                                                            accept=".csv"
                                                            type="file"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                        <span className="browsebutton">
                                                            <span className="browsebuttontext">Browse</span>
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlist: userinfo => dispatch(datashaperlist(userinfo)),
        dataSetList: userinfo => dispatch(dataSetList(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1Yield);


