import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { createTitleBucket, prefillBucket, updateTitleBucket } from "../../../actions/Campaignactions";
import Header from "../../../components/Header";
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NLPNav from "../../../components/NLPNav";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';


import AWS from 'aws-sdk';

//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGZIW5Y5ZF',
    secretAccessKey: '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'
})


//UAT
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARDHV752W5',
//     secretAccessKey: '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'
// })


//uat
const S3_BUCKET = 'leadshaper';

//dev
// const S3_BUCKET = 'titlematch';


const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900; // in seconds

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

toast.configure()

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};






toast.configure()
class CreateTitleBucket extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            fields: {},
            errors: {},
            input_files: '',
            offer_codes: '',
            sourcefile: [],
            title_bucket: [],
            title_column: [],
            threshold: [],
            recipeList: [],
            datasetList: [],


        };

    }

    notify = () => {
        toast.success('Successfully created the NLP bucket', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }




    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    generatePreSignedPutUrl = (fileName, fileType) => {
        myBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.input_files[0]
            }).then((res) => {
                console.log(res, "resssssssssssss")
                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }





    TitleBucketFile = (e) => {
        this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type)
        this.setState({ input_files: e.target.files })
    }





    async componentDidMount() {
        if (this.props.match.params.id) {
            // const data = {
            //     id: this.props.match.params.id
            // }

            const data = {
                id: parseInt(this.props.match.params.id),
            };

            // console.log(data, "data")

            this.props.prefillBucket(data)
        }
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }
    }



    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.createTitleBucket &&
            this.props.campaignReducer.createTitleBucket !==
            nextProps.campaignReducer.createTitleBucket &&
            this.props.campaignReducer.createTitleBucketSuccess === true
        ) {
            this.notify()

        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.prefillbucket &&
            this.props.campaignReducer.prefillbucket !==
            nextProps.campaignReducer.prefillbucket &&
            this.props.campaignReducer.prefillbucketSuccess === true
        ) {
            // console.log(this.props.campaignReducer.prefillbucket, "vs")
            let fields = this.state.fields
            let input_files = this.state.input_files
            input_files = this.props.campaignReducer.prefillbucket && this.props.campaignReducer.prefillbucket.titlefile
            let array = []
            const object = {
                name: input_files
            };

            array.push(object);
            fields['title_bucket_name'] = this.props.campaignReducer.prefillbucket && this.props.campaignReducer.prefillbucket.titlebucket
            this.setState({ fields, input_files: array })

        }



    }
    validateNetline() {
        const errors = {};
        const { fields } = this.state;
        const strings = /^[A-Za-z0-9 ]+$/;

        let formIsValid = true;

        if (!fields.title_bucket_name) {
            errors.title_bucket_name = 'Please enter batch name ';
            formIsValid = false;
        }

        if (fields.title_bucket_name && fields.title_bucket_name.length < 3) {
            errors.title_bucket_name = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.title_bucket_name && fields.title_bucket_name.length > 500) {
            errors.title_bucket_name = 'batch name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.title_bucket_name && !strings.test(fields.title_bucket_name)) {
            errors.title_bucket_name = 'Please enter characters only';
            formIsValid = false;
        }
        if (this.state.offer_codes === '') {
            errors.offer_codes = 'Please select offer file ';
            formIsValid = false;
        }
        if (this.state.input_files.length === 0) {
            errors.input_files = 'Please select input file ';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;


    }

    //customersetup insert
    CreateTitleBucket = () => {
        const userinfo = {
            titlefile: this.state.input_files[0].name,
            titlebucket: this.state.fields.title_bucket_name,
        };

        // if (this.props.match.params && this.props.match.params.id !== undefined) {
        //     this.props.updateTitleBucket(userinfo);
        // }
        // else {

        this.props.createTitleBucket(userinfo)

        // }
    };
    render() {
        const { redirectpath, fields, errors } = this.state;

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NLPNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Create Title Bucket</span>
                            </div>
                           
                        </div>
                        <div className="row mt-3 maindiv1">
                        <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.CreateTitleBucket}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Submit</span>
                                </button>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <div className="row container">
                                            <label className="inputlabel  mt-3 mb-3">NLP Bucket File</label>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <label htmlFor="chooseInputFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                        {this.state.input_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files[0].name

                                                        }
                                                        <input
                                                            className="Field"
                                                            id="chooseInputFile"
                                                            onChange={this.TitleBucketFile}
                                                            accept=".csv"
                                                            type="file"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                        <span className="browsebutton">
                                                            <span className="browsebuttontext">Browse</span>
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <span className="errors mt-2">
                                                {errors && errors.input_files}
                                            </span>


                                            <div className="col-md-12 col-12 col-sm-12">
                                                <p className="inputlabel mt-3 mb-3"> NLP Bucket Name</p>
                                                <input
                                                    type="text"
                                                    value={fields.title_bucket_name}
                                                    onChange={this.handleChange.bind(this, "title_bucket_name")}
                                                    className="campaigntext"
                                                    disabled={this.props.match.params && this.props.match.params.id !== undefined ? true : false}
                                                    placeholder=" NLP Bucket Name"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.title_bucket_name}
                                                </span>
                                            </div>
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.CreateTitleBucket}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Submit</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        createTitleBucket: userinfo => dispatch(createTitleBucket(userinfo)),
        prefillBucket: userinfo => dispatch(prefillBucket(userinfo)),
        updateTitleBucket: userinfo => dispatch(updateTitleBucket(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTitleBucket);


