import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import Header from "../../../components/Header";
import {
    datashaperlistdata, sourceListData, targetListData, deliveryTemplate,
    datashaperlist,
    fetchTitleFileColumnNames,
    listTitleBucket,
    getFileName,
    fetchDelTemColumnNames,
    listOfTitles,
    fetchColumnNames,
    batchIdList,
    nlpMatch,
    getNLPOutputFile,
    insertRecipieNameData,
    getLeadSHaperFilesData,
    nlpInsert,
    deliveryTemplateDynamic, publishRecipe,
    createDataSet, dataSetList, createRecipe, startJob, datashaperInsert,
    jobUpdate, datashaperNotificationList, datashaperSNSNotification
} from '../../../actions/Campaignactions';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NLPNav from "../../../components/NLPNav";
import DeleteIcon from '@material-ui/icons/Delete';
import CreatableSelect from "react-select/creatable";
import { default as ReactSelect } from "react-select";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '@mui/material/Pagination';
import { Scrollbars } from 'react-custom-scrollbars';
import Stack from '@mui/material/Stack';
import { Modal } from "react-bootstrap";
import AddIcon from '@material-ui/icons/Add';
import AWS from 'aws-sdk';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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'
})

//UAT
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARDHV752W5',
//     secretAccessKey: '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'
// })

//dev
// const S3_BUCKET = 'solpub-campaign-workflow';

//uat
const S3_BUCKET = 'leadshaper';



const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900; // in seconds

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

//dev
// const RUNCONSOLE_BUCKET = 'titlematch';

//uat
const RUNCONSOLE_BUCKET = 'leadshaper';

const runConsoleBucket = new AWS.S3({
    params: { Bucket: RUNCONSOLE_BUCKET },
    region: REGION,
})




let step = [
    { value: "RENAME", label: "RENAME" },
    { value: "REPLACE_PATTERN", label: "REPLACE_PATTERN" },
    { value: "REMOVE_MISSING", label: "REMOVE_MISSING" },
    { value: "MOVE_AFTER", label: "MOVE_AFTER" },
    { value: "Create_New_Column", label: "Create_New_Column" },
    { value: "TODAY", label: "TODAY" },
    { value: "NOW", label: "NOW" },
    { value: "DELETE", label: "DELETE" },
    { value: "FILL_WITH_CUSTOM", label: "FILL_WITH_CUSTOM" },
    { value: "REPLACE_TEXT", label: "REPLACE_TEXT" },
    { value: "CHANGE_DATA_TYPE", label: "CHANGE_DATA_TYPE" },
    { value: "CATEGORICAL_MAPPING", label: "CATEGORICAL_MAPPING" }

];
let timezonedata = [
    { value: "Europe/Berlin", label: "Europe/Berlin" },
    { value: "America/Chicago", label: "America/Chicago" },
    { value: "America/Halifax", label: "America/Halifax" },
    { value: "America/Guyana", label: "America/Guyana" },
    { value: "America/Detroit", label: "America/Detroit" },
    { value: "America/Costa_Rica", label: "America/Costa_Rica" },
    { value: "America/Havana", label: "America/Havana" },
    { value: "America/Mexico_City", label: "America/Mexico_City" },
    { value: "America/New_York", label: "America/New_York" },
    { value: "Asia/Dubai", label: "Asia/Dubai" }
]

toast.configure()
let totalitems = ''
let noOfPages = []



const steps = ['Lead Shaper Inputs', 'Shaping', 'Generate the Lead shaper output']
const threshold_value = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]

class CreateRecipe extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            options: [],
            optionscombine: [],
            source_delete: [],
            source_delete_list: [],
            targetdata: [],
            targetvalue: '',
            moveafter: '',
            value: '',
            activeStep: 0,
            valuecombine: '',
            fields: {},
            errors: {},
            counter: 0,
            customvalue: '',
            patternvalue: '',
            input_files: '',
            input_data: '',
            input_files_data: '',
            delivery_template_input_file: '',
            delivery_files: '',
            optionSelected: null,
            timezone: null,
            page: 1,
            itemsPerPage: 10,
            existing_values: '',
            new_value: '',
            Steps: [],
            MapDataList: [],
            MapDataList1: {},
            addmore: false,
            addmapvalue: false,
            nlpvalue: false,
            nlpvalue1: false,
            nlpvalue2: false,
            nlpvalue3: false,
            recipes: [],
            employee_value: [],
            revenue_value: [],
            matchIds: '',
            NLPColumnLIST: [],
            fetchDelTemColumnNamesList: [],
            Fetchtitlebuckets: [],
            recipeList: [],
            recipe_name1: [],
            RunconsoleList: [],
            title_bucket: [],
            title_bucket1: [],
            title_bucket2: [],
            title_bucket3: [],
            title_column: [],
            BatchIdList: [],
            batch_value: [],
            title_column1: [],
            title_column2: [],
            title_column3: [],
            NLP_Column_in_NLPBucket: [],
            NLP_Column_in_NLPBucket1: [],
            NLP_Column_in_NLPBucket2: [],
            NLP_Column_in_NLPBucket3: [],
            OutputcolumninDeliveryT: [],
            OutputcolumninDeliveryT1: [],
            OutputcolumninDeliveryT2: [],
            OutputcolumninDeliveryT3: [],
            thirdStepList: [],
            threshold: [],
            threshold1: [],
            threshold2: [],
            threshold3: [],
            Parameters: {},
            customdata: [
                { value: "email", label: "email" },
                { value: "TM", label: "TM" },
                { value: " - ", label: " - " },
                { value: "keep_or_false", label: "keep_or_false" },
                { value: "DELETE", label: "DELETE" },
                { value: "Tactics to Boost Last-Mile Delivery for Retailers", label: "Tactics to Boost Last-Mile Delivery for Retailers" },
                { value: "Quarterly Market Insights Report - Q2 2021", label: "Quarterly Market Insights Report - Q2 2021" },
                { value: "Full truckload", label: "Full truckload" },
                { value: "Less-than-truckload", label: "Less-than-truckload" }
            ],
            patterndata: [
                { value: "Name", label: "Name" }
            ]
        };
    }




    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }


        this.props.datashaperlist();
        this.props.listOfTitles();
        this.props.listTitleBucket();
        this.props.dataSetList();
        this.props.batchIdList();

    }

    TitleBucket = (event) => {
        const data = {
            titlefile: event.target.value,
            // bucketName: "titlematch"
            bucketName: "leadshaper"

            
        }
        const info = {
            titlebucket: event.target.value
        }
        // this.props.getFileName(info)
        this.props.fetchTitleFileColumnNames(data)
        this.setState({ title_bucket: event.target.value })
    }

    TitleBucket1 = (event) => {
        const data = {
            titlefile: event.target.value,
            // bucketName: "titlematch"
            bucketName: "leadshaper"
        }
        const info = {
            titlebucket: event.target.value
        }
        // this.props.getFileName(info)
        this.props.fetchTitleFileColumnNames(data)
        this.setState({ title_bucket1: event.target.value })
    }

    TitleBucket2 = (event) => {
        const data = {
            titlefile: event.target.value,
            // bucketName: "titlematch"
            bucketName: "leadshaper"
        }
        const info = {
            titlebucket: event.target.value
        }
        // this.props.getFileName(info)
        this.props.fetchTitleFileColumnNames(data)
        this.setState({ title_bucket2: event.target.value })
    }

    TitleBucket3 = (event) => {
        const data = {
            titlefile: event.target.value,
            // bucketName: "titlematch"
            bucketName: "leadshaper"
        }
        const info = {
            titlebucket: event.target.value
        }
        // this.props.getFileName(info)
        this.props.fetchTitleFileColumnNames(data)
        this.setState({ title_bucket3: event.target.value })
    }



    TitleColumn = (event) => {
        this.setState({ title_column: event.target.value })
    }

    BatchId = (event) => {
        const info = {
            nlp_id: event.target.value
        }
        console.log(info, "info")
        if (this.state.activeStep === 1) {
            this.props.getNLPOutputFile(info)
        }
        if (this.state.activeStep === 2) {
            console.log("hi in third")
            this.props.getLeadSHaperFilesData(info)
        }

        this.setState({ batch_value: event.target.value })
    }





    TitleColumn1 = (event) => {
        this.setState({ title_column1: event.target.value })
    }


    TitleColumn2 = (event) => {
        this.setState({ title_column2: event.target.value })
    }

    TitleColumn3 = (event) => {
        this.setState({ title_column3: event.target.value })
    }


    NLPColumninNLPBucket = (event) => {
        this.setState({ NLP_Column_in_NLPBucket: event.target.value })
    }



    NLPColumninNLPBucket1 = (event) => {
        this.setState({ NLP_Column_in_NLPBucket1: event.target.value })
    }


    NLPColumninNLPBucket2 = (event) => {
        this.setState({ NLP_Column_in_NLPBucket2: event.target.value })
    }


    NLPColumninNLPBucket3 = (event) => {
        this.setState({ NLP_Column_in_NLPBucket3: event.target.value })
    }

    OutputcolumninDeliveryTemplateFile = (event) => {
        this.setState({ OutputcolumninDeliveryT: event.target.value })
    }

    OutputcolumninDeliveryTemplateFile1 = (event) => {
        this.setState({ OutputcolumninDeliveryT1: event.target.value })
    }

    OutputcolumninDeliveryTemplateFile2 = (event) => {
        this.setState({ OutputcolumninDeliveryT2: event.target.value })
    }

    OutputcolumninDeliveryTemplateFile3 = (event) => {
        this.setState({ OutputcolumninDeliveryT3: event.target.value })
    }


    Threshold = (event) => {
        this.setState({ threshold: event.target.value })
    }


    Threshold1 = (event) => {
        this.setState({ threshold1: event.target.value })
    }

    Threshold2 = (event) => {
        this.setState({ threshold2: event.target.value })
    }

    Threshold3 = (event) => {
        this.setState({ threshold3: event.target.value })
    }




    handleChange = (inputValue) => {
        this.setState({ value: inputValue })
    }

    handleChangeCombine = (inputValue) => {
        this.setState({ valuecombine: inputValue })
    }

    handleChangeMove = (inputValue) => {
        this.setState({ moveafter: inputValue })
    }

    handleCustom = (inputValue) => {
        this.setState({ customvalue: inputValue })
    }

    handlePattern = (inputValue) => {
        this.setState({ patternvalue: inputValue })
    }

    handleChangeTarget = (inputValue) => {
        this.setState({ targetvalue: inputValue })
    }

    handleChangedata(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    handleChangedata1 = (e) => {
        this.setState({ existing_values: e.target.value });
    }

    handleChangedata2 = (e) => {
        this.setState({ new_value: e.target.value });
    }

    handleCreate = (inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ options: [...this.state.options, newValue], value: newValue });
    }

    handleCreateCombine = (inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ optionscombine: [...this.state.optionscombine, newValue], valuecombine: newValue });
    }

    handleCustomTarget = (inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ customdata: [...this.state.customdata, newValue], customvalue: newValue });
    }

    handlePatternData = (inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ patterndata: [...this.state.patterndata, newValue], patternvalue: newValue });
    }

    handleCreateTarget = (inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ targetdata: [...this.state.options, newValue], targetvalue: newValue });
    }

    handleCreateMoveCombine = (inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ optionscombine: [...this.state.optionscombine, newValue], moveafter: newValue });
    }

    handleChangeStep = (selected) => {
        this.setState({
            optionSelected: selected
        });
    };

    handleTimeZone = (selected) => {
        this.setState({
            timezone: selected
        });
    };


    _onSelectSourceDelete = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ source_delete: typeof value === 'string' ? value.split(',') : value })
    }


    notify = () => {
        toast.success('Successfully submitted the Leadshaper Inputs', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    notify2 = () => {
        toast.success('Successfully generated Shaping Recipe', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }
    notify3 = () => {
        toast.success('Successfully started the Leadshaper Output Job', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }


    addMore = () => {
        this.setState({ addmore: true })
    }

    addMapValue = () => {
        var MapDataList1 = this.state.MapDataList1;
        if (this.state.existing_values !== '') {
            var obj = {};
            obj[this.state.existing_values] = this.state.new_value
            MapDataList1 = { ...MapDataList1, ...obj };
        }


        this.setState({ MapDataList1, new_value: '', existing_values: '' })
        this.setState({
            addmapvalue: true,
            // addmore: false,

        });
    }


    addNLP1 = () => {

        this.setState({
            nlpvalue: true,

        });
    }

    addNLP2 = () => {
        this.setState({
            nlpvalue1: true,

        });
    }

    addNLP3 = () => {

        this.setState({
            nlpvalue2: true,

        });
    }

    addNLP4 = () => {
        this.setState({
            nlpvalue3: true,

        });
    }


    closeModalMapValue = () => {
        this.setState({ addmapvalue: false, nlpvalue: false, nlpvalue1: false, nlpvalue2: false, nlpvalue3: false })
    }

    onCloseModal = () => {
        const Steps = this.state.Steps;
        if (this.state.optionSelected) {
            var Action = {};
            var Parameters = {};
            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'RENAME') {
                Parameters.sourceColumn = this.state.value.value;
                Parameters.targetColumn = this.state.targetvalue.value;
            }
            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'REMOVE_MISSING') {
                Parameters.sourceColumn = this.state.value.value;
            }

            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'FILL_WITH_CUSTOM') {
                Parameters.columnDataType = "string";
                Parameters.sourceColumn = this.state.valuecombine.value;
                Parameters.value = this.state.customvalue !== null && this.state.customvalue.value;
            }
            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'MOVE_AFTER') {
                Parameters.targetIndex = "NaN";
                // Parameters.sourceColumn = this.state.value.value;
                Parameters.sourceColumn = this.state.valuecombine.value;
                Parameters.targetColumn = this.state.moveafter.value;
                // Parameters.targetColumn = this.state.targetvalue.value;
            }
            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'Create_New_Column') {
                Parameters.pattern = " ";
                Parameters.sourceColumn = this.state.value.value;
                Parameters.targetColumn = this.state.targetvalue.value;
            }

            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'REPLACE_TEXT') {
                Parameters.pattern = " ";
                Parameters.sourceColumn = this.state.valuecombine.value;
                Parameters.value = this.state.customvalue !== null && this.state.customvalue.value;
            }

            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'REPLACE_PATTERN') {
                Parameters.pattern = this.state.patternvalue !== null && this.state.patternvalue.value;
                Parameters.sourceColumn = this.state.valuecombine.value;
                Parameters.value = this.state.customvalue !== null && this.state.customvalue.value;
            }

            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'CHANGE_DATA_TYPE') {
                Parameters.columnDataType = "string";
                Parameters.sourceColumn = this.state.value.value;
            }


            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'TODAY') {
                Parameters.functionStepType = "TODAY";
                Parameters.targetColumn = this.state.targetvalue.value;
                Parameters.timeZone = this.state.timezone !== null && this.state.timezone.value;
            }

            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'NOW') {
                Parameters.functionStepType = "NOW";
                Parameters.targetColumn = this.state.targetvalue.value;
                Parameters.timeZone = this.state.timezone !== null && this.state.timezone.value;
            }

            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'DELETE') {
                Parameters.sourceColumns = JSON.stringify(this.state.source_delete);
            }
            if (this.state.optionSelected !== null && this.state.optionSelected.value === 'CATEGORICAL_MAPPING') {
                Parameters.categoryMap = JSON.stringify(this.state.MapDataList1);
                Parameters.deleteOtherRows = false;
                Parameters.mapType = "TEXT";
                Parameters.mappingOption = "CUSTOM_VALUES";
                Parameters.sourceColumn = this.state.value.value;
                Parameters.targetColumn = this.state.fields.target_data;
            }
            let data = this.state.optionSelected !== null && this.state.optionSelected.value === 'Create_New_Column' ? 'EXTRACT_PATTERN' : this.state.optionSelected.value
            Action.Operation = data;
            Action.Parameters = Parameters;

            Steps.push({
                Action
            });

        }
        this.setState({ Steps, optionSelected: '', value: '', targetvalue: '', source_delete: [], MapDataList1: {} })
        this.setState({ addmore: false });
    };

    generatePreSignedPutUrl = (fileName, fileType, file) => {
        runConsoleBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: file
            }).then((res) => {
                // console.log(res, "runConsoleBucket")
                const data = {
                    // bucketName: "titlematch",
                    bucketName: "leadshaper",
                    nlp_output_file: fileName
                }
                // console.log(data, "data")
                this.props.sourceListData(data)
            })
            return url
        });
    }

    generatePreSignedPutUrlTarget = (fileName, fileType, file) => {
        runConsoleBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            console.log(err, "errrrrr")
            fetch(url, {
                method: 'PUT',
                body: file
            }).then((res) => {
                console.log(res, "res for shaping")
                const data = {
                    // bucketName: "titlematch",
                    bucketName: "leadshaper",
                    delivery_template_input_file: fileName
                }
                this.props.deliveryTemplateDynamic(data)
            })

            return url
        });
    }

    uploadInputFiles = (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.input_files = ""
            this.setState({ input_files: e.target.files[0].name, errors })
        } else {
            errors.input_files = "Please select csv only"
            this.setState({ errors })
        }
    }

    generatePreSignedPutUrlDeliveryTemplate = (fileName, fileType) => {
        runConsoleBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.delivery_template_input_file[0]
            }).then((res) => {
                const data = {
                    // bucketName: 'titlematch',
                    bucketName: "leadshaper",
                    delivery_template_input_file: fileName,
                }

                const info = {
                    csv_filename1: this.state.input_data !== '' ? this.state.input_data[0].name : "",
                    delivery_template_input_file: fileName,
                }
                console.log(res, "resssssssssssss")
                this.props.nlpInsert(info)
                this.props.fetchDelTemColumnNames(data)

                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }


    uploadInputFilesDeliveryTemplate = (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrlDeliveryTemplate(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.delivery_template_input_file = ""
            this.setState({ delivery_template_input_file: e.target.files, errors })
        } else {
            errors.delivery_template_input_file = "Please select csv only"
            this.setState({ errors })
        }
    }



    generatePreSignedPutUrlRunConsole = (fileName, fileType) => {
        runConsoleBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.input_data[0]
            }).then((res) => {
                const data = {
                    // bucketName: 'titlematch',
                    bucketName: "leadshaper",
                    sourceFile: fileName,
                }
                console.log(res, "resssssssssssss")
                this.props.fetchColumnNames(data)

                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }


    uploadInputFilesRunConsole = (e) => {
        this.generatePreSignedPutUrlRunConsole(e.target.files[0].name, e.target.files[0].type)
        this.setState({ input_data: e.target.files })
    }



    generatePreSignedPutUrlCreateDataset = (fileName, fileType, file) => {
        myBucket.getSignedUrl('putObject', {
            //dev
            Key: fileName,
            //uat
            // Key: `datashaperInput/${fileName}`,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            console.log(err, "errrrrr")
            fetch(url, {
                method: 'PUT',
                body: file
            }).then((res) => {
                console.log(res, "resss")
                const info = {
                    // name: this.state.input_files[0].name,
                    // input: this.state.input_files[0].name,
                    "csv_filename1": fileName
                }
                // console.log(info)
                // this.props.createDataSet(info)
            })
            return url
        });
    }

    uploadInputFilesCreateDataset = (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrlCreateDataset(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.datasetname = ""
            this.setState({ input_files_data: e.target.files[0].name, errors })
        } else {
            errors.datasetname = "Please select csv only"
            this.setState({ errors })
        }
    }




    handleRecipes = (event) => {
        this.setState({ recipes: event.target.value })
    }

    handleRecipes1 = (event) => {
        this.setState({ recipe_name1: event.target.value })
    }

    handleEmployeesColumn = (event) => {
        this.setState({ employee_value: event.target.value })
    }


    handleRevenueColumn = (event) => {
        this.setState({ revenue_value: event.target.value })
    }




    uploadDeliveryFiles = (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrlTarget(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.delivery_files = ""
            this.setState({ delivery_files: e.target.files[0].name, errors })
        } else {
            errors.delivery_files = "Please select csv only"
            this.setState({ errors })
        }
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.getLeadShaperFiles &&
            this.props.campaignReducer.getLeadShaperFiles !==
            nextProps.campaignReducer.getLeadShaperFiles &&
            this.props.campaignReducer.getLeadShaperFilesSuccess === true
        ) {
            // console.log(this.props.campaignReducer.getLeadShaperFiles.delivery_template_recipe_name, "this.props.campaignReducer.getLeadShaperFiles.delivery_template_recipe_name")
            let input_files_data = this.state.input_files_data
            input_files_data = this.props.campaignReducer.getLeadShaperFiles && this.props.campaignReducer.getLeadShaperFiles.nlp_output_file


            let recipes = this.state.recipes
            recipes = this.props.campaignReducer.getLeadShaperFiles && this.props.campaignReducer.getLeadShaperFiles.delivery_template_recipe_name !== null ? this.props.campaignReducer.getLeadShaperFiles.delivery_template_recipe_name : []

            const thirdStepList = [];

            thirdStepList.push(
                this.props.campaignReducer.getLeadShaperFiles && this.props.campaignReducer.getLeadShaperFiles.delivery_template_recipe_name

            );


            this.setState({ input_files_data: input_files_data, thirdStepList: thirdStepList, recipes: recipes })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.insertRecipieNameData &&
            this.props.campaignReducer.insertRecipieNameData !==
            nextProps.campaignReducer.insertRecipieNameData &&
            this.props.campaignReducer.insertRecipieNameDataSuccess === true
        ) {
            this.notify2()
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.getNLPOutPutFile &&
            this.props.campaignReducer.getNLPOutPutFile !==
            nextProps.campaignReducer.getNLPOutPutFile &&
            this.props.campaignReducer.getNLPOutPutFileSuccess === true
        ) {
            let input_files = this.state.input_files
            input_files = this.props.campaignReducer.getNLPOutPutFile && this.props.campaignReducer.getNLPOutPutFile.nlp_output_file

            let delivery_files = this.state.delivery_files
            delivery_files = this.props.campaignReducer.getNLPOutPutFile && this.props.campaignReducer.getNLPOutPutFile.delivery_template_input_file

            let fields = this.state.fields
            fields.name = this.props.campaignReducer.getNLPOutPutFile && this.props.campaignReducer.getNLPOutPutFile.delivery_template_recipe_name
            this.setState({ input_files: input_files, delivery_files: delivery_files, fields: fields })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.batchIdList &&
            this.props.campaignReducer.batchIdList !==
            nextProps.campaignReducer.batchIdList &&
            this.props.campaignReducer.batchIdListSuccess === true
        ) {
            const BatchIdList = [];
            this.props.campaignReducer.batchIdList && this.props.campaignReducer.batchIdList.length > 0 && this.props.campaignReducer.batchIdList.map((item) => {
                if (item["nlp id"]) {
                    BatchIdList.push(
                        item["nlp id"]
                    );
                }
                return true;
            })

            this.setState({ BatchIdList: BatchIdList })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.delivertemplate &&
            this.props.campaignReducer.delivertemplate !==
            nextProps.campaignReducer.delivertemplate &&
            this.props.campaignReducer.delivertemplateSuccess === true
        ) {
            this.notify()
            const datashaperinfo = {
                Name: this.state.fields.name

            }
            this.props.publishRecipe(datashaperinfo)
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.listTitleBucket &&
            this.props.campaignReducer.listTitleBucket !==
            nextProps.campaignReducer.listTitleBucket &&
            this.props.campaignReducer.listTitleBucketSuccess === true
        ) {

            const recipeList = [];
            this.props.campaignReducer.listTitleBucket && this.props.campaignReducer.listTitleBucket.length > 0 && this.props.campaignReducer.listTitleBucket.map((item, index) => {
                if (item.titlebucket) {
                    recipeList.push(
                        item.titlebucket
                    );
                }
                return true;
            })
            this.setState({ Fetchtitlebuckets: recipeList })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlist !==
            nextProps.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlistSuccess === true
        ) {
            const recipeList = [];
            this.props.campaignReducer.datashaperlist && this.props.campaignReducer.datashaperlist.length > 0 && this.props.campaignReducer.datashaperlist.map((item, index) => {
                if (item.recipe) {
                    recipeList.push(
                        item.recipe
                    );
                }
                return true;
            })
            this.setState({ recipeList: recipeList })


        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.sourcelistdata &&
            this.props.campaignReducer.sourcelistdata !==
            nextProps.campaignReducer.sourcelistdata &&
            this.props.campaignReducer.sourcelistdataSuccess === true
        ) {
            console.log(this.props.campaignReducer.sourcelistdata, "  this.props.campaignReducer.sourcelistdata")
            const userList = [];
            const sourceList = [];
            this.props.campaignReducer.sourcelistdata && this.props.campaignReducer.sourcelistdata.Response.length > 0 && this.props.campaignReducer.sourcelistdata.Response.map((item, index) => {
                if (item) {
                    userList.push({
                        value: item,
                        label: item
                    });

                    sourceList.push(
                        item
                    );
                }
                return true;
            })
            this.setState({ options: userList, source_delete_list: sourceList })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.deliverydynamictemplate &&
            this.props.campaignReducer.deliverydynamictemplate !==
            nextProps.campaignReducer.deliverydynamictemplate &&
            this.props.campaignReducer.deliverydynamictemplateSuccess === true
        ) {
            const userList = [];
            this.props.campaignReducer.deliverydynamictemplate && this.props.campaignReducer.deliverydynamictemplate.Response.length > 0 && this.props.campaignReducer.deliverydynamictemplate.Response.map((item, index) => {
                if (item) {
                    userList.push({
                        value: item,
                        label: item
                    });
                }
                return true;
            })
            let arryFirst = this.state.options.concat(userList);
            this.setState({ targetdata: userList, optionscombine: arryFirst })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.fetchColumns &&
            this.props.campaignReducer.fetchColumns !==
            nextProps.campaignReducer.fetchColumns &&
            this.props.campaignReducer.fetchColumnsSuccess === true
        ) {

            const recipeList = [];
            this.props.campaignReducer.fetchColumns && this.props.campaignReducer.fetchColumns.Response && this.props.campaignReducer.fetchColumns.Response.length > 0 && this.props.campaignReducer.fetchColumns.Response.map((item, index) => {
                if (item) {
                    recipeList.push(
                        item
                    );
                }
                return true;
            })
            this.setState({ RunconsoleList: recipeList })
        }



        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlist !==
            nextProps.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlistSuccess === true
        ) {
            const testList = [];
            this.props.campaignReducer.datashaperlist && this.props.campaignReducer.datashaperlist.length > 0 && this.props.campaignReducer.datashaperlist.map((item, index) => {
                if (item.recipe) {
                    testList.push(
                        item.recipe
                    );
                }
                return true;
            })
            this.setState({ testList: testList })


        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.fetchDelTemColumnNames &&
            this.props.campaignReducer.fetchDelTemColumnNames !==
            nextProps.campaignReducer.fetchDelTemColumnNames &&
            this.props.campaignReducer.fetchDelTemColumnNamesSuccess === true
        ) {
            const testList = [];
            // console.log(this.props.campaignReducer.fetchDelTemColumnNames, "this.props.campaignReducer.fetchDelTemColumnNames")
            this.props.campaignReducer.fetchDelTemColumnNames && this.props.campaignReducer.fetchDelTemColumnNames.Response.length > 0 && this.props.campaignReducer.fetchDelTemColumnNames.Response.map((item, index) => {
                console.log(item, "fetchDelTemColumnNames")
                if (item) {
                    testList.push(
                        item
                    );
                }
                return true;
            })
            this.setState({ fetchDelTemColumnNamesList: testList })


        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.listTitles &&
            this.props.campaignReducer.listTitles !==
            nextProps.campaignReducer.listTitles &&
            this.props.campaignReducer.listTitlesSuccess === true
        ) {
            const datasetList = [];
            this.props.campaignReducer.listTitles && this.props.campaignReducer.listTitles.length > 0 && this.props.campaignReducer.listTitles.map((item, index) => {
                if (item.titlebucket) {
                    datasetList.push(
                        item.titlebucket
                    );
                }
                return true;
            })
            this.setState({ datasetList: datasetList })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.getFileName &&
            this.props.campaignReducer.getFileName !==
            nextProps.campaignReducer.getFileName &&
            this.props.campaignReducer.getFileNameSuccess === true
        ) {
            this.setState({ Campaign_Titles: this.props.campaignReducer.getFileName && this.props.campaignReducer.getFileName.titlefile })
            console.log(this.props.campaignReducer.getFileName && this.props.campaignReducer.getFileName.titlefile, "this.props.campaignReducer.getFileName")
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.nlpInsert &&
            this.props.campaignReducer.nlpInsert !==
            nextProps.campaignReducer.nlpInsert &&
            this.props.campaignReducer.nlpInsertSuccess === true
        ) {

            this.setState({
                matchIds: this.props.campaignReducer.nlpInsert && this.props.campaignReducer.nlpInsert.nlp_id
            })

        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.nlpMatch &&
            this.props.campaignReducer.nlpMatch !==
            nextProps.campaignReducer.nlpMatch &&
            this.props.campaignReducer.nlpMatchSuccess === true
        ) {
            this.notify()
            // console.log(this.props.campaignReducer.nlpMatch, "this.props.campaignReducer.nlpMatch")
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.fetchTitleFileColumnNames &&
            this.props.campaignReducer.fetchTitleFileColumnNames !==
            nextProps.campaignReducer.fetchTitleFileColumnNames &&
            this.props.campaignReducer.fetchTitleFileColumnNamesSuccess === true
        ) {

            const datasetList = [];
            if (this.props.campaignReducer.fetchTitleFileColumnNames &&
                this.props.campaignReducer.fetchTitleFileColumnNames.Response) {
                datasetList.push(
                    this.props.campaignReducer.fetchTitleFileColumnNames &&
                    this.props.campaignReducer.fetchTitleFileColumnNames.Response
                );
            }

            this.setState({ NLPColumnLIST: datasetList })
        }



        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.createdataset &&
            this.props.campaignReducer.createdataset !==
            nextProps.campaignReducer.createdataset &&
            this.props.campaignReducer.createdatasetSuccess === true
        ) {
            this.setState({
                matchId: this.props.campaignReducer.createdataset &&
                    this.props.campaignReducer.createdataset.nlp_id

            })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashapernotificationlist &&
            this.props.campaignReducer.datashapernotificationlist !==
            nextProps.campaignReducer.datashapernotificationlist &&
            this.props.campaignReducer.datashapernotificationlistSuccess === true
        ) {
            // console.log(this.props.campaignReducer.datashapernotificationlist.length, "datasetcreated successfully")
            this.setState({
                notificationdataList: this.props.campaignReducer.datashapernotificationlist && this.props.campaignReducer.datashapernotificationlist.length > 0 && this.props.campaignReducer.datashapernotificationlist[0]
            })
        }



        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsert !==
            nextProps.campaignReducer.datashaperInsert &&
            this.props.campaignReducer.datashaperInsertSuccess === true
        ) {
            this.notify3()
            // console.log(this.props.campaignReducer.datashaperInsert, "this.props.campaignReducer.datashaperInsert")
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipe !==
            nextProps.campaignReducer.createrecipe &&
            this.props.campaignReducer.createrecipeSuccess === true
        ) {
            const shaperinfo = {
                name: this.state.fields.name,
                dataset: this.state.input_files[0].name,
            }
            this.props.startJob(shaperinfo)
            // this.notify()
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.startjob &&
            this.props.campaignReducer.startjob !==
            nextProps.campaignReducer.startjob &&
            this.props.campaignReducer.startjobSuccess === true
        ) {
            const jobinfo = {
                run_id: this.props.campaignReducer.startjob && this.props.campaignReducer.startjob.RunId,
                datashaper_id: this.props.campaignReducer.datashaperInsert && this.props.campaignReducer.datashaperInsert.insertId,
            }
            this.props.jobUpdate(jobinfo)
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.startjob &&
            this.props.campaignReducer.startjob !==
            nextProps.campaignReducer.startjob &&
            this.props.campaignReducer.startjobSuccess === false
        ) {
            // this.notifyforjobfail()
        }
    }


    DeleteIconData = (item) => {
        const Steps = this.state.Steps;

        const index = Steps.indexOf(item);
        if (index > -1) {
            Steps.splice(index, 1);
        }

        this.setState({ Steps })
    };


    DeleteIconData11 = (item) => {
        let MapDataList1 = this.state.MapDataList1;
        delete MapDataList1[item[0]];
        this.setState({ MapDataList1 })
    };



    dataShaper = () => {
        const datashaperinfo = {
            Name: this.state.fields.name,
            Steps: this.state.Steps
        }
        console.log(deliveryTemplate, "deliveryTemplate")
        this.props.deliveryTemplate(datashaperinfo)

        const info = {
            recipe_Name: this.state.fields.name,
            nlp_id: this.state.batch_value
        }
        console.log(info, "insertRecipieNameData")
        this.props.insertRecipieNameData(info)

    };



    dataShaperTranform = () => {
        // if (this.validateDatashaper()) {
        const info = {
            // bucket_name: "titlematch",
            bucket_name: "leadshaper",
            csv_filename1: this.state.input_files_data !== '' ? this.state.input_files_data : this.state.input_files_data[0].name,
            recipe_name: this.state.recipes && this.state.recipes.length > 0 ? this.state.recipes : "",
            recipe_name1: this.state.recipe_name1 && this.state.recipe_name1.length > 0 ? this.state.recipe_name1 : "",
            matchId: this.state.batch_value
        }
        console.log(info, "dataShaperTranform")
        this.props.datashaperInsert(info)
        // }

    };



    isStepOptional = (step) => {
        return step === 1;
    };

    handleNext = () => {
        this.setState({ activeStep: this.state.activeStep + 1 });
    };

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };

    handleReset = () => {
        this.setState({ activeStep: 0 });
    };


    NLPInsert = () => {
        const userinfo = {
            // bucket_name: "titlematch",
            bucket_name: "leadshaper",
            csv_filename1: this.state.input_data !== '' ? this.state.input_data[0].name : "",
            delivery_template_input_file: this.state.delivery_template_input_file !== '' ? this.state.delivery_template_input_file[0].name : "",

            matchId: this.state.matchIds,
            recipe_name: this.state.recipes && this.state.recipes.length > 0 ? this.state.recipes : "",
            inputfile_employeesColumn: this.state.employee_value && this.state.employee_value.length > 0 ? this.state.employee_value : "",
            inputfile_revenueColumn: this.state.revenue_value && this.state.revenue_value.length > 0 ? this.state.revenue_value : "",



            //step1
            threshold1: this.state.threshold && this.state.threshold,
            titlefile1: this.state.title_bucket && this.state.title_bucket.length > 0 ? this.state.title_bucket : "",
            inputfile_confidenceColumn1: this.state.title_column && this.state.title_column.length > 0 ? this.state.title_column : "",
            titlefile1_matchColumn: this.state.NLP_Column_in_NLPBucket && this.state.NLP_Column_in_NLPBucket.length > 0 ? this.state.NLP_Column_in_NLPBucket[0] : "",
            del_template_outputColumn1: this.state.OutputcolumninDeliveryT && this.state.OutputcolumninDeliveryT.length > 0 ? this.state.OutputcolumninDeliveryT : "",

            //step2
            threshold2: this.state.threshold1 && this.state.threshold1,
            titlefile2: this.state.title_bucket1 && this.state.title_bucket1.length > 0 ? this.state.title_bucket1 : "",
            inputfile_confidenceColumn2: this.state.title_column1 && this.state.title_column1.length > 0 ? this.state.title_column1 : "",
            titlefile2_matchColumn: this.state.NLP_Column_in_NLPBucket1 && this.state.NLP_Column_in_NLPBucket1.length > 0 ? this.state.NLP_Column_in_NLPBucket1[0] : "",
            del_template_outputColumn2: this.state.OutputcolumninDeliveryT1 && this.state.OutputcolumninDeliveryT1.length > 0 ? this.state.OutputcolumninDeliveryT1 : "",

            //step3
            threshold3: this.state.threshold2 && this.state.threshold2,
            titlefile3: this.state.title_bucket2 && this.state.title_bucket2.length > 0 ? this.state.title_bucket2 : "",
            inputfile_confidenceColumn3: this.state.title_column2 && this.state.title_column2.length > 0 ? this.state.title_column2 : "",
            titlefile3_matchColumn: this.state.NLP_Column_in_NLPBucket2 && this.state.NLP_Column_in_NLPBucket2.length > 0 ? this.state.NLP_Column_in_NLPBucket2[0] : "",
            del_template_outputColumn3: this.state.OutputcolumninDeliveryT2 && this.state.OutputcolumninDeliveryT2.length > 0 ? this.state.OutputcolumninDeliveryT2 : "",

            //step4
            threshold4: this.state.threshold3 && this.state.threshold3,
            titlefile4: this.state.title_bucket3 && this.state.title_bucket3.length > 0 ? this.state.title_bucket3 : "",
            inputfile_confidenceColumn4: this.state.title_column3 && this.state.title_column3.length > 0 ? this.state.title_column3 : "",
            titlefile4_matchColumn: this.state.NLP_Column_in_NLPBucket3 && this.state.NLP_Column_in_NLPBucket3.length > 0 ? this.state.NLP_Column_in_NLPBucket3[0] : "",
            del_template_outputColumn4: this.state.OutputcolumninDeliveryT3 && this.state.OutputcolumninDeliveryT3.length > 0 ? this.state.OutputcolumninDeliveryT3 : "",
        }

        console.log(userinfo, "userinfo")
        this.props.nlpMatch(userinfo)

    };


    handleChangeDataShaper = (value) => {
        this.setState({ page: value })
    };



    render() {
        const { redirectpath, fields, errors, optionSelected, targetdata, page, itemsPerPage, source_delete_list, timezone, MapDataList1 } = this.state;
        totalitems = this.state.Steps.length;
        noOfPages = Math.ceil(this.state.Steps.length / itemsPerPage)
        const myObjEntries = Object.entries(MapDataList1)


        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NLPNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChangeDataShaper}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>
                        </div>

                        <div className="row mt-3 maindiv1">
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>


                            <div className="col-md-12 col-sm-12 col-12 mb-3 pb-3 d-md-flex justify-content-end">
                                <button
                                    onClick={this.addMore}
                                    className="browsebutton  mt-4 savesetup"
                                >
                                    <span className="browsebuttontext">Add Step</span>
                                </button>
                            </div>

                            <>
                                {this.state.activeStep === steps.length ? (
                                    <div className="mt-3">
                                        <div className="campaignlabel pb-2 mb-2">
                                            All steps completed - you&apos;re finished
                                        </div>
                                        <div className="mb-2 pb-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Button
                                                style={{
                                                    padding: '0.5rem 0.5rem',
                                                    backgroundColor: '#23857A',
                                                    borderRadius: '0.5rem',
                                                    borderColor: 'transparent',
                                                    fontFamily: 'Montserrat',
                                                    color: '#ffffff'
                                                }}
                                                onClick={this.handleReset}>Reset</Button>
                                            <Button
                                                style={{
                                                    padding: '0.5rem 0.5rem',
                                                    backgroundColor: '#23857A',
                                                    borderRadius: '0.5rem',
                                                    borderColor: 'transparent',
                                                    fontFamily: 'Montserrat',
                                                    color: '#ffffff'
                                                }}
                                                onClick={this.campaignInsert}>Save</Button>


                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {this.state.activeStep + 1}</Typography> */}
                                        <div className="pb-3 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Button
                                                style={{
                                                    padding: '0.5rem 0.5rem',
                                                    backgroundColor: '#23857A',
                                                    borderRadius: '0.5rem',
                                                    borderColor: 'transparent',
                                                    fontFamily: 'Montserrat',
                                                    color: '#ffffff'
                                                }}
                                                color="inherit"
                                                disabled={this.state.activeStep === 0}
                                                onClick={this.handleBack}
                                            // sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                                            <div
                                            // style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                                            >

                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        marginRight: '15px',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.state.activeStep === 0 ? this.NLPInsert : this.state.activeStep === 1 ? this.dataShaper : this.state.activeStep === 2 ? this.dataShaperTranform : ''}

                                                >
                                                    {this.state.activeStep === 0 ? 'Submit Leadshaper Inputs' : this.state.activeStep === 1 ? 'Submit Shaping' : this.state.activeStep === 2 ? 'Generate Leadshaper Output' : ''}
                                                </Button>

                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.handleNext}>
                                                    {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>

                            {this.state.activeStep === 0 && (
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                    <div className="listcontainer">
                                        <Scrollbars
                                            style={{ width: '100%', margin: 0 }}
                                        >
                                            <div className="row container">
                                                <label className="inputlabel  mt-3 mb-3">Source File</label>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <label htmlFor="chooseInputFileRunConsole" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                            {this.state.input_data === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_data[0].name

                                                            }
                                                            <input
                                                                className="Field"
                                                                id="chooseInputFileRunConsole"
                                                                onChange={this.uploadInputFilesRunConsole}
                                                                accept=".csv"
                                                                type="file"
                                                                multiple
                                                            />
                                                        </div>
                                                        <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                            <span className="browsebutton">
                                                                <span className="browsebuttontext">Browse</span>
                                                            </span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <span className="errors mt-2">
                                                    {errors && errors.input_data}
                                                </span>


                                                <label className="inputlabel  mt-3 mb-3"> Delivery Template input File</label>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <label htmlFor="chooseInputFileDeliveryTemplate" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                            {this.state.delivery_template_input_file === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.delivery_template_input_file[0].name

                                                            }
                                                            <input
                                                                className="Field"
                                                                id="chooseInputFileDeliveryTemplate"
                                                                onChange={this.uploadInputFilesDeliveryTemplate}
                                                                accept=".csv"
                                                                type="file"
                                                                multiple
                                                            />
                                                        </div>
                                                        <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                            <span className="browsebutton">
                                                                <span className="browsebuttontext">Browse</span>
                                                            </span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <span className="errors mt-2">
                                                    {errors && errors.delivery_template_input_file}
                                                </span>






                                                <label className="inputlabel mt-3 mb-3">Apply Basic Leadshaper Transformations</label>
                                                <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Select
                                                            displayEmpty
                                                            value={this.state.recipes}
                                                            onChange={this.handleRecipes}
                                                            input={<OutlinedInput />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span>Select Basic Leadshaper Transformations...</span>;
                                                                }

                                                                return selected;
                                                            }}
                                                        >
                                                            {this.state.testList && this.state.testList.length > 0 && this.state.testList.map((name) => (
                                                                <MenuItem key={name} value={name}>{name}</MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>

                                                    <span className="errors mt-2">
                                                        {errors && errors.recipes}
                                                    </span>
                                                </div>

                                                <label className="inputlabel mt-3 mb-3"> Select Employees Column in Source File</label>
                                                <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Select
                                                            displayEmpty
                                                            value={this.state.employee_value}
                                                            onChange={this.handleEmployeesColumn}
                                                            input={<OutlinedInput />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span> Select Employees Column in Source File...</span>;
                                                                }

                                                                return selected;
                                                            }}
                                                        >
                                                            {this.state.RunconsoleList && this.state.RunconsoleList.length > 0 && this.state.RunconsoleList.map((name) => (
                                                                <MenuItem key={name} value={name}>{name}</MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>

                                                    <span className="errors mt-2">
                                                        {errors && errors.recipes}
                                                    </span>
                                                </div>


                                                <label className="inputlabel mt-3 mb-3"> Select Revenue Column in Source File</label>
                                                <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Select
                                                            displayEmpty
                                                            value={this.state.revenue_value}
                                                            onChange={this.handleRevenueColumn}
                                                            input={<OutlinedInput />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span> Select Revenue Column in Source File...</span>;
                                                                }

                                                                return selected;
                                                            }}
                                                        >
                                                            {this.state.RunconsoleList && this.state.RunconsoleList.length > 0 && this.state.RunconsoleList.map((name) => (
                                                                <MenuItem key={name} value={name}>{name}</MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>

                                                    <span className="errors mt-2">
                                                        {errors && errors.revenue_value}
                                                    </span>
                                                </div>

                                                <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                    <AddIcon
                                                        onClick={this.addNLP1}
                                                        style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                    />
                                                    <span className="addmapvalue">Add NLP Step 1</span>
                                                    <span className="errors mt-2">
                                                        {errors && errors.html_subject_lines}
                                                    </span>
                                                </div>

                                                <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                    <AddIcon
                                                        onClick={this.addNLP2}
                                                        style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                    />
                                                    <span className="addmapvalue">Add NLP Step 2</span>
                                                    <span className="errors mt-2">
                                                        {errors && errors.html_subject_lines}
                                                    </span>
                                                </div>

                                                <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                    <AddIcon
                                                        onClick={this.addNLP3}
                                                        style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                    />
                                                    <span className="addmapvalue">Add NLP Step 3</span>
                                                    <span className="errors mt-2">
                                                        {errors && errors.html_subject_lines}
                                                    </span>
                                                </div>

                                                <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                    <AddIcon
                                                        onClick={this.addNLP4}
                                                        style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                    />
                                                    <span className="addmapvalue">Add NLP Step 4</span>
                                                    <span className="errors mt-2">
                                                        {errors && errors.html_subject_lines}
                                                    </span>
                                                </div>


                                            </div>

                                        </Scrollbars>
                                    </div>
                                </div>
                            )}
                            {this.state.activeStep === 2 && (
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                    <div className="listcontainer">
                                        <Scrollbars
                                            style={{ width: '100%', margin: 0 }}
                                        >
                                            <div className="row container">
                                                <label className="inputlabel mt-3 mb-3">Batch Id</label>
                                                <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Select
                                                            displayEmpty
                                                            value={this.state.batch_value}
                                                            onChange={this.BatchId}
                                                            input={<OutlinedInput />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span>Select Batch Id...</span>;
                                                                }

                                                                return selected;
                                                            }}
                                                        >
                                                            {this.state.BatchIdList && this.state.BatchIdList.length > 0 && this.state.BatchIdList.map((name) => (
                                                                <MenuItem key={name} value={name}>{name}</MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>


                                                </div>
                                                <label className="inputlabel  mb-3">Input Files</label>
                                                <div className="inputlabel  mb-3" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <label htmlFor="chooseInputFileCreateDataset" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                            {this.state.input_files_data === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files_data

                                                            }
                                                            <input
                                                                className="Field"
                                                                id="chooseInputFileCreateDataset"
                                                                onChange={this.uploadInputFilesCreateDataset}
                                                                accept=".csv"
                                                                type="file"
                                                                multiple
                                                            />
                                                        </div>
                                                        <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                            <span className="browsebutton1">
                                                                <span className="browsebuttontext">Browse</span>
                                                            </span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <span className="errors mt-2">
                                                    {errors && errors.datasetname}
                                                </span>

                                                <label className="inputlabel mt-3 mb-3">Shaping Recipe Name</label>
                                                <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Select
                                                            displayEmpty
                                                            value={this.state.recipes}
                                                            onChange={this.handleRecipes}
                                                            input={<OutlinedInput />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span>Select Shaping Recipe Name...</span>;
                                                                }

                                                                return selected;
                                                            }}
                                                        >
                                                            {this.state.thirdStepList && this.state.thirdStepList.length > 0 && this.state.thirdStepList.map((name) => (
                                                                <MenuItem key={name} value={name}>{name}</MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>

                                                    <span className="errors mt-2">
                                                        {errors && errors.recipes}
                                                    </span>
                                                </div>


                                                <label className="inputlabel mt-3 mb-3">Apply Additional Leadshaper Basic Transformations</label>
                                                <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                    <FormControl style={{ width: '100%' }}>
                                                        <Select
                                                            displayEmpty
                                                            value={this.state.recipe_name1}
                                                            onChange={this.handleRecipes1}
                                                            input={<OutlinedInput />}
                                                            MenuProps={MenuProps}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span>Select Shaping Recipe Name...</span>;
                                                                }

                                                                return selected;
                                                            }}
                                                        >
                                                            {this.state.recipeList && this.state.recipeList.length > 0 && this.state.recipeList.map((name) => (
                                                                <MenuItem key={name} value={name}>{name}</MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>

                                                    <span className="errors mt-2">
                                                        {errors && errors.recipe_name1}
                                                    </span>
                                                </div>


                                            </div>

                                        </Scrollbars>
                                    </div>
                                </div>
                            )}

                            {this.state.activeStep === 1 && (
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                    <div>
                                        {this.state.Steps && this.state.Steps.length === 0 ?
                                            <div className="listcontainer">
                                                <Scrollbars
                                                    style={{ width: '100%', margin: 0 }}
                                                >
                                                    <div className="row container">
                                                        <label className="inputlabel mt-3 mb-3">Batch Id</label>
                                                        <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Select
                                                                    displayEmpty
                                                                    value={this.state.batch_value}
                                                                    onChange={this.BatchId}
                                                                    input={<OutlinedInput />}
                                                                    MenuProps={MenuProps}
                                                                    renderValue={(selected) => {
                                                                        if (selected.length === 0) {
                                                                            return <span>Select Batch Id...</span>;
                                                                        }

                                                                        return selected;
                                                                    }}
                                                                >
                                                                    {this.state.BatchIdList && this.state.BatchIdList.length > 0 && this.state.BatchIdList.map((name) => (
                                                                        <MenuItem key={name} value={name}>{name}</MenuItem>
                                                                    ))}
                                                                </Select>

                                                            </FormControl>


                                                        </div>

                                                        <div className="col-md-12 col-12 col-sm-12 mb-3">
                                                            <p className="inputlabel mt-3 mb-3">Shaping Recipe Name</p>
                                                            <input
                                                                type="text"
                                                                value={fields.name}
                                                                onChange={this.handleChangedata.bind(this, "name")}
                                                                className="campaigntext"
                                                                placeholder="Enter Name"
                                                            />
                                                            <span className="errors mt-2">
                                                                {errors && errors.name}
                                                            </span>
                                                        </div>
                                                        <label className="inputlabel  mb-3">Source File</label>
                                                        <div className="inputlabel  mb-3" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <label htmlFor="chooseInputFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                                    {this.state.input_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files

                                                                    }
                                                                    <input
                                                                        className="Field"
                                                                        id="chooseInputFile"
                                                                        onChange={this.uploadInputFiles}
                                                                        accept=".csv"
                                                                        type="file"
                                                                        multiple
                                                                    />
                                                                </div>
                                                                <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                                    <span className="browsebutton1">
                                                                        <span className="browsebuttontext">Browse</span>
                                                                    </span>
                                                                </div>
                                                            </label>
                                                        </div>

                                                        <label className="inputlabel  mb-3 mt-3">Delivery Template Input File</label>
                                                        <div className="inputlabel  mb-3" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <label htmlFor="chooseDeliveryFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                                    {this.state.delivery_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.delivery_files

                                                                    }
                                                                    <input
                                                                        className="Field"
                                                                        id="chooseDeliveryFile"
                                                                        onChange={this.uploadDeliveryFiles}
                                                                        accept=".csv"
                                                                        type="file"
                                                                        multiple
                                                                    />
                                                                </div>
                                                                <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                                    <span className="browsebutton1">
                                                                        <span className="browsebuttontext">Browse</span>
                                                                    </span>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Scrollbars>
                                            </div> :
                                            <div className="mt-5">
                                                <div className="scroller mt-4">
                                                    <table className="table">
                                                        <thead>
                                                            <tr className="stickyheader">
                                                                <th className="opencampaignheader">Operation</th>
                                                                <th className="opencampaignheader">Source Column</th>
                                                                <th className="opencampaignheader">Target Column</th>
                                                                <th className="opencampaignheader">Value</th>
                                                                <th className="opencampaignheader">Delete</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.Steps && this.state.Steps.length > 0 && this.state.Steps
                                                                .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="opencampaigntext">{item.Action.Operation && item.Action.Operation === 'EXTRACT_PATTERN' ? 'Create_New_Column' : item.Action.Operation}</td>
                                                                        <td className="opencampaigntext">{item.Action.Parameters && item.Action.Parameters.sourceColumn}</td>
                                                                        <td className="opencampaigntext">{item.Action.Parameters && item.Action.Parameters.targetColumn}</td>
                                                                        <td className="opencampaigntext">{item.Action.Parameters && item.Action.Parameters.value}</td>
                                                                        <td className="opencampaigntext">
                                                                            <DeleteIcon
                                                                                onClick={() => this.DeleteIconData(item)}
                                                                                style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                        </td>


                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}

                            <Modal
                                backdrop="static"
                                keyboard={false}
                                // size="lg"
                                // show={lgShow}
                                show={this.state.addmore} onHide={this.onCloseModal}>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12 col-12 col-sm-12 mt-3 mb-3">
                                            <p className="inputlabel mt-3 mb-3">Step</p>
                                            <ReactSelect
                                                isClearable
                                                isSearchable
                                                menuPlacement="auto"
                                                value={optionSelected}
                                                options={step}
                                                onChange={this.handleChangeStep}
                                            />

                                        </div>
                                        {this.state.optionSelected !== null && this.state.optionSelected.value === 'REMOVE_MISSING' ?
                                            <>
                                                <div className="col-md-12 col-12 col-sm-12 mb-3">
                                                    <p className="inputlabel mt-3 mb-3">Source Column</p>
                                                    <CreatableSelect
                                                        isClearable
                                                        isSearchable
                                                        menuPlacement="auto"
                                                        value={this.state.value}
                                                        options={this.state.options}
                                                        onChange={this.handleChange}
                                                        onCreateOption={this.handleCreate}
                                                    />

                                                </div>
                                            </> :

                                            <>
                                                {this.state.optionSelected !== null && this.state.optionSelected.value === 'CATEGORICAL_MAPPING' ?
                                                    <>
                                                        <div className="col-md-12 col-12 col-sm-12  mt-3 mb-3">
                                                            <AddIcon
                                                                onClick={this.addMapValue}
                                                                style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                            />
                                                            <span className="addmapvalue">Add Map Value</span>
                                                        </div>
                                                        <div className="col-md-12 col-12 col-sm-12 mb-3">
                                                            <p className="inputlabel mt-3 mb-3">Source Column</p>
                                                            <CreatableSelect
                                                                isClearable
                                                                isSearchable
                                                                menuPlacement="auto"
                                                                value={this.state.value}
                                                                options={this.state.options}
                                                                onChange={this.handleChange}
                                                                onCreateOption={this.handleCreate}
                                                            />

                                                        </div>
                                                        <div className="col-md-12 col-12 col-sm-12 mt-3">
                                                            <p className="inputlabel mt-3 mb-3">Target Data</p>
                                                            <input
                                                                type="text"
                                                                value={fields.target_data}
                                                                onChange={this.handleChangedata.bind(this, "target_data")}
                                                                className="campaigntext"
                                                                placeholder="Enter Name"
                                                            />

                                                        </div>

                                                    </> :
                                                    <>
                                                        {this.state.optionSelected !== null && this.state.optionSelected.value === 'TODAY' || this.state.optionSelected !== null && this.state.optionSelected.value === 'NOW' ?
                                                            <>
                                                                <div className="col-md-12 col-12 col-sm-12 mt-3 mb-3">
                                                                    <p className="inputlabel mt-3 mb-3">Target Column</p>
                                                                    <CreatableSelect
                                                                        isClearable
                                                                        isSearchable={true}
                                                                        menuPlacement="auto"
                                                                        value={this.state.targetvalue}
                                                                        options={targetdata}
                                                                        onChange={this.handleChangeTarget}
                                                                        onCreateOption={this.handleCreateTarget}
                                                                    />

                                                                </div>
                                                                <div className="col-md-12 col-12 col-sm-12 mt-3 mb-3">
                                                                    <p className="inputlabel  mb-3">TimeZone</p>
                                                                    <ReactSelect
                                                                        isClearable
                                                                        isSearchable
                                                                        menuPlacement="auto"
                                                                        value={timezone}
                                                                        options={timezonedata}
                                                                        onChange={this.handleTimeZone}
                                                                    />

                                                                </div>
                                                            </> :
                                                            <div>
                                                                {this.state.optionSelected !== null && this.state.optionSelected.value === 'DELETE' ?
                                                                    <div>
                                                                        <p className="inputlabel mt-3 mb-3">Source Column</p>
                                                                        {/* <label className="campaignlabel pt-3 mt-3 mb-3 mb-3">Source Column</label> */}
                                                                        <div className="col-md-12 col-sm-12 col-12">
                                                                            <FormControl style={{ width: '100%' }}>
                                                                                <Select
                                                                                    labelId="demo-multiple-checkbox-label"
                                                                                    id="demo-multiple-checkbox"
                                                                                    multiple
                                                                                    displayEmpty
                                                                                    value={this.state.source_delete}
                                                                                    onChange={this._onSelectSourceDelete}
                                                                                    input={<OutlinedInput />}
                                                                                    renderValue={(selected) => {
                                                                                        if (selected.length === 0) {
                                                                                            return <span>Select domain type....</span>;
                                                                                        }

                                                                                        return selected.join(', ');
                                                                                    }}
                                                                                    MenuProps={MenuProps}
                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                >
                                                                                    {source_delete_list.map((name) => (
                                                                                        <MenuItem key={name} value={name}>
                                                                                            <Checkbox checked={this.state.source_delete.indexOf(name) > -1} />
                                                                                            <ListItemText primary={name} />
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>

                                                                            <span className="errors mt-2">
                                                                                {errors && errors.title_id}
                                                                            </span>
                                                                        </div>
                                                                    </div> :
                                                                    <div>
                                                                        {this.state.optionSelected !== null && this.state.optionSelected.value === 'CHANGE_DATA_TYPE' ?
                                                                            <div className="col-md-12 col-12 col-sm-12 mt-3 mb-3">
                                                                                <p className="inputlabel mt-3 mb-3">Source Column</p>
                                                                                <CreatableSelect
                                                                                    isClearable
                                                                                    isSearchable
                                                                                    menuPlacement="auto"
                                                                                    value={this.state.value}
                                                                                    options={this.state.options}
                                                                                    onChange={this.handleChange}
                                                                                    onCreateOption={this.handleCreate}
                                                                                />

                                                                            </div> :
                                                                            <div>

                                                                                {this.state.optionSelected !== null && this.state.optionSelected.value === 'FILL_WITH_CUSTOM' || this.state.optionSelected !== null && this.state.optionSelected.value === 'REPLACE_TEXT' || this.state.optionSelected !== null && this.state.optionSelected.value === "REPLACE_PATTERN" || this.state.optionSelected !== null && this.state.optionSelected.value === "MOVE_AFTER" ?
                                                                                    <div className="col-md-12 col-12 col-sm-12 mb-3">
                                                                                        <p className="inputlabel mt-3 mb-3">Source Column</p>
                                                                                        <CreatableSelect
                                                                                            isClearable
                                                                                            isSearchable
                                                                                            menuPlacement="auto"
                                                                                            value={this.state.valuecombine}
                                                                                            options={this.state.optionscombine}
                                                                                            onChange={this.handleChangeCombine}
                                                                                            onCreateOption={this.handleCreateCombine}
                                                                                        />
                                                                                    </div> :
                                                                                    <div className="col-md-12 col-12 col-sm-12 mb-3">
                                                                                        <p className="inputlabel mt-3 mb-3">Source Column</p>
                                                                                        <CreatableSelect
                                                                                            isClearable
                                                                                            isSearchable
                                                                                            menuPlacement="auto"
                                                                                            value={this.state.value}
                                                                                            options={this.state.options}
                                                                                            onChange={this.handleChange}
                                                                                            onCreateOption={this.handleCreate}
                                                                                        />

                                                                                    </div>
                                                                                }
                                                                                {this.state.optionSelected !== null && this.state.optionSelected.value === 'FILL_WITH_CUSTOM' || this.state.optionSelected !== null && this.state.optionSelected.value === 'REPLACE_TEXT' || this.state.optionSelected !== null && this.state.optionSelected.value === "REPLACE_PATTERN" ?
                                                                                    <div>
                                                                                        <p className="inputlabel mt-3 mb-3">Value</p>
                                                                                        <CreatableSelect
                                                                                            isClearable
                                                                                            isSearchable={true}
                                                                                            menuPlacement="auto"
                                                                                            value={this.state.customvalue}
                                                                                            options={this.state.customdata}
                                                                                            onChange={this.handleCustom}
                                                                                            onCreateOption={this.handleCustomTarget}
                                                                                        />
                                                                                        {this.state.optionSelected !== null && this.state.optionSelected.value === "REPLACE_PATTERN" ?
                                                                                            <>
                                                                                                <p className="inputlabel mt-3 mb-3">Pattern</p>
                                                                                                {/* <p className="inputlabel mt-3 mb-3">Value</p> */}
                                                                                                <CreatableSelect
                                                                                                    isClearable
                                                                                                    isSearchable={true}
                                                                                                    menuPlacement="auto"
                                                                                                    value={this.state.patternvalue}
                                                                                                    options={this.state.patterndata}
                                                                                                    onChange={this.handlePattern}
                                                                                                    onCreateOption={this.handlePatternData}
                                                                                                />
                                                                                            </>
                                                                                            : null}


                                                                                    </div>

                                                                                    :
                                                                                    <>
                                                                                        {this.state.optionSelected !== null && this.state.optionSelected.value === "MOVE_AFTER" ?
                                                                                            <div className="col-md-12 col-12 col-sm-12 mb-3">
                                                                                                <p className="inputlabel mt-3 mb-3">Target Column</p>
                                                                                                <CreatableSelect
                                                                                                    isClearable
                                                                                                    isSearchable
                                                                                                    menuPlacement="auto"
                                                                                                    value={this.state.moveafter}
                                                                                                    options={this.state.optionscombine}
                                                                                                    onChange={this.handleChangeMove}
                                                                                                    onCreateOption={this.handleCreateMoveCombine}
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            <div className="col-md-12 col-12 col-sm-12 mt-3 mb-3">
                                                                                                <p className="inputlabel mt-3 mb-3">Target Column</p>
                                                                                                <CreatableSelect
                                                                                                    isClearable
                                                                                                    isSearchable={true}
                                                                                                    menuPlacement="auto"
                                                                                                    value={this.state.targetvalue}
                                                                                                    options={targetdata}
                                                                                                    onChange={this.handleChangeTarget}
                                                                                                    onCreateOption={this.handleCreateTarget}
                                                                                                />

                                                                                            </div>
                                                                                        }
                                                                                    </>

                                                                                }
                                                                            </div>}
                                                                    </div>}
                                                            </div>}
                                                    </>}
                                            </>}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="col-md-12 col-sm-12  mt-5 col-12 d-md-flex justify-content-end">
                                        <button
                                            onClick={this.onCloseModal}
                                            className="browsebutton  mt-4"
                                        >
                                            <span className="browsebuttontext">Close</span>
                                        </button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>

                <Modal
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.addmapvalue} onHide={this.closeModalMapValue}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12  mt-3 mb-3">
                                <AddIcon
                                    onClick={this.addMapValue}
                                    style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                />
                                <span className="addmapvalue">Add Map Value</span>
                            </div>

                            {this.state.addmapvalue === true ?
                                <div className="col-md-12 col-12 col-sm-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="inputlabel mt-3 mb-3">Existing Value</p>
                                            <input
                                                type="text"
                                                value={this.state.existing_values}
                                                onChange={this.handleChangedata1}
                                                className="workstackmultiplenotes"
                                                placeholder="Enter Value"
                                            />

                                        </div>
                                        <div className="col-md-6">
                                            <p className="inputlabel mt-3 mb-3">New Value</p>
                                            <input
                                                type="text"
                                                value={this.state.new_value}
                                                onChange={this.handleChangedata2}
                                                className="workstackmultiplenotes"
                                                placeholder="Enter Value"
                                            />

                                        </div>
                                    </div>

                                </div>
                                : null}

                        </div>
                        {myObjEntries && myObjEntries.length === 0 ?
                            <div
                                className="opencampaignheader mt-5"
                                style={{ textAlign: 'center' }}
                            >no records found</div> :
                            <div className="mt-5">
                                <div className="row">
                                    <div className="col-md-5 pt-3 pb-3 opencampaignheader102">
                                        Existing Value
                                    </div>
                                    <div className="col-md-5 pt-3 pb-3 opencampaignheader102">
                                        New Value
                                    </div>
                                    <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                        Delete
                                    </div>
                                </div>

                                {myObjEntries && myObjEntries.length > 0 && myObjEntries
                                    .map((item, index) => (

                                        <div
                                            className="row rowdata">
                                            <div className="col-md-5 pt-3 pb-3 opencampaigntext102">
                                                {item[0]}
                                            </div>
                                            <div className="col-md-5 pt-3 pb-3 opencampaigntext102">
                                                {item[1]}
                                                {/* <DeleteIcon
                                                    onClick={() => this.DeleteIconData(item)}
                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} /> */}

                                            </div>
                                            <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                <DeleteIcon
                                                    onClick={() => this.DeleteIconData11(item)}
                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                            </div>
                                        </div>
                                    ))}

                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                onClick={this.closeModalMapValue}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Close</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="md"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.nlpvalue} onHide={this.closeModalMapValue}>
                    <Modal.Body>
                        <div className="row">

                            {this.state.nlpvalue === true ?
                                <div className="col-md-12 col-12 col-sm-12 mt-3">
                                    <label className="inputlabel mt-3 mb-3">Threshold</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.threshold}
                                                onChange={this.Threshold}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Threshold...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {threshold_value && threshold_value.length > 0 && threshold_value.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>

                                    <label className="inputlabel mt-3 mb-3">NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_bucket}
                                                onChange={this.TitleBucket}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP Bucket...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.Fetchtitlebuckets && this.state.Fetchtitlebuckets.length > 0 && this.state.Fetchtitlebuckets.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>

                                        <span className="errors mt-2">
                                            {errors && errors.title_bucket}
                                        </span>
                                    </div>




                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in Source File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_column}
                                                onChange={this.TitleColumn}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.RunconsoleList && this.state.RunconsoleList.length > 0 && this.state.RunconsoleList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>


                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.NLP_Column_in_NLPBucket}
                                                onChange={this.NLPColumninNLPBucket}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.NLPColumnLIST && this.state.NLPColumnLIST.length > 0 && this.state.NLPColumnLIST.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>

                                    <label className="inputlabel mt-3 mb-3"> Select Output column in Delivery Template File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.OutputcolumninDeliveryT}
                                                onChange={this.OutputcolumninDeliveryTemplateFile}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Output column ...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.fetchDelTemColumnNamesList && this.state.fetchDelTemColumnNamesList.length > 0 && this.state.fetchDelTemColumnNamesList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </div>

                                </div>
                                : null}

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                onClick={this.closeModalMapValue}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Close</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>


                <Modal
                    size="md"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.nlpvalue1} onHide={this.closeModalMapValue}>
                    <Modal.Body>
                        <div className="row">


                            {this.state.nlpvalue1 === true ?
                                <div className="col-md-12 col-12 col-sm-12 mt-3">

                                    <label className="inputlabel mt-3 mb-3">Threshold</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.threshold1}
                                                onChange={this.Threshold1}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Threshold...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {threshold_value && threshold_value.length > 0 && threshold_value.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>

                                    <label className="inputlabel mt-3 mb-3">NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_bucket1}
                                                onChange={this.TitleBucket1}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP Bucket...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.Fetchtitlebuckets && this.state.Fetchtitlebuckets.length > 0 && this.state.Fetchtitlebuckets.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>

                                        <span className="errors mt-2">
                                            {errors && errors.title_bucket}
                                        </span>
                                    </div>




                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in Source File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_column1}
                                                onChange={this.TitleColumn1}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.RunconsoleList && this.state.RunconsoleList.length > 0 && this.state.RunconsoleList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>


                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.NLP_Column_in_NLPBucket1}
                                                onChange={this.NLPColumninNLPBucket1}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.NLPColumnLIST && this.state.NLPColumnLIST.length > 0 && this.state.NLPColumnLIST.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>



                                    <label className="inputlabel mt-3 mb-3"> Select Output column in Delivery Template File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.OutputcolumninDeliveryT1}
                                                onChange={this.OutputcolumninDeliveryTemplateFile1}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Output column ...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.fetchDelTemColumnNamesList && this.state.fetchDelTemColumnNamesList.length > 0 && this.state.fetchDelTemColumnNamesList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </div>

                                </div>
                                : null}

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                onClick={this.closeModalMapValue}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Close</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>


                <Modal
                    size="md"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.nlpvalue2} onHide={this.closeModalMapValue}>
                    <Modal.Body>
                        <div className="row">

                            {this.state.nlpvalue2 === true ?
                                <div className="col-md-12 col-12 col-sm-12 mt-3">


                                    <label className="inputlabel mt-3 mb-3">Threshold</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.threshold2}
                                                onChange={this.Threshold2}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Threshold...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {threshold_value && threshold_value.length > 0 && threshold_value.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>

                                    <label className="inputlabel mt-3 mb-3">NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_bucket2}
                                                onChange={this.TitleBucket2}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP Bucket...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.Fetchtitlebuckets && this.state.Fetchtitlebuckets.length > 0 && this.state.Fetchtitlebuckets.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>

                                        <span className="errors mt-2">
                                            {errors && errors.title_bucket}
                                        </span>
                                    </div>




                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in Source File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_column2}
                                                onChange={this.TitleColumn2}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.RunconsoleList && this.state.RunconsoleList.length > 0 && this.state.RunconsoleList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>


                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.NLP_Column_in_NLPBucket2}
                                                onChange={this.NLPColumninNLPBucket2}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.NLPColumnLIST && this.state.NLPColumnLIST.length > 0 && this.state.NLPColumnLIST.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>


                                    <label className="inputlabel mt-3 mb-3"> Select Output column in Delivery Template File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.OutputcolumninDeliveryT2}
                                                onChange={this.OutputcolumninDeliveryTemplateFile2}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Output column ..</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.fetchDelTemColumnNamesList && this.state.fetchDelTemColumnNamesList.length > 0 && this.state.fetchDelTemColumnNamesList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </div>

                                </div>
                                : null}

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                onClick={this.closeModalMapValue}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Close</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>



                <Modal
                    size="md"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.nlpvalue3} onHide={this.closeModalMapValue}>
                    <Modal.Body>
                        <div className="row">


                            {this.state.nlpvalue3 === true ?
                                <div className="col-md-12 col-12 col-sm-12 mt-3">

                                    <label className="inputlabel mt-3 mb-3">Threshold</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.threshold3}
                                                onChange={this.Threshold3}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Threshold...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {threshold_value && threshold_value.length > 0 && threshold_value.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>

                                    <label className="inputlabel mt-3 mb-3">NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_bucket3}
                                                onChange={this.TitleBucket3}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP Bucket...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.Fetchtitlebuckets && this.state.Fetchtitlebuckets.length > 0 && this.state.Fetchtitlebuckets.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>

                                        <span className="errors mt-2">
                                            {errors && errors.title_bucket}
                                        </span>
                                    </div>




                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in Source File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.title_column3}
                                                onChange={this.TitleColumn3}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.RunconsoleList && this.state.RunconsoleList.length > 0 && this.state.RunconsoleList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>


                                    </div>

                                    <label className="inputlabel mt-3 mb-3">Select NLP Column in NLP Bucket</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.NLP_Column_in_NLPBucket3}
                                                onChange={this.NLPColumninNLPBucket3}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select NLP column...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.NLPColumnLIST && this.state.NLPColumnLIST.length > 0 && this.state.NLPColumnLIST.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </div>


                                    <label className="inputlabel mt-3 mb-3"> Select Output column in Delivery Template File</label>
                                    <div className="col-md-12 col-sm-12 col-12 mb-3">
                                        <FormControl style={{ width: '100%' }}>
                                            <Select
                                                displayEmpty
                                                value={this.state.OutputcolumninDeliveryT3}
                                                onChange={this.OutputcolumninDeliveryTemplateFile3}
                                                input={<OutlinedInput />}
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <span>Select Output column ...</span>;
                                                    }

                                                    return selected;
                                                }}
                                            >
                                                {this.state.fetchDelTemColumnNamesList && this.state.fetchDelTemColumnNamesList.length > 0 && this.state.fetchDelTemColumnNamesList.map((name) => (
                                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </div>


                                </div>
                                : null}

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                onClick={this.closeModalMapValue}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Close</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlistdata: userinfo => dispatch(datashaperlistdata(userinfo)),
        datashaperlist: userinfo => dispatch(datashaperlist(userinfo)),
        sourceListData: userinfo => dispatch(sourceListData(userinfo)),
        targetListData: userinfo => dispatch(targetListData(userinfo)),
        deliveryTemplate: userinfo => dispatch(deliveryTemplate(userinfo)),
        deliveryTemplateDynamic: userinfo => dispatch(deliveryTemplateDynamic(userinfo)),
        publishRecipe: userinfo => dispatch(publishRecipe(userinfo)),
        listTitleBucket: userinfo => dispatch(listTitleBucket(userinfo)),
        listOfTitles: userinfo => dispatch(listOfTitles(userinfo)),
        fetchColumnNames: userinfo => dispatch(fetchColumnNames(userinfo)),
        getFileName: userinfo => dispatch(getFileName(userinfo)),
        nlpInsert: userinfo => dispatch(nlpInsert(userinfo)),
        nlpMatch: userinfo => dispatch(nlpMatch(userinfo)),
        dataSetList: userinfo => dispatch(dataSetList(userinfo)),
        createRecipe: userinfo => dispatch(createRecipe(userinfo)),
        startJob: userinfo => dispatch(startJob(userinfo)),
        datashaperInsert: userinfo => dispatch(datashaperInsert(userinfo)),
        datashaperNotificationList: userinfo => dispatch(datashaperNotificationList(userinfo)),
        datashaperSNSNotification: userinfo => dispatch(datashaperSNSNotification(userinfo)),
        createDataSet: userinfo => dispatch(createDataSet(userinfo)),
        jobUpdate: userinfo => dispatch(jobUpdate(userinfo)),
        fetchTitleFileColumnNames: userinfo => dispatch(fetchTitleFileColumnNames(userinfo)),
        fetchDelTemColumnNames: userinfo => dispatch(fetchDelTemColumnNames(userinfo)),
        batchIdList: userinfo => dispatch(batchIdList(userinfo)),
        getNLPOutputFile: userinfo => dispatch(getNLPOutputFile(userinfo)),
        insertRecipieNameData: userinfo => dispatch(insertRecipieNameData(userinfo)),
        getLeadSHaperFilesData: userinfo => dispatch(getLeadSHaperFilesData(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateRecipe);

