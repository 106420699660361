import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { listOfTitles, fetchColumnNames, getFileName,datashaperlist, nlpMatch, nlpInsert } from "../../../actions/Campaignactions";
import Header from "../../../components/Header";
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NLPNav from "../../../components/NLPNav";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import AWS from 'aws-sdk';

//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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'
})

// AWS.config.update({
//     accessKeyId: 'AKIAXTPFNQTGZIW5Y5ZF',
//     secretAccessKey: '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'
// })


//uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARDHV752W5',
//     secretAccessKey: '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'
// })

//dev
// const S3_BUCKET = 'titlematch';

//UAT
const S3_BUCKET = 'leadshaper';


const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900; // in seconds

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

toast.configure()

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const threshold_value = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]


toast.configure()
class RunConsole extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            fields: {},
            errors: {},
            input_files: '',
            offer_codes: '',
            sourcefile: [],
            title_bucket: [],
            title_column: [],
            threshold: [],
            recipes: [],
            recipeList: [],
            datasetList: [],
            testList: [],
            Campaign_Titles: ''

        };
    }


    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    generatePreSignedPutUrl = (fileName, fileType) => {
        myBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.input_files[0]
            }).then((res) => {
                const data = {
                    // bucketName: 'titlematch',
                    bucketName: 'leadshaper',
                    sourceFile: fileName,
                }
                console.log(data, "resssssssssssss")
                this.props.fetchColumnNames(data)

                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }




    uploadInputFiles = (e) => {
        this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type)
        this.setState({ input_files: e.target.files })
    }

   
    handleRecipes = (event) => {
        this.setState({ recipes: event.target.value })
    }

    TitleBucket = (event) => {
        const data = {
            'titlebucket': event.target.value
        }
        this.props.getFileName(data)
        this.setState({ title_bucket: event.target.value })
    }

    TitleColumn = (event) => {
        this.setState({ title_column: event.target.value })
    }

    Threshold = (event) => {
        this.setState({ threshold: event.target.value })
    }



    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }

      
        this.props.datashaperlist();
        this.props.listOfTitles()
    }



    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.fetchColumns &&
            this.props.campaignReducer.fetchColumns !==
            nextProps.campaignReducer.fetchColumns &&
            this.props.campaignReducer.fetchColumnsSuccess === true
        ) {

            const recipeList = [];
            this.props.campaignReducer.fetchColumns && this.props.campaignReducer.fetchColumns.Response && this.props.campaignReducer.fetchColumns.Response.length > 0 && this.props.campaignReducer.fetchColumns.Response.map((item, index) => {
                if (item) {
                    recipeList.push(
                        item
                    );
                }
                return true;
            })
            this.setState({ recipeList: recipeList })
        }

        

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlist !==
            nextProps.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlistSuccess === true
        ) {
            const testList= [];
            this.props.campaignReducer.datashaperlist && this.props.campaignReducer.datashaperlist.length > 0 && this.props.campaignReducer.datashaperlist.map((item, index) => {
                if (item.recipe) {
                    testList.push(
                        item.recipe
                    );
                }
                return true;
            })
            this.setState({ testList: testList})


        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.listTitles &&
            this.props.campaignReducer.listTitles !==
            nextProps.campaignReducer.listTitles &&
            this.props.campaignReducer.listTitlesSuccess === true
        ) {
            const datasetList = [];
            this.props.campaignReducer.listTitles && this.props.campaignReducer.listTitles.length > 0 && this.props.campaignReducer.listTitles.map((item, index) => {
                if (item.titlebucket) {
                    datasetList.push(
                        item.titlebucket
                    );
                }
                return true;
            })
            this.setState({ datasetList: datasetList })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.getFileName &&
            this.props.campaignReducer.getFileName !==
            nextProps.campaignReducer.getFileName &&
            this.props.campaignReducer.getFileNameSuccess === true
        ) {
            this.setState({ Campaign_Titles: this.props.campaignReducer.getFileName && this.props.campaignReducer.getFileName.titlefile })
            // console.log(this.props.campaignReducer.getFileName && this.props.campaignReducer.getFileName.titlefile, "this.props.campaignReducer.getFileName")
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.nlpInsert &&
            this.props.campaignReducer.nlpInsert !==
            nextProps.campaignReducer.nlpInsert &&
            this.props.campaignReducer.nlpInsertSuccess === true
        ) {
            const data = {
                // bucket_name: "titlematch",
                bucket_name: "leadshaper",
                csv_filename1: this.state.input_files[0].name,
                threshold: parseInt(this.state.threshold),
                csv_filename2: this.state.Campaign_Titles,
                outputColumn: "Title Match NLP",
                confidenceColumn: this.state.title_column,
                matchId: this.props.campaignReducer.nlpInsert && this.props.campaignReducer.nlpInsert.nlp_id,
                recipe_name: this.state.recipes,
            }
            console.log(data, "data")
            this.props.nlpMatch(data)
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.nlpMatch &&
            this.props.campaignReducer.nlpMatch !==
            nextProps.campaignReducer.nlpMatch &&
            this.props.campaignReducer.nlpMatchSuccess === true
        ) {
            // this.setState({ Campaign_Titles: this.props.campaignReducer.getFileName && this.props.campaignReducer.getFileName.titlefile })
            console.log(this.props.campaignReducer.nlpMatch, "this.props.campaignReducer.nlpMatch")
        }
    }
    validateNetline() {
        const errors = {};
        const { fields } = this.state;
        const strings = /^[A-Za-z0-9 ]+$/;

        let formIsValid = true;

        if (!fields.batch_name) {
            errors.batch_name = 'Please enter batch name ';
            formIsValid = false;
        }

        if (fields.batch_name && fields.batch_name.length < 3) {
            errors.batch_name = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.batch_name && fields.batch_name.length > 500) {
            errors.batch_name = 'batch name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.batch_name && !strings.test(fields.batch_name)) {
            errors.batch_name = 'Please enter characters only';
            formIsValid = false;
        }
        if (this.state.offer_codes === '') {
            errors.offer_codes = 'Please select offer file ';
            formIsValid = false;
        }
        if (this.state.input_files.length === 0) {
            errors.input_files = 'Please select input file ';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;


    }

    //customersetup insert
    NLPInsert = () => {
        const userinfo = {
            csv_filename1: this.state.input_files[0].name,
            csv_filename2: this.state.Campaign_Titles,
            delivery_template_file: "",
            recipe_name: "",

        };
        console.log(userinfo, "userinfo")
        this.props.nlpInsert(userinfo)
        // }
    };
    render() {
        const { redirectpath, fields, errors } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NLPNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Netline</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <div className="row container">
                                            {/* <div className="col-md-12 col-12 col-sm-12">
                                                <p className="inputlabel mt-3 mb-3">Batch Name</p>
                                                <input
                                                    type="text"
                                                    value={fields.batch_name}
                                                    onChange={this.handleChange.bind(this, "batch_name")}
                                                    className="campaigntext"
                                                    placeholder="Batch Name"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.batch_name}
                                                </span>
                                            </div> */}

                                            <label className="inputlabel  mt-3 mb-3">Input File</label>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <label htmlFor="chooseInputFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className="col-md-12 col-sm-12 col-12 DatashaperinputfileContainer">
                                                        {this.state.input_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files[0].name

                                                        }
                                                        <input
                                                            className="Field"
                                                            id="chooseInputFile"
                                                            onChange={this.uploadInputFiles}
                                                            accept=".csv"
                                                            type="file"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                                        <span className="browsebutton">
                                                            <span className="browsebuttontext">Browse</span>
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <span className="errors mt-2">
                                                {errors && errors.input_files}
                                            </span>

                                            <label className="inputlabel mt-3 mb-3">Campaign Titles</label>
                                            <span className="inputfileplaceholder mb-2">{this.state.Campaign_Titles}</span>

                                            <label className="inputlabel mt-3 mb-3">Title Bucket</label>
                                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        displayEmpty
                                                        value={this.state.title_bucket}
                                                        onChange={this.TitleBucket}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select Title bucket...</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {this.state.datasetList && this.state.datasetList.length > 0 && this.state.datasetList.map((name) => (
                                                            <MenuItem key={name} value={name}>{name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>

                                                <span className="errors mt-2">
                                                    {errors && errors.title_bucket}
                                                </span>
                                            </div>




                                            <label className="inputlabel mt-3 mb-3">Title Column in Input File</label>
                                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        displayEmpty
                                                        value={this.state.title_column}
                                                        onChange={this.TitleColumn}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select Title Column...</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {this.state.recipeList && this.state.recipeList.length > 0 && this.state.recipeList.map((name) => (
                                                            <MenuItem key={name} value={name}>{name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>

                                                {/* <span className="errors mt-2">
                                                    {errors && errors.recipes}
                                                </span> */}
                                            </div>



                                            <label className="inputlabel mt-3 mb-3">Threshold</label>
                                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        displayEmpty
                                                        value={this.state.threshold}
                                                        onChange={this.Threshold}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select Threshold...</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {threshold_value && threshold_value.length > 0 && threshold_value.map((name) => (
                                                            <MenuItem key={name} value={name}>{name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>

                                                {/* <span className="errors mt-2">
                                                    {errors && errors.recipes}
                                                </span> */}
                                            </div>


                                            <label className="inputlabel mt-3 mb-3">Recipe Selection</label>
                                            <div className="col-md-12 col-sm-12 col-12 mb-3">
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        displayEmpty
                                                        value={this.state.recipes}
                                                        onChange={this.handleRecipes}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select recipe...</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {this.state.testList && this.state.testList.length > 0 && this.state.testList.map((name) => (
                                                            <MenuItem key={name} value={name}>{name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>

                                                <span className="errors mt-2">
                                                    {errors && errors.recipes}
                                                </span>
                                            </div>

                                        

                                        </div>

                                    </Scrollbars>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.NLPInsert}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Submit</span>
                                </button>
                            </div>
                            {/* {this.state.loading === true ?
                                <Loader type="spinner-circle" bgColor={"rgba(29, 65, 122, 1)"} color={'rgba(29, 65, 122, 1)'} size={100} /> :
                                null} */}
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlist: userinfo => dispatch(datashaperlist(userinfo)),
        listOfTitles: userinfo => dispatch(listOfTitles(userinfo)),
        fetchColumnNames: userinfo => dispatch(fetchColumnNames(userinfo)),
        getFileName: userinfo => dispatch(getFileName(userinfo)),
        nlpInsert: userinfo => dispatch(nlpInsert(userinfo)),
        nlpMatch: userinfo => dispatch(nlpMatch(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RunConsole);


