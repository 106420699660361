import React, { Component } from "react";
import { Auth } from "aws-amplify";
import '../styles/LoginStyles.css';
import logo from '../assets/images/logo.png';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationcode: '',
            email: '',
            newpassword: '',
            showPassword: false,
            errors: {}

        }
        this.handleCode = this.handleCode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNewPassword = this.handleNewPassword.bind(this);
    }

    handleCode(event) {
        this.setState({ verificationcode: event.target.value });
    }

    handleNewPassword(event) {
        this.setState({ newpassword: event.target.value });
    }

    handleChange(event) {
        this.setState({ email: event.target.value });
    }
    //For password visivility
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    validateChangePassword() {
        const errors = {};
        let formIsValid = true;
        if (!this.state.email) {
            errors.email = 'Please enter email';
            formIsValid = false;
        }
        if (!this.state.verificationcode) {
            errors.verificationcode = 'Please enter verification code';
            formIsValid = false;
        }
        if (this.state.verificationcode && this.state.verificationcode.length < 6) {
            errors.verificationcode = 'Verification code must be 6 digit';
            formIsValid = false;
        }
        if (!this.state.newpassword) {
            errors.newpassword = 'Please enter new password';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;
    }

    handleSubmit = async () => {
        if (this.validateChangePassword()) {
            try {
                await Auth.forgotPasswordSubmit(
                    this.state.email,
                    this.state.verificationcode,
                    this.state.newpassword
                );
                this.props.history.push("/");
            } catch (error) {
                const errors = {};
                errors.newpassword = 'Verification code or email is incorrect';
                this.setState({ errors })
                // console.log('error in signing', error);
            }
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}
                        className="col-md-6 leftpanel">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="lefttext">Hello, Welcome to Arc Man!</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <p className="audiencetext">Audience Response Curation Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 rightpanel">
                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <img
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">

                            </div>
                            <div className="col-md-6">
                                <label className="emailtext mt-5 mb-3">Verification Code</label>
                                <input
                                    value={this.state.verificationcode}
                                    onChange={this.handleCode}
                                    type="password"
                                    className="emailinput"
                                    placeholder="Enter verification code"
                                />
                                <span className="errors mt-2">
                                    {errors && errors.verificationcode}
                                </span>

                                <label className="emailtext mt-4 mb-3">E-mail</label>
                                <input
                                    type="text"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    className="emailinput"
                                    placeholder="Example@gmail.com"
                                />
                                <span className="errors mt-2">
                                    {errors && errors.email}
                                </span>

                                <label className="emailtext mt-5 mb-3">New Password</label>
                                <div className="input-icons">
                                    <input
                                        type={this.state.showPassword ? "text" : "password"}
                                        value={this.state.newpassword}
                                        onChange={this.handleNewPassword}
                                        className="emailinput"
                                        placeholder="Enter Password"
                                    />
                                    <i
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        className="toggleicon"> {this.state.showPassword ? <Visibility /> : <VisibilityOff />}</i>
                                </div>
                                {/* <input
                                    value={this.state.newpassword}
                                    onChange={this.handleNewPassword}
                                    type="password"
                                    className="emailinput"
                                    placeholder="Confirm Password"
                                /> */}
                                <span className="errors mt-2">
                                    {errors && errors.newpassword}
                                </span>


                                <button
                                    onClick={this.handleSubmit}
                                    type="button"
                                    className="loginbutton mt-3"
                                >
                                    <span className="logintext">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ChangePassword;

