// local
// export const SERVERURL = 'http://localhost:5001/';

//dev
export const SERVERURL = 'https://np09ecy5e7.execute-api.us-east-1.amazonaws.com/dev-api/'

//demo
// export const SERVERURL = 'https://0bv4bwb6z0.execute-api.us-east-1.amazonaws.com/demo-api/'

//datashaper uat
// export const DATASHAPER = 'http://leadshaper-1924319397.us-east-1.elb.amazonaws.com/datashaper/'

// datashaper demo
// export const DATASHAPER = 'http://leadshaper-1924319397.us-east-1.elb.amazonaws.com:5001/leadshaper/'


//datashaper dev
export const DATASHAPER = 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/'

//uat
// export const SERVERURL = 'https://epe0mctg8i.execute-api.us-east-1.amazonaws.com/uat/'
// export const SERVERURL = 'https://vyev13ii22.execute-api.us-east-1.amazonaws.com/dev-api/'



export const SERVERURL1 = 'http://localhost:8080/';


//dev cognito
// {
//     "cognito": {
//       "REGION": "us-east-1",
//       "USER_POOL_ID": "us-east-1_HtJoni20s",
//       "APP_CLIENT_ID": "7bcf09qomokuitlr3akjvbr9u8"
//     }
//     }

//uat
// {
//     "cognito": {
//       "REGION": "us-east-1",
//       "USER_POOL_ID": "us-east-1_mM639VqI8",
//       "APP_CLIENT_ID": "4jnce4tuoag4ohffb2pndj25pa"
//     }
// }


