import React, { Component } from "react";
import "../../styles/Styles.css";
import moment from "moment";
import { Auth } from 'aws-amplify';
import Header from "../../components/Header";
import NavigationIcon from '@material-ui/icons/Navigation';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { campaignList, linkedindata, campaignDelete } from '../../actions/Campaignactions';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import Radio from '@mui/material/Radio';
import LeftNavigation from "../../components/LeftNavigation";
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EditIcon from '@mui/icons-material/Edit';



const statusdata = [
    { label: 'Active' },
    { label: 'Created' },
    { label: 'Pending' }
];


let sub_id = ''
class CampaignsList extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            noOfPages: [],
            itemsPerPage: 10,
            totalitems: '',
            page: 1,
            campaignList: [],
            selectedValue: '',
            fields: {},
            statusinfo: [],
            user_list: [],
            functioncodes: null
        };
    }

    //Signout
    signOut = async () => {
        try {
            await localStorage.removeItem('userdetails');
            await localStorage.removeItem('linkedinauth')
            await Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            this.setState({ redirectpath: '/' })
        }
        catch (error) {
            console.log('error sign out:', error)
        }
    };

    //redirect
    goNext = (params) => {
        if (params === 'tool') {
            this.setState({ redirectpath: '/campaignreview' })
        }
        if (params === 'setup') {
            this.setState({ redirectpath: '/campaignsetup' })
        }

    };

    _onSelectFunctions = (functioncodes) => {
        this.setState({ functioncodes });
    };


    //to handle radio button
    handleChangedata = (item) => {
        console.log(item, "itemmmmmmmm")
        let fields = this.state.fields
        fields.campaign_id = item.campaign_id
        this.setState({ fields, redirectpath: '/campaignreview' });
    };

    handleChange = (event, value) => {
        this.setState({ page: value })
    };



    submit = (item) => {
        this.setState({ redirectpath: `/campaignsetup/${item}` })
    };

    delete = (item) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: "Are you sure you want to delete selected info? Press 'Yes' to continue.",
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.campaignDelete(item)
                },
                {
                    label: 'Cancel',
                    onClick: () => ''
                }
            ]
        });
    };


    //status
    _onSelectStatus = (event) => {
        this.setState({ statusinfo: event.target.value });
    };


    async componentDidMount() {
        const token = {
            AccessToken: this.props && this.props.location && this.props.location.search.split('&')[0] && this.props.location.search.split('&')[0].slice(14)
        }
        this.props.linkedindata(token)

        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            sub_id = userdetails && userdetails.attributes.sub;
            const userinfo = {
                sub: userdetails && userdetails.attributes.sub,
                status: 'Pending'
            }
            this.props.campaignDetails(userinfo);
        }

        let usersinfo = await localStorage.getItem("usersinfo");
        if (usersinfo) {
            usersinfo = JSON.parse(usersinfo);
            const userinfo1 = {
                sub: usersinfo && usersinfo.length > 0 && usersinfo[0].Value,
                status: 'Pending'
            }
            this.props.campaignDetails(userinfo1);

        }
        // this.setupBeforeUnloadListener();
    }

    doSomethingBeforeUnload = () => {
        // Do something
    }

    // Setup the `beforeunload` event listener
    // setupBeforeUnloadListener = () => {
    //     window.addEventListener("beforeunload", (ev) => {
    //         ev.preventDefault();
    //         return this.doSomethingBeforeUnload();
    //     });
    // };

    clickupArrow = (e) => {
        const userinfo = {
            sub: sub_id,
            status: 'Pending',
            name: "DESC"
        }
        // console.log(userinfo, "hi inside")
        this.props.campaignDetails(userinfo);
    }



    clickDownArrow = () => {
        const userinfo = {
            sub: sub_id,
            status: 'Pending',
            name: "ASC"
        }
        this.props.campaignDetails(userinfo);
    }


    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.campaigndelete &&
            this.props.campaignReducer.campaigndelete !==
            nextProps.campaignReducer.campaigndelete &&
            this.props.campaignReducer.campaigndeleteSuccess === true
        ) {
            const userinfo = {
                sub: sub_id,
                status: 'Pending'
            }
            this.props.campaignDetails(userinfo);
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.campaignList &&
            this.props.campaignReducer.campaignList !==
            nextProps.campaignReducer.campaignList &&
            this.props.campaignReducer.campaignListSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({
                campaignList: this.props.campaignReducer.campaignList,
                noOfPages: Math.ceil(this.props.campaignReducer.campaignList.length / itemsPerPage),
                totalitems: this.props.campaignReducer.campaignList.length
            })
            const userList = [];
            statusdata && statusdata.map((item) => {
                if (item.label) {
                    userList.push({
                        value: item.label,
                        label: item.label
                    });
                }
                return true;
            })
            this.setState({ user_list: userList })

        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.linkedindata &&
            this.props.campaignReducer.linkedindata !==
            nextProps.campaignReducer.linkedindata &&
            this.props.campaignReducer.linkedindataSuccess === true
        ) {
            // console.log(this.props.campaignReducer.linkedindata, "linkedindata")
        }
    }

    render() {
        let tokeninfo = this.props && this.props.location && this.props.location.search.split('&')[0] && this.props.location.search.split('&')
        const { redirectpath, campaignList, fields, noOfPages, page, itemsPerPage, totalitems } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: fields.campaign_id, status: this.state.functioncodes && this.state.functioncodes.value } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <LeftNavigation
                            data={{
                                accesstoken: tokeninfo && tokeninfo[0].slice(14),
                                idtoken: tokeninfo && tokeninfo[1].slice(8),
                                refreshtoken: tokeninfo && tokeninfo[2].slice(17)
                            }}
                        />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Pending Campaigns</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChange}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={() => this.goNext("setup")}
                                    className="createbuttonnext mt-4"
                                >
                                    <span className="createtext">Activate Campaign</span>
                                </button>
                            </div>
                            {/* 
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={this.doGetUserMedia}
                                    className="createbuttonnext mt-4"
                                >
                                    <span className="createtext">Click to call</span>
                                </button>
                            </div> */}

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="mt-5">
                                    <div className="scroller mt-4">
                                        {campaignList && campaignList.length === 0 ?
                                            <div
                                                className="opencampaignheader mt-5"
                                                style={{ textAlign: 'center' }}
                                            >no records found</div> :
                                            <table className="table">
                                                <thead>
                                                    <tr className="stickyheader">
                                                        <th className="opencampaignheader">
                                                            <div>
                                                                Name
                                                            </div>

                                                            <div className="position-absolute">
                                                                <div className="up-arrow"
                                                                    onClick={(e) => this.clickupArrow(e)}
                                                                >

                                                                </div>
                                                                <div className="down-arrow"
                                                                    onClick={(e) => this.clickDownArrow(e)}
                                                                >
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="opencampaignheader">Client</th>
                                                        <th className="opencampaignheader">Created Date</th>
                                                        <th className="opencampaignheader"> Update</th>
                                                        <th className="opencampaignheader"> Delete</th>
                                                        <th className="opencampaignheader">Activate campaign</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {campaignList && campaignList.length > 0 && campaignList
                                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="opencampaigntext">   {item.campaign_name}</td>
                                                                <td className="opencampaigntext"> {item.customer}</td>

                                                                <td className="opencampaigntext">
                                                                    {moment(item.created_at).format('MM-DD-YYYY')}
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <EditIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.submit(item.campaign_id)}
                                                                    />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <DeleteIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.delete(item.campaign_id)}
                                                                    />

                                                                </td>


                                                                <td className="opencampaigntext">
                                                                    <NavigationIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.handleChangedata(item)}
                                                                    />

                                                                </td>


                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <button
                                    onClick={() => this.goNext("setup")}
                                    className="createbuttonnext mt-5 mb-5"
                                >
                                    <span className="createtext">Activate Campaign</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        campaignDetails: userinfo => dispatch(campaignList(userinfo)),
        linkedindata: userinfo => dispatch(linkedindata(userinfo)),
        campaignDelete: userinfo => dispatch(campaignDelete(userinfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignsList);

