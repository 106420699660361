import React, { Component } from 'react';
import line from "../assets/images/line.svg";
import logo from "../assets/images/logo.png";
import { Redirect, Link } from 'react-router-dom';
import "../styles/Styles.css";
import { connect } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@material-ui/icons/Dashboard';
import database from "../assets/images/database.png";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TransformIcon from '@material-ui/icons/Transform';


import { Auth } from 'aws-amplify';
import axios from 'axios';


class LeftNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectpath: ''
        }
    }

    handleLogOut = async () => {
        const data = {
            token: this.props.data
        }
        axios({
            method: "POST",
            url: `http://localhost:8080/auth/signout`,
            crossDomain: true,
            data
        })
            .then(res => {
                console.log("hi logout linkedin")
            })
    
        try {
            await Auth.signOut({ global: true });
            await localStorage.removeItem('userdetails');
            await localStorage.removeItem('usersinfo')
            this.setState({ redirectpath: '/' })
        } catch (err) {
            console.log(err, 'error signing out...');
        }
    };


    homePage = () => {
        this.setState({ redirectpath: '/campaignslist' })
    }


    NLPTool = () => {
        window.open("/nlpdashboard")
    }

    render() {
        const { redirectpath } = this.state;
        return (
            <div>
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div
                    className="row"
                    style={{ padding: "27px 0" }}
                >
                    <img
                        onClick={this.homePage}
                        src={logo}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />
                    <img
                        src={line}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />

                </div>
                <ul className="nav row">
                    <li
                        onClick={this.NLPTool}
                    >
                        <span style={{ marginRight: "15px", display: "inline-flex" }}>
                            <TransformIcon
                                style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                            />
                        </span>
                        <span
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "30px",
                                letterSpacing: "2px",
                                textDecoration: "none"
                            }}
                        >
                            NLP Tool
                        </span>
                    </li>


                    {/* <li
                        id={window.location.pathname === '/createdataset' ? 'selectednav' : ''}
                    >
                        <Link
                            to="/createdataset"
                            style={{
                                color: "#ffffff",
                                fontSize: "16px",
                                lineHeight: "24px",
                                display: "inline-flex",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <TransformIcon
                                    style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>Transform</span>
                        </Link>
                    </li>
                    <li
                        id={window.location.pathname === '/createrecipe' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/createrecipe"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <FileCopyIcon
                                    style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>Delivery Template</span>
                        </Link>
                    </li> */}

                    {/* <li
                        id={window.location.pathname === '/datashaperstep1' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/datashaperstep1"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <DashboardIcon
                                    style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>Dashboard</span>
                        </Link>
                    </li> */}



                    <li
                        onClick={this.handleLogOut}
                    >
                        <span style={{ marginRight: "15px", display: "inline-flex" }}>
                            <LogoutIcon
                                style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                            />
                        </span>
                        <span
                            style={{ alignSelf: 'center' }}>  Log out</span>
                    </li>
                </ul>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        // linkedinlogout: userinfo => dispatch(linkedinlogout(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeftNavigation);


