import React, { Component } from "react";
import "../../styles/Styles.css";
import "../../styles/CreateCampaignStyles.css"
import { toast } from 'react-toastify';
import upload from "../../assets/images/upload.svg";
import 'react-toastify/dist/ReactToastify.css';
import { userDetails } from '../../actions/ManagerActions';
import { campaignSetUpInsert, customerPrefillData, countryList, stateList, usersInfo, toolSwap, campaignUpdate } from "../../actions/Campaignactions";
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Scrollbars } from 'react-custom-scrollbars';
import Header from "../../components/Header";
import { Redirect } from 'react-router-dom';
import LeftNavigation from "../../components/LeftNavigation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';
import 'react-dropdown/style.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AddBoxIcon from '@mui/icons-material/AddBox';
import AWS from 'aws-sdk';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Modal } from "react-bootstrap";
import AddIcon from '@material-ui/icons/Add';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CreatableSelect from "react-select/creatable";

const steps = ['General', 'Lead Order Details', 'Audience', 'Campaign Artifacts']




const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

// let sub = ''


//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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'
})

//uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARKRGNQL5O',
//     secretAccessKey: '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'
// })

//dev
// const S3_BUCKET = 'solpubtest';

//UAT
const S3_BUCKET = 'solpubtest';


const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900; // in seconds

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})



let linkedincred = ''
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'C-Level',
    'Director',
    'Executive Vice Presiden',
    'Individual Contributor',
    'Manager',
    'Senior Director',
    'Senior Manager'
];

toast.configure()
const leadtype = [
    { value: 'HQL', label: 'HQL' },
    { value: 'MQL', label: 'MQL' },
    { value: 'SQL', label: 'SQL' },
    { value: 'Other', label: 'Other...' }

];

const deliveryformat = [
    { value: 'CSV', label: 'CSV' },
    { value: 'Excel', label: 'Excel' },
    { value: 'Form Post', label: 'Form Post' },
    { value: 'Marketo', label: 'Marketo' }
];

// const leads_per_company = [
//     { value: '1-20', label: '1-20' },
//     { value: '20-50', label: '20-50' },
//     { value: '50-100', label: '50-100' },
//     { value: '100-200', label: '100-200' }
// ];


const options = [
    '.edu',
    '.gov'
];

const department1 = [
    'Marketing',
    'Finance',
    'Accounting',
    'Human Resources',
    'Administration, Operations',
    'Information Technology',
    'Engineering',
    'Operations'

];

const managmentlevels = [
    'Executive',
    'Senior Management',
    'Mid-level Management',
    'C-Level',
    'VP',
    'Director',
    'Manager',
    'Staff'

];

const suppressions3 = [];
const abmdata = [];
const photo_list = [];


class Campaignsetup extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            pictures: [],
            listabm: '',
            leadtemplates: '',
            matchfiles: '',
            tm_scripts: '',
            vm_files: '',
            response_creatives: '',
            Lead_type: [],
            dateList: null,
            personName: [],
            linkedindata: [],
            startDate: null,
            deadline_date: null,
            endDate: null,
            matchfiless3: '',
            delivery_dates: null,
            showSection: true,
            selectedOption: [],
            customer_list: [],
            delivery_format: [],
            customvalue: '',
            no_of_leads_per_company: [],
            customdata: [
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '6' },
                { value: '7', label: '7' },
                { value: '8', label: '8' },
                { value: '9', label: '9' },
                { value: '10', label: '10' },
                { value: '11', label: '11' },
                { value: '12', label: '12' },
                { value: '13', label: '13' },
                { value: '14', label: '14' },
                { value: '15', label: '15' },
                { value: '16', label: '16' },
                { value: '17', label: '17' },
                { value: '18', label: '18' },
                { value: '19', label: '19' },
                { value: '20', label: '20' },
                { value: 'Unlimited', label: 'Unlimited' },
            ],
            title_id: [],
            managment_levels: [],
            department: [],
            suppression_files: [],
            domain_types: [],
            country_list: [],
            state_list: [],
            countries: [],
            fields: {},
            fields1: {},
            errors: {},
            MapDataList1: {},
            MapDataList2: {},
            MapDataList: {},
            MapDataList3: {},
            MapDataList4: {},
            MapDataList5: {},
            MapDataList6: {},
            MapDataList7: {},
            MapDataList8: {},
            MapDataList9: {},
            country: '',
            value_create: '',
            price_lead: '',
            revenue: '',
            delivery_contacts: '',
            html_subject_lines: '',
            accepted_title_list: '',
            creative_subject_lines: '',
            creative_subject_lines2: '',
            creative_subject_lines3: '',
            creative_subject_lines4: '',
            creative_subject_lines5: '',
            creative_subject_lines_data: [],
            creative_subject_lines_arr: [],
            sic_codes: '',
            naics_codes: '',
            addmapvalue: false,
            addmapvalue1: false,
            addmapvalue2: false,
            addmapvalue3: false,
            addmapvalue4: false,
            addmapvalue5: false,
            activeStep: 0,
            counter: 0,
            disable: false
        };
    }

    redirecToNext = (event) => {
        this.setState({ redirectpath: '/campaignslist' })
    };

    //to customer list
    handleCustomerList = (event) => {
        const {
            target: { value },
        } = event;
        this.setState(
            { selectedOption: value }
        );
    };


    _onSelectLeadType = (event) => {
        this.setState({ Lead_type: event.target.value });
    };

    //delivery format
    _onSelectDeliveryformat = (event) => {
        this.setState({ delivery_format: event.target.value });
    }


    //delivery format
    _onSelectLeadspercompany = (event) => {
        this.setState({ no_of_leads_per_company: event.target.value });
    }


    handleCustom = (inputValue) => {
        this.setState({ customvalue: inputValue })
    }


    handleCustomTarget = (inputValue) => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ customdata: [...this.state.customdata, newValue], customvalue: newValue });
    }


    _onSelectDepartments = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ department: typeof value === 'string' ? value.split(',') : value })
    }

    _onSelectManagmentlevels = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ managment_levels: typeof value === 'string' ? value.split(',') : value })
    }
    _onSelectCountry = (event) => {
        this.props.stateList(event.target.value)
        this.setState({ countries: event.target.value })
    }

    _onSelectDomainTypes = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ domain_types: typeof value === 'string' ? value.split(',') : value })
    }

    handleChangeTitle = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ personName: typeof value === 'string' ? value.split(',') : value })
    }
    _onSelectDates = (dateList) => {
        this.setState({ dateList }
        );
    };

    _onSelectDate = (date) => {
        this.setState({ startDate: date }
        );
    };

    _onSelectDeadlineDate = (date) => {
        this.setState({ deadline_date: date })
    }
    _onSelectEndDate = (date) => {
        this.setState({ endDate: date }
        );
    };

    _onSelectDeliveryDates = (date) => {
        this.setState({ delivery_dates: date }
        );
    }

    // <===== Handle price lead =====>
    handleRevenue(value) {
        this.setState({ revenue: value })
    }

    handlePriceLead(value) {
        this.setState({ price_lead: value })
    }

    //to handle input field
    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }


    handleChangedata1 = (e) => {
        const errors = {};
        const regExp = RegExp(
            /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
        )


        if (regExp.test(e.target.value)) {
            console.log("hi inside email only")
            errors.delivery_contacts = ""
            this.setState({ delivery_contacts: e.target.value, errors });

        }
        else {
            errors.delivery_contacts = 'Please provide email only';
            this.setState({ delivery_contacts: e.target.value, errors });

        }

    }


    handleChangedata2 = (e) => {
        const errors = {};
        if (e.target.value !== '') {
            errors.delivery_contacts = ""
            this.setState({ html_subject_lines: e.target.value, errors });

        }
        else {
            errors.html_subject_lines = 'Please enter html subject lines how many do you want';
            this.setState({ html_subject_lines: e.target.value, errors });

        }

    }

    handleChangedata3 = (e) => {
        const errors = {};
        if (e.target.value === '') {
            errors.accepted_title_list = "Please enter accepted title how many do you want"
            this.setState({ accepted_title_list: e.target.value, errors });

        }
        else if (e.target.value.length < 4) {
            errors.accepted_title_list = "Please provide at least 4 characters"
            this.setState({ accepted_title_list: e.target.value, errors });

        }
        else {
            errors.accepted_title_list = '';
            this.setState({ accepted_title_list: e.target.value, errors });

        }
    }

    handleChangedata4 = (e) => {
        const errors = {};
        const regExp = RegExp(
            /^[-+]?[0-9]+$/
        )

        if (!regExp.test(e.target.value)) {
            errors.sic_codes = "Please provide number only"
            this.setState({ sic_codes: e.target.value, errors });

        }
        else if (e.target.value.length < 1 || e.target.value.length > 4) {
            errors.sic_codes = 'sic code should be minimum 1 and maximum 4';
            this.setState({ sic_codes: e.target.value, errors });
        }

        else {
            errors.sic_codes = '';
            this.setState({ sic_codes: e.target.value, errors });

        }
    }

    handleChangedata5 = (e) => {
        const errors = {};
        const regExp = RegExp(
            /^[-+]?[0-9]+$/
        )

        if (!regExp.test(e.target.value)) {
            errors.naics_codes = "Please provide number only"
            this.setState({ naics_codes: e.target.value, errors });

        }
        else if (e.target.value.length < 1 || e.target.value.length > 6) {
            errors.naics_codes = 'naics codes should be minimum 1 and maximum 6';
            this.setState({ naics_codes: e.target.value, errors });
        }

        else {
            errors.naics_codes = '';
            this.setState({ naics_codes: e.target.value, errors });

        }

    }


    handleChangedata6 = (e) => {
        this.setState({ creative_subject_lines: e.target.value });
    }

    handleChangedata7 = (e) => {
        this.setState({ creative_subject_lines2: e.target.value });
    }

    handleChangedata8 = (e) => {
        this.setState({ creative_subject_lines3: e.target.value });
    }


    handleChangedata9 = (e) => {
        this.setState({ creative_subject_lines4: e.target.value });
    }
    handleChangedata10 = (e) => {
        this.setState({ creative_subject_lines5: e.target.value });
    }


    generatePreSignedPutUrl = (fileName, fileType, file) => {
        myBucket.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: file
            }).then((res) => {
                console.log(res, "resssssssssssss")
                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }


    uploadFileHTML = (e) => {
        const errors = {};
        for (let i = 0; i < e.target.files.length; i++) {
            let counter = this.state.counter
            counter = counter + 1
            let file = e.target.files[i]
            if (file !== undefined && file.type.indexOf("pdf") !== -1) {
                this.generatePreSignedPutUrl(e.target.files[i].name, e.target.files[i].type, e.target.files[i])
                photo_list.push({ photo_list: e.target.files[i].name })
                errors.pictures = ""
                this.setState({ pictures: [...this.state.pictures, ...e.target.files], errors, counter: counter, addmapvalue5: true })
            }
            else if (file !== undefined && file.type.indexOf("gif") !== -1) {
                this.generatePreSignedPutUrl(e.target.files[i].name, e.target.files[i].type, e.target.files[i])
                photo_list.push({ photo_list: e.target.files[i].name })
                errors.pictures = ""
                this.setState({ pictures: [...this.state.pictures, ...e.target.files], errors, counter: counter, addmapvalue5: true })
            }
            else if (file !== undefined && file.type.indexOf("msword") !== -1) {
                this.generatePreSignedPutUrl(e.target.files[i].name, e.target.files[i].type, e.target.files[i])
                photo_list.push({ photo_list: e.target.files[i].name })
                errors.pictures = ""
                this.setState({ pictures: [...this.state.pictures, ...e.target.files], errors, counter: counter, addmapvalue5: true })
            }
            else if (file !== undefined && file.type.indexOf("jpg") !== -1) {
                this.generatePreSignedPutUrl(e.target.files[i].name, e.target.files[i].type, e.target.files[i])
                photo_list.push({ photo_list: e.target.files[i].name })
                errors.pictures = ""
                this.setState({ pictures: [...this.state.pictures, ...e.target.files], errors, counter: counter, addmapvalue5: true })
            }
            else if (file !== undefined && file.type.indexOf("png") !== -1) {
                this.generatePreSignedPutUrl(e.target.files[i].name, e.target.files[i].type, e.target.files[i])
                photo_list.push({ photo_list: e.target.files[i].name })
                errors.pictures = ""
                this.setState({ pictures: [...this.state.pictures, ...e.target.files], errors, counter: counter, addmapvalue5: true })
            }
            else {
                errors.pictures = "Please Select (.pdf,.doc,.gif, .jpg, .png) files"
                this.setState({ errors })
            }
        }
    }

    uploadListABM = (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.listabm = ""
            this.setState({ listabm: e.target.files[0].name })

        } else {
            errors.listabm = "Please select csv only"
            this.setState({ errors })
        }

    }



    uploadLeadFiles = (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.listabm = ""
            this.setState({ leadtemplates: e.target.files[0].name })

        } else {
            errors.listabm = "Please select csv only"
            this.setState({ errors })
        }

    }



    uploadFileSuppression = (e) => {
        const errors = {};
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i]
            if (file !== undefined && file.type.indexOf("csv") !== -1) {
                this.generatePreSignedPutUrl(e.target.files[i].name, e.target.files[i].type, e.target.files[i])
                suppressions3.push({ suppressions3: e.target.files[i].name })
                errors.suppression_files = ""
                this.setState({ suppression_files: [...this.state.suppression_files, ...e.target.files], errors })
            } else {
                errors.suppression_files = "Please select csv only"
                this.setState({ errors, disable: false })
            }

        }

    }

    uploadMatchFiles = async (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.matchfiles = ""
            this.setState({ matchfiles: e.target.files[0].name, errors })

        } else {
            errors.matchfiles = "Please select csv only"
            this.setState({ errors })
        }


    }



    //this will upload TM Scripts file in s3
    uploadTMScripts = async (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.tm_scripts = ""
            this.setState({ tm_scripts: e.target.files[0].name, errors })
        } else {
            errors.tm_scripts = "Please select csv only"
            this.setState({ errors })
        }
    }


    uploadVMFILES = async (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.vm_files = ""
            this.setState({ vm_files: e.target.files[0].name, errors })
        } else {
            errors.vm_files = "Please select csv only"
            this.setState({ errors })
        }

    }

    //this will upload TM Scripts file in s3
    uploadResponseCreatives = async (e) => {
        const errors = {};
        let file = e.target.files[0]
        if (file !== undefined && file.type.indexOf("csv") !== -1) {
            this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type, e.target.files[0])
            errors.response_creatives = ""
            this.setState({ response_creatives: e.target.files[0].name, errors })

        } else {
            errors.response_creatives = "Please select csv only"
            this.setState({ errors })
        }
    }







    notify = () => {
        toast.success('successfully Saved If you have fiiled all info then please check in pending list page', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
        this.setState({ redirectpath: '/campaignsetuplist' })
    }


    async componentDidMount() {
        if (this.props.match.params.id) {
            const data = {
                campaign_id: this.props.match.params.id
            }

            this.props.toolSwap(data)
        }
        this.props.countryList()
        this.props.customerPrefillData()
        this.props.userDetails();
        this.props.usersInfo()
        let usersinfo = await localStorage.getItem("usersinfo");
        if (usersinfo) {
            usersinfo = JSON.parse(usersinfo)
            linkedincred = usersinfo && usersinfo.length > 0 && usersinfo[0].Value
        }

        // window.onbeforeunload = async function () {
        //     try {
        //         await Auth.signOut({ global: true });
        //         await localStorage.removeItem('userdetails');
        //         await localStorage.removeItem('usersinfo')
        //         this.setState({ redirectpath: '/' })
        //     } catch (err) {
        //         console.log(err, 'error signing out...');
        //     }
        //     return 'You have unsaved changes!';
        // }


    }




    componentWillUnmount() {
        window.onbeforeunload = async function () {
            try {
                await Auth.signOut({ global: true });
                await localStorage.removeItem('userdetails');
                await localStorage.removeItem('usersinfo')
                this.setState({ redirectpath: '/' })
            } catch (err) {
                console.log(err, 'error signing out...');
            }
            return 'You have unsaved changes!';
        }
    }


    submit = (item) => {
        confirmAlert({
            title: 'Cancel',
            message: "Are you sure you want to cancel? you may lose some information Press 'Yes' to continue.",
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.setState({ redirectpath: '/campaignsetuplist' })
                },
                {
                    label: 'Cancel',
                    onClick: () => ''
                }
            ]
        });
    };

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.campaignSetUpInsert &&
            this.props.campaignReducer.campaignSetUpInsert !==
            nextProps.campaignReducer.campaignSetUpInsert &&
            this.props.campaignReducer.campaignSetUpInsertSuccess === true
        ) {
            this.notify()
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.campaignSetUpInsert &&
            this.props.campaignReducer.campaignSetUpInsert !==
            nextProps.campaignReducer.campaignSetUpInsert &&
            this.props.campaignReducer.campaignSetUpInsertSuccess === false
        ) {
            const errors = {};
            errors.campaign_name = "Campaign name already exists."
            this.setState({ errors })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.campaignUpdate &&
            this.props.campaignReducer.campaignUpdate !==
            nextProps.campaignReducer.campaignUpdate &&
            this.props.campaignReducer.campaignUpdateSuccess === true
        ) {
            this.notify()
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.campaignUpdate &&
            this.props.campaignReducer.campaignUpdate !==
            nextProps.campaignReducer.campaignUpdate &&
            this.props.campaignReducer.campaignUpdateSuccess === false
        ) {
            const errors = {};
            errors.campaign_name = "Campaign name already exists."
            this.setState({ errors })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.customerprefilldata &&
            this.props.campaignReducer.customerprefilldata !==
            nextProps.campaignReducer.customerprefilldata &&
            this.props.campaignReducer.customerprefilldataSuccess === true
        ) {
            const customerList = [];
            this.props.campaignReducer.customerprefilldata && this.props.campaignReducer.customerprefilldata.map((item, index) => {
                if (item.customer_name) {
                    customerList.push({
                        value: item.customer_name,
                        label: item.customer_name
                    });
                }
                return true;

            })
            this.setState({ customer_list: customerList })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.countryList &&
            this.props.campaignReducer.countryList !==
            nextProps.campaignReducer.countryList &&
            this.props.campaignReducer.countryListSuccess === true
        ) {
            const countryList = [];
            this.props.campaignReducer.countryList && this.props.campaignReducer.countryList.map((item, index) => {
                if (item.country_name) {
                    countryList.push({
                        value: item.country_id,
                        label: item.country_name
                    });
                }
                return true;

            })
            this.setState({ country_list: countryList })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.stateList &&
            this.props.campaignReducer.stateList !==
            nextProps.campaignReducer.stateList &&
            this.props.campaignReducer.stateListSuccess === true
        ) {
            const stateList = [];
            this.props.campaignReducer.stateList && this.props.campaignReducer.stateList.map((item, index) => {
                if (item.state_name) {
                    // console.log(item, "itmmm")
                    stateList.push({
                        value: item.country_id,
                        label: item.state_name,
                        id: item.country_id
                    });
                }
                return true;
            })
            this.setState({ state_list: stateList })
        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.arcMan &&
            this.props.managerReducer.arcMan !==
            nextProps.managerReducer.arcMan &&
            this.props.managerReducer.arcManSuccess === true
        ) {
            this.setState({ userData: this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes })
        }


        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.linkedindata &&
            this.props.campaignReducer.linkedindata !==
            nextProps.campaignReducer.linkedindata &&
            this.props.campaignReducer.linkedindataSuccess === true
        ) {
            this.setState({ linkedindata: this.props.campaignReducer.linkedindata })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.toolSwapList &&
            this.props.campaignReducer.toolSwapList !==
            nextProps.campaignReducer.toolSwapList &&
            this.props.campaignReducer.toolSwapListSuccess === true
        ) {
            // let fields = this.state.fields

            const field = this.props.campaignReducer.toolSwapList[0]
            console.log(field, "field")
            var MapDataList = this.state.MapDataList;
            var MapDataList1 = this.state.MapDataList1;
            var MapDataList2 = this.state.MapDataList2;
            var MapDataList3 = this.state.MapDataList3;
            var MapDataList4 = this.state.MapDataList4;

            field && field.accepted_title_list && field.accepted_title_list.split(',').forEach(element => {
                MapDataList2[element] = element;
            });

            field && field.accepted_title_list && field.delivery_contacts.split(',').forEach(element => {
                MapDataList1[element] = element;
            });

            field && field.html_subject_lines && field.html_subject_lines.split(',').forEach(element => {
                MapDataList[element] = element;
            });

            field && field.sic_codes && field.sic_codes.split(',').forEach(element => {
                MapDataList3[element] = element;
            });
            field && field.naics_codes && field.naics_codes.split(',').forEach(element => {
                MapDataList4[element] = element;
            });


            let selectedOption = this.state.selectedOption
            selectedOption = field.customer !== null ? field.customer : []

            let Lead_type = this.state.Lead_type
            Lead_type = field.lead_type !== null ? field.lead_type : []
            var customvalue = this.state.customvalue
            let obj = {}
            obj['value'] = field.no_of_leads_per_company !== null ? field.no_of_leads_per_company : ''
            obj['label'] = field.no_of_leads_per_company !== null ? field.no_of_leads_per_company : ''
            customvalue = obj
            let price_lead = this.state.price_lead
            price_lead = field.price_lead
            let startDate = this.state.startDate
            startDate = new Date(field.start_date)
            let endDate = this.state.endDate
            endDate = new Date(field.end_date)
            let deadline_date = this.state.deadline_date
            deadline_date = new Date(field.deadline_date)
            let delivery_dates = this.state.delivery_dates
            delivery_dates = new Date(field.delivery_dates)
            let delivery_format = this.state.delivery_format
            delivery_format = field.delivery_format !== null ? field.delivery_format : []
            let personName = this.state.personName
            personName = field.titles !== '' ? field.titles.split(',') : []
            let domain_types = this.state.domain_types
            domain_types = field.excluded_domain_types !== "0" ? field.excluded_domain_types.split(',') : []
            let countries = this.state.countries
            countries = field.geo_country !== null ? field.geo_country : []
            let revenue = this.state.revenue
            revenue = field.revenue
            let department = this.state.department
            department = field.departments !== null ? field.departments.split(',') : []
            let managment_levels = this.state.managment_levels
            managment_levels = field.management_levels !== null ? field.management_levels.split(',') : []
            let tm_scripts = this.state.tm_scripts
            tm_scripts = field.tm_script



            let listabm = this.state.listabm
            listabm = field.company_list_abm

            // let pictures = this.state.pictures
            // pictures = field.email_creatives

            var MapDataList5 = this.state.MapDataList5;
            var MapDataList6 = this.state.MapDataList6;
            var MapDataList7 = this.state.MapDataList7;
            var MapDataList8 = this.state.MapDataList8;
            var MapDataList9 = this.state.MapDataList9;

            // console.log(field.email_creatives, "field.email_creatives")
            const abmList = [];
            let chipsArray = field.email_creatives !== "[object Object]" && field.email_creatives !== '' ? JSON.parse(field.email_creatives) : []
            chipsArray && chipsArray.length > 0 && chipsArray.map((item, index) => {
                abmList.push({
                    name: Object.keys(item)
                });
                if (index === 0) {
                    let data = Object.values(item)
                    MapDataList5 = data[0]
                }

                if (index === 1) {
                    let data = Object.values(item)
                    MapDataList6 = data[0]
                }
                if (index === 2) {
                    let data = Object.values(item)
                    MapDataList7 = data[0]
                }
                if (index === 3) {
                    let data = Object.values(item)
                    MapDataList8 = data[0]
                }
                if (index === 4) {
                    let data = Object.values(item)
                    MapDataList9 = data[0]
                }

                return true;
            })

            // console.log(abmList, "abmList")

            var suppression = field.suppression_files && field.suppression_files.split(',');
            var supList = [];
            suppression && suppression.length > 0 && suppression.map((item) => {
                supList.push({
                    name: item
                });
                return true;
            })


            let vm_files = this.state.vm_files
            vm_files = field.vm_recording_file
            let response_creatives = this.state.response_creatives
            response_creatives = field.tm_email_response_creative
            let leadtemplates = this.state.leadtemplates
            leadtemplates = field.load_delivery_shaping_template
            let matchfiles = this.state.matchfiles
            matchfiles = field.match_files


            this.setState({ fields: field, MapDataList5, MapDataList6, MapDataList7, MapDataList8, MapDataList9, selectedOption, suppression_files: supList, Lead_type, customvalue, price_lead, startDate, endDate, deadline_date, delivery_dates, delivery_format, countries, revenue, personName, domain_types, managment_levels, department, tm_scripts, pictures: abmList, listabm: listabm, vm_files, response_creatives, leadtemplates, matchfiles })

        }
    }

    addMapValue = () => {
        var MapDataList1 = this.state.MapDataList1;
        if (this.state.delivery_contacts !== '') {
            var obj = {};
            obj[this.state.delivery_contacts] = this.state.delivery_contacts
            MapDataList1 = { ...MapDataList1, ...obj };
        }

        this.setState({ MapDataList1, delivery_contacts: '', addmapvalue: true })

    }

    addMapValue3 = () => {
        var MapDataList3 = this.state.MapDataList3;
        if (this.state.sic_codes !== '') {
            var obj = {};
            obj[this.state.sic_codes] = this.state.sic_codes
            MapDataList3 = { ...MapDataList3, ...obj };
        }

        this.setState({ MapDataList3, sic_codes: '', addmapvalue3: true })

    }

    addMapValue4 = () => {
        var MapDataList4 = this.state.MapDataList4;
        if (this.state.naics_codes !== '') {
            var obj = {};
            obj[this.state.naics_codes] = this.state.naics_codes
            MapDataList4 = { ...MapDataList4, ...obj };
        }

        this.setState({ MapDataList4, naics_codes: '', addmapvalue4: true })
    }

    addMapValue5 = () => {
        var MapDataList5 = this.state.MapDataList5;
        if (this.state.creative_subject_lines !== '') {
            var obj = {};
            obj[this.state.creative_subject_lines] = this.state.creative_subject_lines
            MapDataList5 = { ...MapDataList5, ...obj };
        }

        var MapDataList6 = this.state.MapDataList6;
        if (this.state.creative_subject_lines2 !== '') {
            var obj = {};
            obj[this.state.creative_subject_lines2] = this.state.creative_subject_lines2
            MapDataList6 = { ...MapDataList6, ...obj };
        }

        var MapDataList7 = this.state.MapDataList7;
        if (this.state.creative_subject_lines3 !== '') {
            var obj = {};
            obj[this.state.creative_subject_lines3] = this.state.creative_subject_lines3
            MapDataList7 = { ...MapDataList7, ...obj };
        }

        var MapDataList8 = this.state.MapDataList8;
        if (this.state.creative_subject_lines4 !== '') {
            var obj = {};
            obj[this.state.creative_subject_lines4] = this.state.creative_subject_lines4
            MapDataList8 = { ...MapDataList8, ...obj };
        }

        var MapDataList9 = this.state.MapDataList9;
        if (this.state.creative_subject_lines5 !== '') {
            var obj = {};
            obj[this.state.creative_subject_lines5] = this.state.creative_subject_lines5
            MapDataList9 = { ...MapDataList9, ...obj };
        }

        this.setState({ MapDataList5, MapDataList6, MapDataList7, MapDataList8, MapDataList9, creative_subject_lines5: '', creative_subject_lines4: '', creative_subject_lines3: '', creative_subject_lines: '', creative_subject_lines2: '', addmapvalue5: true })


    }


    addMapValue6 = () => {
        let creative_subject_lines_data = this.state.creative_subject_lines_data
        console.log(this.state.pictures, "pictures")
        // let creative_subject_lines_arr = this.state.creative_subject_lines_arr

        var obj = {};
        if (this.state.counter === 1) {
            let filename = this.state.pictures && this.state.pictures.length > 0 && this.state.pictures[0].name
            obj[filename] = this.state.MapDataList5

        }
        if (this.state.counter === 2) {
            // var obj = {};
            let filename = this.state.pictures && this.state.pictures.length > 0 && this.state.pictures[1].name
            obj[filename] = this.state.MapDataList6
            // creative_subject_lines_data.push(obj);
        }

        if (this.state.counter === 3) {
            // var obj = {};
            let filename = this.state.pictures && this.state.pictures.length > 0 && this.state.pictures[2].name
            obj[filename] = this.state.MapDataList7
            // creative_subject_lines_data.push(obj);
        }

        if (this.state.counter === 4) {
            // var obj = {};
            let filename = this.state.pictures && this.state.pictures.length > 0 && this.state.pictures[3].name
            obj[filename] = this.state.MapDataList8
            // creative_subject_lines_data.push(obj);
        }

        if (this.state.counter === 5) {
            // var obj = {};
            let filename = this.state.pictures && this.state.pictures.length > 0 && this.state.pictures[4].name
            obj[filename] = this.state.MapDataList9
            // creative_subject_lines_data.push(obj);
        }

        creative_subject_lines_data.push(obj);
        console.log(creative_subject_lines_data, "creative_subject_lines_data")
        this.setState({ creative_subject_lines_data: creative_subject_lines_data })
    }

    addMapValue1 = () => {
        var MapDataList = this.state.MapDataList;
        if (this.state.html_subject_lines !== '') {
            var obj = {};
            obj[this.state.html_subject_lines] = this.state.html_subject_lines
            MapDataList = { ...MapDataList, ...obj };
        }

        this.setState({ MapDataList, html_subject_lines: '' })
        this.setState({
            addmapvalue1: true,
            // addmore: false,

        });
    }

    addMapValue2 = () => {
        var MapDataList2 = this.state.MapDataList2;
        if (this.state.accepted_title_list !== '') {
            var obj = {};
            obj[this.state.accepted_title_list] = this.state.accepted_title_list
            MapDataList2 = { ...MapDataList2, ...obj };
        }

        this.setState({ MapDataList2, accepted_title_list: '' })
        this.setState({
            addmapvalue2: true,
            // addmore: false,

        });
    }

    DeleteIconData11 = (item) => {
        let MapDataList1 = this.state.MapDataList1;
        delete MapDataList1[item[0]];
        this.setState({ MapDataList1 })
    };

    DeleteIconData12 = (item) => {
        let MapDataList = this.state.MapDataList;
        delete MapDataList[item[0]];
        this.setState({ MapDataList })
    };

    DeleteIconData13 = (item) => {
        let MapDataList2 = this.state.MapDataList2;
        delete MapDataList2[item[0]];
        this.setState({ MapDataList2 })
    };


    DeleteIconData14 = (item) => {
        let MapDataList3 = this.state.MapDataList3;
        delete MapDataList3[item[0]];
        this.setState({ MapDataList3 })
    };


    DeleteIconData15 = (item) => {
        let MapDataList4 = this.state.MapDataList4;
        delete MapDataList4[item[0]];
        this.setState({ MapDataList4 })
    };

    DeleteIconData16 = (item) => {
        let MapDataList5 = this.state.MapDataList5;
        let MapDataList6 = this.state.MapDataList6;
        let MapDataList7 = this.state.MapDataList7;
        let MapDataList8 = this.state.MapDataList8;
        let MapDataList9 = this.state.MapDataList9;
        if (this.state.counter === 1) {
            delete MapDataList5[item[0]];
        }
        if (this.state.counter === 2) {
            delete MapDataList6[item[0]];
        }
        if (this.state.counter === 3) {
            delete MapDataList7[item[0]];
        }
        if (this.state.counter === 4) {
            delete MapDataList8[item[0]];
        }
        if (this.state.counter === 5) {
            delete MapDataList9[item[0]];
        }
        this.setState({ MapDataList5, MapDataList6, MapDataList7, MapDataList8, MapDataList9 })
    };



    closeModalMapValue = () => {
        this.setState({ addmapvalue: false, addmapvalue1: false, addmapvalue2: false, addmapvalue3: false, addmapvalue4: false, addmapvalue5: false })
    }


    closeModalMapValue12 = () => {
        this.addMapValue6()
        this.setState({ addmapvalue5: false })
    }

    validateSaveCampaign() {
        if (this.state.activeStep === 0) {
            const errors = {};
            const { fields } = this.state;
            const strings = /^[A-Za-z0-9 ]+$/;

            let formIsValid = true;
            // <=== CAMPAIGN NAME VALIDATION ===>
            if (!fields.campaign_name) {
                errors.campaign_name = 'Please enter campaign name';
                formIsValid = false;
            }
            if (fields.campaign_name && fields.campaign_name.length < 3) {
                errors.campaign_name = 'Please enter minimum 3 characters';
                formIsValid = false;
            }
            if (fields.campaign_name && fields.campaign_name.length > 100) {
                errors.campaign_name = 'Campaign name should be 100 characters only';
                formIsValid = false;
            }

            // <=== CUSTOMER VALIDATION ===>
            if (this.state.selectedOption && this.state.selectedOption.length === 0) {
                errors.customer = 'Please select customer name';
                formIsValid = false;
            }

            this.setState({ errors });
            return formIsValid;
        }

        if (this.state.activeStep === 1) {
            const errors = {};
            const { fields } = this.state;
            const numbers = /^[-+]?[0-9]+$/;
            const email = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

            let formIsValid = true;
            // <=== CAMPAIGN START DATE VALIDATION ===>
            if (this.state.startDate === null) {
                errors.startDate = 'Start date is required';
                formIsValid = false;
            }
            // <=== CAMPAIGN END DATE VALIDATION ===>
            if (this.state.endDate === null) {
                errors.endDate = 'End date is required';
                formIsValid = false;
            }
            if (this.state.endDate < this.state.startDate) {
                errors.endDate = 'End date should be greater than start date..';
                formIsValid = false;
            }

            // <=== CAMPAIGN DELIVERY FORMAT VALIDATION ===>
            if (this.state.delivery_format && this.state.delivery_format.length === 0) {
                errors.deliveryformat = 'Please select delivery format name';
                formIsValid = false;
            }

            // <=== CAMPAIGN REQUIRED LEADS VALIDATION ===>
            if (!fields.required_leads) {
                errors.required_leads = 'Please enter no of leads';
                formIsValid = false;
            }

            if (fields.required_leads && !numbers.test(fields.required_leads)) {
                errors.required_leads = 'Please provide number only';
                formIsValid = false;
            }
            // <=== CAMPAIGN PRICE LEAD VALIDATION ===>
            if (!this.state.price_lead) {
                errors.price_lead = 'Please enter price lead';
                formIsValid = false;
            }
            // <=== CAMPAIGN  LEAD TYPE VALIDATION ===>
            if (this.state.Lead_type && this.state.Lead_type.length === 0) {
                errors.lead_type = 'Please select lead type';
                formIsValid = false;
            }

            // if (this.state.MapDataList1 // 👈 null and undefined check
            //     && Object.keys(this.state.MapDataList1).length === 0
            //     && Object.getPrototypeOf(this.state.MapDataList1) === Object.prototype) {
            //     errors.delivery_contacts = 'Please enter  lead delivery contact how many do you want';
            //     formIsValid = false;
            // }

            // for (const [key, value] of Object.entries(this.state.MapDataList1)) {
            //     if (!email.test(value)) {
            //         errors.delivery_contacts = 'Please provide email only';
            //         formIsValid = false;
            //     }
            // }


            this.setState({ errors });
            return formIsValid;
        }



        if (this.state.activeStep === 2) {
            console.log("hi inside step 2")
            const errors = {};
            const numbers = /^[-+]?[0-9]+$/;
            let formIsValid = true;
            // <=== CAMPAIGN COUNTRY VALIDATION ===>

            if (this.state.countries && this.state.countries.length === 0) {
                errors.countries = 'Please select country';
                formIsValid = false;
            }

            this.setState({ errors });
            return formIsValid;
        }

        if (this.state.activeStep === 3) {
            const errors = {};
            let formIsValid = true;
            // <=== CAMPAIGN  TM Phone Qual Script VALIDATION ===>
            if (this.state.tm_scripts === '') {
                errors.tm_scripts = 'Please enter tm scripts';
                formIsValid = false;
            }

            if (this.state.pictures === '') {
                errors.pictures = 'Please upload HTML Files';
                formIsValid = false;
            }

            // if (this.state.MapDataList // 👈 null and undefined check
            //     && Object.keys(this.state.MapDataList).length === 0
            //     && Object.getPrototypeOf(this.state.MapDataList) === Object.prototype) {
            //     errors.html_subject_lines = 'Please enter html subject lines how many do you want';
            //     formIsValid = false;
            // }


            this.setState({ errors });
            return formIsValid;
        }

    }




    //Campaign insert
    campaignInsert = () => {

        const campaigninfo = {
            sub: this.state.linkedindata && this.state.linkedindata.length > 0 ? this.state.linkedindata[0].Value : this.state.userData ? this.state.userData.sub : null,
            delivery_format: this.state.delivery_format ? this.state.delivery_format : null,
            campaign_name: this.state.fields.campaign_name ? this.state.fields.campaign_name : null,
            domain_types: this.state.domain_types ? this.state.domain_types : null,
            response_creatives: this.state.response_creatives,
            vm_files: this.state.vm_files,
            tm_scripts: this.state.tm_scripts,
            managment_levels: this.state.managment_levels ? this.state.managment_levels : null,
            department: this.state.department ? this.state.department : null,
            title_id: this.state.personName ? this.state.personName : null,
            revenue: this.state.revenue ? this.state.revenue : null,
            po: this.state.fields.po ? this.state.fields.po : null,
            employee: this.state.fields.employees ? this.state.fields.employees : null,
            country: this.state.countries ? this.state.countries : null,
            delivery_dates: this.state.delivery_dates ? this.state.delivery_dates : null,
            end_date: this.state.endDate ? this.state.endDate : null,
            start_date: this.state.startDate ? this.state.startDate : null,
            ts_campaign: this.state.fields.ts_campaign ? this.state.fields.ts_campaign : null,
            sponsor: this.state.fields.sponsor ? this.state.fields.sponsor : null,
            price_lead: this.state.price_lead ? this.state.price_lead : null,
            Lead_type: this.state.Lead_type ? this.state.Lead_type : null,
            customer_name: this.state.selectedOption ? this.state.selectedOption : null,
            arcman_id: this.state.fields.arcman_id ? this.state.fields.arcman_id : null,
            campaign_id: this.state.fields.extcampaign_id ? this.state.fields.extcampaign_id : null,
            extcampaign_id: this.state.fields.extcampaign_id ? this.state.fields.extcampaign_id : null,
            email_creatives: this.state.creative_subject_lines_data ? this.state.creative_subject_lines_data : null,
            list_abm: this.state.listabm,
            delivery_shaping: this.state.leadtemplates,
            suppression_files: suppressions3 ? suppressions3 : null,
            match_files: this.state.matchfiles,
            status: 'Pending',
            required_leads: this.state.fields.required_leads ? this.state.fields.required_leads : null,
            deadline_date: this.state.deadline_date,
            MapDataList: this.state.MapDataList,
            MapDataList1: this.state.MapDataList1,
            MapDataList2: this.state.MapDataList2,
            MapDataList3: Object.entries(this.state.MapDataList3).length === 0 ? '' : this.state.MapDataList3,
            MapDataList4: Object.entries(this.state.MapDataList4).length === 0 ? '' : this.state.MapDataList4,
            no_of_leads_per_company: this.state.customvalue !== null && this.state.customvalue.value
        }
        console.log(campaigninfo, "campginfo")
        if (this.props.match.params && this.props.match.params.id !== undefined) {
            const campaigninfo = {
                c_id: this.props.match.params.id,
                sub: this.state.linkedindata && this.state.linkedindata.length > 0 ? this.state.linkedindata[0].Value : this.state.userData ? this.state.userData.sub : null,
                delivery_format: this.state.delivery_format ? this.state.delivery_format : null,
                campaign_name: this.state.fields.campaign_name ? this.state.fields.campaign_name : null,
                domain_types: this.state.domain_types ? this.state.domain_types : null,
                response_creatives: this.state.response_creatives,
                vm_files: this.state.vm_files,
                tm_scripts: this.state.tm_scripts,
                managment_levels: this.state.managment_levels ? this.state.managment_levels : null,
                department: this.state.department ? this.state.department : null,
                title_id: this.state.personName ? this.state.personName : null,
                revenue: this.state.revenue ? this.state.revenue : null,
                po: this.state.fields.po ? this.state.fields.po : null,
                employee: this.state.fields.employees ? this.state.fields.employees : null,
                country: this.state.countries ? this.state.countries : null,
                delivery_dates: this.state.delivery_dates ? this.state.delivery_dates : null,
                end_date: this.state.endDate ? this.state.endDate : null,
                start_date: this.state.startDate ? this.state.startDate : null,
                ts_campaign: this.state.fields.ts_campaign ? this.state.fields.ts_campaign : null,
                sponsor: this.state.fields.sponsor ? this.state.fields.sponsor : null,
                price_lead: this.state.price_lead ? this.state.price_lead : null,
                Lead_type: this.state.Lead_type ? this.state.Lead_type : null,
                customer_name: this.state.selectedOption ? this.state.selectedOption : null,
                arcman_id: this.state.fields.arcman_id ? this.state.fields.arcman_id : null,
                campaign_id: this.state.fields.extcampaign_id ? this.state.fields.extcampaign_id : null,
                extcampaign_id: this.state.fields.extcampaign_id ? this.state.fields.extcampaign_id : null,
                email_creatives: this.state.creative_subject_lines_data ? this.state.creative_subject_lines_data : null,
                list_abm: this.state.listabm,
                delivery_shaping: this.state.leadtemplates,
                suppression_files: suppressions3 ? suppressions3 : null,
                match_files: this.state.matchfiles,
                status: 'Pending',
                required_leads: this.state.fields.required_leads ? this.state.fields.required_leads : null,
                deadline_date: this.state.deadline_date,
                MapDataList: this.state.MapDataList,
                MapDataList1: this.state.MapDataList1,
                MapDataList2: this.state.MapDataList2,
                MapDataList3: Object.entries(this.state.MapDataList3).length === 0 ? '' : this.state.MapDataList3,
                MapDataList4: Object.entries(this.state.MapDataList4).length === 0 ? '' : this.state.MapDataList4,
                no_of_leads_per_company: this.state.customvalue !== null && this.state.customvalue.value
            }
            console.log(campaigninfo, "campginfo")
            this.props.campaignUpdate(campaigninfo)
        }
        else {
            this.props.campaignsetupInsert(campaigninfo)
        }
        // }
    };








    isStepOptional = (step) => {
        return step === 1;
    };

    handleNext = () => {
        if (this.validateSaveCampaign()) {
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
    };

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };

    handleReset = () => {
        this.setState({ activeStep: 0 });
    };

    render() {
        const { redirectpath, Lead_type, fields, selectedOption, MapDataList5, MapDataList6, MapDataList7, MapDataList8, MapDataList9, errors, customer_list, MapDataList1, MapDataList2, MapDataList3, MapDataList4, MapDataList, delivery_format, no_of_leads_per_company } = this.state;
        var myObjEntries = Object.entries(MapDataList1)
        var myObjEntrieshtmlinput = Object.entries(MapDataList)
        var myObjEntriestitle = Object.entries(MapDataList2)
        var myObjEntriessic_codes = Object.entries(MapDataList3)
        var myObjEntriesnaics_codes = Object.entries(MapDataList4)
        var myObjSubjectLines = Object.entries(MapDataList5)
        var myObjSubjectLines1 = Object.entries(MapDataList6)
        var myObjSubjectLines2 = Object.entries(MapDataList7)
        var myObjSubjectLines3 = Object.entries(MapDataList8)
        var myObjSubjectLines4 = Object.entries(MapDataList9)






        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 col-12 col-sm-12 menublock">
                        <LeftNavigation
                            data={{ showBox: this.state.showBox }}
                        />
                    </div>
                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <span className="campaignsetup">Campaign Setup</span>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                                <>
                                    {this.state.activeStep === steps.length ? (
                                        <div className="mt-3">
                                            <div className="campaignlabel pb-2 mb-2">
                                                All steps completed - you&apos;re finished
                                            </div>
                                            <div className="mb-2 pb-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.handleReset}>Reset</Button>
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.campaignInsert}>Save</Button>


                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {this.state.activeStep + 1}</Typography> */}
                                            <div className="pb-3 mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    color="inherit"
                                                    disabled={this.state.activeStep === 0}
                                                    onClick={this.handleBack}
                                                // sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.handleNext}>
                                                    {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </>
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%' }}
                                    >
                                        <div className="row container">
                                            {this.state.activeStep === 0 && (
                                                <>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Select Customer it's mandatory to create campaign......" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Customer</p>
                                                        </BootstrapTooltip>
                                                        <FormControl style={{ width: '100%' }}>
                                                            <Select
                                                                displayEmpty
                                                                value={selectedOption}
                                                                onChange={this.handleCustomerList}
                                                                input={<OutlinedInput />}
                                                                MenuProps={MenuProps}
                                                                className='select'

                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span>Select Title...</span>;
                                                                    }

                                                                    return selected;
                                                                }}
                                                            >
                                                                {customer_list.map((name, index) => (
                                                                    <MenuItem value={name.value}>{name.label}</MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                        <span className="errors mt-2">
                                                            {errors && errors.customer}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Enter campaign name it's mandatory to create campaign" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Campaign Name</p>
                                                        </BootstrapTooltip>
                                                        <input
                                                            type="text"
                                                            value={fields.campaign_name}
                                                            onChange={this.handleChange.bind(this, "campaign_name")}
                                                            // onKeyDown={this.handleChange}
                                                            className="campaigntext"
                                                            placeholder="Enter campaign name"
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.campaign_name}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        {/* <BootstrapTooltip title="Arcman Campaign ID is not editable it will auto fill during campaign update" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Arcman Campaign ID</p>
                                                        </BootstrapTooltip> */}
                                                        <input
                                                            type="hidden"
                                                            disabled={true}
                                                            value={fields.arcman_id ? fields.arcman_id : fields.arcman_campaign_id ? fields.arcman_campaign_id : ''}
                                                            onChange={this.handleChange.bind(this, "arcman_id")}
                                                            className="campaigntext"
                                                            placeholder="- (non-editable field)"
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.arcman_id}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Enter your External Campaign ID if you are having it's not mandatory to create campaign" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">External Campaign ID</p>
                                                        </BootstrapTooltip>
                                                        <input
                                                            type="text"
                                                            value={fields.extcampaign_id ? fields.extcampaign_id : fields.external_campaign_id ? fields.external_campaign_id : ''}
                                                            onChange={this.handleChange.bind(this, "extcampaign_id")}
                                                            className="campaigntext"
                                                            placeholder="Enter campaign id"
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.extcampaign_id}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Enter Sponsor it's mandatory to create campaign........" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Sponsor</p>
                                                        </BootstrapTooltip>
                                                        <input
                                                            type="text"
                                                            value={fields.sponsor}
                                                            onChange={this.handleChange.bind(this, "sponsor")}
                                                            className="campaigntext"
                                                            placeholder="Enter sponsor"
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.sponsor}
                                                        </span>
                                                    </div>
                                                </>
                                            )}

                                            {this.state.activeStep === 1 && (
                                                <>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Enter Required Leads it's mandatory to create campaign" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Required Leads</p>
                                                        </BootstrapTooltip>
                                                        <input
                                                            type="text"
                                                            value={fields.required_leads}
                                                            onChange={this.handleChange.bind(this, "required_leads")}
                                                            className="campaigntext"
                                                            placeholder="Enter no of leads"
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.required_leads}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Enter Price/Lead it's mandatory to create campaign........" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">$ Price/Lead</p>
                                                        </BootstrapTooltip>
                                                        <CurrencyInput
                                                            id="input-example"
                                                            name="input-name"
                                                            placeholder="Please price lead"
                                                            // defaultValue={1000}
                                                            value={this.state.price_lead}
                                                            decimalsLimit={2}
                                                            onValueChange={(value) => this.handlePriceLead(value)}
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.price_lead}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Enter Start Date it's mandatory to create campaign....................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Start Date</p>
                                                        </BootstrapTooltip>
                                                        <DatePicker
                                                            selected={this.state.startDate}
                                                            placeholderText=" Select Start Date"
                                                            // dateFormat="MMMM d, yyyy h:mm aa"
                                                            onChange={(date) => this._onSelectDate(date)}
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.startDate}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Enter End Date it's mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">End Date</p>
                                                        </BootstrapTooltip>
                                                        <DatePicker
                                                            selected={this.state.endDate}
                                                            placeholderText=" Select End Date"
                                                            // dateFormat="MMMM d, yyyy h:mm aa"
                                                            onChange={(date) => this._onSelectEndDate(date)}
                                                        />
                                                        <span className="errors mt-2">
                                                            {errors && errors.endDate}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <BootstrapTooltip title="Select Lead Type it's mandatory to create campaign........" placement="top">
                                                            <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">Lead Type</p>
                                                        </BootstrapTooltip>
                                                        <FormControl style={{ width: '100%' }}>
                                                            <Select
                                                                displayEmpty
                                                                value={Lead_type}
                                                                onChange={this._onSelectLeadType}
                                                                input={<OutlinedInput />}
                                                                MenuProps={MenuProps}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span>Select lead type</span>;
                                                                    }

                                                                    return selected;
                                                                }}
                                                            >
                                                                {leadtype.map((name) => (
                                                                    <MenuItem value={name.value}>{name.label}</MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                        <span className="errors mt-2">
                                                            {errors && errors.lead_type}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Select Delivery Format it's mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Delivery Format</p>
                                                        </BootstrapTooltip>
                                                        <FormControl style={{ width: '100%' }}>
                                                            <Select
                                                                displayEmpty
                                                                value={delivery_format}
                                                                onChange={this._onSelectDeliveryformat}
                                                                // input={<OutlinedInput />}
                                                                MenuProps={MenuProps}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span>Select Delivery Format</span>;
                                                                    }

                                                                    return selected;
                                                                }}
                                                            >
                                                                {deliveryformat.map((name) => (
                                                                    <MenuItem value={name.value}>{name.label}</MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                        <span className="errors mt-2">
                                                            {errors && errors.deliveryformat}
                                                        </span>
                                                    </div>

                                                    <div className="col-md-12 col-12 col-sm-12">
                                                        <BootstrapTooltip title="Select Delivery Format it's mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3">Max Leads per company</p>
                                                        </BootstrapTooltip>
                                                        <div className="col-md-12 col-12 col-sm-12 mb-3">
                                                            <CreatableSelect
                                                                isClearable
                                                                isSearchable={true}
                                                                menuPlacement="auto"
                                                                value={this.state.customvalue}
                                                                options={this.state.customdata}
                                                                onChange={this.handleCustom}
                                                                onCreateOption={this.handleCustomTarget}
                                                            />
                                                        </div>
                                                        {/* <FormControl style={{ width: '100%' }}>
                                                            <Select
                                                                displayEmpty
                                                                value={no_of_leads_per_company}
                                                                onChange={this._onSelectLeadspercompany}
                                                                // input={<OutlinedInput />}
                                                                MenuProps={MenuProps}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span>Select Max Leads per company</span>;
                                                                    }

                                                                    return selected;
                                                                }}
                                                            >
                                                                {leads_per_company.map((name) => (
                                                                    <MenuItem value={name.value}>{name.label}</MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl> */}
                                                        <span className="errors mt-2">
                                                            {errors && errors.no_of_leads_per_company}
                                                        </span>
                                                    </div>

                                                    <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 pb-5 mb-3">
                                                        <AddIcon
                                                            onClick={this.addMapValue}
                                                            style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                        />
                                                        <span className="addmapvalue">Lead Delivery Contacts</span>

                                                        {/* <span className="errors mt-2">
                                                            {errors && errors.delivery_contacts}
                                                        </span> */}
                                                    </div>


                                                </>

                                            )}

                                            {this.state.activeStep === 2 && (
                                                <>
                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <BootstrapTooltip title="Select Country e.g, (USA, UK, CANADA)  it's mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">Country</p>
                                                        </BootstrapTooltip>
                                                        <FormControl style={{ width: '100%' }}>
                                                            <Select
                                                                displayEmpty
                                                                value={this.state.countries}
                                                                onChange={this._onSelectCountry}
                                                                input={<OutlinedInput />}
                                                                MenuProps={MenuProps}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span>Select country.....</span>;
                                                                    }

                                                                    return selected;
                                                                }}
                                                            >
                                                                {this.state.country_list && this.state.country_list.length > 0 && this.state.country_list.map((name) => (
                                                                    <MenuItem key={name.value} value={name.label}>{name.label}</MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>

                                                        <span className="errors mt-2">
                                                            {errors && errors.countries}
                                                        </span>
                                                    </div>

                                                    <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                        <AddIcon
                                                            onClick={this.addMapValue2}
                                                            style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                        />
                                                        <span className="addmapvalue">Accepted Title List</span>

                                                    </div>


                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <BootstrapTooltip title="Select Departments it's mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">Departments</p>
                                                        </BootstrapTooltip>
                                                        <FormControl style={{ width: '100%' }}>
                                                            <Select
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                displayEmpty
                                                                value={this.state.department}
                                                                placeholder="Select title"
                                                                onChange={this._onSelectDepartments}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span>Select Departments...</span>;
                                                                    }

                                                                    return selected.join(', ');
                                                                }}
                                                                MenuProps={MenuProps}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                {department1.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <Checkbox checked={this.state.department.indexOf(name) > -1} />
                                                                        <ListItemText primary={name} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <span className="errors mt-2">
                                                            {errors && errors.departments}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <BootstrapTooltip title="Select Managment Levels it's mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">Managment Levels</p>
                                                        </BootstrapTooltip>
                                                        <FormControl style={{ width: '100%' }}>
                                                            <Select
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                displayEmpty
                                                                value={this.state.managment_levels}
                                                                placeholder="Select title"
                                                                onChange={this._onSelectManagmentlevels}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span>Select managment levels....</span>;
                                                                    }

                                                                    return selected.join(', ');
                                                                }}
                                                                MenuProps={MenuProps}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                {managmentlevels.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <Checkbox checked={this.state.managment_levels.indexOf(name) > -1} />
                                                                        <ListItemText primary={name} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <span className="errors mt-2">
                                                            {errors && errors.managment_levels}
                                                        </span>
                                                    </div>

                                                    <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                        <AddIcon
                                                            onClick={this.addMapValue3}
                                                            style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                        />
                                                        <span className="addmapvalue">SIC CODES</span>

                                                    </div>


                                                    <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                        <AddIcon
                                                            onClick={this.addMapValue4}
                                                            style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                        />
                                                        <span className="addmapvalue">NAICS Codes</span>

                                                    </div>
                                                    <BootstrapTooltip title="Upload Company List ABM files in CSV Format it's mandatory to create campaign................." placement="top">
                                                        <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">Company List ABM</p>
                                                    </BootstrapTooltip>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <label htmlFor="chooseFile" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                            <div className="col-md-12 col-sm-12 col-12 fileContainer">
                                                                {this.state.listabm === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.listabm

                                                                }

                                                                <i className="icon">

                                                                    <img
                                                                        src={upload}
                                                                        alt="upload"
                                                                        style={{ width: '18px', height: '18px', color: '#000000', justifyContent: 'flex-end' }}
                                                                    />
                                                                </i>
                                                                <input
                                                                    className="Field"
                                                                    id="chooseFile"
                                                                    onChange={this.uploadListABM}
                                                                    accept=".csv"
                                                                    // disabled={this.state.counter === 5 ? true : false}
                                                                    type="file"

                                                                // multiple
                                                                />
                                                            </div>
                                                            {/* <div
                                                                style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
                                                                <AddBoxIcon
                                                                    fontSize={'medium'}
                                                                    style={{ color: '#1D417A', justifyContent: 'flex-end', marginLeft: '15px' }}
                                                                />
                                                                <span className="uploads">Upload</span>
                                                            </div> */}
                                                        </label>
                                                    </div>
                                                    <span className="errors mt-2">
                                                        {errors && errors.listabm}
                                                    </span>


                                                    <BootstrapTooltip title="Upload Suppression Files it's  mandatory to create campaign................." placement="top">
                                                        <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">Suppression Files</p>
                                                    </BootstrapTooltip>

                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <label htmlFor="chooseFileSuppression" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                            <div className="col-md-12 col-sm-12 col-12 fileContainer">
                                                                {this.state.suppression_files.length > 0 && this.state.suppression_files.map((file) => (
                                                                    <span>
                                                                        {/* {file.name} */}
                                                                        {file.name && file.name.length > 5 ? `${file.name.substring(0, 5)}...` : file.name}
                                                                    </span>
                                                                ))
                                                                }

                                                                {this.state.suppression_files.length === 0 &&
                                                                    <span>
                                                                        Select csv only
                                                                        {/* {file.name && file.name.length > 5 ? `${file.name.substring(0, 5)}...` : file.name} */}
                                                                    </span>

                                                                }

                                                                <i className="icon">

                                                                    <img
                                                                        src={upload}
                                                                        alt="upload"
                                                                        style={{ width: '18px', height: '18px', color: '#000000', justifyContent: 'flex-end' }}
                                                                    />
                                                                </i>
                                                                <input
                                                                    className="Field"
                                                                    id="chooseFileSuppression"
                                                                    onChange={this.uploadFileSuppression}
                                                                    accept=".csv"
                                                                    type="file"
                                                                    multiple
                                                                />
                                                            </div>
                                                            <div
                                                                style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
                                                                <AddBoxIcon
                                                                    fontSize={'medium'}
                                                                    style={{ color: '#1D417A', justifyContent: 'flex-end', marginLeft: '15px' }}
                                                                />
                                                                <span className="uploads">Upload</span>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <span className="errors mt-2">
                                                        {errors && errors.suppression_files}
                                                    </span>


                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <BootstrapTooltip title="Select Excluded Domain Types it's  mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">Excluded Domain Types</p>
                                                        </BootstrapTooltip>
                                                        <div className="col-md-12 col-sm-12 col-12">
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Select
                                                                    labelId="demo-multiple-checkbox-label"
                                                                    id="demo-multiple-checkbox"
                                                                    multiple
                                                                    displayEmpty
                                                                    value={this.state.domain_types}
                                                                    placeholder="Select title"
                                                                    onChange={this._onSelectDomainTypes}
                                                                    input={<OutlinedInput />}
                                                                    renderValue={(selected) => {
                                                                        if (selected.length === 0) {
                                                                            return <span>Select domain type....</span>;
                                                                        }

                                                                        return selected.join(', ');
                                                                    }}
                                                                    MenuProps={MenuProps}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    {options.map((name) => (
                                                                        <MenuItem key={name} value={name}>
                                                                            <Checkbox checked={this.state.domain_types.indexOf(name) > -1} />
                                                                            <ListItemText primary={name} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>

                                                            <span className="errors mt-2">
                                                                {errors && errors.title_id}
                                                            </span>
                                                        </div>

                                                    </div>
                                                </>
                                            )}

                                            {this.state.activeStep === 3 && (
                                                <>

                                                    <BootstrapTooltip title="Upload Email Creatives files e.g, (.pdf,.doc,.gif, .jpg, .png)  it's mandatory to create campaign................." placement="top">
                                                        <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">html Creatives</p>
                                                    </BootstrapTooltip>

                                                    <label htmlFor="chooseFile11" style={{ width: '100%' }}>
                                                        <div className="col-md-12 col-sm-12 col-12 fileContainer">
                                                            {/* <span className="">{this.state.pictures ? this.state.pictures : 'Select (.pdf,.doc,.gif, .jpg, .png) files'} </span> */}
                                                            {this.state.pictures.length === 0 || this.state.pictures === "" ? "Select (.pdf,.doc,.gif, .jpg, .png) files" : this.state.pictures.length > 0 && this.state.pictures.map((file) => (
                                                                <span>
                                                                    {/* {file.name} */}
                                                                    {file.name[0].length > 0 && file.name[0].length > 5 ? `${file.name[0].substring(0, 5)}...` : file.name && file.name.length > 5 ? `${file.name.substring(0, 5)}...` : file.name}
                                                                </span>
                                                            ))
                                                            }

                                                            <i className="icon">
                                                                <img
                                                                    src={upload}
                                                                    alt="upload"
                                                                    style={{ width: '18px', height: '18px', color: '#000000', verticalAlign: 'none' }}
                                                                />
                                                            </i>
                                                            <input
                                                                className="Field"
                                                                id="chooseFile11"
                                                                accept=".pdf,.doc,.gif, .jpg, .png,"
                                                                onChange={this.uploadFileHTML}
                                                                disabled={this.state.counter === 5 ? true : false}
                                                                type="file"
                                                                multiple
                                                            />
                                                        </div>
                                                        <span className="errors mt-2">
                                                            {errors && errors.pictures}
                                                        </span>
                                                    </label>

                                                    <div className="col-md-12 col-12 col-sm-12  mt-3 pt-3 mb-3">
                                                        <AddIcon
                                                            onClick={this.addMapValue1}
                                                            style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                                        />
                                                        <span className="addmapvalue">html subject lines</span>

                                                    </div>

                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <BootstrapTooltip title="Enter TM Scripts it's mandatory to create campaign................." placement="top">
                                                            <p className="campaignlabel pt-3 mt-3 mb-3 mb-3">TM Phone Qual Script</p>
                                                        </BootstrapTooltip>
                                                        <label htmlFor="chooseFileTMScripts" style={{ width: '100%' }}>
                                                            <div className="col-md-12 col-sm-12 col-12 fileContainer">
                                                                <span className="">{this.state.tm_scripts ? this.state.tm_scripts : 'Select csv only'} </span>
                                                                <i className="icon">
                                                                    <img
                                                                        src={upload}
                                                                        alt="upload"
                                                                        style={{ width: '18px', height: '18px', color: '#000000' }}
                                                                    />
                                                                </i>
                                                                <input
                                                                    className="Field"
                                                                    id="chooseFileTMScripts"
                                                                    accept=".csv"
                                                                    type="file"
                                                                    onChange={this.uploadTMScripts}
                                                                />
                                                            </div>
                                                            <span className="errors mt-2">
                                                                {errors && errors.tm_scripts}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </Scrollbars>
                                </div>
                                <>
                                    {this.state.activeStep === steps.length ? (
                                        <div className="mt-3">
                                            <div className="campaignlabel pb-2 mb-2">
                                                All steps completed - you&apos;re finished
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2, pb: 2 }}>
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.handleReset}>Reset</Button>
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.campaignInsert}>Save</Button>


                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {this.state.activeStep + 1}</Typography> */}
                                            <div className="pb-3 mb-3 pt-3 mt-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    color="inherit"
                                                    disabled={this.state.activeStep === 0}
                                                    onClick={this.handleBack}
                                                // sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                                                <Button
                                                    style={{
                                                        padding: '0.5rem 0.5rem',
                                                        backgroundColor: '#23857A',
                                                        borderRadius: '0.5rem',
                                                        borderColor: 'transparent',
                                                        fontFamily: 'Montserrat',
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={this.handleNext}>
                                                    {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>




                    <Modal
                        size="md"
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.addmapvalue} onHide={this.closeModalMapValue}>
                        <Modal.Body>
                            <div className="row">
                                {this.state.addmapvalue === true ?
                                    <div className="col-md-12 col-12 col-sm-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="inputlabel mt-3 mb-3">Lead Delivery Contacts</p>
                                                <input
                                                    type="text"
                                                    value={this.state.delivery_contacts}
                                                    onChange={this.handleChangedata1}
                                                    className="workstackmultiplenotes"
                                                    placeholder="Enter Value"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.delivery_contacts}
                                                </span>

                                            </div>
                                        </div>

                                    </div>
                                    : null}

                            </div>
                            {myObjEntries && myObjEntries.length === 0 ?
                                <div
                                    className="opencampaignheader mt-5"
                                    style={{ textAlign: 'center' }}
                                >no records found</div> :
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                            Lead Delivery Contacts
                                        </div>
                                        <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                            Delete
                                        </div>
                                    </div>

                                    {myObjEntries && myObjEntries.length > 0 && myObjEntries
                                        .map((item, index) => (

                                            <div
                                                className="row rowdata">
                                                <div className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                    {item[0]}
                                                </div>
                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                    <DeleteIcon
                                                        onClick={() => this.DeleteIconData11(item)}
                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                </div>
                                            </div>
                                        ))}

                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                <button
                                    style={{ marginRight: '15px', paddingRight: '15px' }}
                                    onClick={this.closeModalMapValue}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Close</span>
                                </button>

                                <button
                                    onClick={this.addMapValue}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Save</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>



                    <Modal
                        size="md"
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.addmapvalue1} onHide={this.closeModalMapValue}>
                        <Modal.Body>
                            <div className="row">
                                {this.state.addmapvalue1 === true ?
                                    <div className="col-md-12 col-12 col-sm-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="inputlabel mt-3 mb-3">html subject lines</p>
                                                <TextareaAutosize
                                                    minRows={3}
                                                    value={this.state.html_subject_lines}
                                                    onChange={this.handleChangedata2}
                                                    placeholder="Enter Value"
                                                    style={{
                                                        width: '100%',
                                                        fontFamily: 'Montserrat',
                                                        border: '1px solid #E2E2E2',
                                                        borderRadius: '12px',
                                                        backgroundColor: '#F9FBFD'
                                                    }}

                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.html_subject_lines}
                                                </span>

                                            </div>
                                        </div>

                                    </div>
                                    : null}

                            </div>
                            {myObjEntrieshtmlinput && myObjEntrieshtmlinput.length === 0 ?
                                <div
                                    className="opencampaignheader mt-5"
                                    style={{ textAlign: 'center' }}
                                >no records found</div> :
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                            html_subject_lines
                                        </div>
                                        <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                            Delete
                                        </div>
                                    </div>

                                    {myObjEntrieshtmlinput && myObjEntrieshtmlinput.length > 0 && myObjEntrieshtmlinput
                                        .map((item, index) => (

                                            <div
                                                className="row rowdata">
                                                <div className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                    {item[0]}
                                                </div>
                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                    <DeleteIcon
                                                        onClick={() => this.DeleteIconData12(item)}
                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                </div>
                                            </div>
                                        ))}

                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                <button
                                    style={{ marginRight: '15px', paddingRight: '15px' }}
                                    onClick={this.closeModalMapValue}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Close</span>
                                </button>

                                <button
                                    onClick={this.addMapValue1}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Save</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>


                    <Modal
                        size="md"
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.addmapvalue2} onHide={this.closeModalMapValue}>
                        <Modal.Body>
                            <div className="row">
                                {this.state.addmapvalue2 === true ?
                                    <div className="col-md-12 col-12 col-sm-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="inputlabel mt-3 mb-3"> Accepted Ttitle List</p>
                                                <TextareaAutosize
                                                    // aria-label="minimum height"
                                                    minRows={3}
                                                    value={this.state.accepted_title_list}
                                                    onChange={this.handleChangedata3}
                                                    placeholder="Enter Value"
                                                    style={{
                                                        width: '100%',
                                                        fontFamily: 'Montserrat',
                                                        border: '1px solid #E2E2E2',
                                                        borderRadius: '12px',
                                                        backgroundColor: '#F9FBFD'
                                                    }}

                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.accepted_title_list}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    : null}

                            </div>
                            {myObjEntriestitle && myObjEntriestitle.length === 0 ?
                                <div
                                    className="opencampaignheader mt-5"
                                    style={{ textAlign: 'center' }}
                                >no records found</div> :
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                            Accepted Ttitle List
                                        </div>
                                        <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                            Delete
                                        </div>
                                    </div>

                                    {myObjEntriestitle && myObjEntriestitle.length > 0 && myObjEntriestitle
                                        .map((item, index) => (

                                            <div
                                                className="row rowdata">
                                                <div
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                    {item[0]}
                                                </div>
                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                    <DeleteIcon
                                                        onClick={() => this.DeleteIconData13(item)}
                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                </div>
                                            </div>
                                        ))}

                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                <button
                                    style={{ marginRight: '15px', paddingRight: '15px' }}
                                    onClick={this.closeModalMapValue}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Close</span>
                                </button>

                                <button
                                    onClick={this.addMapValue2}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Save</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>


                    <Modal
                        size="md"
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.addmapvalue3} onHide={this.closeModalMapValue}>
                        <Modal.Body>
                            <div className="row">
                                {this.state.addmapvalue3 === true ?
                                    <div className="col-md-12 col-12 col-sm-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="inputlabel mt-3 mb-3"> SIC CODES</p>
                                                <TextareaAutosize
                                                    // aria-label="minimum height"
                                                    minRows={3}
                                                    value={this.state.sic_codes}
                                                    onChange={this.handleChangedata4}
                                                    placeholder="Enter Value"
                                                    style={{
                                                        width: '100%',
                                                        fontFamily: 'Montserrat',
                                                        border: '1px solid #E2E2E2',
                                                        borderRadius: '12px',
                                                        backgroundColor: '#F9FBFD'
                                                    }}

                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.sic_codes}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    : null}

                            </div>
                            {myObjEntriessic_codes && myObjEntriessic_codes.length === 0 ?
                                <div
                                    className="opencampaignheader mt-5"
                                    style={{ textAlign: 'center' }}
                                >no records found</div> :
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                            SIC CODES
                                        </div>
                                        <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                            Delete
                                        </div>
                                    </div>

                                    {myObjEntriessic_codes && myObjEntriessic_codes.length > 0 && myObjEntriessic_codes
                                        .map((item, index) => (

                                            <div
                                                className="row rowdata">
                                                <div
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                    {item[0]}
                                                </div>
                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                    <DeleteIcon
                                                        onClick={() => this.DeleteIconData14(item)}
                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                </div>
                                            </div>
                                        ))}

                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                <button
                                    style={{ marginRight: '15px', paddingRight: '15px' }}
                                    onClick={this.closeModalMapValue}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Close</span>
                                </button>

                                <button
                                    onClick={this.addMapValue3}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Save</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>


                    <Modal
                        size="md"
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.addmapvalue4} onHide={this.closeModalMapValue}>
                        <Modal.Body>
                            <div className="row">
                                {this.state.addmapvalue4 === true ?
                                    <div className="col-md-12 col-12 col-sm-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="inputlabel mt-3 mb-3"> NAICS Codes</p>
                                                <TextareaAutosize
                                                    // aria-label="minimum height"
                                                    minRows={3}
                                                    value={this.state.naics_codes}
                                                    onChange={this.handleChangedata5}
                                                    placeholder="Enter Value"
                                                    style={{
                                                        width: '100%',
                                                        fontFamily: 'Montserrat',
                                                        border: '1px solid #E2E2E2',
                                                        borderRadius: '12px',
                                                        backgroundColor: '#F9FBFD'
                                                    }}

                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.naics_codes}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    : null}

                            </div>
                            {myObjEntriesnaics_codes && myObjEntriesnaics_codes.length === 0 ?
                                <div
                                    className="opencampaignheader mt-5"
                                    style={{ textAlign: 'center' }}
                                >no records found</div> :
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                            NAICS Codes
                                        </div>
                                        <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                            Delete
                                        </div>
                                    </div>

                                    {myObjEntriesnaics_codes && myObjEntriesnaics_codes.length > 0 && myObjEntriesnaics_codes
                                        .map((item, index) => (

                                            <div
                                                className="row rowdata">
                                                <div
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                    {item[0]}
                                                </div>
                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                    <DeleteIcon
                                                        onClick={() => this.DeleteIconData15(item)}
                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                </div>
                                            </div>
                                        ))}

                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                <button
                                    style={{ marginRight: '15px', paddingRight: '15px' }}
                                    onClick={this.closeModalMapValue}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Close</span>
                                </button>

                                <button
                                    onClick={this.addMapValue4}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Save</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>






                    <Modal
                        size="md"
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.addmapvalue5} onHide={this.closeModalMapValue}>
                        <Modal.Body>
                            <div className="row">
                                {this.state.addmapvalue5 === true ?
                                    <div className="col-md-12 col-12 col-sm-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="inputlabel mt-3 mb-3">HTML Creative subject lines</p>

                                                {this.state.counter === 1 &&
                                                    <TextareaAutosize
                                                        // aria-label="minimum height"
                                                        minRows={3}
                                                        value={this.state.creative_subject_lines}
                                                        onChange={this.handleChangedata6}
                                                        placeholder="Enter Value"
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Montserrat',
                                                            border: '1px solid #E2E2E2',
                                                            borderRadius: '12px',
                                                            backgroundColor: '#F9FBFD'
                                                        }}

                                                    />
                                                }


                                                {this.state.counter === 2 &&
                                                    <TextareaAutosize
                                                        // aria-label="minimum height"
                                                        minRows={3}
                                                        value={this.state.creative_subject_lines2}
                                                        onChange={this.handleChangedata7}
                                                        placeholder="Enter Value"
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Montserrat',
                                                            border: '1px solid #E2E2E2',
                                                            borderRadius: '12px',
                                                            backgroundColor: '#F9FBFD'
                                                        }}

                                                    />}


                                                {this.state.counter === 3 &&
                                                    <TextareaAutosize
                                                        // aria-label="minimum height"
                                                        minRows={3}
                                                        value={this.state.creative_subject_lines3}
                                                        onChange={this.handleChangedata8}
                                                        placeholder="Enter Value"
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Montserrat',
                                                            border: '1px solid #E2E2E2',
                                                            borderRadius: '12px',
                                                            backgroundColor: '#F9FBFD'
                                                        }}

                                                    />}

                                                {this.state.counter === 4 &&
                                                    <TextareaAutosize
                                                        // aria-label="minimum height"
                                                        minRows={3}
                                                        value={this.state.creative_subject_lines4}
                                                        onChange={this.handleChangedata9}
                                                        placeholder="Enter Value"
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Montserrat',
                                                            border: '1px solid #E2E2E2',
                                                            borderRadius: '12px',
                                                            backgroundColor: '#F9FBFD'
                                                        }}

                                                    />}

                                                {this.state.counter === 5 &&
                                                    <TextareaAutosize
                                                        // aria-label="minimum height"
                                                        minRows={3}
                                                        value={this.state.creative_subject_lines5}
                                                        onChange={this.handleChangedata10}
                                                        placeholder="Enter Value"
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Montserrat',
                                                            border: '1px solid #E2E2E2',
                                                            borderRadius: '12px',
                                                            backgroundColor: '#F9FBFD'
                                                        }}

                                                    />}
                                            </div>
                                        </div>
                                        {this.state.counter === 1 &&
                                            <>
                                                {myObjSubjectLines && myObjSubjectLines.length === 0 ?
                                                    <div
                                                        className="opencampaignheader mt-5"
                                                        style={{ textAlign: 'center' }}
                                                    >no records found</div> :
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                                                Creative subject lines
                                                            </div>
                                                            <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                                                Delete
                                                            </div>
                                                        </div>

                                                        {myObjSubjectLines && myObjSubjectLines.length > 0 && myObjSubjectLines.map((item, index) => (

                                                            <div
                                                                className="row rowdata">
                                                                <div
                                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                                    {item[0]}
                                                                </div>
                                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                                    <DeleteIcon
                                                                        onClick={() => this.DeleteIconData16(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                }
                                            </>}

                                        {this.state.counter === 2 &&
                                            <>
                                                {myObjSubjectLines1 && myObjSubjectLines1.length === 0 ?
                                                    <div
                                                        className="opencampaignheader mt-5"
                                                        style={{ textAlign: 'center' }}
                                                    >no records found</div> :
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                                                Creative subject lines
                                                            </div>
                                                            <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                                                Delete
                                                            </div>
                                                        </div>

                                                        {myObjSubjectLines1 && myObjSubjectLines1.length > 0 && myObjSubjectLines1.map((item, index) => (

                                                            <div
                                                                className="row rowdata">
                                                                <div
                                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                                    {item[0]}
                                                                </div>
                                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                                    <DeleteIcon
                                                                        onClick={() => this.DeleteIconData16(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                }
                                            </>}

                                        {this.state.counter === 3 &&
                                            <>
                                                {myObjSubjectLines2 && myObjSubjectLines2.length === 0 ?
                                                    <div
                                                        className="opencampaignheader mt-5"
                                                        style={{ textAlign: 'center' }}
                                                    >no records found</div> :
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                                                Creative subject lines
                                                            </div>
                                                            <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                                                Delete
                                                            </div>
                                                        </div>

                                                        {myObjSubjectLines2 && myObjSubjectLines2.length > 0 && myObjSubjectLines2.map((item, index) => (

                                                            <div
                                                                className="row rowdata">
                                                                <div
                                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                                    {item[0]}
                                                                </div>
                                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                                    <DeleteIcon
                                                                        onClick={() => this.DeleteIconData16(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                }
                                            </>}


                                        {this.state.counter === 4 &&
                                            <>
                                                {myObjSubjectLines3 && myObjSubjectLines3.length === 0 ?
                                                    <div
                                                        className="opencampaignheader mt-5"
                                                        style={{ textAlign: 'center' }}
                                                    >no records found</div> :
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                                                Creative subject lines
                                                            </div>
                                                            <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                                                Delete
                                                            </div>
                                                        </div>

                                                        {myObjSubjectLines3 && myObjSubjectLines3.length > 0 && myObjSubjectLines3.map((item, index) => (

                                                            <div
                                                                className="row rowdata">
                                                                <div
                                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                                    {item[0]}
                                                                </div>
                                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                                    <DeleteIcon
                                                                        onClick={() => this.DeleteIconData16(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                }
                                            </>}

                                        {this.state.counter === 5 &&
                                            <>
                                                {myObjSubjectLines4 && myObjSubjectLines4.length === 0 ?
                                                    <div
                                                        className="opencampaignheader mt-5"
                                                        style={{ textAlign: 'center' }}
                                                    >no records found</div> :
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                                                Creative subject lines
                                                            </div>
                                                            <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                                                Delete
                                                            </div>
                                                        </div>

                                                        {myObjSubjectLines4 && myObjSubjectLines4.length > 0 && myObjSubjectLines4.map((item, index) => (

                                                            <div
                                                                className="row rowdata">
                                                                <div
                                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                                    className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                                    {item[0]}
                                                                </div>
                                                                <div className="col-md-2 pt-3 pb-3 opencampaigntext102">

                                                                    <DeleteIcon
                                                                        onClick={() => this.DeleteIconData16(item)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                }
                                            </>}



                                    </div>
                                    : null}

                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                <button
                                    style={{ marginRight: '15px', paddingRight: '15px' }}
                                    onClick={this.closeModalMapValue12}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Close</span>
                                </button>

                                <button
                                    onClick={this.addMapValue5}
                                    className="browsebutton  mt-4"
                                >
                                    <span className="browsebuttontext">Save</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        campaignsetupInsert: userinfo => dispatch(campaignSetUpInsert(userinfo)),
        customerPrefillData: userinfo => dispatch(customerPrefillData(userinfo)),
        userDetails: userinfo => dispatch(userDetails(userinfo)),
        countryList: userinfo => dispatch(countryList(userinfo)),
        stateList: userinfo => dispatch(stateList(userinfo)),
        usersInfo: userinfo => dispatch(usersInfo(userinfo)),
        toolSwap: userinfo => dispatch(toolSwap(userinfo)),
        campaignUpdate: userinfo => dispatch(campaignUpdate(userinfo)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Campaignsetup);

