import React, { Component } from "react";
import "../../styles/Styles.css";
import Dropdown from 'react-dropdown';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from "../../components/Header";
import { BarChart, Bar, LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, Label } from 'recharts';
import { toolList, barData } from '../../actions/Campaignactions';
import { launchtool, toolWaiting } from '../../actions/ManagerActions';
import LeftNavigation from "../../components/LeftNavigation";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';




const data = [
    {
        name: 'Monday',
        students: 50
    },
    {
        name: 'Tuesday',
        students: 60
    },
    {
        name: 'Wednesday',
        students: 70
    },
    {
        name: 'Thursday',
        students: 80
    },
    {
        name: 'Friday',
        students: 90
    },
    {
        name: 'Saturday',
        students: 20
    },
];


class ToolStates extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            showBox: false,
            campaign: false,
            userstate: false,
            toolstate: false,
            campaignList: [],
            tool_list: [],
            hoursList: [],
            launchList: [],
            bardata: [],
            filtersdata: null,
            tabvalue: 'one'
        };
    }


    _onSelectMail = (dateList) => {
        this.setState({ dateList }, () =>
            console.log(`Option selected:`, this.state.dateList)
        );
    };

    handleChange = (event, newValue) => {
        console.log(this.state.filtersdata, "this.state.filtersdata")
        this.setState({ tabvalue: newValue })
        const bardata = {
            tool_id: this.state.filtersdata !== null && this.state.filtersdata.value,
            dateinfo: newValue
        }
        this.props.barData(bardata)

        // setValue(newValue);
    };

    formatAMPM = (date) => {
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        // let date = new Date()
        let formatted_date = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
        // gets the hours
        var hours = date.getHours();
        // gets the month
        var minutes = date.getMinutes();
        // gets AM/PM
        var ampm = hours >= 12 ? 'pm' : 'am';
        // converts hours to 12 hour instead of 24 hour
        hours = hours % 12;
        // converts 0 (midnight) to 12
        hours = hours ? hours : 12; // the hour '0' should be '12'
        // converts minutes to have leading 0
        minutes = minutes < 10 ? '0' + minutes : minutes;
        // the time string
        var time = hours + ':' + minutes + ' ' + ampm;
        return formatted_date + ',' + time
    }

    _onFilter = (filtersdata) => {
        const uinfo = {
            tool_id: filtersdata.value
        }
        const bardata = {
            tool_id: filtersdata.value,
            dateinfo: this.state.tabvalue
        }
        this.props.barData(bardata)
        this.props.launchtool(uinfo)
        this.props.toolwaiting(uinfo)
        this.setState({ filtersdata });
    }


    componentDidMount() {
        this.props.toollist();
    }


    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.toolList &&
            this.props.campaignReducer.toolList !==
            nextProps.campaignReducer.toolList &&
            this.props.campaignReducer.toolListSuccess === true
        ) {
            const userList = [];
            this.props.campaignReducer.toolList && this.props.campaignReducer.toolList.map((item, index) => {
                if (item.tool_name) {
                    userList.push({
                        value: item.tool_id,
                        label: item.tool_name
                    });
                }
                return true;
            })
            this.setState({ tool_list: userList })
        }


        if (
            this.props.managerReducer &&
            this.props.managerReducer.laucnTool &&
            this.props.managerReducer.laucnTool !==
            nextProps.managerReducer.laucnTool &&
            this.props.managerReducer.laucnToolSuccess === true
        ) {
            this.setState({ launchList: this.props.managerReducer.laucnTool })

        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.toolwaiting &&
            this.props.managerReducer.toolwaiting !==
            nextProps.managerReducer.toolwaiting &&
            this.props.managerReducer.toolwaitingSuccess === true
        ) {
            this.setState({ hoursList: this.props.managerReducer.toolwaiting })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.bardata &&
            this.props.campaignReducer.bardata !==
            nextProps.campaignReducer.bardata &&
            this.props.campaignReducer.bardataSuccess === true
        ) {
            console.log(this.props.campaignReducer.bardata, "data")
            this.setState({ bardata: this.props.campaignReducer.bardata })
        }

    }




    render() {
        const { redirectpath, tool_list, hoursList, launchList, bardata } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock1">
                        <LeftNavigation />
                    </div>
                    <div
                        className="col-md-10 header"
                    >
                        <Header />

                        <div className="row filterdiv">
                            <div className="col-md-9 col-sm-12 col-12 mb-3 mt-4">

                            </div>
                            <div className="col-md-3 col-sm-12 col-12 mb-3 mt-4">
                                <Dropdown
                                    options={tool_list}
                                    onChange={this._onFilter}
                                    className='myClassName'
                                    placeholder="Select..." />
                            </div>
                        </div>


                        <div className="row mt-3 maindiv1">
                            <div className="listcontainer1 pl-0 pr-0 pt-3 pb-5">

                                <div style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <span className="not">Number of times used (in hrs)</span><br />
                                            <span className="notblur">as of &nbsp;{this.formatAMPM(new Date())}</span>
                                        </div>
                                        <div className="col-md-8">
                                            <TabContext value={this.state.tabvalue}>
                                                <Box sx={{ borderBottom: 1, borderColor: '#FFFFFF' }}>
                                                    <TabList
                                                        onChange={this.handleChange} aria-label="lab API tabs example">
                                                        <Tab label="This Week" value='one' />
                                                        <Tab label="This Month" value='two' />
                                                    </TabList>
                                                </Box>
                                            </TabContext>
                                        </div>
                                    </div>
                                </div>
                                {/* <TabPanel value={this.state.value === 'one'}> */}
                                <ResponsiveContainer>
                                    <BarChart
                                        width={900}
                                        height={400}
                                        data={bardata}
                                        style={{ paddingBottom: '30px' }}
                                    >
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="10%" stopColor="#9191B7" stopOpacity={1} />
                                                <stop offset="90%" stopColor="#484870" stopOpacity={1} />
                                            </linearGradient>

                                        </defs>
                                        <Bar dataKey={this.state.tabvalue === 'one' ? "time_taken" : this.state.tabvalue === 'two' ? "total_time" : ''} barSize={25} fill="url(#colorUv)" />
                                        <XAxis dataKey={this.state.tabvalue === 'one' ? "dayName" : this.state.tabvalue === 'two' ? "mname" : ''} interval='preserveStartEnd' axisLine={false}
                                            style={{
                                                fontSize: '12px',
                                                color: 'rgba(0, 0, 0, 0.4)',
                                                fontFamily: 'Montserrat',
                                                letterSpacing: '1px'
                                            }}
                                            tickLine={false} />

                                        <YAxis
                                            //    label={{ value: 'Time in Hours', angle: -90, position: 'insideRight', textAnchor: 'middle',verticalAlign: 'center' }}
                                            orientation='right'
                                            interval='preserveStartEnd'
                                            axisLine={false} tickLine={false} />
                                        <Legend verticalAlign="top" height={36} />
                                        <Tooltip />
                                    </BarChart>
                                </ResponsiveContainer>
                                {/* </TabPanel> */}

                            </div>
                            <div
                                style={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <div className="mt-2 pl-0 pr-0 pt-5 pb-5 linecontainer1">

                                    <div style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                                        <span className="strokelinetext pb-2">Average time from tool launch to completion</span>
                                    </div>
                                    <ResponsiveContainer>
                                        <LineChart
                                            width={400}
                                            height={150}
                                            data={launchList}
                                            style={{ padding: '14px' }}
                                        >
                                            <XAxis
                                                style={{
                                                    fontSize: '11px',
                                                    color: 'rgba(0, 0, 0, 0.4)',
                                                    fontFamily: 'Montserrat',
                                                    letterSpacing: '0.035em'
                                                }}
                                                dataKey="noofdays" interval={'preserveStartEnd'} axisLine={false} tickLine={false} />
                                            <YAxis
                                                style={{
                                                    fontSize: '14px',
                                                    color: 'rgba(0, 0, 0, 0.4)',
                                                    fontFamily: 'Montserrat',
                                                }}
                                                // dataKey="students"
                                                interval='preserveStartEnd'
                                                orientation='right' axisLine={false} tickLine={false} />
                                            {/* <Tooltip
                                                contentStyle={{ fontSize: 12 }}
                                                labelStyle={{ fontSize: 12 }}
                                            /> */}
                                            <Line
                                                // isAnimationActive={false}
                                                type="monotone" dot={false} dataKey="time_taken" stroke="#9191B7" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="mt-2 pl-0 pr-0 pt-5 pb-5 linecontainer2">
                                    <div style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)', justifyContent: 'center' }}>
                                        <span className="strokelinetext">Average time spent waiting for users to start the tool</span>

                                    </div>
                                    <ResponsiveContainer>
                                        <LineChart
                                            width={400} height={150} data={hoursList}
                                            style={{ padding: '14px' }}

                                        >
                                            <XAxis
                                                style={{
                                                    fontSize: '11px',
                                                    color: 'rgba(0, 0, 0, 0.4)',
                                                    fontFamily: 'Montserrat',
                                                }}
                                                dataKey="noofdays" interval={'preserveStartEnd'} axisLine={false} tickLine={false} />
                                            <YAxis
                                                style={{
                                                    fontSize: '11px',
                                                    color: 'rgba(0, 0, 0, 0.4)',
                                                    fontFamily: 'Montserrat',
                                                }}
                                                // dataKey="students"
                                                orientation='right' axisLine={false} tickLine={false} />
                                            {/* <Tooltip
                                                contentStyle={{ fontSize: 12 }}
                                                labelStyle={{ fontSize: 12 }}
                                            /> */}
                                            <Line type="monotone" dot={false} dataKey="waiting_time" stroke="#9191B7" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer

    };
}
function mapDispatchToProps(dispatch) {
    return {
        toollist: userinfo => dispatch(toolList(userinfo)),
        launchtool: userinfo => dispatch(launchtool(userinfo)),
        toolwaiting: userinfo => dispatch(toolWaiting(userinfo)),
        barData: userinfo => dispatch(barData(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToolStates);

