import React, { Component } from "react";
// import { Auth } from "aws-amplify";
import { Link, Redirect } from 'react-router-dom';
import '../styles/LoginStyles.css';
import linkedin from '../assets/images/linkedin.png';
import logo from '../assets/images/logo.png';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { arcManlogin } from '../actions/ManagerActions';
import { connect } from 'react-redux';
import axios from 'axios';



const updateByPropertyName = (propertyName, value) => () => ({
    [propertyName]: value
});

const INITIAL_STATE = {
    email: "",
    password: "",
    // error: null
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...INITIAL_STATE,
            redirectpath: '',
            isAuthenticated: false,
            showPassword: false,
            errors: {}
        };
    }

    changeState(type, event) {
        const { changeAuthState } = this.props;
        changeAuthState(type, event);
    }

    setAuthStatus = authenticated => {
        this.setState({ isAuthenticated: authenticated });
    }


    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // handlePasswordChange =  (event) => {
    //     this.setState({ password: event.target.value });
    // };

    validateLogin() {
        const errors = {};
        let formIsValid = true;
        if (!this.state.email) {
            errors.email = 'Please enter email';
            formIsValid = false;
        }

        if (!this.state.password) {
            errors.password = 'Please enter password';
            formIsValid = false;
        }
        this.setState({ errors });
        return formIsValid;
    }

    onSubmit = async (event) => {
        if (this.validateLogin()) {
            const info = {
                email: this.state.email,
                password: this.state.password
            }
            this.props.workstackList(info)
            // try {
            //     console.log(email, password, "ps")
            //     const user = await Auth.signIn(email, password);
            //     console.log(user.attributes.profile, "userrsssss")

            //     if (user.attributes && user.attributes.profile && user.attributes.profile === 'Manager') {
            //         this.setState({ redirectpath: '/opencampaign' })
            //     }

            //     else if (user.attributes && user.attributes.profile && user.attributes.profile === 'Admin') {
            //         this.setState({ redirectpath: '/campaignslist' })
            //     }
            //     else if (user.attributes && user.attributes.profile && user.attributes.profile === 'Producer') {
            //         this.setState({ redirectpath: '/producerworkstack' })
            //     }
            //     else {
            //         this.setState({ redirectpath: '/login' })
            //     }



            // } catch (err) {
            //     console.log('error in signing', err);
            //     const errors = {};
            //     errors.password = 'Your authentication information is incorrect. Please try again.';
            //     this.setState({ errors })

            // }
        }
    };

    componentDidMount() {

        // this.props.linkedLogin()
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.managerReducer &&
            this.props.managerReducer.arcMan &&
            this.props.managerReducer.arcMan !==
            nextProps.managerReducer.arcMan &&
            this.props.managerReducer.arcManSuccess === true
        ) {

            if (this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes && this.props.managerReducer.arcMan.attributes.profile === 'Manager') {
                this.setState({ redirectpath: '/opencampaign' })
            }
            else if (this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes && this.props.managerReducer.arcMan.attributes.profile === 'Admin') {
                this.setState({ redirectpath: '/campaignslist' })
            }
            else if (this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes && this.props.managerReducer.arcMan.attributes.profile === 'Producer') {
                this.setState({ redirectpath: '/producerworkstack' })
            }
            else {
                this.setState({ redirectpath: '/' })
            }

        }
        if (
            this.props.managerReducer &&
            this.props.managerReducer.arcMan &&
            this.props.managerReducer.arcMan !==
            nextProps.managerReducer.arcMan &&
            this.props.managerReducer.arcManSuccess === false
        ) {
            // console.log(this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.message, "hi false is coming")
            const errors = {};
            errors.password = "Your username or password is incorrect. Please try again."
            this.setState({ errors })
        }
    }

    linkedIn = () => {
        axios.get(`https://61aonvy5u7.execute-api.us-east-1.amazonaws.com/dev/login`)
            .then(res => {
                console.log(res,"hi inside linkedin")
                window.location.href = res.data
                // axios.get(`http://localhost:8080/users`)
                //     .then(res => {
                //         console.log(res.data && res.data.UserAttributes, "resssssssssssssss")
                //         const userdetails1 = JSON.stringify(res.data && res.data.UserAttributes);
                //         localStorage.setItem("userdetails1", userdetails1)
                //     })
            })

        // this.props.linkedLogin()  
    }

    render() {
        const { email, password, redirectpath, errors } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}
                        className="col-md-6 col-sm-6 col-12 leftpanel">
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12">
                                <p className="lefttext">Hello, Welcome to Arc Man!</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12 col-12 col-sm-12">
                                <p className="audiencetext">Audience Response Curation Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 rightpanel">
                        <div className="row mt-5 text-center">
                            <div className="col-md-12 col-12 col-sm-12">
                                <img
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">

                            </div>
                            <div className="col-md-6">
                                <label className="emailtext mt-4 mb-2">E-mail</label>
                                <input
                                    type="text"
                                    id="inputID"
                                    value={email}
                                    onChange={event =>
                                        this.setState(updateByPropertyName("email", event.target.value))
                                    }
                                    className="emailinput"
                                    placeholder="Example@gmail.com"
                                />
                                <span className="errors mt-2">
                                    {errors && errors.email}
                                </span>

                                <label className="emailtext mt-4 mb-2">Password</label>
                                <div className="input-icons">
                                    <input
                                        type={this.state.showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={event =>
                                            this.setState(
                                                updateByPropertyName("password", event.target.value)
                                            )
                                        }
                                        className="emailinput"
                                        placeholder="Enter Password"
                                    />
                                    <i
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        className="toggleicon"> {this.state.showPassword ? <Visibility /> : <VisibilityOff />}</i>
                                </div>
                                <span className="errors mt-2">
                                    {errors && errors.password}
                                </span>

                                <div
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    className="col-md-12 mt-3 mb-3">
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to="/signup">
                                        <span className="forgotpasswords"> Sign up</span>
                                    </Link>
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to="/forgotpassword">
                                        <span className="forgotpasswords">Forgot Password?</span>
                                    </Link>
                                </div>

                                <button
                                    onClick={this.onSubmit}
                                    type="button"
                                    className="loginbutton mt-3"
                                >
                                    <span className="logintext">Login</span>
                                </button>
                                <p style={{ textAlign: 'right', color: 'red', fontSize: '12px', marginTop: '10px' }}>{this.state.error ? this.state.error.message : null}</p>

                                <div
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                    className="mt-4">
                                    <hr
                                        style={{ width: '50%' }}
                                    />
                                    <span className="ortext pl-2">&nbsp;OR&nbsp;</span>
                                    <hr
                                        style={{ width: '50%' }}
                                    />
                                </div>

                                <button
                                    onClick={this.linkedIn}
                                    type="button" className="linkedinbutton mt-4">
                                    <div className="row">
                                        <div className="col-md-2 col-sm-2 col-2">
                                            <img
                                                src={linkedin}
                                                style={{ height: '22px', width: '22px' }}
                                                alt="linkedin"
                                            />
                                        </div>
                                        <div className="col-md-8 col-sm-8 col-8">
                                            <span className="linkedintext">Signin with LinkedIn</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        workstackList: userinfo => dispatch(arcManlogin(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

// export default Login;
