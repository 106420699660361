import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import moment from "moment";
import Header from "../../../components/Header";
import { saveAs } from 'file-saver';
import { updateTitleBucket, listTitleBucket, deleteTitleBucket, appendTitleBucket } from '../../../actions/Campaignactions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import NLPNav from "../../../components/NLPNav";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import "react-responsive-modal/styles.css";
import { Modal } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AWS from 'aws-sdk';




//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGZIW5Y5ZF',
    secretAccessKey: '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'
})

//uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARDHV752W5',
//     secretAccessKey: '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'
// })

//dev
// const S3_BUCKET = 'solpub-campaign-workflow';

//uat
const S3_BUCKET = 'leadshaper';

//dev
// const S3_BUCKET11 = 'titlematch';

//uat
const S3_BUCKET11 = 'leadshaper';

const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900;


const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
    signatureVersion: 'v4',
})


const myBucket11 = new AWS.S3({
    params: { Bucket: S3_BUCKET11 },
    region: REGION,
    signatureVersion: 'v4',
})


toast.configure()

class ListofTitleBuckets extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            input_files: '',
            old_files: '',
            new_files: '',
            title_bucket_name: '',
            campaignList: [],
            fields: {},
            fields1: {},
            noOfPages: [],
            itemsPerPage: 10,
            totalitems: '',
            page: 1,
            open: false,
            show: false
        };
    }



    handleChange = (event, value) => {
        this.setState({ page: value })
    };


    //status


    // generatePreSignedPutUrl = (item) => {
    //     let data = item + '_part00000.csv'
    //     myBucket.getSignedUrl('getObject', {
    //         //dev
    //         Key: `address_std_output/${data}`,
    //         //uat
    //         // Key: `datashaperOutput/${data}`,
    //         Expires: URL_EXPIRATION_TIME
    //     }, (err, url) => {
    //         console.log(err, "errrrr in output file download")
    //         saveAs(url)
    //         return url
    //     });
    // }


    generatePreSignedPutUrl = (fileName, fileType) => {
        myBucket11.getSignedUrl('putObject', {
            Key: fileName,
            ContentType: fileType,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            fetch(url, {
                method: 'PUT',
                body: this.state.input_files[0]
            }).then((res) => {
                console.log(res, "resssssssssssss")
                // DO WHATEVER YOU WANT
            })
            return url // API Response Here
        });
    }





    TitleBucketFile = (e) => {
        this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type)
        this.setState({ input_files: e.target.files })
    }


    appendBucketFile = (e) => {
        this.generatePreSignedPutUrl(e.target.files[0].name, e.target.files[0].type)
        this.setState({ new_files: e.target.files })
    }


    generatePreSignedPutUrl11 = (item) => {
        myBucket11.getSignedUrl('getObject', {
            //dev
            Key: item,
            //uat
            // Key: `datashaperInput/${item}`,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            saveAs(url)
            return url
        });
    }






    //input file
    // downLoadOutputfile = async (item) => {
    //     if (item !== null) {
    //         this.generatePreSignedPutUrl(item)

    //     }

    // }

    downLoadInputfile = (item) => {
        if (item !== null && item !== undefined && item !== '') {
            this.generatePreSignedPutUrl11(item)

        }

    }


    delete = (item) => {

        const titlebucket = {
            titlebucket: item.titlebucket
        }
        console.log(titlebucket, "itemm")
        confirmAlert({
            title: 'Confirm to delete',
            message: "Are you sure you want to delete selected info? Press 'Yes' to continue.",
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.deleteTitleBucket(titlebucket)
                },
                {
                    label: 'Cancel',
                    onClick: () => ''
                }
            ]
        });
    };


    notify = () => {
        toast.success('Successfully deleted the NLP bucket', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }


    notify7 = () => {
        toast.success('Successfully added the new titles to the existing NLP bucket', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    notify5 = () => {
        toast.success('Successfully updated the NLP bucket', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }



    submit = (item) => {
        this.setState({ redirectpath: `/createtitlebucket/${item}` })
    };



    openModal = (item) => {
        let array = []
        const object = {
            name: item.titlefile
        };

        array.push(object);
        this.setState({ open: true, input_files: array, title_bucket_name: item.titlebucket });
    };

    append = (item) => {
        this.setState({ show: true, old_files: item.titlefile, title_bucket_name: item.titlebucket });
    }

    async componentDidMount() {
        this.props.listTitleBucket();
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.listTitleBucket &&
            this.props.campaignReducer.listTitleBucket !==
            nextProps.campaignReducer.listTitleBucket &&
            this.props.campaignReducer.listTitleBucketSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({ campaignList: this.props.campaignReducer.listTitleBucket, noOfPages: Math.ceil(this.props.campaignReducer.listTitleBucket.length / itemsPerPage), totalitems: this.props.campaignReducer.listTitleBucket.length })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.deletebucket &&
            this.props.campaignReducer.deletebucket !==
            nextProps.campaignReducer.deletebucket &&
            this.props.campaignReducer.deletebucketSuccess === true
        ) {
            this.notify()
            this.props.listTitleBucket();
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.updatebucket &&
            this.props.campaignReducer.updatebucket !==
            nextProps.campaignReducer.updatebucket &&
            this.props.campaignReducer.updatebucketSuccess === true
        ) {
            this.notify5()
            this.setState({ open: false, input_files: '' })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.appendtitle &&
            this.props.campaignReducer.appendtitle !==
            nextProps.campaignReducer.appendtitle &&
            this.props.campaignReducer.appendtitleSuccess === true
        ) {
            this.notify7()
            this.setState({ show: false, new_files: '' })
        }

    }

    closeModalMapValue = () => {
        this.setState({ open: false, show: false })
    }

    updateBucket = () => {
        const userinfo = {
            titlefile: this.state.input_files[0].name,
            titlebucket: this.state.title_bucket_name,
        };
        this.props.updateTitleBucket(userinfo);

    };

    appendBucket = () => {
        const userinfo = {
            // titlefile: this.state.old_files,
            titlebucket: this.state.old_files,
            // bucket_name: 'titlematch',
            bucket_name: 'leadshaper',

            appending_file: this.state.new_files[0].name
        }
        console.log(userinfo, "userinfo")
        this.props.appendTitleBucket(userinfo);

    };




    render() {
        const { redirectpath, campaignList, fields, fields1, errors, noOfPages, page, itemsPerPage, totalitems } = this.state;

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: fields.campaign_id, status: this.state.functioncodes && this.state.functioncodes.value, fields1 } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NLPNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChange}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>



                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">


                                <div className="mt-5">
                                    <div className="scroller mt-4">
                                        {campaignList && campaignList.length === 0 ?
                                            <div
                                                className="opencampaignheader mt-5"
                                                style={{ textAlign: 'center' }}
                                            >no records found</div> :
                                            <table className="table">
                                                <thead>
                                                    <tr className="stickyheader">
                                                        <th className="opencampaignheader">Input File</th>
                                                        <th className="opencampaignheader">NLP Bucket</th>
                                                        <th className="opencampaignheader">Created Date</th>
                                                        <th className="opencampaignheader"> Update</th>
                                                        <th className="opencampaignheader"> Delete</th>
                                                        <th className="opencampaignheader"> Append</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {campaignList && campaignList.length > 0 && campaignList
                                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                            <tr key={index}>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadInputfile(item.titlefile)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    {item.titlebucket}
                                                                </td>
                                                                <td className="opencampaigntext">{moment(item.create_at).format('MM-DD-YYYY')}</td>

                                                                <td className="opencampaigntext">
                                                                    <EditIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.openModal(item)}
                                                                    />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <DeleteIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.delete(item)}
                                                                    />

                                                                </td>
                                                                <td className="opencampaigntext">
                                                                    <AddCircleOutlineIcon
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }}
                                                                        onClick={() => this.append(item)}
                                                                    />

                                                                </td>
                                                                {/* AddCircleOutlineIcon */}

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <Modal
                    size="md"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.open} onHide={this.closeModalMapValue}>
                    <Modal.Body>
                        <div>
                            <label className="inputlabel  mt-3 mb-3">Title Bucket File</label>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label htmlFor="chooseInputFile" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="col-md-8 col-sm-8 col-8 DatashaperinputfileContainer">
                                        {this.state.input_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.input_files[0].name

                                        }
                                        <input
                                            className="Field"
                                            id="chooseInputFile"
                                            onChange={this.TitleBucketFile}
                                            accept=".csv"
                                            type="file"
                                            multiple
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-4 browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                        <span className="browsebutton">
                                            <span className="browsebuttontext">Browse</span>
                                        </span>
                                    </div>
                                </label>
                            </div>
                            <span className="errors mt-2">
                                {errors && errors.input_files}
                            </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                style={{ marginRight: '15px', paddingRight: '15px' }}
                                onClick={this.closeModalMapValue}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Close</span>
                            </button>

                            <button
                                onClick={this.updateBucket}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Save</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>




                <Modal
                    size="md"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show} onHide={this.closeModalMapValue}>
                    <Modal.Body>
                        <div>
                            <label className="inputlabel  mt-3 mb-3">Title Bucket File</label>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label htmlFor="chooseInputFileAppend" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="col-md-8 col-sm-8 col-8 DatashaperinputfileContainer">
                                        {this.state.new_files === '' ? <span className="inputfileplaceholder">Select csv only</span> : this.state.new_files[0].name

                                        }
                                        <input
                                            className="Field"
                                            id="chooseInputFileAppend"
                                            onChange={this.appendBucketFile}
                                            accept=".csv"
                                            type="file"
                                            multiple
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-4 browsecontainer" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }} >
                                        <span className="browsebutton">
                                            <span className="browsebuttontext">Browse</span>
                                        </span>
                                    </div>
                                </label>
                            </div>
                            <span className="errors mt-2">
                                {errors && errors.new_files}
                            </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                            <button
                                style={{ marginRight: '15px', paddingRight: '15px' }}
                                onClick={this.closeModalMapValue}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Close</span>
                            </button>

                            <button
                                onClick={this.appendBucket}
                                className="browsebutton  mt-4"
                            >
                                <span className="browsebuttontext">Save</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        deleteTitleBucket: userinfo => dispatch(deleteTitleBucket(userinfo)),
        listTitleBucket: userinfo => dispatch(listTitleBucket(userinfo)),
        updateTitleBucket: userinfo => dispatch(updateTitleBucket(userinfo)),
        appendTitleBucket: userinfo => dispatch(appendTitleBucket(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListofTitleBuckets);

