import { combineReducers } from 'redux';
import campaignReducer from './campaignReducer';
import managerReducer from './managerReducer';
import customerReducer from './customerReducer'



const appReducer = combineReducers({
    campaignReducer,
    managerReducer,
    customerReducer
});

export default appReducer;