import { SERVERURL } from "../config1"
import axios from "axios";



import {
    CUSTOMERS_LIST_LOADING,
    CUSTOMERS_LIST_SUCCESS,
    CUSTOMERS_LIST_FAILURE,

    CUSTOMERS_DELETE_LOADING,
    CUSTOMERS_DELETE_SUCCESS,
    CUSTOMERS_DELETE_FAILURE,

    CUSTOMERS_EDIT_LOADING,
    CUSTOMERS_EDIT_SUCCESS,
    CUSTOMERS_EDIT_FAILURE,

    CUSTOMERS_DISPLAY_DATA_LOADING,
    CUSTOMERS_DISPLAY_DATA_SUCCESS,
    CUSTOMERS_DISPLAY_DATA_FAILURE

} from "../actions/constants";

//to prefill customer data
export function customersdisplatDataLoading() {
    return {
        type: CUSTOMERS_DISPLAY_DATA_LOADING
    };
}
export function customersdisplatDataSuccess(payload) {
    return {
        type: CUSTOMERS_DISPLAY_DATA_SUCCESS,
        payload: payload
    };
}
export function customersdisplatDataFailure(payload) {
    return {
        type: CUSTOMERS_DISPLAY_DATA_FAILURE,
        payload: payload
    };
}


export function customersdisplatData(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(customersdisplatDataLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}customersdisplay`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/customersdisplay",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(customersdisplatDataSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(customersdisplatDataFailure(error));
            });
    };
}


export function customersListLoading() {
    return {
        type: CUSTOMERS_LIST_LOADING
    };
}
export function customersListSuccess(payload) {
    return {
        type: CUSTOMERS_LIST_SUCCESS,
        payload: payload
    };
}
export function customersListFailure(payload) {
    return {
        type: CUSTOMERS_LIST_FAILURE,
        payload: payload
    };
}


export function customersList(userinfo) {
    const data = userinfo;
    const Username = 'admin'
    const Password = 'secret'

    // const token = Buffer.from(`${Username}:${Password}`, 'utf8').toString('base64')
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/customerslist',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(customersListLoading());
        axios({
            // config
            method: "POST",
            url: `${SERVERURL}customerslist`,
            // url: "https://2chheh5do8.execute-api.us-east-1.amazonaws.com/dev/customerslist",
            // headers: {
            //     'Authorization': `Basic ${token}`
            // },
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(customersListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(customersListFailure(error));
            });
    };
}


export function customerDeleteLoading() {
    return {
        type: CUSTOMERS_DELETE_LOADING
    };
}
export function customerDeleteSuccess(payload) {
    return {
        type: CUSTOMERS_DELETE_SUCCESS,
        payload: payload
    };
}
export function customerDeleteFailure(payload) {
    return {
        type: CUSTOMERS_DELETE_FAILURE,
        payload: payload
    };
}


export function customerDelete(userinfo) {
    const data = {
        customer_setup_id: userinfo
    }
    return dispatch => {
        dispatch(customerDeleteLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}customerdelete`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/customerdelete",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(customerDeleteSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(customerDeleteFailure(error));
            });
    };
}


export function customersEditLoading() {
    return {
        type: CUSTOMERS_EDIT_LOADING
    };
}
export function customersEditSuccess(payload) {
    return {
        type: CUSTOMERS_EDIT_SUCCESS,
        payload: payload
    };
}
export function customersEditFailure(payload) {
    return {
        type: CUSTOMERS_EDIT_FAILURE,
        payload: payload
    };
}


export function customersEdit(userinfo) {
    console.log(userinfo, "for update actionn")
    const data = userinfo;
    return dispatch => {
        dispatch(customersEditLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}customersedit`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/customersedit",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(customersEditSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(customersEditFailure(error));
            });
    };
}











