import React, { Component } from "react";
import "../../styles/Styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { CustomerSetUpInsert } from "../../actions/Campaignactions";
import { customersdisplatData, customersEdit } from '../../actions/Customeractions';
import Header from "../../components/Header";
// import socketIOClient from "socket.io-client";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNavigation from "../../components/LeftNavigation";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';



const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));


const payment_type = [
    { value: 'Cheque', label: 'Cheque' },
    { value: 'ACH', label: 'ACH' },
    { value: 'Bankwire', label: 'Bankwire' },
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class CustomerSetUp extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            payment_methods: [],
            fields: {},
            errors: {},
            phone: '',
            customernotification: ''
        };
    }



    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    _onselectPaymentMethods = (event) => {
        this.setState({ payment_methods: event.target.value })
    }


    // getData = (params) => {
    //     this.setState({ customernotification: params })
    // }

    async componentDidMount() {
        if (this.props.match.params.id) {
            const data = {
                customer_setup_id: this.props.match.params.id
            }

            this.props.customersdata(data)
        }

        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.customerSetUpInsert &&
            this.props.campaignReducer.customerSetUpInsert !==
            nextProps.campaignReducer.customerSetUpInsert &&
            this.props.campaignReducer.customerSetUpInsertSuccess === true
        ) {
            this.setState({ redirectpath: '/acustomers' })
            const userinfo = {
                sub: this.state.sub,
                id: this.props.campaignReducer.customerSetUpInsert.insertId,
                _id: 1,

            }
            console.log(userinfo)
            // socket.emit("get_data", userinfo);

            // const userdata = {
            //     sub: this.props.auth.user &&
            //         this.props.auth.user.attributes &&
            //         this.props.auth.user.attributes.sub,
            //     id: this.props.campaignReducer.customerSetUpInsert.insertId,
            //     _id: 1
            // }
            // console.log(this.props.auth.user &&
            //     this.props.auth.user.attributes &&
            //     this.props.auth.user.attributes.sub, "udata")

        }

        if (
            this.props.customerReducer &&
            this.props.customerReducer.customersdisplay &&
            this.props.customerReducer.customersdisplay !==
            nextProps.customerReducer.customersdisplay &&
            this.props.customerReducer.customersdisplaySuccess === true
        ) {
            let payment_methods = this.state.payment_methods
            payment_methods = this.props.customerReducer.customersdisplay && this.props.customerReducer.customersdisplay[0].payment_methods
            this.setState({ fields: this.props.customerReducer.customersdisplay && this.props.customerReducer.customersdisplay[0], payment_methods: payment_methods })

        }

        if (
            this.props.customerReducer &&
            this.props.customerReducer.customersedit &&
            this.props.customerReducer.customersedit !==
            nextProps.customerReducer.customersedit &&
            this.props.customerReducer.customerseditSuccess === true
        ) {
            this.setState({ redirectpath: '/acustomers' })
        }
    }


    validateCampaign() {
        const errors = {};
        const { fields } = this.state;
        const reg = /^(?:\d{10}|\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$)$/;
        const strings = /[a-zA-Z]/;
        const city = /^[a-zA-Z][a-zA-Z\s-]+[a-zA-Z]$/;

        let formIsValid = true;
        if (!fields.customer_name) {
            errors.customer_name = 'Please enter customer name';
            formIsValid = false;
        }
        if (fields.customer_name && fields.customer_name.length < 3) {
            errors.customer_name = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.customer_name && fields.customer_name.length > 15) {
            errors.customer_name = 'Campaign name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.customer_name && !strings.test(fields.customer_name)) {
            errors.customer_name = 'Please enter characters only';
            formIsValid = false;
        }
        if (!fields.contact_name) {
            errors.contact_name = 'Please enter contact name';
            formIsValid = false;
        }
        if (fields.contact_name && fields.contact_name.length < 3) {
            errors.contact_name = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.contact_name && fields.contact_name.length > 15) {
            errors.contact_name = 'Contact name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.contact_name && !strings.test(fields.contact_name)) {
            errors.contact_name = 'Please enter characters only';
            formIsValid = false;
        }

        if (!fields.mobile_no) {
            errors.mobile_no = 'Please enter contact number';
            formIsValid = false;
        }

        if (!fields.email) {
            errors.email = 'Please enter email';
            formIsValid = false;
        }
        if (fields.email && !reg.test(fields.email)) {
            errors.email = 'Please enter valid email';
            formIsValid = false;
        }

        if (!fields.street) {
            errors.street = 'Please enter street name';
            formIsValid = false;
        }
        if (fields.street && fields.street.length > 25) {
            errors.street = 'street should be 15 characters only';
            formIsValid = false;
        }

        if (!fields.city) {
            errors.city = 'Please enter city name';
            formIsValid = false;
        }
        if (fields.city && fields.city.length > 15) {
            errors.city = 'city should be 15 characters only';
            formIsValid = false;
        }
        if (fields.city && !city.test(fields.city)) {
            errors.city = 'Please enter valid city';
            formIsValid = false;
        }

        if (!fields.state) {
            errors.state = 'Please enter state name';
            formIsValid = false;
        }
        if (fields.state && fields.state.length > 15) {
            errors.state = 'state should be 15 characters only';
            formIsValid = false;
        }

        if (!fields.country) {
            errors.country = 'Please enter country name';
            formIsValid = false;
        }
        if (fields.country && fields.country.length > 15) {
            errors.country = 'country should be 15 characters only';
            formIsValid = false;
        }

        if (this.state.payment_methods && this.state.payment_methods.length === 0) {
            errors.payment_methods = 'Please select payment type';
            formIsValid = false;
        }


        this.setState({ errors });
        return formIsValid;
    }

    handleChangePhone = (phone) => {
        const fields = this.state.fields;
        fields['mobile_no'] = phone;
        this.setState({ fields });
        // console.log(phone, "pppppppppp")
    }

    //customersetup insert
    customerSetUpInsert = () => {
        console.log(this.state.phone, "matchiddd")
        if (this.validateCampaign()) {
            const userinfo = {
                customer_name: this.state.fields.customer_name,
                contact_name: this.state.fields.contact_name,
                mobile_no: this.state.fields.mobile_no,
                email: this.state.fields.email,
                payment_methods: this.state.payment_methods,
                street: this.state.fields.street,
                city: this.state.fields.city,
                state: this.state.fields.state,
                country: this.state.fields.country
            };

            if (this.props.match.params && this.props.match.params.id !== undefined) {
                const data = {
                    customer_name: this.state.fields.customer_name,
                    contact_name: this.state.fields.contact_name,
                    mobile_no: this.state.fields.mobile_no,
                    email: this.state.fields.email,
                    payment_methods: this.state.payment_methods,
                    street: this.state.fields.street,
                    city: this.state.fields.city,
                    state: this.state.fields.state,
                    country: this.state.fields.country,
                    customer_setup_id: this.props.match.params.id
                }
                this.props.customersEdit(data)
            }
            else {
                this.props.customerInsert(userinfo);

            }

        }

    };
    render() {
        const { redirectpath, fields, errors } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div
                        style={{ height: '200vh' }}
                        className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <LeftNavigation />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Customer Setup</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="listcontainercustomer">
                                    {/* <Scrollbars
                                        style={{ width: '100%', margin: 0 }}
                                    > */}
                                    <div
                                        className="row container">
                                        <div className="col-md-12 col-12 col-sm-12">
                                            <BootstrapTooltip title="Enter you customer name it's mandatory to create campaign" placement="top">
                                                <p className="campaignlabel">Customer Name</p>
                                            </BootstrapTooltip>
                                            {/* <p className="campaignlabel">Customer Name</p> */}
                                            <input
                                                type="text"
                                                value={fields.customer_name}
                                                onChange={this.handleChange.bind(this, "customer_name")}
                                                className="campaigntext"
                                                placeholder="Enter customer name"
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.customer_name}
                                            </span>

                                            <BootstrapTooltip title="Enter you contact name it's mandatory for customer creation" placement="top">
                                                <p className="campaignlabel pt-4">Contact Name</p>
                                            </BootstrapTooltip>
                                            <input
                                                type="text"
                                                value={fields.contact_name}
                                                onChange={this.handleChange.bind(this, "contact_name")}
                                                className="campaigntext"
                                                placeholder="Enter contact name"
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.contact_name}
                                            </span>
                                            <BootstrapTooltip title="Enter you contact number it's mandatory for customer creation" placement="top">
                                                <p className="campaignlabel pt-4">Contact Number</p>
                                            </BootstrapTooltip>

                                            <PhoneInput
                                                country={'us'}
                                                value={fields.mobile_no}
                                                enableSearch={true}
                                                onChange={(phone) => this.handleChangePhone(phone)}
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.mobile_no}
                                            </span>
                                            <BootstrapTooltip title="Enter your email it's mandatory for customer creation.........." placement="top">
                                                <p className="campaignlabel pt-4">Email</p>
                                            </BootstrapTooltip>
                                            <input
                                                type="text"
                                                value={fields.email}
                                                onChange={this.handleChange.bind(this, "email")}
                                                className="campaigntext"
                                                placeholder="Enter email"
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.email}
                                            </span>
                                            <BootstrapTooltip title="Enter your city Street it's mandatory for customer creation........." placement="top">
                                                <p className="campaignlabel pt-4">Street</p>
                                            </BootstrapTooltip>
                                            <input
                                                type="text"
                                                value={fields.street}
                                                onChange={this.handleChange.bind(this, "street")}
                                                className="campaigntext ml-5"
                                                placeholder="Enter street"
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.street}
                                            </span>
                                            <BootstrapTooltip title="Enter your city name it's mandatory for customer creation........." placement="top">
                                                <p className="campaignlabel pt-4">City</p>
                                            </BootstrapTooltip>
                                            <input
                                                type="text"
                                                value={fields.city}
                                                onChange={this.handleChange.bind(this, "city")}
                                                className="campaigntext ml-5"
                                                placeholder="Enter city"
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.city}
                                            </span>
                                            <BootstrapTooltip title="Enter your state name it's mandatory for customer creation........." placement="top">
                                                <p className="campaignlabel pt-4">State</p>
                                            </BootstrapTooltip>
                                            <input
                                                type="text"
                                                value={fields.state}
                                                onChange={this.handleChange.bind(this, "state")}
                                                className="campaigntext ml-5"
                                                placeholder="Enter state"
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.state}
                                            </span>
                                            <BootstrapTooltip title="Enter your country name it's mandatory for customer creation........." placement="top">
                                                <p className="campaignlabel pt-4">Country</p>
                                            </BootstrapTooltip>
                                            <input
                                                type="text"
                                                value={fields.country}
                                                onChange={this.handleChange.bind(this, "country")}
                                                className="campaigntext ml-5"
                                                placeholder="Enter country name"
                                            />
                                            <span className="errors mt-2">
                                                {errors && errors.country}
                                            </span>

                                            <div className="col-md-12 col-sm-12 col-12">

                                                <BootstrapTooltip title="Select payment method which one is flexible for you it's mandatory for customer creation........." placement="top">
                                                    <p className="campaignlabel pt-4 pb-1">Payment Methods</p>
                                                </BootstrapTooltip>
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        displayEmpty
                                                        value={this.state.payment_methods}
                                                        onChange={this._onselectPaymentMethods}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        className='select'

                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select Payment methods...</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {payment_type.map((name, index) => (
                                                            <MenuItem value={name.value}>{name.label}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                                                {/* <FormControl style={{ width: '100%' }} size="small">
                                                    <Select
                                                        displayEmptys
                                                        value={this.state.payment_methods}
                                                        onChange={this._onselectPaymentMethods}
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return <span>Select managment levels....</span>;
                                                            }

                                                            return selected;
                                                        }}
                                                    >
                                                        {payment_type.map((name) => (
                                                            <MenuItem value={name.label}>{name.label}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl> */}

                                                <span className="errors mt-2">
                                                    {errors && errors.payment_methods}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* </Scrollbars> */}
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 mb-5 d-md-flex justify-content-end">
                                <button
                                    onClick={this.customerSetUpInsert}
                                    className="createbuttonsetup  mt-5"
                                >
                                    <span className="createtext">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        customerReducer: state.customerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        customerInsert: userinfo => dispatch(CustomerSetUpInsert(userinfo)),
        customersdata: userinfo => dispatch(customersdisplatData(userinfo)),
        customersEdit: userinfo => dispatch(customersEdit(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerSetUp);


