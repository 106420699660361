import React, { Component } from 'react';
import invoice from "../assets/images/invoice.png";
import deadline11 from "../assets/images/deadline11.png";
import portfolio  from "../assets/images/portfolio.png";
import deadlines from "../assets/images/deadlines.png";
import emailes from "../assets/images/emailes.png";
import line from "../assets/images/line.svg";
import logo from "../assets/images/logo.png";
import LogoutIcon from '@mui/icons-material/Logout';
import { Redirect, Link } from 'react-router-dom';
import "../styles/Styles.css"
import { Auth } from 'aws-amplify';


export default class LeftNavigation2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectpath: ''
        }
    }

    handleLogOut = async () => {
        try {
            await Auth.signOut({ global: true });
            await 
            
            await localStorage.removeItem('linkedinauth')
            this.setState({ redirectpath: '/' })
        } catch (err) {
            console.log(err, 'error signing out...');
        }
    };



    render() {
        const { redirectpath } = this.state;
        return (
            <div>
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div
                    className="row"
                    style={{ padding: "27px 0" }}
                >
                    <img
                        src={logo}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />
                    <img
                        src={line}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />

                </div>
                <ul className="nav row">
                    <li
                        id={window.location.pathname === '/opencampaign' ? 'selectednav' : ''}
                    >
                        <Link
                            to="/opencampaign"
                            style={{
                                color: "#ffffff",
                                fontSize: "16px",
                                lineHeight: "24px",
                                display: "inline-flex",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={emailes}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="Open Campaigns"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>Open Campaigns</span>
                        </Link>
                    </li>

                    <li
                        id={window.location.pathname === '/hot' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/hot"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={deadlines}
                                    style={{ height: "20px", width: "20px" }}
                                    alt=" What's Hot"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>What's Hot</span>
                        </Link>
                    </li>
                    <li
                        id={window.location.pathname === '/redzone' ? 'selectednav' : ''}
                    >
                        <Link
                            to="/redzone"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={deadline11}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="Red Zone"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}> Red Zone</span>
                        </Link>
                    </li>

                    <li
                        id={window.location.pathname === '/workstack' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/workstack"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={portfolio}
                                    style={{ height: "20px", width: "20px" }}
                                    alt=" Ready To Bill"
                                />
                            </span>
                            <span
                                style={{ alignSelf: 'center' }}> My Workstack</span>
                        </Link>
                    </li>
                    <li
                        id={window.location.pathname === '/readytobill' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/readytobill"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img
                                    src={invoice}
                                    style={{ height: "20px", width: "20px" }}
                                    alt=" Ready To Bill"
                                />
                            </span>
                            <span
                            style={{alignSelf: 'center'}}>Ready To Bill</span>
                        </Link>
                    </li>

                    <li
                        onClick={this.handleLogOut}
                    >
                        {/* <Link
                            to="/toolstates"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        > */}
                        <span style={{ marginRight: "15px", display: "inline-flex" }}>
                            <LogoutIcon
                              style={{ height: "20px", width: "20px",alignSelf: 'center' }}
                             />
                        </span>
                        <span
                            style={{ alignSelf: 'center' }}>Log out</span>
                        {/* </Link> */}
                    </li>
                </ul>
            </div>
        )
    }
}
