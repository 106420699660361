import { SERVERURL } from "../config1"
import { DATASHAPER } from "../config1"

import axios from "axios";
import { aws4Interceptor } from "aws4-axios";




import {
    CAMPAIGN_LIST_LOADING,
    CAMPAIGN_LIST_SUCCESS,
    CAMPAIGN_LIST_FAILURE,

    TOOL_LIST_LOADING,
    TOOL_LIST_SUCCESS,
    TOOL_LIST_FAILURE,

    TOOL_SWAP_LIST_LOADING,
    TOOL_SWAP_LIST_SUCCESS,
    TOOL_SWAP_LIST_FAILURE,

    USER_LIST_LOADING,
    USER_LIST_SUCCESS,
    USER_LIST_FAILURE,

    CAMPAIGNSETUP_INSERT_LOADING,
    CAMPAIGNSETUP_INSERT_SUCCESS,
    CAMPAIGNSETUP_INSERT_FAILURE,

    CUSTOMERSETUP_INSERT_LOADING,
    CUSTOMERSETUP_INSERT_SUCCESS,
    CUSTOMERSETUP_INSERT_FAILURE,

    TOOLCAMPAIGN_INSERT_LOADING,
    TOOLCAMPAIGN_INSERT_SUCCESS,
    TOOLCAMPAIGN_INSERT_FAILURE,

    CAMPAIGN_UPDATE_LOADING,
    CAMPAIGN_UPDATE_SUCCESS,
    CAMPAIGN_UPDATE_FAILURE,

    CUSTOMER_PREFILLDATA_LOADING,
    CUSTOMER_PREFILLDATA_SUCCESS,
    CUSTOMER_PREFILLDATA_FAILURE,

    CUSTOMER_LASTRECORDS_LOADING,
    CUSTOMER_LASTRECORDS_SUCCESS,
    CUSTOMER_LASTRECORDS_FAILURE,

    COUNTRY_LIST_LOADING,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAILURE,

    STATE_LIST_LOADING,
    STATE_LIST_SUCCESS,
    STATE_LIST_FAILURE,

    NOTIFICATION_LIST_LOADING,
    NOTIFICATION_LIST_SUCCESS,
    NOTIFICATION_LIST_FAILURE,

    USER_STATES_LOADING,
    USER_STATES_SUCCESS,
    USER_STATES_FAILURE,

    UPDATE_PROFILE_LOADING,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,


    PROFILE_DATA_LOADING,
    PROFILE_DATA_SUCCESS,
    PROFILE_DATA_FAILURE,

    LINKEDIN_LIST_LOADING,
    LINKEDIN_LIST_SUCCESS,
    LINKEDIN_LIST_FAILURE,

    BARGRAPH_DATA_LOADING,
    BARGRAPH_DATA_SUCCESS,
    BARGRAPH_DATA_FAILURE,

    PRODUCTIVITY_DATA_LOADING,
    PRODUCTIVITY_DATA_SUCCESS,
    PRODUCTIVITY_DATA_FAILURE,

    DATASHAPER_LIST_LOADING,
    DATASHAPER_LIST_SUCCESS,
    DATASHAPER_LIST_FAILURE,

    DATASETS_LIST_LOADING,
    DATASETS_LIST_SUCCESS,
    DATASETS_LIST_FAILURE,

    NETLINE_INSERT_LOADING,
    NETLINE_INSERT_SUCCESS,
    NETLINE_INSERT_FAILURE,

    NETLINE_LIST_LOADING,
    NETLINE_LIST_SUCCESS,
    NETLINE_LIST_FAILURE,

    NETLINE_INPUT_INSERT_LOADING,
    NETLINE_INPUT_INSERT_SUCCESS,
    NETLINE_INPUT_INSERT_FAILURE,

    CAMPAIGN_DELETE_LOADING,
    CAMPAIGN_DELETE_SUCCESS,
    CAMPAIGN_DELETE_FAILURE,

    CREATE_RECIPE_LOADING,
    CREATE_RECIPE_SUCCESS,
    CREATE_RECIPE_FAILURE,

    CREATE_DATASET_LOADING,
    CREATE_DATASET_SUCCESS,
    CREATE_DATASET_FAILURE,

    START_JOB_LOADING,
    START_JOB_SUCCESS,
    START_JOB_FAILURE,

    DATASHAPER_INSERT_LOADING,
    DATASHAPER_INSERT_SUCCESS,
    DATASHAPER_INSERT_FAILURE,

    DATASHAPER_LISTDATA_LOADING,
    DATASHAPER_LISTDATA_SUCCESS,
    DATASHAPER_LISTDATA_FAILURE,

    NETLINE_POPUP_LOADING,
    NETLINE_POPUP_SUCCESS,
    NETLINE_POPUP_FAILURE,

    SOURCE_LIST_DATA_LOADING,
    SOURCE_LIST_DATA_SUCCESS,
    SOURCE_LIST_DATA_FAILURE,

    TARGET_LIST_DATA_LOADING,
    TARGET_LIST_DATA_SUCCESS,
    TARGET_LIST_DATA_FAILURE,

    DELIVERY_TEMPLATE_INSERT_LOADING,
    DELIVERY_TEMPLATE_INSERT_SUCCESS,
    DELIVERY_TEMPLATE_INSERT_FAILURE,

    JOB_UPDATE_LOADING,
    JOB_UPDATE_SUCCESS,
    JOB_UPDATE_FAILURE,

    JOB_STATUS_LOADING,
    JOB_STATUS_SUCCESS,
    JOB_STATUS_FAILURE,

    DELIVERY_DYNAMIC_LOADING,
    DELIVERY_DYNAMIC_SUCCESS,
    DELIVERY_DYNAMIC_FAILURE,

    PUBLISH_RECEPIE_LOADING,
    PUBLISH_RECEPIE_SUCCESS,
    PUBLISH_RECEPIE_FAILURE,

    DELETE_JOB_LOADING,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_FAILURE,

    DELETE_DATASET_LOADING,
    DELETE_DATASET_SUCCESS,
    DELETE_DATASET_FAILURE,

    DELETE_DATASETDB_LOADING,
    DELETE_DATASETDB_SUCCESS,
    DELETE_DATASETDB_FAILURE,

    DATASHAPER_NOTIFICATION_LOADING,
    DATASHAPER_NOTIFICATION_SUCCESS,
    DATASHAPER_NOTIFICATION_FAILURE,

    DATASHAPER_NOTIFICATIONLIST_LOADING,
    DATASHAPER_NOTIFICATIONLIST_SUCCESS,
    DATASHAPER_NOTIFICATIONLIST_FAILURE,

    FETCH_COLUMN_NAMES_LOADING,
    FETCH_COLUMN_NAMES_SUCCESS,
    FETCH_COLUMN_NAMES_FAILURE,

    LIST_OF_TITLES_LOADING,
    LIST_OF_TITLES_SUCCESS,
    LIST_OF_TITLES_FAILURE,

    GET_FILE_NAME_LOADING,
    GET_FILE_NAME_SUCCESS,
    GET_FILE_NAME_FAILURE,

    NLP_INSERT_LOADING,
    NLP_INSERT_SUCCESS,
    NLP_INSERT_FAILURE,

    NLP_MATCH_LOADING,
    NLP_MATCH_SUCCESS,
    NLP_MATCH_FAILURE,

    NLP_DASHBOARD_LOADING,
    NLP_DASHBOARD_SUCCESS,
    NLP_DASHBOARD_FAILURE,

    CREATE_TITLE_BUCKET_LOADING,
    CREATE_TITLE_BUCKET_SUCCESS,
    CREATE_TITLE_BUCKET_FAILURE,

    LIST_TITLE_BUCKET_LOADING,
    LIST_TITLE_BUCKET_SUCCESS,
    LIST_TITLE_BUCKET_FAILURE,

    DELETE_TITLE_BUCKET_LOADING,
    DELETE_TITLE_BUCKET_SUCCESS,
    DELETE_TITLE_BUCKET_FAILURE,


    UPDATE_TITLE_BUCKET_LOADING,
    UPDATE_TITLE_BUCKET_SUCCESS,
    UPDATE_TITLE_BUCKET_FAILURE,


    APPEND_TITLE_BUCKET_LOADING,
    APPEND_TITLE_BUCKET_SUCCESS,
    APPEND_TITLE_BUCKET_FAILURE,


    PREFILL_TITLE_BUCKET_LOADING,
    PREFILL_TITLE_BUCKET_SUCCESS,
    PREFILL_TITLE_BUCKET_FAILURE,

    FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_LOADING,
    FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_SUCCESS,
    FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_FAILURE,

    FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_LOADING,
    FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_SUCCESS,
    FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_FAILURE,

    BATCH_ID_LOADING,
    BATCH_ID_SUCCESS,
    BATCH_ID_FAILURE,

    GET_NLP_OUTPUTFILE_LOADING,
    GET_NLP_OUTPUTFILE_SUCCESS,
    GET_NLP_OUTPUTFILE_FAILURE,

    INSERT_RECIPIE_NAME_LOADING,
    INSERT_RECIPIE_NAME_SUCCESS,
    INSERT_RECIPIE_NAME_FAILURE,

    GET_LEADSHAPER_FILES_LOADING,
    GET_LEADSHAPER_FILES_SUCCESS,
    GET_LEADSHAPER_FILES_FAILURE,

    STATUS_DASHBOARD_LOADING,
    STATUS_DASHBOARD_SUCCESS,
    STATUS_DASHBOARD_FAILURE,


    GET_STATUS_LOADING,
    GET_STATUS_SUCCESS,
    GET_STATUS_FAILURE,




} from "../actions/constants";

const Username = 'admin'
const Password = 'secret'

const token = Buffer.from(`${Username}:${Password}`, 'utf8').toString('base64')


export function getStatusListLoading() {
    return {
        type: GET_STATUS_LOADING
    };
}
export function getStatusListSuccess(payload) {
    return {
        type: GET_STATUS_SUCCESS,
        payload: payload
    };
}
export function getStatusListFailure(payload) {
    return {
        type: GET_STATUS_FAILURE,
        payload: payload
    };
}


export function getStatusList(userinfo) {
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(getStatusListLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}getLeadshaperStepsStatus`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res, "res for getStatusList")
                if (res.status === 200) {
                    dispatch(getStatusListSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for getStatusList")
                dispatch(getStatusListFailure(error));
            });
    };
}

export function statusDashboardListLoading() {
    return {
        type: STATUS_DASHBOARD_LOADING
    };
}
export function statusDashboardListSuccess(payload) {
    return {
        type: STATUS_DASHBOARD_SUCCESS,
        payload: payload
    };
}
export function statusDashboardListFailure(payload) {
    return {
        type: STATUS_DASHBOARD_FAILURE,
        payload: payload
    };
}


export function statusDashboardList(userinfo) {
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(statusDashboardListLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}getLeadshaperStepsStatus`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res, "res for statusDashboardList")
                if (res.status === 200) {
                    dispatch(statusDashboardListSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for statusDashboardList")
                dispatch(statusDashboardListFailure(error));
            });
    };
}


export function getLeadSHaperFilesDataLoading() {
    return {
        type: GET_LEADSHAPER_FILES_LOADING
    };
}
export function getLeadSHaperFilesDataSuccess(payload) {
    return {
        type: GET_LEADSHAPER_FILES_SUCCESS,
        payload: payload
    };
}
export function getLeadSHaperFilesDataFailure(payload) {
    return {
        type: GET_LEADSHAPER_FILES_FAILURE,
        payload: payload
    };
}


export function getLeadSHaperFilesData(userinfo) {
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(getLeadSHaperFilesDataLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}getLeadShaperFiles`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res, "res for getLeadSHaperFilesData")
                if (res.status === 200) {
                    dispatch(getLeadSHaperFilesDataSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for getLeadSHaperFilesData")
                dispatch(getLeadSHaperFilesDataFailure(error));
            });
    };
}


export function insertRecipieNameDataLoading() {
    return {
        type: INSERT_RECIPIE_NAME_LOADING
    };
}
export function insertRecipieNameDataSuccess(payload) {
    return {
        type: INSERT_RECIPIE_NAME_SUCCESS,
        payload: payload
    };
}
export function insertRecipieNameDataFailure(payload) {
    return {
        type: INSERT_RECIPIE_NAME_FAILURE,
        payload: payload
    };
}


export function insertRecipieNameData(userinfo) {
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(insertRecipieNameDataLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}insertRecipeName`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res, "res for insertRecipieNameData")
                if (res.status === 200) {
                    dispatch(insertRecipieNameDataSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for insertRecipieNameData")
                dispatch(insertRecipieNameDataFailure(error));
            });
    };
}


export function getNLPOutputFileLoading() {
    return {
        type: GET_NLP_OUTPUTFILE_LOADING
    };
}
export function getNLPOutputFileSuccess(payload) {
    return {
        type: GET_NLP_OUTPUTFILE_SUCCESS,
        payload: payload
    };
}
export function getNLPOutputFileFailure(payload) {
    return {
        type: GET_NLP_OUTPUTFILE_FAILURE,
        payload: payload
    };
}


export function getNLPOutputFile(userinfo) {
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(getNLPOutputFileLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}getnlpOutputFile`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                // console.log(res, "res for getNLPOutputFile")
                if (res.status === 200) {
                    dispatch(getNLPOutputFileSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "error for getNLPOutputFile")
                dispatch(getNLPOutputFileFailure(error));
            });
    };
}


export function batchIdListLoading() {
    return {
        type: BATCH_ID_LOADING
    };
}
export function batchIdListSuccess(payload) {
    return {
        type: BATCH_ID_SUCCESS,
        payload: payload
    };
}
export function batchIdListFailure(payload) {
    return {
        type: BATCH_ID_FAILURE,
        payload: payload
    };
}


export function batchIdList(userinfo) {
    // console.log(userinfo, "batchIdList")
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(batchIdListLoading());
        var config = {
            method: 'GET',
            url: `${DATASHAPER}shownlpids`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                // console.log(res, "res for batchIdList")
                if (res.status === 200) {
                    dispatch(batchIdListSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "error for batchIdList")
                dispatch(batchIdListFailure(error));
            });
    };
}

export function fetchDelTemColumnNamesLoading() {
    return {
        type: FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_LOADING
    };
}
export function fetchDelTemColumnNamesSuccess(payload) {
    return {
        type: FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function fetchDelTemColumnNamesFailure(payload) {
    return {
        type: FETCHDELTEMCOLUMNNAMES_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function fetchDelTemColumnNames(userinfo) {
    // console.log(userinfo, "fetchDelTemColumnNames")
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(fetchDelTemColumnNamesLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}fetchDelTemColumnNames`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                // console.log(res, "res for fetchDelTemColumnNames")
                if (res.status === 200) {
                    dispatch(fetchDelTemColumnNamesSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "error for fetchDelTemColumnNames")
                dispatch(fetchDelTemColumnNamesFailure(error));
            });
    };
}


// fetchTitleFileColumnNames
export function fetchTitleFileColumnNamesLoading() {
    return {
        type: FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_LOADING
    };
}
export function fetchTitleFileColumnNamesSuccess(payload) {
    return {
        type: FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function fetchTitleFileColumnNamesFailure(payload) {
    return {
        type: FETCHTITLEFILECOLUMNNAMES_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function fetchTitleFileColumnNames(userinfo) {
    // console.log(userinfo, "fetchTitleFileColumnNames")
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(fetchTitleFileColumnNamesLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}fetchTitleFileColumnNames`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                // console.log(res, "res for fetchTitleFileColumnNames")
                if (res.status === 200) {
                    dispatch(fetchTitleFileColumnNamesSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "error for fetchTitleFileColumnNames")
                dispatch(fetchTitleFileColumnNamesFailure(error));
            });
    };
}

export function prefillBucketLoading() {
    return {
        type: PREFILL_TITLE_BUCKET_LOADING
    };
}
export function prefillBucketSuccess(payload) {
    return {
        type: PREFILL_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function prefillBucketFailure(payload) {
    return {
        type: PREFILL_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function prefillBucket(userinfo) {
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(prefillBucketLoading());
        var config = {
            method: 'POST',
            url: `${DATASHAPER}fetchtitlebucketsbyid`,
            // url: 'http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebucketsbyid',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res, "res for prefillBucket")
                if (res.status === 200) {
                    dispatch(prefillBucketSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for prefillBucket")
                dispatch(prefillBucketFailure(error));
            });
    };
}

export function appendTitleBucketLoading() {
    return {
        type: APPEND_TITLE_BUCKET_LOADING
    };
}
export function appendTitleBucketSuccess(payload) {
    return {
        type: APPEND_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function appendTitleBucketFailure(payload) {
    return {
        type: APPEND_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function appendTitleBucket(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(appendTitleBucketLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}appenddataToTitleBucket`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/appenddataToTitleBucket",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for appendTitleBucket")
                if (res.status === 200) {
                    dispatch(appendTitleBucketSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for appendTitleBucket")
                dispatch(appendTitleBucketFailure(error));
            });
    };
}


export function updateTitleBucketLoading() {
    return {
        type: UPDATE_TITLE_BUCKET_LOADING
    };
}
export function updateTitleBucketSuccess(payload) {
    return {
        type: UPDATE_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function updateTitleBucketFailure(payload) {
    return {
        type: UPDATE_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function updateTitleBucket(userinfo) {
    const data = userinfo
    console.log(data)
    return dispatch => {
        dispatch(updateTitleBucketLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}updatetitlebucket`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/updatetitlebucket",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for updateTitleBucket")
                if (res.status === 200) {
                    dispatch(updateTitleBucketSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for updateTitleBucket")
                dispatch(updateTitleBucketFailure(error));
            });
    };
}


export function deleteTitleBucketLoading() {
    return {
        type: DELETE_TITLE_BUCKET_LOADING
    };
}
export function deleteTitleBucketSuccess(payload) {
    return {
        type: DELETE_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function deleteTitleBucketFailure(payload) {
    return {
        type: DELETE_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function deleteTitleBucket(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(deleteTitleBucketLoading());
        axios({
            method: "DELETE",
            mode: 'no-cors',
            url: `${DATASHAPER}deletetitlebucket`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/deletetitlebucket",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for deleteTitleBucket")
                if (res.status === 200) {
                    dispatch(deleteTitleBucketSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for deleteTitleBucket")
                dispatch(deleteTitleBucketFailure(error));
            });
    };
}



export function listTitleBucketLoading() {
    return {
        type: LIST_TITLE_BUCKET_LOADING
    };
}
export function listTitleBucketSuccess(payload) {
    return {
        type: LIST_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function listTitleBucketFailure(payload) {
    return {
        type: LIST_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function listTitleBucket(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(listTitleBucketLoading());
        axios({
            method: "GET",
            mode: 'no-cors',
            url: `${DATASHAPER}fetchtitlebuckets`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebuckets",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for listTitleBucket")
                if (res.status === 200) {
                    dispatch(listTitleBucketSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for listTitleBucket")
                dispatch(listTitleBucketFailure(error));
            });
    };
}



export function createTitleBucketLoading() {
    return {
        type: CREATE_TITLE_BUCKET_LOADING
    };
}
export function createTitleBucketSuccess(payload) {
    return {
        type: CREATE_TITLE_BUCKET_SUCCESS,
        payload: payload
    };
}
export function createTitleBucketFailure(payload) {
    return {
        type: CREATE_TITLE_BUCKET_FAILURE,
        payload: payload
    };
}


export function createTitleBucket(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(createTitleBucketLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}addtitlebucket`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/addtitlebucket",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for createTitleBucket")
                if (res.status === 200) {
                    dispatch(createTitleBucketSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for createTitleBucket")
                dispatch(createTitleBucketFailure(error));
            });
    };
}


export function nlpDashboardLoading() {
    return {
        type: NLP_DASHBOARD_LOADING
    };
}
export function nlpDashboardSuccess(payload) {
    return {
        type: NLP_DASHBOARD_SUCCESS,
        payload: payload
    };
}
export function nlpDashboardFailure(payload) {
    return {
        type: NLP_DASHBOARD_FAILURE,
        payload: payload
    };
}


export function nlpDashboard(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(nlpDashboardLoading());
        axios({
            method: "GET",
            mode: 'no-cors',
            url: `${DATASHAPER}fetchAllrecords`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchAllrecords",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for nlpDashboard")
                if (res.status === 200) {
                    dispatch(nlpDashboardSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for nlpDashboard")
                dispatch(nlpDashboardFailure(error));
            });
    };
}

export function nlpMatchLoading() {
    return {
        type: NLP_MATCH_LOADING
    };
}
export function nlpMatchSuccess(payload) {
    return {
        type: NLP_MATCH_SUCCESS,
        payload: payload
    };
}
export function nlpMatchFailure(payload) {
    return {
        type: NLP_MATCH_FAILURE,
        payload: payload
    };
}


export function nlpMatch(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(nlpMatchLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}runnlpmatch`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/runnlpmatch",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for nlpMatch")
                if (res.status === 200) {
                    dispatch(nlpMatchSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for nlpMatch")
                dispatch(nlpMatchFailure(error));
            });
    };
}

export function nlpInsertLoading() {
    return {
        type: NLP_INSERT_LOADING
    };
}
export function nlpInsertSuccess(payload) {
    return {
        type: NLP_INSERT_SUCCESS,
        payload: payload
    };
}
export function nlpInsertFailure(payload) {
    return {
        type: NLP_INSERT_FAILURE,
        payload: payload
    };
}


export function nlpInsert(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(nlpInsertLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}insertRecord`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/insertRecord",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for nlpInsert")
                if (res.status === 200) {
                    dispatch(nlpInsertSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for nlpInsert")
                dispatch(nlpInsertFailure(error));
            });
    };
}

export function getFileNameLoading() {
    return {
        type: GET_FILE_NAME_LOADING
    };
}
export function getFileNameSuccess(payload) {
    return {
        type: GET_FILE_NAME_SUCCESS,
        payload: payload
    };
}
export function getFileNameFailure(payload) {
    return {
        type: GET_FILE_NAME_FAILURE,
        payload: payload
    };
}


export function getFileName(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(getFileNameLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}fetchtitlefile`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlefile",
            crossDomain: true,
            data
        })
            .then(res => {
                // console.log(res, "res for getFileName")
                if (res.status === 200) {
                    dispatch(getFileNameSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "error for getFileName")
                dispatch(getFileNameFailure(error));
            });
    };
}


export function listOfTitlesLoading() {
    return {
        type: LIST_OF_TITLES_LOADING
    };
}
export function listOfTitlesSuccess(payload) {
    return {
        type: LIST_OF_TITLES_SUCCESS,
        payload: payload
    };
}
export function listOfTitlesFailure(payload) {
    return {
        type: LIST_OF_TITLES_FAILURE,
        payload: payload
    };
}


export function listOfTitles(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(listOfTitlesLoading());
        axios({
            method: "GET",
            mode: 'no-cors',
            url: `${DATASHAPER}fetchtitlebuckets`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchtitlebuckets",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for listOfTitles")
                if (res.status === 200) {
                    dispatch(listOfTitlesSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for listOfTitles")
                dispatch(listOfTitlesFailure(error));
            });
    };
}



export function fetchColumnNamesLoading() {
    return {
        type: FETCH_COLUMN_NAMES_LOADING
    };
}
export function fetchColumnNamesSuccess(payload) {
    return {
        type: FETCH_COLUMN_NAMES_SUCCESS,
        payload: payload
    };
}
export function fetchColumnNamesFailure(payload) {
    return {
        type: FETCH_COLUMN_NAMES_FAILURE,
        payload: payload
    };
}


export function fetchColumnNames(userinfo) {
    // console.log(userinfo, "insidefetch")
    const data = userinfo
    return dispatch => {
        dispatch(fetchColumnNamesLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}fetchColumnNames`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/fetchColumnNames",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for fetchColumnNames")
                if (res.status === 200) {
                    dispatch(fetchColumnNamesSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for fetchColumnNames")
                dispatch(fetchColumnNamesFailure(error));
            });
    };
}



export function datashaperNotificationListLoading() {
    return {
        type: DATASHAPER_NOTIFICATIONLIST_LOADING
    };
}
export function datashaperNotificationListSuccess(payload) {
    return {
        type: DATASHAPER_NOTIFICATIONLIST_SUCCESS,
        payload: payload
    };
}
export function datashaperNotificationListFailure(payload) {
    return {
        type: DATASHAPER_NOTIFICATIONLIST_FAILURE,
        payload: payload
    };
}


export function datashaperNotificationList(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(datashaperNotificationListLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${SERVERURL}datashapernotificationdata`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/datashapernotificationdata",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for sns")
                if (res.status === 200) {
                    dispatch(datashaperNotificationListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(datashaperNotificationListFailure(error));
            });
    };
}

// To update job status in db
export function datashaperSNSNotificationLoading() {
    return {
        type: DATASHAPER_NOTIFICATION_LOADING
    };
}
export function datashaperSNSNotificationSuccess(payload) {
    return {
        type: DATASHAPER_NOTIFICATION_SUCCESS,
        payload: payload
    };
}
export function datashaperSNSNotificationFailure(payload) {
    return {
        type: DATASHAPER_NOTIFICATION_FAILURE,
        payload: payload
    };
}


export function datashaperSNSNotification(userinfo) {
    // console.log(userinfo,"uinfoooo for  ds")
    const data = userinfo
    return dispatch => {
        dispatch(datashaperSNSNotificationLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            // url: `${SERVERURL}datashaperSNSNotification`,
            url: "https://solpub.digitivy.com/datashaper/insertDatashaperDetails",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for sns")
                if (res.status === 200) {
                    dispatch(datashaperSNSNotificationSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for sns")
                dispatch(datashaperSNSNotificationFailure(error));
            });
    };
}


// To update job status in db
export function deletedatasetdatabaseLoading() {
    return {
        type: DELETE_DATASETDB_LOADING
    };
}
export function deletedatasetdatabaseSuccess(payload) {
    return {
        type: DELETE_DATASETDB_SUCCESS,
        payload: payload
    };
}
export function deletedatasetdatabaseFailure(payload) {
    return {
        type: DELETE_DATASETDB_FAILURE,
        payload: payload
    };
}


export function deletedatasetdatabase(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(deletedatasetdatabaseLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${SERVERURL}deletedatasetdatabase`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/deletedatasetdatabase",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(deletedatasetdatabaseSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(deletedatasetdatabaseFailure(error));
            });
    };
}

// To update job status in db
export function deletedatasetLoading() {
    return {
        type: DELETE_DATASET_LOADING
    };
}
export function deletedatasetSuccess(payload) {
    return {
        type: DELETE_DATASET_SUCCESS,
        payload: payload
    };
}
export function deletedatasetFailure(payload) {
    return {
        type: DELETE_DATASET_FAILURE,
        payload: payload
    };
}


export function deletedataset(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(deletedatasetLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${SERVERURL}deletedataset`,
            // url: "https://af07q9h0af.execute-api.us-east-1.amazonaws.com/dev/deletedataset",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for publish")
                if (res.status === 200) {
                    dispatch(deletedatasetSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "Err for delivery")
                dispatch(deletedatasetFailure(error));
            });
    };
}


// To update job status in db
export function deleteJOBLoading() {
    return {
        type: DELETE_JOB_LOADING
    };
}
export function deleteJOBSuccess(payload) {
    return {
        type: DELETE_JOB_SUCCESS,
        payload: payload
    };
}
export function deleteJOBFailure(payload) {
    return {
        type: DELETE_JOB_FAILURE,
        payload: payload
    };
}


export function deleteJOB(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(deleteJOBLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${SERVERURL}deletejob`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/deletejob",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for deletejob")
                if (res.status === 200) {
                    dispatch(deleteJOBSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "Err for deletejob")
                dispatch(deleteJOBFailure(error));
            });
    };
}


// To update job status in db
export function publishRecipeLoading() {
    return {
        type: PUBLISH_RECEPIE_LOADING
    };
}
export function publishRecipeSuccess(payload) {
    return {
        type: PUBLISH_RECEPIE_SUCCESS,
        payload: payload
    };
}
export function publishRecipeFailure(payload) {
    return {
        type: PUBLISH_RECEPIE_FAILURE,
        payload: payload
    };
}


export function publishRecipe(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(publishRecipeLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${SERVERURL}publishrecipe`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/publishrecipe",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for publish")
                if (res.status === 200) {
                    dispatch(publishRecipeSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "Err for delivery")
                dispatch(publishRecipeFailure(error));
            });
    };
}


// To update job status in db
export function deliveryTemplateDynamicLoading() {
    return {
        type: DELIVERY_DYNAMIC_LOADING
    };
}
export function deliveryTemplateDynamicSuccess(payload) {
    return {
        type: DELIVERY_DYNAMIC_SUCCESS,
        payload: payload
    };
}
export function deliveryTemplateDynamicFailure(payload) {
    return {
        type: DELIVERY_DYNAMIC_FAILURE,
        payload: payload
    };
}


export function deliveryTemplateDynamic(userinfo) {
    const data = userinfo
    console.log(data, "data for delivery")
    return dispatch => {
        dispatch(deliveryTemplateDynamicLoading());
        axios({
            method: "POST",
            mode: 'no-cors',
            url: `${DATASHAPER}fetchDelTemColumnNames`,
            // url: "https://af07q9h0af.execute-api.us-east-1.amazonaws.com/dev/deliveryfile",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for delivery")
                if (res.status === 200) {
                    dispatch(deliveryTemplateDynamicSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "Err for delivery")
                dispatch(deliveryTemplateDynamicFailure(error));
            });
    };
}


// To update job status in db
export function jobstatusLoading() {
    return {
        type: JOB_STATUS_LOADING
    };
}
export function jobstatusSuccess(payload) {
    return {
        type: JOB_STATUS_SUCCESS,
        payload: payload
    };
}
export function jobstatusFailure(payload) {
    return {
        type: JOB_STATUS_FAILURE,
        payload: payload
    };
}


export function jobstatus(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(jobstatusLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}runstatus`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/runstatus",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(jobstatusSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(jobstatusFailure(error));
            });
    };
}

// To update job ID in db
export function jobUpdateLoading() {
    return {
        type: JOB_UPDATE_LOADING
    };
}
export function jobUpdateSuccess(payload) {
    return {
        type: JOB_UPDATE_SUCCESS,
        payload: payload
    };
}
export function jobUpdateFailure(payload) {
    return {
        type: JOB_UPDATE_FAILURE,
        payload: payload
    };
}


export function jobUpdate(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(jobUpdateLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}runupdate`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/runupdate",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for runupdate")
                if (res.status === 200) {
                    dispatch(jobUpdateSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(jobUpdateFailure(error));
            });
    };
}

export function deliveryTemplateLoading() {
    return {
        type: DELIVERY_TEMPLATE_INSERT_LOADING
    };
}
export function deliveryTemplateSuccess(payload) {
    return {
        type: DELIVERY_TEMPLATE_INSERT_SUCCESS,
        payload: payload
    };
}
export function deliveryTemplateFailure(payload) {
    return {
        type: DELIVERY_TEMPLATE_INSERT_FAILURE,
        payload: payload
    };
}


export function deliveryTemplate(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(deliveryTemplateLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}deliverytemplate`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/deliverytemplate",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for target deliverytemplate")
                if (res.status === 200) {
                    dispatch(deliveryTemplateSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(deliveryTemplateFailure(error));
            });
    };
}



export function targetListDataLoading() {
    return {
        type: TARGET_LIST_DATA_LOADING
    };
}
export function targetListDataSuccess(payload) {
    return {
        type: TARGET_LIST_DATA_SUCCESS,
        payload: payload
    };
}
export function targetListDataFailure(payload) {
    return {
        type: TARGET_LIST_DATA_FAILURE,
        payload: payload
    };
}


export function targetListData(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(targetListDataLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}targetlist`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/targetlist",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(targetListDataSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(targetListDataFailure(error));
            });
    };
}

export function sourceListDataLoading() {
    return {
        type: SOURCE_LIST_DATA_LOADING
    };
}
export function sourceListDataSuccess(payload) {
    return {
        type: SOURCE_LIST_DATA_SUCCESS,
        payload: payload
    };
}
export function sourceListDataFailure(payload) {
    return {
        type: SOURCE_LIST_DATA_FAILURE,
        payload: payload
    };
}


export function sourceListData(userinfo) {
    console.log(userinfo, "sourceListData")
    const data = userinfo
    return dispatch => {
        dispatch(sourceListDataLoading());
        axios({
            method: "POST",
            url: `${DATASHAPER}fetchNlpOutputFileColumnNames`,
            // url: "https://af07q9h0af.execute-api.us-east-1.amazonaws.com/dev/sourcefile",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for source list")
                if (res.status === 200) {
                    dispatch(sourceListDataSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for source list")
                dispatch(sourceListDataFailure(error));
            });
    };
}



export function netlinePopupLoading() {
    return {
        type: NETLINE_POPUP_LOADING
    };
}
export function netlinePopupSuccess(payload) {
    return {
        type: NETLINE_POPUP_SUCCESS,
        payload: payload
    };
}
export function netlinePopupFailure(payload) {
    return {
        type: NETLINE_POPUP_FAILURE,
        payload: payload
    };
}


export function netlinePopup(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(netlinePopupLoading());
        axios({
            method: "POST",
            // url: `${SERVERURL}netlinepopup`,
            //UAT
            // url: "https://l0o9m7npp7.execute-api.us-east-1.amazonaws.com/dev/netlinepopup",
            //dev
            url: "https://af07q9h0af.execute-api.us-east-1.amazonaws.com/dev/netlinepopup",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(netlinePopupSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(netlinePopupFailure(error));
            });
    };
}



export function datashaperInsertLoading() {
    return {
        type: DATASHAPER_INSERT_LOADING
    };
}
export function datashaperInsertSuccess(payload) {
    return {
        type: DATASHAPER_INSERT_SUCCESS,
        payload: payload
    };
}
export function datashaperInsertFailure(payload) {
    return {
        type: DATASHAPER_INSERT_FAILURE,
        payload: payload
    };
}


export function datashaperInsert(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(datashaperInsertLoading());
        axios({
            method: "POST",
            url: `${DATASHAPER}createLeadShaperJob`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/createLeadShaperJob",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "datashaper")
                if (res.status === 200) {
                    dispatch(datashaperInsertSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "datashaper")
                dispatch(datashaperInsertFailure(error));
            });
    };
}

export function datashaperlistdataLoading() {
    return {
        type: DATASHAPER_LISTDATA_LOADING
    };
}
export function datashaperlistdataSuccess(payload) {
    return {
        type: DATASHAPER_LISTDATA_SUCCESS,
        payload: payload
    };
}
export function datashaperlistdataFailure(payload) {
    return {
        type: DATASHAPER_LISTDATA_FAILURE,
        payload: payload
    };
}


export function datashaperlistdata(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(datashaperlistdataLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}datashaperlist`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/datashaperlist",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(datashaperlistdataSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(datashaperlistdataFailure(error));
            });
    };
}

export function startJobLoading() {
    return {
        type: START_JOB_LOADING
    };
}
export function startJobSuccess(payload) {
    return {
        type: START_JOB_SUCCESS,
        payload: payload
    };
}
export function startJobFailure(payload) {
    return {
        type: START_JOB_FAILURE,
        payload: payload
    };
}


export function startJob(userinfo) {
    console.log("datasett")
    const data = userinfo
    return dispatch => {
        dispatch(startJobLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}startjob`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/startjob",
            crossDomain: true,
            data
        })
            .then(res => {
                // console.log(res, "ressssssss")
                if (res.status === 200) {
                    dispatch(startJobSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "errrrrrrrr for jobbb")
                dispatch(startJobFailure(error));
            });
    };
}

export function createDataSetLoading() {
    return {
        type: CREATE_DATASET_LOADING
    };
}
export function createDataSetSuccess(payload) {
    return {
        type: CREATE_DATASET_SUCCESS,
        payload: payload
    };
}
export function createDataSetFailure(payload) {
    return {
        type: CREATE_DATASET_FAILURE,
        payload: payload
    };
}


export function createDataSet(userinfo) {
    // console.log("datasett")
    const data = userinfo
    return dispatch => {
        dispatch(createDataSetLoading());
        axios({
            method: "POST",
            // url: `${SERVERURL}createdataset`,
            url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/insertInput",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "datasett")
                if (res.status === 200) {
                    dispatch(createDataSetSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "datasett")
                dispatch(createDataSetFailure(error));
            });
    };
}


export function createRecipeLoading() {
    return {
        type: CREATE_RECIPE_LOADING
    };
}
export function createRecipeSuccess(payload) {
    return {
        type: CREATE_RECIPE_SUCCESS,
        payload: payload
    };
}
export function createRecipeFailure(payload) {
    return {
        type: CREATE_RECIPE_FAILURE,
        payload: payload
    };
}


export function createRecipe(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(createRecipeLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}recepie`,
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/recepie",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(createRecipeSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(createRecipeFailure(error));
            });
    };
}


export function campaignDeleteLoading() {
    return {
        type: CAMPAIGN_DELETE_LOADING
    };
}
export function campaignDeleteSuccess(payload) {
    return {
        type: CAMPAIGN_DELETE_SUCCESS,
        payload: payload
    };
}
export function campaignDeleteFailure(payload) {
    return {
        type: CAMPAIGN_DELETE_FAILURE,
        payload: payload
    };
}


export function campaignDelete(userinfo) {
    const data = {
        campaign_id: userinfo
    };
    return dispatch => {
        dispatch(campaignDeleteLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}campaigndelete`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/campaigndelete",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(campaignDeleteSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(campaignDeleteFailure(error));
            });
    };
}

export function netlineInputInsertLoading() {
    return {
        type: NETLINE_INPUT_INSERT_LOADING
    };
}
export function netlineInputInsertSuccess(payload) {
    return {
        type: NETLINE_INPUT_INSERT_SUCCESS,
        payload: payload
    };
}
export function netlineInputInsertFailure(payload) {
    return {
        type: NETLINE_INPUT_INSERT_FAILURE,
        payload: payload
    };
}


export function netlineInputInsert(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(netlineInputInsertLoading());
        axios({
            method: "POST",
            // url: `${SERVERURL}netlineinsert`,
            //uat
            // url: "https://l0o9m7npp7.execute-api.us-east-1.amazonaws.com/dev/netlineinsert",
            //dev
            url: "https://af07q9h0af.execute-api.us-east-1.amazonaws.com/dev/netlineinsert",

            crossDomain: true,
            data
        })
            .then(res => {
                // console.log(res, "resss node input insert")
                if (res.status === 200) {
                    dispatch(netlineInputInsertSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "sssddddd")
                dispatch(netlineInputInsertFailure(error));
            });
    };
}

export function netlineBatchListLoading() {
    return {
        type: NETLINE_LIST_LOADING
    };
}
export function netlineBatchListSuccess(payload) {
    return {
        type: NETLINE_LIST_SUCCESS,
        payload: payload
    };
}
export function netlineBatchListFailure(payload) {
    return {
        type: NETLINE_LIST_FAILURE,
        payload: payload
    };
}


export function netlineBatchList(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(netlineBatchListLoading());
        axios({
            method: "POST",
            // url: `${SERVERURL}batchlist`,
            //uat
            // url: "https://l0o9m7npp7.execute-api.us-east-1.amazonaws.com/dev/batchlist",
            //dev
            url: "https://af07q9h0af.execute-api.us-east-1.amazonaws.com/dev/batchlist",

            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(netlineBatchListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(netlineBatchListFailure(error));
            });
    };
}

export function netLineInsertLoading() {
    return {
        type: NETLINE_INSERT_LOADING
    };
}
export function netLineInsertSuccess(payload) {
    return {
        type: NETLINE_INSERT_SUCCESS,
        payload: payload
    };
}
export function netLineInsertFailure(payload) {
    return {
        type: NETLINE_INSERT_FAILURE,
        payload: payload
    };
}


export function netLineInsert(userinfo) {
    return dispatch => {
        dispatch(netLineInsertLoading());
        axios({
            method: "POST",
            //dev
            url: `http://ec2-3-145-10-51.us-east-2.compute.amazonaws.com:8080/devapp/rest/netline/runBatch?input=${userinfo.input}&offer=${userinfo.offer}&batchName=${userinfo.batchName}&bucketName=${userinfo.bucketName}&mode=${userinfo.mode}&executionMode=${userinfo.executionMode}`,
            //local
            // url: `${SERVERURL}toolnetLineInsert`,
            //uat
            // url: `http://ec2-3-239-168-46.compute-1.amazonaws.com:8080/devapp/rest/netline/runBatch?input=${userinfo.input}&offer=${userinfo.offer}&batchName=${userinfo.batchName}&bucketName=${userinfo.bucketName}&mode=${userinfo.mode}&executionMode=${userinfo.executionMode}`,
            crossDomain: true,
            // data
        })
            .then(res => {
                // console.log(res.data, "java api success response")
                if (res.status === 200) {
                    dispatch(netLineInsertSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "java api error response")
                dispatch(netLineInsertFailure(error));
            });
    };
}


export function dataSetListLoading() {
    return {
        type: DATASETS_LIST_LOADING
    };
}
export function dataSetListSuccess(payload) {
    return {
        type: DATASETS_LIST_SUCCESS,
        payload: payload
    };
}
export function dataSetListFailure(payload) {
    return {
        type: DATASETS_LIST_FAILURE,
        payload: payload
    };
}


export function dataSetList(userinfo) {
    const client = axios.create();

    const interceptor = aws4Interceptor(
        {
            region: "us-east-1",
            service: "databrew",
            host: 'databrew.us-east-1.amazonaws.com',
            headers: {
                'content-Type': 'application/json',
                "Accept": "application/json",

            },
            'Access-Control-Allow-Origin': '*'

        },
        //dev
        {
            accessKeyId: "AKIAXTPFNQTGZIW5Y5ZF",
            secretAccessKey: "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",
        }
        //uat
        // {
        //     accessKeyId: "AKIAQ4QZYNARDHV752W5",
        //     secretAccessKey: "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"
        // }
    );
    client.interceptors.request.use(interceptor);

    return async dispatch => {
        dispatch(dataSetListLoading());
        client.get("https://databrew.us-east-1.amazonaws.com/datasets?maxResults=100").then((res) => {
            // console.log(res, "resss")
            if (res.status === 200) {
                dispatch(dataSetListSuccess(res.data && res.data.Datasets));
            }
        })
            .catch(error => {
                dispatch(dataSetListFailure(error));
            });
    };
}

export function datashaperlistLoading() {
    return {
        type: DATASHAPER_LIST_LOADING
    };
}
export function datashaperlistSuccess(payload) {
    return {
        type: DATASHAPER_LIST_SUCCESS,
        payload: payload
    };
}
export function datashaperlistFailure(payload) {
    return {
        type: DATASHAPER_LIST_FAILURE,
        payload: payload
    };
}

export function datashaperlist(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(datashaperlistLoading());
        axios({
            method: "GET",
            url: `${DATASHAPER}listrecipes`,
            // url: "http://datashaper-759740083.us-east-1.elb.amazonaws.com/datashaper/listrecipes",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "listrecipes")
                if (res.status === 200) {
                    dispatch(datashaperlistSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "listrecipes")
                dispatch(datashaperlistFailure(error));
            });
    };
}



// export function datashaperlist(userinfo) {
//     const client = axios.create();

//     const interceptor = aws4Interceptor(
//         {
//             region: "us-east-1",
//             service: "databrew",
//             host: 'databrew.us-east-1.amazonaws.com',
//             headers: {
//                 'content-Type': 'application/json',
//                 "Accept": "application/json",

//             },
//             'Access-Control-Allow-Origin': '*'

//         },
//         //dev
//         {
//             accessKeyId: "AKIAXTPFNQTGZIW5Y5ZF",
//             secretAccessKey: "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",
//         }
//         //uat
//         // {
//         //     accessKeyId: "AKIAQ4QZYNARDHV752W5",
//         //     secretAccessKey: "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"
//         // }
//     );
//     client.interceptors.request.use(interceptor);

//     return async dispatch => {
//         dispatch(datashaperlistLoading());
//         client.get("https://databrew.us-east-1.amazonaws.com/recipes?maxResults=100").then((res) => {
//             // console.log(res.data && res.data.Recipes, "resss")
//             if (res.status === 200) {
//                 dispatch(datashaperlistSuccess(res.data && res.data.Recipes));
//             }
//         })
//             .catch(error => {
//                 dispatch(datashaperlistFailure(error));
//             });
//     };
// }


export function productivitydataLoading() {
    return {
        type: PRODUCTIVITY_DATA_LOADING
    };
}
export function productivitydataSuccess(payload) {
    return {
        type: PRODUCTIVITY_DATA_SUCCESS,
        payload: payload
    };
}
export function productivitydataFailure(payload) {
    return {
        type: PRODUCTIVITY_DATA_FAILURE,
        payload: payload
    };
}


export function productivityData(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(productivitydataLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}productivity`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/productivity",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "resss")
                if (res.status === 200) {
                    dispatch(productivitydataSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(productivitydataFailure(error));
            });
    };
}


export function bardataLoading() {
    return {
        type: BARGRAPH_DATA_LOADING
    };
}
export function bardataSuccess(payload) {
    return {
        type: BARGRAPH_DATA_SUCCESS,
        payload: payload
    };
}
export function bardataFailure(payload) {
    return {
        type: BARGRAPH_DATA_FAILURE,
        payload: payload
    };
}


export function barData(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(bardataLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}toolbardata`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/toolbardata",
            crossDomain: true,
            data
        })
            .then(res => {
                // console.log(res, "resss")
                if (res.status === 200) {
                    dispatch(bardataSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "errrrrrrrrrrrrrrrr")
                dispatch(bardataFailure(error));
            });
    };
}


export function linkedindataLoading() {
    return {
        type: LINKEDIN_LIST_LOADING
    };
}
export function linkedindataSuccess(payload) {
    return {
        type: LINKEDIN_LIST_SUCCESS,
        payload: payload
    };
}
export function linkedindataFailure(payload) {
    return {
        type: LINKEDIN_LIST_FAILURE,
        payload: payload
    };
}

export const usersInfo = () => async dispatch => {
    dispatch(linkedindataLoading());
    let usersinfo = await localStorage.getItem("usersinfo");
    if (usersinfo) {
        usersinfo = JSON.parse(usersinfo);
        dispatch(linkedindataSuccess(usersinfo));
    } else {
        dispatch(linkedindataFailure());
    }
};


export function linkedindata(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(linkedindataLoading());
        axios({
            method: "POST",
            // url: `http://localhost:8080/usersdata`,
            url: "https://61aonvy5u7.execute-api.us-east-1.amazonaws.com/dev/usersdata",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    // console.log(res, "resss")
                    const usersinfo = JSON.stringify(res && res.data && res.data.UserAttributes);
                    localStorage.setItem("usersinfo", usersinfo)
                    dispatch(linkedindataSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "error")
                dispatch(linkedindataFailure(error));
            });
    };
}

export function profiledataLoading() {
    return {
        type: PROFILE_DATA_LOADING
    };
}
export function profiledataSuccess(payload) {
    return {
        type: PROFILE_DATA_SUCCESS,
        payload: payload
    };
}
export function profiledataFailure(payload) {
    return {
        type: PROFILE_DATA_FAILURE,
        payload: payload
    };
}


export function profiledata(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(profiledataLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}profiledata`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/userlist",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for profile data")
                if (res.status === 200) {
                    dispatch(profiledataSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "res for profile data")
                dispatch(profiledataFailure(error));
            });
    };
}

export function profileUpadteLoading() {
    return {
        type: UPDATE_PROFILE_LOADING
    };
}
export function profileUpadteSuccess(payload) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        payload: payload
    };
}
export function profileUpadteFailure(payload) {
    return {
        type: UPDATE_PROFILE_FAILURE,
        payload: payload
    };
}


export function profileUpadte(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(profileUpadteLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}updateprofile`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/updateprofile",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "ress for profileee")
                if (res.status === 200) {
                    dispatch(profileUpadteSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for profile")
                dispatch(profileUpadteFailure(error));
            });
    };
}

export function userStatesLoading() {
    return {
        type: USER_STATES_LOADING
    };
}
export function userStatesSuccess(payload) {
    return {
        type: USER_STATES_SUCCESS,
        payload: payload
    };
}
export function userStatesFailure(payload) {
    return {
        type: USER_STATES_FAILURE,
        payload: payload
    };
}


export function userStates(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(userStatesLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}campaignsetlist`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/campaigncount",
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/campaigncount",

            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(userStatesSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(userStatesFailure(error));
            });
    };
}


export function campaignListLoading() {
    return {
        type: CAMPAIGN_LIST_LOADING
    };
}
export function campaignListSuccess(payload) {
    return {
        type: CAMPAIGN_LIST_SUCCESS,
        payload: payload
    };
}
export function campaignListFailure(payload) {
    return {
        type: CAMPAIGN_LIST_FAILURE,
        payload: payload
    };
}


export function campaignList(userinfo) {
    const data = userinfo;
    return dispatch => {
        dispatch(campaignListLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}campaignsetlist`,
            // url: "https://2chheh5do8.execute-api.us-east-1.amazonaws.com/dev/campaignsetlist",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res")
                if (res.status === 200) {
                    dispatch(campaignListSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "erorr")
                dispatch(campaignListFailure(error));
            });
    };
}

//To get country list
export function countryListLoading() {
    return {
        type: COUNTRY_LIST_LOADING
    };
}
export function countryListSuccess(payload) {
    return {
        type: COUNTRY_LIST_SUCCESS,
        payload: payload
    };
}
export function countryListFailure(payload) {
    return {
        type: COUNTRY_LIST_FAILURE,
        payload: payload
    };
}


export function countryList() {
    // const data = userinfo;
    return dispatch => {
        dispatch(countryListLoading());
        axios({
            method: "GET",
            url: `${SERVERURL}countrylist`,
            // url: "https://o04b0v7ief.execute-api.us-east-1.amazonaws.com/dev/countrylist",
            crossDomain: true,
            // data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(countryListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(countryListFailure(error));
            });
    };
}

//To get state list
export function stateListLoading() {
    return {
        type: STATE_LIST_LOADING
    };
}
export function stateListSuccess(payload) {
    return {
        type: STATE_LIST_SUCCESS,
        payload: payload
    };
}
export function stateListFailure(payload) {
    return {
        type: STATE_LIST_FAILURE,
        payload: payload
    };
}


export function stateList(userinfo) {
    const data = {
        id: userinfo
    }
    // console.log(data, "info")
    // const data = userinfo;
    return dispatch => {
        dispatch(stateListLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}stateslist`,
            // url: "https://o04b0v7ief.execute-api.us-east-1.amazonaws.com/dev/stateslist",
            crossDomain: true,
            data
        })
            .then(res => {
                // console.log(res,"ress")
                if (res.status === 200) {
                    dispatch(stateListSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error,"eroo in countrg")
                dispatch(stateListFailure(error));
            });
    };
}

//to get notification
export function notificationListLoading() {
    return {
        type: NOTIFICATION_LIST_LOADING
    };
}
export function notificationListSuccess(payload) {
    return {
        type: NOTIFICATION_LIST_SUCCESS,
        payload: payload
    };
}
export function notificationListFailure(payload) {
    return {
        type: NOTIFICATION_LIST_FAILURE,
        payload: payload
    };
}


export function notificationList(userinfo) {
    const data = {
        sub: userinfo
    }
    // console.log(data, "info")
    return dispatch => {
        dispatch(notificationListLoading());
        axios({
            method: "POST",
            // url: "http://ec2-3-89-90-185.compute-1.amazonaws.com:3001/listapi",
            crossDomain: true,
            data
        })
            .then(res => {
                // console.log(res, "ress")
                if (res.status === 200) {
                    dispatch(notificationListSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "eroo in countrg")
                dispatch(notificationListFailure(error));
            });
    };
}


//campaign insert
export function toolListLoading() {
    return {
        type: TOOL_LIST_LOADING
    };
}
export function toolListSuccess(payload) {
    return {
        type: TOOL_LIST_SUCCESS,
        payload: payload
    };
}
export function toolListFailure(payload) {
    return {
        type: TOOL_LIST_FAILURE,
        payload: payload
    };
}


export function toolList(userinfo) {
    return dispatch => {
        dispatch(toolListLoading());
        axios({
            method: "GET",
            url: 'https://w6oj9rdyab.execute-api.us-east-1.amazonaws.com/dev/toollist',
            crossDomain: true,
            // data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(toolListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(toolListFailure(error));
            });
    };
}

//swaptool
export function toolSwapLoading() {
    return {
        type: TOOL_SWAP_LIST_LOADING
    };
}
export function toolSwapSuccess(payload) {
    return {
        type: TOOL_SWAP_LIST_SUCCESS,
        payload: payload
    };
}
export function toolSwapFailure(payload) {
    return {
        type: TOOL_SWAP_LIST_FAILURE,
        payload: payload
    };
}


export function toolSwap(userinfo) {
    const data = {
        campaign_id: userinfo.campaign_id
    }
    return dispatch => {
        dispatch(toolSwapLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}campaignprefilldata`,
            // url: 'https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/campaignprefilldata',
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(toolSwapSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(toolSwapFailure(error));
            });
    };
}

//User list
export function userListLoading() {
    return {
        type: USER_LIST_LOADING
    };
}
export function userListSuccess(payload) {
    return {
        type: USER_LIST_SUCCESS,
        payload: payload
    };
}
export function userListFailure(payload) {
    return {
        type: USER_LIST_FAILURE,
        payload: payload
    };
}


export function userList(userinfo) {
    return dispatch => {
        dispatch(userListLoading());
        axios({
            method: "GET",
            // url: `${SERVERURL}users`,
            url: 'https://w6oj9rdyab.execute-api.us-east-1.amazonaws.com/dev/users',
            crossDomain: true,
            // data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(userListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(userListFailure(error));
            });
    };
}


//campaign update
export function campaignUpdateLoading() {
    return {
        type: CAMPAIGN_UPDATE_LOADING
    };
}
export function campaignUpdateSuccess(payload) {
    return {
        type: CAMPAIGN_UPDATE_SUCCESS,
        payload: payload
    };
}
export function campaignUpdateFailure(payload) {
    return {
        type: CAMPAIGN_UPDATE_FAILURE,
        payload: payload
    };
}


export function campaignUpdate(userinfo) {
    const data = userinfo
    // console.log(data,"dataaa")
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/campaignsetupupdate',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(campaignUpdateLoading());
        axios(
            // config
            {
                method: "POST",
                // url: "https://2chheh5do8.execute-api.us-east-1.amazonaws.com/dev/campaignsetupupdate",
                // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/campaignsetupupdate",
                url: `${SERVERURL}campaignsetupupdate`,
                crossDomain: true,
                data

            }
        )
            .then(res => {
                // console.log(res, "resssssssss")
                if (res.status === 200) {
                    dispatch(campaignUpdateSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "sddnjdnj for full up date")
                dispatch(campaignUpdateFailure(error));
            });
    };
}


//campaign insert
export function campaignSetUpInsertLoading() {
    return {
        type: CAMPAIGNSETUP_INSERT_LOADING
    };
}
export function campaignSetUpInsertSuccess(payload) {
    return {
        type: CAMPAIGNSETUP_INSERT_SUCCESS,
        payload: payload
    };
}
export function campaignSetUpInsertFailure(payload) {
    return {
        type: CAMPAIGNSETUP_INSERT_FAILURE,
        payload: payload
    };
}


export function campaignSetUpInsert(userinfo) {
    const data = userinfo
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/campaignsetupinsert',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(campaignSetUpInsertLoading());
        axios({
            // config
            method: "POST",
            // url: "https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/campaignsetupinsert",
            url: `${SERVERURL}campaignsetupinsert`,
            // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/campaignsetupinsert",
            crossDomain: true,
            data
        }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(campaignSetUpInsertSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(campaignSetUpInsertFailure(error));
            });
    };
}

//Customersetup insert
export function CustomerSetUpInsertLoading() {
    return {
        type: CUSTOMERSETUP_INSERT_LOADING
    };
}
export function CustomerSetUpInsertSuccess(payload) {
    return {
        type: CUSTOMERSETUP_INSERT_SUCCESS,
        payload: payload
    };
}
export function CustomerSetUpInsertFailure(payload) {
    return {
        type: CUSTOMERSETUP_INSERT_FAILURE,
        payload: payload
    };
}


export function CustomerSetUpInsert(userinfo) {
    const data = userinfo
    // const Username = 'admin'
    // const Password = 'secret'

    // const token = Buffer.from(`${Username}:${Password}`, 'utf8').toString('base64')
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/customersetupinsert',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(CustomerSetUpInsertLoading());
        axios({
            // config
            method: "POST",
            url: `${SERVERURL}customersetupinsert`,
            // url: "https://o04b0v7ief.execute-api.us-east-1.amazonaws.com/dev/customersetupinsert",
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "res for create customer")
                if (res.status === 200) {
                    dispatch(CustomerSetUpInsertSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "error for customer setup")
                dispatch(CustomerSetUpInsertFailure(error));
            });
    };
}

//customer prefill data
export function customerPrefillDataLoading() {
    return {
        type: CUSTOMER_PREFILLDATA_LOADING
    };
}
export function customerPrefillDataSuccess(payload) {
    return {
        type: CUSTOMER_PREFILLDATA_SUCCESS,
        payload: payload
    };
}
export function customerPrefillDataFailure(payload) {
    return {
        type: CUSTOMER_PREFILLDATA_FAILURE,
        payload: payload
    };
}


export function customerPrefillData(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(customerPrefillDataLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}customerprefilldata`,
            // url: "https://o04b0v7ief.execute-api.us-east-1.amazonaws.com/dev/customerprefilldata",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(customerPrefillDataSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(customerPrefillDataFailure(error));
            });
    };
}


//customer prefill data
export function customerlastrecordsLoading() {
    return {
        type: CUSTOMER_LASTRECORDS_LOADING
    };
}
export function customerlastrecordsSuccess(payload) {
    return {
        type: CUSTOMER_LASTRECORDS_SUCCESS,
        payload: payload
    };
}
export function customerlastrecordsFailure(payload) {
    return {
        type: CUSTOMER_LASTRECORDS_FAILURE,
        payload: payload
    };
}


export function customerlastrecords(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(customerlastrecordsLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}getlastrecords`,
            // url: "https://o04b0v7ief.execute-api.us-east-1.amazonaws.com/dev/getlastrecords",
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(customerlastrecordsSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(customerlastrecordsFailure(error));
            });
    };
}

//toolcampaign insert
export function toolCampaignInsertLoading() {
    return {
        type: TOOLCAMPAIGN_INSERT_LOADING,
    };
}
export function toolCampaignInsertSuccess(payload) {
    return {
        type: TOOLCAMPAIGN_INSERT_SUCCESS,
        payload: payload
    };
}
export function toolCampaignInsertFailure(payload) {
    return {
        type: TOOLCAMPAIGN_INSERT_FAILURE,
        payload: payload
    };
}


export function toolCampaignInsert(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(toolCampaignInsertLoading());
        axios({
            method: "POST",
            url: `${SERVERURL}toolsequencing`,
            // url: 'https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/toolsequencing',
            crossDomain: true,
            data
        })
            .then(res => {
                console.log(res, "tool campg insert")
                if (res.status === 200) {
                    dispatch(toolCampaignInsertSuccess(res.data));
                }
            })
            .catch(error => {
                console.log(error, "tool error")
                dispatch(toolCampaignInsertFailure(error));
            });
    };
}



