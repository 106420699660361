import axios from 'axios';
import { Auth } from "aws-amplify";
import { SERVERURL } from "../config1"
import {
    OPENCAMPAIGN_LIST_LOADING,
    OPENCAMPAIGN_LIST_SUCCESS,
    OPENCAMPAIGN_LIST_FAILURE,

    MYWORKSTACK_LIST_LOADING,
    MYWORKSTACK_LIST_SUCCESS,
    MYWORKSTACK_LIST_FAILURE,

    HOT_MOVE_LOADING,
    HOT_MOVE_SUCCESS,
    HOT_MOVE_FAILURE,

    WHAT_IS_HOT_LIST_LOADING,
    WHAT_IS_HOT_LIST_SUCCESS,
    WHAT_IS_HOT_LIST_FAILURE,

    MANAGERWORKSTACK_LIST_LOADING,
    MANAGERWORKSTACK_LIST_SUCCESS,
    MANAGERWORKSTACK_LIST_FAILURE,

    READYTOBILL_LIST_LOADING,
    READYTOBILL_LIST_SUCCESS,
    READYTOBILL_LIST_FAILURE,

    ARCMAN_LOGIN_LOADING,
    ARCMAN_LOGIN_SUCCESS,
    ARCMAN_LOGIN_FAILURE,

    MANAGER_NOTES_LOADING,
    MANAGER_NOTES_SUCCESS,
    MANAGER_NOTES_FAILURE,

    SES_NOTIFICATION_LOADING,
    SES_NOTIFICATION_SUCCESS,
    SES_NOTIFICATION_FAILURE,

    NOTES_INSERT_LOADING,
    NOTES_INSERT_SUCCESS,
    NOTES_INSERT_FAILURE,

    INPUT_FILE_LOADING,
    INPUT_FILE_SUCCESS,
    INPUT_FILE_FAILURE,

    LAUNCH_TOOL_LOADING,
    LAUNCH_TOOL_SUCCESS,
    LAUNCH_TOOL_FAILURE,

    CAMPAIGN_NAMELIST_LOADING,
    CAMPAIGN_NAMELIST_SUCCESS,
    CAMPAIGN_NAMELIST_FAILURE,

    TOOL_WAITINGTIME_LOADING,
    TOOL_WAITINGTIME_SUCCESS,
    TOOL_WAITINGTIME_FAILURE

} from "../actions/constants";

const Username = 'admin'
const Password = 'secret'

const token = Buffer.from(`${Username}:${Password}`, 'utf8').toString('base64')

//campaignname list
export function toolWaitingLoading() {
    return {
        type: TOOL_WAITINGTIME_LOADING,
    };
}
export function toolWaitingSuccess(payload) {
    return {
        type: TOOL_WAITINGTIME_SUCCESS,
        payload: payload
    };
}
export function toolWaitingFailure(payload) {
    return {
        type: TOOL_WAITINGTIME_FAILURE,
        payload: payload
    };
}


export function toolWaiting(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(toolWaitingLoading());
        axios({
            method: "POST",
            // url: `https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/toolwaiting`,
            url: `${SERVERURL}toolwaiting`,
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(toolWaitingSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(toolWaitingFailure(error));
            });
    };
}

//campaignname list
export function campaignnamelistLoading() {
    return {
        type: CAMPAIGN_NAMELIST_LOADING,
    };
}
export function campaignnamelistSuccess(payload) {
    return {
        type: CAMPAIGN_NAMELIST_SUCCESS,
        payload: payload
    };
}
export function campaignnamelistFailure(payload) {
    return {
        type: CAMPAIGN_NAMELIST_FAILURE,
        payload: payload
    };
}


export function campaignnamelist(userinfo) {
    return dispatch => {
        dispatch(campaignnamelistLoading());
        axios({
            method: "GET",
            // url: `https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/campaignnamelist`,
            url: `${SERVERURL}campaignnamelist`,
            crossDomain: true,
            // data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(campaignnamelistSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(campaignnamelistFailure(error));
            });
    };
}

//SES Notification
export function sesNotificationLoading() {
    return {
        type: SES_NOTIFICATION_LOADING,
    };
}
export function sesNotificationSuccess(payload) {
    return {
        type: SES_NOTIFICATION_SUCCESS,
        payload: payload
    };
}
export function sesNotificationFailure(payload) {
    return {
        type: SES_NOTIFICATION_FAILURE,
        payload: payload
    };
}


export function sesNotification(userinfo) {
    const data = userinfo
    return dispatch => {
        dispatch(sesNotificationLoading());
        axios({
            method: "POST",
            // url: "http://ec2-3-89-90-185.compute-1.amazonaws.com:3001/sendnotification",
            // url: "https://bq7q7co3x5.execute-api.us-east-1.amazonaws.com/dev/sendnotification",
            url: `${SERVERURL}sendnotification`,
            crossDomain: true,
            data
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(sesNotificationSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(sesNotificationFailure(error));
            });
    };
}

//Launch tool
export function launchtoolLoading() {
    return {
        type: LAUNCH_TOOL_LOADING,
    };
}
export function launchtoolSuccess(payload) {
    return {
        type: LAUNCH_TOOL_SUCCESS,
        payload: payload
    };
}
export function launchtoolFailure(payload) {
    return {
        type: LAUNCH_TOOL_FAILURE,
        payload: payload
    };
}


export function launchtool(userinfo) {
    const data = userinfo
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/launchtool',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(launchtoolLoading());
        axios(
            // config
            {
                method: "POST",
                // url: `https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/launchtool`,
                url: `${SERVERURL}launchtool`,
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(launchtoolSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(launchtoolFailure(error));
            });
    };
}

//input file
export function inputFileLoading() {
    return {
        type: INPUT_FILE_LOADING,
    };
}
export function inputFileSuccess(payload) {
    return {
        type: INPUT_FILE_SUCCESS,
        payload: payload
    };
}
export function inputFileFailure(payload) {
    return {
        type: INPUT_FILE_FAILURE,
        payload: payload
    };
}


export function inputFile(userinfo) {
    // console.log("hi i am in input file")
    const data = {
        "bucket_name": "titlematch",
        "csv_filename": "GR.csv",
        "column_bucket_title": "IT Security Director plus",
        "title_column": "Title"
    }
    // const data = userinfo
    return dispatch => {
        dispatch(inputFileLoading());
        axios({
            method: "POST",
            url: "http://ec2-44-192-103-191.compute-1.amazonaws.com:7010/",
            // url: "https://bq7q7co3x5.execute-api.us-east-1.amazonaws.com/dev/sendnotification",
            // url: `${SERVERURL}notesinsert`,
            crossDomain: true,
            data
        })
            .then(res => {
                // console.log(res, "resss")
                if (res.status === 200) {
                    dispatch(inputFileSuccess(res.data));
                }
            })
            .catch(error => {
                // console.log(error, "error in notess")
                dispatch(inputFileFailure(error));
            });
    };
}

//workstack notes insert
export function notesInsertLoading() {
    return {
        type: NOTES_INSERT_LOADING,
    };
}
export function notesInsertSuccess(payload) {
    return {
        type: NOTES_INSERT_SUCCESS,
        payload: payload
    };
}
export function notesInsertFailure(payload) {
    return {
        type: NOTES_INSERT_FAILURE,
        payload: payload
    };
}


export function notesInsert(userinfo) {
    const data = userinfo
    var config = {
        method: 'POST',
        url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/notesinsert',
        headers: {
            'Authorization': `Basic ${token}`
        },
        data
    };
    return dispatch => {
        dispatch(notesInsertLoading());
        axios(
            // config
            {
                method: "POST",
                // url: "http://ec2-3-89-90-185.compute-1.amazonaws.com:3001/sendnotification",
                // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/notesinsert",
                url: `${SERVERURL}updatestepdata`,
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(notesInsertSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(notesInsertFailure(error));
            });
    };
}


//User list
export function openCampaignListLoading() {
    return {
        type: OPENCAMPAIGN_LIST_LOADING
    };
}
export function openCampaignListSuccess(payload) {
    return {
        type: OPENCAMPAIGN_LIST_SUCCESS,
        payload: payload
    };
}
export function openCampaignListFailure(payload) {
    return {
        type: OPENCAMPAIGN_LIST_FAILURE,
        payload: payload
    };
}


export function openCampaignList(userinfo) {
    const data = userinfo
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/openlist',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(openCampaignListLoading());
        axios(
            // config
            {
                method: "POST",
                // url: "https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/openlist",
                url: `${SERVERURL}openlist`,
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(openCampaignListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(openCampaignListFailure(error));
            });
    };
}


//User list
export function myworkstackListLoading() {
    return {
        type: MYWORKSTACK_LIST_LOADING
    };
}
export function myworkstackListSuccess(payload) {
    return {
        type: MYWORKSTACK_LIST_SUCCESS,
        payload: payload
    };
}
export function myworkstackListFailure(payload) {
    return {
        type: MYWORKSTACK_LIST_FAILURE,
        payload: payload
    };
}

//<===== Red zone list =====>
export function myworkstackList(userinfo) {
    const data = userinfo
    var config = {
        method: 'POST',
        url: `${SERVERURL}redzone`,
        // url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/redzone',
        headers: {
            'Authorization': `Basic ${token}`
        },
        data
    };
    return dispatch => {
        dispatch(myworkstackListLoading());
        axios(config
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(myworkstackListSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(myworkstackListFailure(error));
            });
    };
}


//toolcampaign insert
export function hotMoveLoading() {
    return {
        type: HOT_MOVE_LOADING,
    };
}
export function hotMoveSuccess(payload) {
    return {
        type: HOT_MOVE_SUCCESS,
        payload: payload
    };
}
export function hotMoveFailure(payload) {
    return {
        type: HOT_MOVE_FAILURE,
        payload: payload
    };
}


export function hotMove(userinfo) {
    const data = userinfo
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/workstackinsert',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(hotMoveLoading());
        axios(
            // config
            {
                method: "POST",
                // url: "https://o04b0v7ief.execute-api.us-east-1.amazonaws.com/dev/workstackinsert",
                url: `${SERVERURL}workstackinsert`,
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(hotMoveSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(hotMoveFailure(error));
            });
    };
}
//<=====WHAT IS HOT LIST=====>
export function whatishotlistLoading() {
    return {
        type: WHAT_IS_HOT_LIST_LOADING,
    };
}
export function whatishotlistSuccess(payload) {
    return {
        type: WHAT_IS_HOT_LIST_SUCCESS,
        payload: payload
    };
}
export function whatishotlistFailure(payload) {
    return {
        type: WHAT_IS_HOT_LIST_FAILURE,
        payload: payload
    };
}


export function whatishotlist(datalist) {
    const data = {
        campaign_id: datalist
    }
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/hotcampaign',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(whatishotlistLoading());
        axios(

            // config
            {
                method: "POST",
                url: `${SERVERURL}hotcampaign`,
                // url: 'https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/hotcampaign',
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(whatishotlistSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(whatishotlistFailure(error));
            });
    };
}

//<=====Manager workstack list=====>
export function managerWorkStacklistLoading() {
    return {
        type: MANAGERWORKSTACK_LIST_LOADING,
    };
}
export function managerWorkStacklistSuccess(payload) {
    return {
        type: MANAGERWORKSTACK_LIST_SUCCESS,
        payload: payload
    };
}
export function managerWorkStacklistFailure(payload) {
    return {
        type: MANAGERWORKSTACK_LIST_FAILURE,
        payload: payload
    };
}


export function managerWorkStacklist(userinfo) {
    const data = userinfo
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/workstacklist',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(managerWorkStacklistLoading());
        axios(
            // config
            {
                method: "POST",
                url: `${SERVERURL}workstacklist`,
                // url: 'https://ld30g2mvlb.execute-api.us-east-1.amazonaws.com/dev/workstacklist',
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(managerWorkStacklistSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(managerWorkStacklistFailure(error));
            });
    };
}

//<=====Ready to list=====>
export function readyToBilllistLoading() {
    return {
        type: READYTOBILL_LIST_LOADING,
    };
}
export function readyToBilllistSuccess(payload) {
    return {
        type: READYTOBILL_LIST_SUCCESS,
        payload: payload
    };
}
export function readyToBilllistFailure(payload) {
    return {
        type: READYTOBILL_LIST_FAILURE,
        payload: payload
    };
}


export function readyToBilllist(userinfo) {
    const data = userinfo
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/readytobill',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(readyToBilllistLoading());
        axios(
            // config
            {
                method: "POST",
                url: `${SERVERURL}readytobill`,
                // url: 'https://kiaym1mmc3.execute-api.us-east-1.amazonaws.com/dev/readytobill',
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(readyToBilllistSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(readyToBilllistFailure(error));
            });
    };
}

//<=====manager Notes=====>
export function managerWorkStackNotesLoading() {
    return {
        type: MANAGER_NOTES_LOADING,
    };
}
export function managerWorkStackNotesSuccess(payload) {
    return {
        type: MANAGER_NOTES_SUCCESS,
        payload: payload
    };
}
export function managerWorkStackNotesFailure(payload) {
    return {
        type: MANAGER_NOTES_FAILURE,
        payload: payload
    };
}


export function managerWorkStackNotes(userinfo) {
    const data = userinfo
    // var config = {
    //     method: 'POST',
    //     url: 'https://6uewr10nyf.execute-api.us-east-1.amazonaws.com/production/managernotes',
    //     headers: {
    //         'Authorization': `Basic ${token}`
    //     },
    //     data
    // };
    return dispatch => {
        dispatch(managerWorkStackNotesLoading());
        axios(
            // config
            {
                method: "POST",
                url: `${SERVERURL}managernotes`,
                // url: 'https://o04b0v7ief.execute-api.us-east-1.amazonaws.com/dev/managernotes',
                crossDomain: true,
                data
            }
        )
            .then(res => {
                if (res.status === 200) {
                    dispatch(managerWorkStackNotesSuccess(res.data));
                }
            })
            .catch(error => {
                dispatch(managerWorkStackNotesFailure(error));
            });
    };
}



//<=====user information=====>
export function arcManloginLoading() {
    return {
        type: ARCMAN_LOGIN_LOADING,
    };
}
export function arcManloginSuccess(payload) {
    return {
        type: ARCMAN_LOGIN_SUCCESS,
        payload: payload
    };
}
export function arcManloginFailure(payload) {
    return {
        type: ARCMAN_LOGIN_FAILURE,
        payload: payload
    };
}

export const userDetails = () => async dispatch => {
    dispatch(arcManloginLoading());
    let userdetails = await localStorage.getItem("userdetails");
    if (userdetails) {
        userdetails = JSON.parse(userdetails);
        dispatch(arcManloginSuccess(userdetails));
    } else {
        dispatch(arcManloginFailure());
    }
};

export function arcManlogin(userinfo) {
    let email = userinfo.email;
    let password = userinfo.password;
    return async dispatch => {
        dispatch(arcManloginLoading());
        try {
            const user = await Auth.signIn(email, password);
            const userdetails = JSON.stringify(user);
            localStorage.setItem("userdetails", userdetails)
            dispatch(arcManloginSuccess(user));
        }
        catch (err) {
            dispatch(arcManloginFailure(err));
        }

    };
}












