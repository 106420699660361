import React, { Component } from "react";
import "../../styles/Styles.css";
import "../../styles/CreateCampaignStyles.css"
import backarrow from "../../assets/images/backarrow.png";
import { Auth } from 'aws-amplify';
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import { campaignUpdate, customerlastrecords, toolSwap } from '../../actions/Campaignactions';
import Header from "../../components/Header";
import { connect } from 'react-redux';
import 'react-dropdown/style.css';
import LeftNavigation from "../../components/LeftNavigation";

// const options = [
//     { value: 'Government', label: 'Government' },
//     { value: 'Private', label: 'Private' },
// ];


class CampaignReview extends Component {
    constructor() {
        // this.multiselectRef = React.createRef();
        super();
        this.state = {
            redirectpath: '',
            campaign_id: '',
            fields: {},
            fields1: {}
        };
    }

    //Signout
    signOut = async () => {
        try {
            await localStorage.removeItem('userdetails');
            await localStorage.removeItem('linkedinauth')
            await Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            this.setState({ redirectpath: '/' })
        }
        catch (error) {
            console.log('error sign out:', error)
        }
    };

    //redirect
    redirecToBack = (event) => {
        this.setState({ redirectpath: '/createcampaigns' })
    };


    redirecToCmpaignsetup = (event) => {
        this.setState({ redirectpath: '/campaignsetup' })
    };

    componentDidMount() {
        const data = {
            campaign_id: this.props.location.state !== undefined && this.props.location.state.campaign_id
        }

        this.props.toolSwap(data)
        this.props.customerlastrecords()
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.campaignUpdate &&
            this.props.campaignReducer.campaignUpdate !==
            nextProps.campaignReducer.campaignUpdate &&
            this.props.campaignReducer.campaignUpdateSuccess === true
        ) {
            this.props.history.push('/toolsequencing', { campaign_id: this.props.campaignReducer.campaignUpdate && this.props.campaignReducer.campaignUpdate.insertId });
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.lastRecords &&
            this.props.campaignReducer.lastRecords !==
            nextProps.campaignReducer.lastRecords &&
            this.props.campaignReducer.lastRecordsSuccess === true
        ) {
            this.setState({ fields: this.props.campaignReducer.lastRecords[0] })
        }
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.toolSwapList &&
            this.props.campaignReducer.toolSwapList !==
            nextProps.campaignReducer.toolSwapList &&
            this.props.campaignReducer.toolSwapListSuccess === true
        ) {
            this.setState({ fields1: this.props.campaignReducer.toolSwapList[0] })
        }
    }



    gotoTool = () => {
        this.setState({redirectpath: '/toolsequencing'})
    };

    render() {
        const { redirectpath, fields, fields1 } = this.state;
        var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        let cdate = new Date(fields1.deadline_date)
        let mname = monthShortNames[cdate.getMonth()] + ' ' + cdate.getDate();

        let totalpayment = (fields1 && fields1.price_lead) * (fields1 && fields1.required_leads)

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: this.props.location.state !== undefined && this.props.location.state.campaign_id } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 col-12 col-sm-12 menublock">
                        <LeftNavigation />
                    </div>
                    <div className="col-md-10 header">
                        <Header />
                        <div className="row maindiv">
                            <div className="col-md-12 mb-2">
                                <img
                                    onClick={this.redirecToBack}
                                    src={backarrow}
                                    style={{ height: "24px", width: "24px", alignSelf: 'center', justifyContent: 'center' }}
                                    alt="backarrow"
                                />
                            </div>
                        </div>

                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Pending Campaigns</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-md-between">
                                <span className="campaignsetup12">Invoicing Information</span>
                                <button
                                    onClick={this.gotoTool}
                                    className="createbuttonnext mt-4"
                                >
                                    <span className="createtext">Next</span>
                                </button>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                                <div className="listcontainer">
                                    <Scrollbars
                                        style={{ width: '100%' }}
                                    >

                                        <div className="container">
                                            <div
                                                //  style={{background: 'red'}}
                                                className="row" >
                                                <div className="col-md-12 col-sm-12 col-12">
                                                    <span className="invoicesfor">Invoice For</span>
                                                </div>
                                            </div>
                                            <div
                                                //  style={{background: 'red'}}
                                                className="row mt-3" >
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortext">{fields && fields.customer_name ? fields.customer_name : ''}</span>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortext">{this.props.location.state.userinfo && this.props.location.state.userinfo.campaign_name}</span>
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortextlight">
                                                        {fields && fields.street ? fields.street : ''}
                                                    </span>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortextlight">
                                                        Open Campaign
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="invoicesborder pt-2 pb-2">


                                            </div>
                                            <div
                                                //  style={{background: 'red'}}
                                                className="row mt-2">
                                                <div className="col-md-12 col-sm-12 col-12">
                                                    <span className="invoicesfor">Campaign Information</span>
                                                </div>
                                            </div>
                                            <div
                                                //  style={{background: 'red'}}
                                                className="row mt-3" >
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortext">
                                                        Required Leads
                                                    </span>
                                                    <p className="invoicesfortext pt-2">
                                                        Deadline
                                                    </p>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortext">
                                                        {fields1 && fields1.required_leads ? fields1.required_leads : ''}
                                                    </span>
                                                    <p className="invoicesfortext">
                                                        {mname ? mname : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="invoicesborder pt-2 pb-2">
                                            </div>
                                            <div
                                                //  style={{background: 'red'}}
                                                className="row mt-3" >
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortext">
                                                        Total Payment
                                                    </span>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-6">
                                                    <span className="invoicesfortext">
                                                        {totalpayment.toString()}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        campaignsupdate: userinfo => dispatch(campaignUpdate(userinfo)),
        customerlastrecords: userinfo => dispatch(customerlastrecords(userinfo)),
        toolSwap: userinfo => dispatch(toolSwap(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignReview);
