import React, { Component } from 'react';
import bell from "../assets/images/bell.png";
import useroriginal from "../assets/images/user-original.png";
import { userDetails } from '../actions/ManagerActions';
import { notificationList, profileUpadte, profiledata, usersInfo } from '../actions/Campaignactions';
import "../styles/HeaderStyles.css";
import check from "../assets/images/check.png";
import socketIOClient from "socket.io-client";
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import S3FileUpload from 'react-s3';
import axios from "axios"

// var socket;
let informationdata = ''
let profilephoto = ''
// const URL = 'http://ec2-3-89-90-185.compute-1.amazonaws.com:3001/'


const config = {
    bucketName: 'solpubprofile',
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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',
    region: 'us-east-1',
}


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            userData: '',
            visible: false,
            visibleprofile: false,
            customernotification: [],
            notificationList: [],
            profile: '',
            fields: {},
            linkedindata: [],
            errors: {},
            pfdata: ''

            // userdetails1: ''
        };
        // socket = socketIOClient(URL);

    }

    openModal() {
        this.setState({
            visible: true
        });
    }

    closeModal() {
        this.setState({
            visible: false
        });
    }
    // For profile modal
    openModal1() {
        this.setState({
            visibleprofile: true
        });
    }

    closeModal1() {
        this.setState({
            visibleprofile: false
        });
    }

    // getData = (data) => {
    //     this.setState({ customernotification: data })
    // }
    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            const userinfo = {
                sub: userdetails && userdetails.attributes.sub
            }
            const userinfo1 = {
                sub_id: userdetails && userdetails.attributes.sub
            }
            this.props.profiledata(userinfo1)
            this.props.notificationList(userinfo)
        }

        let usersinfo = await localStorage.getItem("usersinfo");
        if (usersinfo) {
            usersinfo = JSON.parse(usersinfo);
            const userinfo1 = {
                sub_id: usersinfo && usersinfo.length > 0 && usersinfo[0].Value
            }
            this.props.profiledata(userinfo1)

        }

        this.props.userDetails();
        this.props.usersInfo();

    }

    componentDidUpdate(nextProps) {

        if (
            this.props.managerReducer &&
            this.props.managerReducer.arcMan &&
            this.props.managerReducer.arcMan !==
            nextProps.managerReducer.arcMan &&
            this.props.managerReducer.arcManSuccess === true
        ) {
            this.setState({ userData: this.props.managerReducer && this.props.managerReducer.arcMan && this.props.managerReducer.arcMan.attributes })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.notificationList &&
            this.props.campaignReducer.notificationList !==
            nextProps.campaignReducer.notificationList &&
            this.props.campaignReducer.notificationListSuccess === true
        ) {

            this.setState({ notificationList: this.props.campaignReducer.notificationList })

        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.linkedindata &&
            this.props.campaignReducer.linkedindata !==
            nextProps.campaignReducer.linkedindata &&
            this.props.campaignReducer.linkedindataSuccess === true
        ) {
            this.setState({ linkedindata: this.props.campaignReducer.linkedindata })
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.profileupdate &&
            this.props.campaignReducer.profileupdate !==
            nextProps.campaignReducer.profileupdate &&
            this.props.campaignReducer.profileupdateSuccess === true
        ) {
            const userinfo1 = {
                sub_id: this.state.userData && this.state.userData.sub
            }
            this.props.profiledata(userinfo1)
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.profiledata &&
            this.props.campaignReducer.profiledata !==
            nextProps.campaignReducer.profiledata &&
            this.props.campaignReducer.profiledataSuccess === true
        ) {
            this.setState({ fields: this.props.campaignReducer.profiledata })

        }
    }

    uploadFile = ({ target: { files } }) => {
        this.setState({ profile: files[0] })
        S3FileUpload
            .uploadFile(files[0], config)
            .then(data => {
                profilephoto = data.location
                this.setState({ pfdata: data.location })
            })
            .catch(err => console.error(err))
    }

    //to handle input field
    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    validateProfile() {
        const errors = {};
        const { fields } = this.state;
        const strings = /^[A-Za-z0-9 ]+$/;

        let formIsValid = true;

        if (fields.firstname && fields.firstname.length < 3) {
            errors.firstname = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.firstname && fields.firstname.length > 15) {
            errors.firstname = 'firstname name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.firstname && !strings.test(fields.firstname)) {
            errors.firstname = 'Please enter characters only';
            formIsValid = false;
        }

        if (fields.lastname && fields.lastname.length < 3) {
            errors.lastname = 'Please enter minimum 3 characters';
            formIsValid = false;
        }
        if (fields.lastname && fields.lastname.length > 15) {
            errors.lastname = 'lastname name should be 15 characters only';
            formIsValid = false;
        }
        if (fields.lastname && !strings.test(fields.lastname)) {
            errors.lastname = 'Please enter characters only';
            formIsValid = false;
        }

        this.setState({ errors });
        return formIsValid;


    }

    //customersetup insert
    upDateProfile = () => {
        const { fields } = this.state;
        if (this.validateProfile()) {
            this.setState({
                visibleprofile: false
            });
            const userinfo = {
                firstname: this.state.fields.firstname ? this.state.fields.firstname : fields && fields[0] ? fields[0].firstname : null,
                lastname: this.state.fields.lastname ? this.state.fields.lastname : fields && fields[0] ? fields[0].lastname : null,
                sub_id: this.state.linkedindata && this.state.linkedindata.length > 0 ? this.state.linkedindata[0].Value : this.state.userData ? this.state.userData.sub : null,
                profilephoto: profilephoto ? profilephoto : fields && fields[0] ? fields[0].profile_photo : null
            };
            this.props.profileUpadte(userinfo);
        }
    };


    render() {
        const { userData, notificationList, fields, linkedindata, errors, pfdata } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9 col-lg-9  d-flex justify-content-end mt-3 parent">
                        <img
                            onClick={() => this.openModal()}
                            className="image1"
                            src={bell}
                            style={{ height: "24px", width: "24px", alignSelf: 'center', justifyContent: 'center' }}
                            alt="bell"
                        />
                    </div>
                    <div className="col-md-1 mt-2 admin">
                        <img
                            onClick={() => this.openModal1()}
                            // 
                            src={fields && fields.length > 0 ? fields[0].profile_photo : useroriginal}
                            style={{
                                width: '55px',
                                height: '55px',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                alignSelf: 'center',
                                marginTop: '10px',
                                boxShadow: '0 2px 2px #ddd',
                                // border: '3px solid #fff'
                            }}
                            alt="profile"
                        />

                    </div>
                    <div className="col-md-2 mt-3 d-flex flex-column">
                        <span className="alexa">
                            {linkedindata && linkedindata.UserAttributes && linkedindata.UserAttributes.length > 0 ? linkedindata.UserAttributes[2].Value + ' ' + linkedindata.UserAttributes[3].Value : fields && fields[0] ?
                                fields[0].firstname + '  ' + fields[0].lastname : userData && userData.email ?
                                    userData.email :
                                    informationdata ? informationdata[2].Value + ' ' + informationdata[3].Value :
                                        null}

                        </span>
                        <span className="admin_user">
                            {userData && userData.profile}
                        </span>
                    </div>
                </div>
                <Modal visible={this.state.visible} width={'65%'} height={'89%'} effect="fadeInDown" onClickAway={() => this.closeModal()}>
                    {/* {this.state.customernotification && this.state.customernotification.length > 0 ? */}
                    {notificationList && notificationList.length > 0 && notificationList.map((item, index) => (
                        <div
                            key={index}
                            className="p-4"
                            style={{ borderBottom: '1px solid #D3D3D3', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="notificationtext">
                                        {item.notification_msg}
                                    </span>
                                </div>
                            </div>
                            <img
                                src={check}
                                alt="check"
                            />
                        </div>
                    ))}
                </Modal>

                <Modal visible={this.state.visibleprofile} width={'30%'} height={'89%'} effect="fadeInDown" onClickAway={() => this.closeModal1()}>
                    <div className="profilecontainer">
                        <label htmlFor="chooseFileButton" style={{ width: '110px', height: '110px', marginLeft: '150px' }}>
                            <div className="col-md-12 col-sm-12 col-12 fileContainers">
                                <img
                                    onClick={() => this.openModal1()}
                                    // 
                                    src={pfdata ? pfdata : useroriginal}
                                    style={{
                                        width: '110px',
                                        height: '110px',
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                        alignSelf: 'center',
                                        marginTop: '10px',
                                        boxShadow: '0 2px 2px #ddd'
                                    }}
                                    alt="profile"
                                />
                                <input
                                    className="Field"
                                    id="chooseFileButton"
                                    accept="image/*"
                                    type="file"
                                    onChange={this.uploadFile}
                                />
                            </div>

                        </label>
                        <div className="col-md-12 col-12 col-sm-12 mt-5 pt-3">
                            <p className="profilelabel">First Name</p>
                            <input
                                type="text"
                                onChange={this.handleChange.bind(this, "firstname")}
                                className="profiletext"
                                placeholder="Enter first name"
                            />
                            <span className="errors mt-2">
                                {errors && errors.firstname}
                            </span>
                        </div>
                        <div className="col-md-12 col-12 col-sm-12 mt-2 pt-3">
                            <p className="profilelabel">Last Name</p>
                            <input
                                type="text"
                                // value={fields.sponsor}
                                onChange={this.handleChange.bind(this, "lastname")}
                                className="profiletext"
                                placeholder="Enter last name"
                            />
                            <span className="errors mt-2">
                                {errors && errors.lastname}
                            </span>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                            <button
                                onClick={this.upDateProfile}
                                className="profilebutton mt-4"
                            >
                                <span className="profiletextbutton">Save</span>
                            </button>
                        </div>
                    </div>

                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        managerReducer: state.managerReducer,
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        userDetails: userinfo => dispatch(userDetails(userinfo)),
        notificationList: userinfo => dispatch(notificationList(userinfo)),
        profileUpadte: userinfo => dispatch(profileUpadte(userinfo)),
        profiledata: userinfo => dispatch(profiledata(userinfo)),
        usersInfo: userinfo => dispatch(usersInfo(userinfo)),


    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);




