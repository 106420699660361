import React, { Component } from 'react';
import line from "../assets/images/line.svg";
import logo from "../assets/images/logo.png";
import { Redirect, Link } from 'react-router-dom';
import "../styles/Styles.css";
import { connect } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import addfriend from "../assets/images/add-friend.png";
import dashboard from "../assets/images/dashboard.png";




import { Auth } from 'aws-amplify';
import axios from 'axios';


class NetlineNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectpath: ''
        }
    }

    handleLogOut = async () => {
        const data = {
            token: this.props.data
        }
        axios({
            method: "POST",
            url: `http://localhost:8080/auth/signout`,
            crossDomain: true,
            data
        })
            .then(res => {
                console.log("hi logout linkedin")
            })
        // axios.get(`http://localhost:8080/auth/signout`)

        try {
            await Auth.signOut({ global: true });
            await localStorage.removeItem('userdetails');
            await localStorage.removeItem('usersinfo')
            this.setState({ redirectpath: '/' })
        } catch (err) {
            console.log(err, 'error signing out...');
        }
    };




    render() {
        const { redirectpath } = this.state;
        return (
            <div>
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div
                    className="row"
                    style={{ padding: "27px 0" }}
                >
                    <img
                        src={logo}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />
                    <img
                        src={line}
                        alt="Testbx"
                        style={{ width: "auto", height: "70px", margin: "0px auto" }}
                    />

                </div>
                <ul className="nav row">
                    <li
                        id={window.location.pathname === '/dashboard' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/dashboard"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img src={dashboard}
                                    style={{ height: "20px", alignSelf: 'center' }}
                                />

                            </span>
                            <span
                                style={{ alignSelf: 'center' }}>Dashboard</span>
                        </Link>
                    </li>
                    <li
                        id={window.location.pathname === '/step1' ? 'selectednav' : window.location.pathname === '/netlineprogress' ? 'selectednav' : ''}

                    >
                        <Link
                            to="/step1"
                            style={{
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textDecoration: "none"
                            }}
                        >
                            <span style={{ marginRight: "15px", display: "inline-flex" }}>
                                <img src={addfriend}
                                    style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                                />

                            </span>
                            <span
                                style={{ alignSelf: 'center' }}> Submit</span>
                        </Link>
                    </li>

                    <li
                        onClick={this.handleLogOut}
                    >
                        <span style={{ marginRight: "15px", display: "inline-flex" }}>
                            <LogoutIcon
                                style={{ height: "20px", width: "20px", alignSelf: 'center' }}
                            />
                        </span>
                        <span
                            style={{ alignSelf: 'center' }}>  Log out</span>
                    </li>
                </ul>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        // linkedinlogout: userinfo => dispatch(linkedinlogout(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NetlineNav);


