import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import moment from "moment";
import Header from "../../../components/Header";
import { saveAs } from 'file-saver';
import { nlpDashboard } from '../../../actions/Campaignactions';

import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import NLPNav from "../../../components/NLPNav";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import AWS from 'aws-sdk';




//dev
AWS.config.update({
    accessKeyId: 'AKIAXTPFNQTGZIW5Y5ZF',
    secretAccessKey: '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'
})

//uat
// AWS.config.update({
//     accessKeyId: 'AKIAQ4QZYNARDHV752W5',
//     secretAccessKey: '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'
// })

//dev
// const S3_BUCKET = 'solpub-campaign-workflow';

//uat
const S3_BUCKET = 'leadshaper';

//dev
// const S3_BUCKET11 = 'titlematch';

//uat
const S3_BUCKET11 = 'leadshaper';

const REGION = 'us-east-1';
const URL_EXPIRATION_TIME = 900;


const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
    signatureVersion: 'v4',
})


const myBucket11 = new AWS.S3({
    params: { Bucket: S3_BUCKET11 },
    region: REGION,
    signatureVersion: 'v4',
})


toast.configure()

class NLPDashboard extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            campaignList: [],
            fields: {},
            fields1: {},
            noOfPages: [],
            itemsPerPage: 10,
            totalitems: '',
            page: 1,
            functioncodes: null,
            datashaper_id: ''
        };
    }



    handleChange = (event, value) => {
        this.setState({ page: value })
    };





    generatePreSignedPutUrl11 = (item) => {
        myBucket11.getSignedUrl('getObject', {
            //dev
            Key: item,
            //uat
            // Key: `datashaperInput/${item}`,
            Expires: URL_EXPIRATION_TIME
        }, (err, url) => {
            saveAs(url)
            return url
        });
    }








    downLoadInputfile = (item) => {
        // console.log(item !== null && item !== undefined && item !== '', "downLoadInputfile")
        if (item !== null && item !== undefined && item !== '') {
            this.generatePreSignedPutUrl11(item)

        }

    }



    async componentDidMount() {
        this.props.nlpDashboard();
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.nlpDashboard &&
            this.props.campaignReducer.nlpDashboard !==
            nextProps.campaignReducer.nlpDashboard &&
            this.props.campaignReducer.nlpDashboardSuccess === true
        ) {
            const itemsPerPage = 10

            console.log(this.props.campaignReducer.nlpDashboard, "this.props.campaignReducer.nlpDashboard")
            this.setState({ campaignList: this.props.campaignReducer.nlpDashboard, noOfPages: Math.ceil(this.props.campaignReducer.nlpDashboard.length / itemsPerPage), totalitems: this.props.campaignReducer.nlpDashboard.length })
        }

    }

    render() {
        const { redirectpath, campaignList, fields, fields1, noOfPages, page, itemsPerPage, totalitems } = this.state;

        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={{ pathname: redirectpath, state: { campaign_id: fields.campaign_id, status: this.state.functioncodes && this.state.functioncodes.value, fields1 } }} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NLPNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChange}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>



                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">


                                <div className="mt-5">
                                    <div className="scroller mt-4">
                                        {campaignList && campaignList.length === 0 ?
                                            <div
                                                className="opencampaignheader mt-5"
                                                style={{ textAlign: 'center' }}
                                            >no records found</div> :
                                            <table className="table">
                                                <thead>
                                                    <tr className="stickyheader">
                                                        <th className="opencampaignheader">  Batch No</th>
                                                        <th className="opencampaignheader">Source File</th>
                                                        <th className="opencampaignheader">NLP Output File</th>
                                                        <th className="opencampaignheader"> Delivery Template Input File</th>
                                                        <th className="opencampaignheader">Shaping Recipe Name</th>
                                                        {/* <th className="opencampaignheader"> Delivery Template Name</th> */}
                                                        <th className="opencampaignheader">   Leadshaper Output File</th>
                                                        <th className="opencampaignheader"> Submitted Date</th>
                                                        <th className="opencampaignheader"> Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {campaignList && campaignList.length > 0 && campaignList
                                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                            <tr key={index}>

                                                                <td className="opencampaigntext">
                                                                    {item.nlp_id}
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadInputfile(item.input_file)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadInputfile(item.nlp_output_file)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadInputfile(item.delivery_template_input_file)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>

                                                               

                                                                {/* <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadInputfile(item.delivery_template_recipe_name)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td> */}
                                                                <td className="opencampaigntext">{item.delivery_template_recipe_name}</td>

                                                                <td className="opencampaigntext">
                                                                    <CloudDownloadIcon
                                                                        onClick={() => this.downLoadInputfile(item.leadshaper_output_file)}
                                                                        style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                                </td>
                                                                <td className="opencampaigntext">{moment(item.created_at).format('MM-DD-YYYY')}</td>

                                                                <td className="opencampaigntext">
                                                                    {item.status}
                                                                </td>


                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        nlpDashboard: userinfo => dispatch(nlpDashboard(userinfo))


    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NLPDashboard);

