import React, { Component } from "react";
import "../../styles/ManagerStyles.css";
import "../../styles/CreateCampaignStyles.css";
import moment from "moment";
import filter1 from "../../assets/images/filter.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from 'react-dropdown';
import DeleteIcon from '@material-ui/icons/Delete';
import { Modal } from "react-bootstrap";
import RateReviewIcon from '@material-ui/icons/RateReview';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MoveToInboxSharpIcon from '@material-ui/icons/MoveToInboxSharp';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import 'react-dropdown/style.css';
import Header from "../../components/Header";
import { hotMove, whatishotlist, sesNotification, campaignnamelist } from '../../actions/ManagerActions';
import { userList } from '../../actions/Campaignactions';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftNavigation2 from '../../components/LeftNavigation2';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";


toast.configure()


// const statusdata = [
//     { value: '', label: 'Select...' },
//     { value: 'Active', label: 'Active' },
//     { value: 'Created', label: 'Created' },
//     { value: 'Pending', label: 'Pending' }
// ];
const headers = [
    // { value: '', label: 'Select...' },
    'Active',
    'Created',
    'Pending'
];


class Hot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectpath: '',
            Steps: [],
            itemsPerPage: 10,
            totalitems: '',
            addmore: false,
            page: 1,
            errors: {},
            description: '',
            functioncodes: null,
            assigner_user_list: [],
            campaignList: [],
            user_list: [],
            campaignname_list: [],
            opencampaignList: [],
            whatishotList: [],
            sesNotificationData: null,
            filtersdata: null,
            visible: false
        };
    }

    // handleChange = (e) => {
    //     const sesInfo = {
    //         tool_name: this.state.sesNotificationData,
    //         assigned_user: e.target.value
    //     }
    //     this.props.sesNotification(sesInfo)
    // };

    handleClick = (item) => {
        this.setState({ sesNotificationData: item.tool_name })
    }


    _onSelectFunctions = (e) => {
        this.setState({ functioncodes: e.target.value });
    };

    _onFilter = (filtersdata) => {
        this.setState({ filtersdata });
        let campaign_id = filtersdata.value
        this.props.whatisHotList(campaign_id)
    }

    //to handle input field
    handleChangeDesc = (e) => {
        this.setState({ description: e.target.value });
    }
    // <----- to handle pagination ----->
    handleChangePagination = (event, value) => {
        this.setState({ page: value })
    };

    openModal = (item) => {
        this.setState({
            visible: true,
            managerworkstack_id: item.tool_camp_id
        });
    }


    DeleteIconData = (item) => {
        const Steps = this.state.Steps;

        const index = Steps.indexOf(item);
        if (index > -1) {
            Steps.splice(index, 1);
        }

        this.setState({ Steps })
    };


    closeModal = () => {
        this.setState({
            visible: false,
            addmore: false,
            Steps: []
        });
    }


    addMoreData = () => {
        const Steps = this.state.Steps;
        if (this.state.description !== '') {
            var Action = {};
            Action.notes = this.state.description
            Steps.push({
                Action
            });
        }


        this.setState({ Steps, description: '' })
        this.setState({
            addmore: true
        });
    }



    handleOnUserList = (selectedList) => {
        // const sesInfo = {
        //     tool_name: this.state.sesNotificationData,
        //     assigned_user: selectedList
        // }
        // console.log(sesInfo, "sesinfor")
        // this.props.sesNotification(sesInfo)
        this.setState({ assigner_user_list: selectedList })
    }




    onSubmit = (item) => {
        const userinfo = {
            assigned_to_me: this.props.auth && this.props.auth.user
                && this.props.auth.user.attributes && this.props.auth.user.attributes.sub,
            campaign_name: item.campaign_name,
            created_at: item.created_at,
            deadline_date: item.deadline_date,
            steps_assigned: item.tool_name

        };
        this.props.hotMove(userinfo)
    }

    notify = () => {
        toast.success('Moved into your workstack', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    notifyses = () => {
        toast.success('Mail sent to assigned user', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    notifysesnot = () => {
        toast.success('Email address is not verified.', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    componentDidMount() {
        this.props.whatisHotList();
        this.props.userlist();
        this.props.campaignnamelist()

    }

    clickupArrow = (e) => {
        const data = {
            sort: "DESC"
        }
        this.props.whatisHotList(data);
    }



    clickDownArrow = () => {
        const data = {
            sort: "ASC"
        }
        this.props.whatisHotList(data);
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.managerReducer &&
            this.props.managerReducer.whatishotList &&
            this.props.managerReducer.whatishotList !==
            nextProps.managerReducer.whatishotList &&
            this.props.managerReducer.whatishotListSuccess === true
        ) {
            const itemsPerPage = 10
            this.setState({
                whatishotList: this.props.managerReducer.whatishotList,
                noOfPages: Math.ceil(this.props.managerReducer.whatishotList.length / itemsPerPage),
                totalitems: this.props.managerReducer.whatishotList.length

            })

        }
        if (
            this.props.managerReducer &&
            this.props.managerReducer.hotMove &&
            this.props.managerReducer.hotMove !==
            nextProps.managerReducer.hotMove &&
            this.props.managerReducer.hotMoveSuccess === true
        ) {

            this.notify()
        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.sesNotification &&
            this.props.managerReducer.sesNotification !==
            nextProps.managerReducer.sesNotification &&
            this.props.managerReducer.sesNotificationSuccess === true
        ) {
            this.notifyses()
        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.campaignnamelist &&
            this.props.managerReducer.campaignnamelist !==
            nextProps.managerReducer.campaignnamelist &&
            this.props.managerReducer.campaignnamelistSuccess === true
        ) {
            const campgnnameList = [];
            this.props.managerReducer.campaignnamelist && this.props.managerReducer.campaignnamelist.map((item, index) => {
                if (item.campaign_name) {
                    campgnnameList.push({
                        value: item.campaign_id,
                        label: item.campaign_name
                    });
                }
                return true;
            })
            this.setState({ campaignname_list: campgnnameList })
        }

        if (
            this.props.managerReducer &&
            this.props.managerReducer.sesNotification &&
            this.props.managerReducer.sesNotification !==
            nextProps.managerReducer.sesNotification &&
            this.props.managerReducer.sesNotificationSuccess === false
        ) {
            this.notifysesnot()
        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.userList &&
            this.props.campaignReducer.userList !==
            nextProps.campaignReducer.userList &&
            this.props.campaignReducer.userListSuccess === true
        ) {
            const userList = [];
            this.props.campaignReducer.userList && this.props.campaignReducer.userList.users.map((item, index) => {
                if (item.Attributes[3]) {
                    userList.push({
                        key: item.Attributes[3].Value && item.Attributes[3].Value.length > 8 ? `${item.Attributes[3].Value.substring(0, 8)}.....` : item.Attributes[3].Value,
                        label: item.Attributes[3].Value && item.Attributes[3].Value.length > 8 ? `${item.Attributes[3].Value.substring(0, 8)}.....` : item.Attributes[3].Value
                    });
                }
                return true;
            })
            this.setState({ user_list: userList })
        }
    }


    render() {
        const { redirectpath, whatishotList, user_list, campaignname_list, noOfPages, page, itemsPerPage, totalitems, errors } = this.state;
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <LeftNavigation2 />
                    </div>

                    <div className="col-md-10 header">
                        <Header />

                        <div className="row mt-3 maindiv">
                            <div
                                //  onClick={() => this.setState({ showPanel: true })}
                                className="col-md-12 col-12 col-sm-12">
                                <span className="opencampaignheading">Whats's Hot</span>
                            </div>
                        </div>
                        <div className="row mt-4 mb-2 maindiv">
                            <div className="col-md-6 col-12 col-sm-12">
                                <Stack spacing={6}>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={this.handleChangePagination}
                                        defaultPage={1}
                                        size='small'
                                        color="primary" />
                                </Stack>
                            </div>

                            <div className="col-md-4 col-12 col-sm-12">
                                <span className="netlinedate">Showing total {totalitems} entries</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-md-12 col-sm-12 col-12 d-md-flex justify-content-end">
                                <div className="col-md-7">
                                </div>
                                <div className="col-md-3 shadowdropdown">
                                    <Dropdown
                                        options={campaignname_list}
                                        onChange={this._onFilter}
                                        className='myClassName ml-3'
                                        placeholder="Select....." />
                                </div>
                                <div className="col-md-1 d-md-flex justify-content-end">

                                    <img
                                        src={filter1}
                                        style={{ height: "29px", alignSelf: 'center' }}
                                        alt="filter"
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className="scroller mt-4">
                                    {whatishotList && whatishotList.length === 0 ?
                                        <div
                                            className="opencampaignheader mt-5"
                                            style={{ textAlign: 'center' }}
                                        >no records found</div> :
                                        <table className="table">
                                                   <thead>
                                                <tr className="stickyheader">
                                                    <th className="opencampaignheader">
                                                        {/* Name */}
                                                        <div>
                                                            Name
                                                        </div>

                                                        <div className="position-absolute">
                                                            <div className="up-arrow"
                                                                onClick={(e) => this.clickupArrow(e)}
                                                            >

                                                            </div>
                                                            <div className="down-arrow"
                                                                onClick={(e) => this.clickDownArrow(e)}
                                                            >
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th className="opencampaignheader">Step</th>
                                                    <th className="opencampaignheader">Deadline</th>
                                                    <th className="opencampaignheader"> Users</th>
                                                    <th className="opencampaignheader">Assign To</th>
                                                    <th className="opencampaignheader">  Move To<br /> My Workstack</th>
                                                    <th className="opencampaignheader">Status</th>
                                                    <th className="opencampaignheader">Notes</th>
                                                </tr>
                                            </thead>
                                            {/* <thead>
                                                <tr className="stickyheader">
                                                    <th className="opencampaignheader position-relative">
                                                        <div>
                                                            Name
                                                        </div>

                                                        <div className="position-absolute">
                                                            <div className="up-arrow"
                                                                onClick={(e) => this.clickupArrow(e)}
                                                            >

                                                            </div>
                                                            <div className="down-arrow"
                                                                onClick={(e) => this.clickDownArrow(e)}
                                                            >
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className="opencampaignheader">Step</th>
                                                    <th className="opencampaignheader">Deadline</th>
                                                    <th className="opencampaignheader"> Users</th>
                                                    <th className="opencampaignheader">Assign To</th>
                                                    <th className="opencampaignheader">  Move To<br /> My Workstack</th>
                                                    <th className="opencampaignheader">Status</th>
                                                    <th className="opencampaignheader">Notes</th>
                                                </tr>
                                            </thead> */}
                                            <tbody>

                                                {whatishotList && whatishotList.length > 0 && whatishotList
                                                    .slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="opencampaigntext mt-4">
                                                                {item.campaign_name && item.campaign_name.length > 15 ? `${item.campaign_name.substring(0, 15)}...` : item.campaign_name}
                                                            </td>
                                                            <td className="opencampaigntext"> {item.tool_name}</td>
                                                            <td className="opencampaigntext">{moment(item.deadline_date).format('MM-DD-YYYY')}</td>
                                                            <td className="opencampaigntext">{item.assigned_user}</td>
                                                            <td onClick={() => this.handleClick(item)}>
                                                                <DropdownMultiselect
                                                                    options={user_list}
                                                                    placeholder={"Select...................."}
                                                                    handleOnChange={this.handleOnUserList}
                                                                    buttonClass='userlist-button'
                                                                    name="countries"
                                                                />
                                                            </td>

                                                            <td className="opencampaigntext">
                                                                <MoveToInboxSharpIcon
                                                                    onClick={() => this.onSubmit(item)}
                                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                            </td>
                                                            <td
                                                            // onClick={() => this.handleClick(item)}
                                                            >
                                                                <select
                                                                    onChange={this._onSelectFunctions}
                                                                    className="statusdrop">
                                                                    <option value="">{item.workstack_status ? item.workstack_status : "Select Value"}</option>
                                                                    {headers.map((headers) => {
                                                                        return (
                                                                            <option key={headers}>{headers}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td className="opencampaigntext">
                                                                <RateReviewIcon
                                                                    onClick={() => this.openModal(item)}
                                                                    style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>

                            <Modal show={this.state.visible} onHide={this.closeModal}>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12 col-12 col-sm-12 mt-3">
                                            <AddCircleIcon
                                                fontSize={'large'}
                                                onClick={this.addMoreData}
                                                style={{ color: '#1D417A', alignSelf: 'center', justifyContent: 'center', marginRight: '15px' }}
                                            />
                                            <span className="createtextnoteshead">Add Note</span>
                                        </div>

                                        {this.state.addmore === true ?
                                            <div className="col-md-12 col-12 col-sm-12 mt-3">
                                                <input
                                                    type="text"
                                                    value={this.state.description}
                                                    onChange={this.handleChangeDesc}
                                                    className="workstackmultiplenotes"
                                                    placeholder="Enter Name"
                                                />
                                                <span className="errors mt-2">
                                                    {errors && errors.name}
                                                </span>
                                            </div> : null}

                                    </div>
                                    {this.state.Steps && this.state.Steps.length === 0 ?
                                        <div
                                            className="opencampaignheader mt-5"
                                            style={{ textAlign: 'center' }}
                                        >no records found</div> :
                                        <div className="mt-5">
                                            <div className="row">
                                                <div className="col-md-10 pt-3 pb-3 opencampaignheader102">
                                                    Description
                                                </div>
                                                <div className="col-md-2 pt-3 pb-3 opencampaignheader102">
                                                    Delete
                                                </div>
                                            </div>

                                            {this.state.Steps && this.state.Steps.length > 0 && this.state.Steps
                                                .map((item, index) => (

                                                    <div
                                                        className="row rowdata">
                                                        <div className="col-md-10 pt-3 pb-3 opencampaigntext102">
                                                            {/* {item.Action && item.Action.notes} */}
                                                            {item.Action && item.Action.notes && item.Action.notes.length > 50 ? `${item.Action.notes.substring(0, 50)}...` : item.Action.notes}
                                                        </div>
                                                        <div className="col-md-2 pt-3 pb-3 opencampaigntext102">
                                                            <DeleteIcon
                                                                onClick={() => this.DeleteIconData(item)}
                                                                style={{ color: '#23857A', alignSelf: 'center', justifyContent: 'center' }} />

                                                        </div>
                                                    </div>
                                                ))}

                                        </div>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="col-md-12 col-sm-12  mt-2 col-12 d-md-flex justify-content-end">
                                        <button
                                            onClick={this.notesInsertDetails}
                                            className="browsebutton  mt-4"
                                        >
                                            <span className="browsebuttontext">Save</span>
                                        </button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer,
        managerReducer: state.managerReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        userlist: userinfo => dispatch(userList(userinfo)),
        whatisHotList: userinfo => dispatch(whatishotlist(userinfo)),
        hotMove: userinfo => dispatch(hotMove(userinfo)),
        sesNotification: userinfo => dispatch(sesNotification(userinfo)),
        campaignnamelist: userinfo => dispatch(campaignnamelist(userinfo))


    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hot);

