import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from "aws-amplify";
import config from "./config";
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';


Amplify.configure({
    Auth: {
        // mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        // oauth: {
            // domain : 'your-app.auth.your-region.amazoncognito.com',
            // redirectSignIn: environment.cognito.oauthCallbackLogin,
            // redirectSignOut: environment.cognito.oauthCallbackLogout,
            // responseType: 'token',
            // scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        // }
    }
});

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();


