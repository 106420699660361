import {
    CUSTOMERS_LIST_LOADING,
    CUSTOMERS_LIST_SUCCESS,
    CUSTOMERS_LIST_FAILURE,

    CUSTOMERS_DELETE_LOADING,
    CUSTOMERS_DELETE_SUCCESS,
    CUSTOMERS_DELETE_FAILURE,

    CUSTOMERS_EDIT_LOADING,
    CUSTOMERS_EDIT_SUCCESS,
    CUSTOMERS_EDIT_FAILURE,

    CUSTOMERS_DISPLAY_DATA_LOADING,
    CUSTOMERS_DISPLAY_DATA_SUCCESS,
    CUSTOMERS_DISPLAY_DATA_FAILURE


} from "../actions/constants";

const initialState = {
    customersList: {},
    customersListSuccess: false,

    customersdelete: {},
    customersdeleteSuccess: false,

    customersedit: {},
    customerseditSuccess: false,

    customersdisplay: {},
    customersdisplaySuccess: false,
};
export default function customerReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case CUSTOMERS_DISPLAY_DATA_LOADING:
            return {
                ...state,
                customersdisplaySuccess: false
            };
        case CUSTOMERS_DISPLAY_DATA_SUCCESS:
            return {
                ...state,
                customersdisplay: action.payload,
                customersdisplaySuccess: true
            };
        case CUSTOMERS_DISPLAY_DATA_FAILURE:
            return {
                ...state,
                customersdisplay: action.payload,
                customersdisplaySuccess: false
            };

        case CUSTOMERS_LIST_LOADING:
            return {
                ...state,
                customersListSuccess: false
            };
        case CUSTOMERS_LIST_SUCCESS:
            // console.log(action.payload,"paylodd")
            return {
                ...state,
                customersList: action.payload,
                customersListSuccess: true
            };
        case CUSTOMERS_LIST_FAILURE:
            return {
                ...state,
                customersList: action.payload,
                customersListSuccess: false
            };

        case CUSTOMERS_DELETE_LOADING:
            return {
                ...state,
                customersdeleteSuccess: false
            };
        case CUSTOMERS_DELETE_SUCCESS:
            return {
                ...state,
                customersdelete: action.payload,
                customersdeleteSuccess: true
            };
        case CUSTOMERS_DELETE_FAILURE:
            return {
                ...state,
                customersdelete: action.payload,
                customersdeleteSuccess: false
            };

        case CUSTOMERS_EDIT_LOADING:
            return {
                ...state,
                customerseditSuccess: false
            };
        case CUSTOMERS_EDIT_SUCCESS:
            return {
                ...state,
                customersedit: action.payload,
                customerseditSuccess: true
            };
        case CUSTOMERS_EDIT_FAILURE:
            return {
                ...state,
                customersedit: action.payload,
                customerseditSuccess: false
            };
        default:
            return state;
    }
}