import React, { Component } from "react";
import "../../../styles/DatashaperStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { datashaperlist, dataSetList } from "../../../actions/Campaignactions";
import Header from "../../../components/Header";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NetlineNav from "../../../components/NetlineNav";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import S3FileUpload from 'react-s3';



toast.configure()

const percentage = 63;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const inputconfig = {
    bucketName: 'solpubtest',
    dirName: 'suppressionfiles',
    accessKeyId: 'AKIAXTPFNQTGXBMUUTGL',
    secretAccessKey: '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',
    region: 'us-east-1',
}

const inputfiles3 = []

const names = [
    'Address',
    'City',
    'Company',
    'Phone Number'
];
const recipe = [
    'Data Cleaning and normalizing',
    'rough character scrubing'

]
class NetLineProgress extends Component {
    constructor() {
        super();
        this.state = {
            redirectpath: '',
            sub: '',
            fields: {},
            errors: {},
            input_files: [],
            sourcefile: [],
            recipes: [],
            recipeList: [],
            datasetList: [],

        };
        // socket = socketIOClient(URL);
    }



    handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    //handle input file
    handleInputFileUpload = async (file) => {
        const result = await S3FileUpload.uploadFile(file, inputconfig)
            .then((data) => {
                if (data && data.location) {
                    inputfiles3.push({
                        inputfiles3: data.location,
                    });
                }
            })
            .catch((err) => console.error(err));
        return result;
    };

    uploadInputFiles = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            this.handleInputFileUpload(e.target.files[i]);
        }
        this.setState({ input_files: [...this.state.input_files, ...e.target.files] })

    }

    notify = () => {
        toast.success('successfully Saved', { position: toast.POSITION.TOP_CENTER }, { autoClose: 3000 })
    }

    //handle source files
    handleSourceFile = (event) => {
        // console.log(event.target.value, "value")
        const {
            target: { value },
        } = event;

        this.setState({ sourcefile: typeof value === 'string' ? value.split(',') : value })
    }

    handleRecipes = (event) => {
        // console.log(event.target.value, "value")
        const {
            target: { value },
        } = event;

        this.setState({ recipes: typeof value === 'string' ? value.split(',') : value })
    }

    // getData = (params) => {
    //     this.setState({ customernotification: params })
    // }

    async componentDidMount() {
        let userdetails = await localStorage.getItem("userdetails");
        if (userdetails) {
            userdetails = JSON.parse(userdetails);
            this.setState({ sub: userdetails && userdetails.attributes.sub })
        }

        this.props.datashaperlist();
        this.props.dataSetList()
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlist !==
            nextProps.campaignReducer.datashaperlist &&
            this.props.campaignReducer.datashaperlistSuccess === true
        ) {

            // console.log(this.props.campaignReducer.datashaperlist, "datashaperlist")
            const recipeList = [];
            this.props.campaignReducer.datashaperlist && this.props.campaignReducer.datashaperlist.length > 0 && this.props.campaignReducer.datashaperlist.map((item, index) => {
                if (item.Name) {
                    recipeList.push(
                        item.Name
                        // value: item.Name,
                        // label: item.Name
                    );
                }
                return true;
            })
            this.setState({ recipeList: recipeList })


        }

        if (
            this.props.campaignReducer &&
            this.props.campaignReducer.datasetlist &&
            this.props.campaignReducer.datasetlist !==
            nextProps.campaignReducer.datasetlist &&
            this.props.campaignReducer.datasetlistSuccess === true
        ) {

            console.log(this.props.campaignReducer.datasetlist, "datashaperlist")
            const datasetList = [];
            this.props.campaignReducer.datasetlist && this.props.campaignReducer.datasetlist.length > 0 && this.props.campaignReducer.datasetlist.map((item, index) => {
                if (item.Name) {
                    datasetList.push(
                        item.Name
                        // value: item.Name,
                        // label: item.Name
                    );
                }
                return true;
            })
            this.setState({ datasetList: datasetList })


        }
    }


    validateCampaign() {
        // const errors = {};
        // const { fields } = this.state;

        // this.setState({ errors });
        // return formIsValid;
    }

    //customersetup insert
    netLine = () => {
        this.setState({ redirectpath: '/stepyield' })
        // if (this.validateCampaign()) {
        const userinfo = {
            customer_name: this.state.fields.customer_name,
            contact_name: this.state.fields.contact_name,
            mobile_no: this.state.fields.mobile_no,
            email: this.state.fields.email
        };
        // datashaperstep1
        // console.log(userinfo, "info")
        // this.props.customerInsert(userinfo);
        // }

    };
    render() {
        const { redirectpath } = this.state;
        // console.log(recipeList, "assssswwssssss")
        return (
            <div className="container-fluid">
                {redirectpath && redirectpath !== '' ? (
                    <Redirect to={redirectpath} />
                ) : null}
                <div className="row">
                    <div className="col-md-2 col-lg-2 d-md-block d-sm-none d-none menublock">
                        <NetlineNav />
                    </div>

                    <div className="col-md-10 header">
                        <Header customernotification={this.state.customernotification} />
                        <div className="row mt-3 maindiv">
                            <div className="col-md-12 col-12 col-sm-12">
                                <span className="campaignsetup">Netline</span>
                            </div>
                        </div>
                        <div className="row mt-3 maindiv1">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-none d-none mt-5">
                                <div className="progresslistcontainer">
                                    <div className="progresscontainer">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div
                                                    style={{ width: 250 }}
                                                    className="progressinnercontainer">
                                                    {/* <div style={{ width: 200, height: 200 }}>
                                                    <CircularProgressbar value={66} />
                                                </div> */}
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                        strokeWidth={3}
                                                        styles={buildStyles({
                                                            // Rotation of path and trail, in number of turns (0-1)
                                                            rotation: 0.120,

                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            // strokeLinecap: 'butt',

                                                            // Text size
                                                            textSize: '14px',


                                                            // How long animation takes to go from one percentage to another, in seconds
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `rgba(29, 65, 122,1)`,
                                                            textColor: '#1D417A',
                                                            trailColor: '#E6E6EA',
                                                            backgroundColor: '#1D417A',
                                                        })}
                                                    />
                                                    <span className="progressrange">63 out of 100 </span>
                                                    <span className="progressrange">items processed</span>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="row pt-5 pb-4">
                                                    <div className="col-md-4">
                                                        <span className="progressrange1">Accepted Items</span>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <ProgressBar
                                                            percent={60}
                                                            height = {'15.77px'}
                                                            text={`${60}%`}
                                                            filledBackground="linear-gradient(to right, #484870, #9191B7)"
                                                            unfilledBackground={'#E6E6EA'}
                                                        />
                                                        {/* <span className="progressrange">Accepted Items</span> */}
                                                    </div>
                                                </div>
                                                <div className="row pt-4 pb-4">
                                                    <div className="col-md-4">
                                                        <span className="progressrange1">Rejected Items</span>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <ProgressBar
                                                            percent={11}
                                                            height = {'15.77px'}
                                                            text={`${11}%`}
                                                            filledBackground="linear-gradient(to right, #484870, #9191B7)"
                                                            unfilledBackground={'#E6E6EA'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row  pt-4">
                                                    <div className="col-md-4">
                                                        <span className="progressrange1">Unsubmitted Items</span>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <ProgressBar
                                                            percent={23}
                                                            height = {'15.77px'}
                                                            text={`${23}%`}
                                                            filledBackground="linear-gradient(to right, #484870, #9191B7)"
                                                            unfilledBackground={'#E6E6EA'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignReducer: state.campaignReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        datashaperlist: userinfo => dispatch(datashaperlist(userinfo)),
        dataSetList: userinfo => dispatch(dataSetList(userinfo))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NetLineProgress);


